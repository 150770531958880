import {Report} from '../../../shared/models/report.model';
import {translateEnum} from '../../../shared/enum/translateEnum';
import {User} from '../../../shared/models/user.model';
import {Task} from '../../../shared/models/task.model';

export const download = (reports: Report[], users: User[]) => {
    const data = [];

    for (const report of reports) {
        const row = {
            [translateEnum.STOP_CODE_STATION]: report.stop.stop_code,
            [translateEnum.STOP_NAME]: report.stop.stop_name,
            [translateEnum.STOP_ADDRESS]: report.stop.stop_desc,
            [translateEnum.REPORT_PERFORMER]: report.userName(users),
            [translateEnum.ACTION_DATE]: report.userDate,
            [translateEnum.CONTROL]: report.last_audit ? translateEnum.YES : translateEnum.NO,
            [translateEnum.INSPECTOR_NAME]: report.last_audit ? report.last_audit.user : '',
            [translateEnum.CONTROL_DATE]: report.last_audit ? report.last_audit.date : '',
            [translateEnum.CONTROL_COMMENTS]: report.last_audit ? report.last_audit.comment : '',
            [translateEnum.TASKS]: report.getTasksString(),
        };

        data.push(row);
    }

    const header: string[] = [
        translateEnum.STOP_CODE_STATION,
        translateEnum.STOP_NAME,
        translateEnum.STOP_ADDRESS,
        translateEnum.REPORT_PERFORMER,
        translateEnum.ACTION_DATE,
        translateEnum.CONTROL,
        translateEnum.INSPECTOR_NAME,
        translateEnum.CONTROL_DATE,
        translateEnum.CONTROL_COMMENTS,
        translateEnum.TASKS
    ];

    return {header, data};
};
