import { TASK_STATUSES } from "../../shared/enum/task";
import { translateEnum } from "../../shared/enum/translateEnum";
import { Option } from "../interfaces/option";

export const taskStatuses: Option[] = [
  { id: TASK_STATUSES.PENDING, name: translateEnum.TASK_STATUS_PENDING },
  { id: TASK_STATUSES.DONE, name: translateEnum.TASK_STATUS_DONE },
  { id: TASK_STATUSES.APPROVE, name: translateEnum.TASK_STATUS_APPROVE },
];

export const taskColorStatuses: Option[] = [
  { id: TASK_STATUSES.PENDING, name: "gray" },
  { id: TASK_STATUSES.DONE, name: "blue" },
  { id: TASK_STATUSES.APPROVE, name: "yellow" },
];
