import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DefaultLayoutComponent} from './containers';
import {LoginComponent} from './views/login/login.component';
import {NeedAuthGuard} from './auth.guard';

export const routes: Routes = [
    {
        path: 'login',
        pathMatch: 'full',
        component: LoginComponent,
        data: {
            title: 'Login Page'
        }
    },
    {path: '', redirectTo: 'reports', pathMatch: 'full'},
    {
        path: '',
        component: DefaultLayoutComponent,
        children: [
            {
                path: 'reports',
                loadChildren: './views/reports/reports.module#ReportsModule',
                pathMatch: 'full',
            },
            {
                path: 'lines_csv_upload',
                loadChildren: './views/lines-csv-upload/lines-csv-upload.module#LinesCsvUploadModule',
                pathMatch: 'full',
            },
            {
                path: 'flag',
                loadChildren: './views/flag/flag.module#FlagModule',
                pathMatch: 'full',
            },
            {
                path: 'schedules',
                loadChildren: './views/schedules/schedules.module#SchedulesModule',
                pathMatch: 'full',
            },
            {
                path: 'upload_report',
                loadChildren: './views/uploads/uploads.module#UploadsModule',
                pathMatch: 'full',
            },
            {
                path: 'traffic_change',
                loadChildren: './views/traffic-change/traffic-change.module#UploadsModule',
                pathMatch: 'full',
            },  
            {
                path: 'image_analyzer',
                loadChildren: './views/image-analayzer/image-analayzer.module#StopsFileUploadModule',
                pathMatch: 'full',
            },    
            {
                path: 'images',
                loadChildren: './views/photos/photos.module#PhotosModule',
                pathMatch: 'full',
            },
            {
                path: 'users',
                loadChildren: './views/users/users.module#UsersModule',
                pathMatch: 'full',
            },
            {
                path: 'projects',
                loadChildren: './views/projects/projects.module#ProjectsModule',
                pathMatch: 'full',
            },
            {
                path: 'stops_file_upload',
                loadChildren: './views/stops-file-upload/stops-file-upload.module#StopsFileUploadModule',
                pathMatch: 'full',
            },
            {
                path: '**',
                loadChildren: './views/reports/reports.module#ReportsModule'
            }
        ],
        canActivate: [NeedAuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
