import {OnInit, OnDestroy, Input, Component} from '@angular/core';
import {Observable} from 'rxjs';
import {translateEnum} from './shared/enum/translateEnum';
import {getISO8601FromDate, getISO8601ToDate, top} from './global.component';

@Component({
    styles: [],
    template: "",
})
export class BaseModalComponent implements OnInit, OnDestroy {

    public getISO8601FromDate = getISO8601FromDate;
    public getISO8601ToDate = getISO8601ToDate;
    public top = top;

    readonly translateEnum = translateEnum;

    @Input() public modal: any;

    @Input() observable: Observable<any>; // notify on new data from OUTER -> to COMPONENT
    subscription: any;

    @Input() public observe: Function; // notify on finish action from COMPONENT -> to OUTER

    child: any;

    constructor() {
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal(modal = null) {
        if (modal) {
            modal.hide();
        } else {
            this.modal.hide();
        }
    }

    ngOnInit(): void {
        if (this.observable) {
            this.subscription = this.observable.subscribe((data) => this.openModal(data));
        }
    }

    openModal(data) {
        this.child.open(data);
    }

    ngOnDestroy() {
        if (this.observable && this.subscription) {
            this.subscription.unsubscribe();
        }
    }

}
