import {Component, Input} from '@angular/core';
import { translateEnum } from '../../../shared/enum/translateEnum';
import { FlagGeneralSetting, FlagGeneralStyle } from '../../../shared/interfaces/flag';
import { FlagPageLine } from '../../../shared/models/flag-page-line.model';
import { Line } from '../../../shared/models/line.model';
import flagStyle from '../flag-style';
import { ApiService } from '../../../core/service/api.service';

@Component({
    selector: 'flag-strip',
    templateUrl: 'flag-strip.component.html',
    styleUrls: ['flag-strip.component.css', '../../../app.component.css']
})
export class FlagStripComponent {

    readonly translateEnum: typeof translateEnum = translateEnum;
    public flagStyle = flagStyle;

    @Input() public zoom: number = 40;
    width: number = 1146.67087;
    height: number = 175.63465;

    line_sign: number = null;
    line_sign_loading: boolean = false;
    download_loading: boolean = false;
    line_sign_error: string | null = null;
    lines: Line[] = [];
    table: Line[] = [];
    template_key: string = 'flag-strip';
    file_names: string[] = [];
    lines_selected: Line[] = [];
    flagPageLines: FlagPageLine[] = [];
    line_dest: string = '';
    style: FlagGeneralStyle = {
        page_width: this.width,
        page_height: this.height,
        orientation: 'l',
    };

    constructor(private api: ApiService) {
        this.onCheckbox = this.onCheckbox.bind(this);
        this.onChangeLineDest = this.onChangeLineDest.bind(this);
        this.getSetting = this.getSetting.bind(this);
    }

    onCode() {
        if (this.line_sign_loading) {
            return;
        }

        const self = this;
        self.line_dest = '';
        self.flagPageLines = [];
        self.file_names = [];
        self.lines = [];
        self.lines_selected = [];
        self.line_sign_error = null;
        self.line_sign_loading = true;
        self.download_loading = false;

        this.api.getLinesByLineSign(this.line_sign).subscribe(
            async r => {
                if (r.length === 0) {
                    self.line_sign_error = translateEnum.NO_LINE;
                    self.line_sign_loading = false;
                    return;
                }

                // check for duplicates
                const uniques = r.reduce((acc, line) => {
                    if (!acc.has(line.line_dest)) {
                        acc.set(line.line_dest, line);
                    }
                    return acc;
                }, new Map<string, Line>());

                self.lines = Array.from(uniques.values());
                self.table = self.lines;
                self.line_sign_loading = false;
            },
            r => {
                console.log({r});
                self.line_sign_error = translateEnum.ERROR_MESSAGE.replace('[message]', r.error.message);
                self.line_sign_loading = false;
            });
    }

    onCheckbox(checked, item) {
        this.download_loading = true;

        if (checked) {
            this.lines_selected.push(item);
        } else {
            this.lines_selected = this.lines_selected.filter(line_selected => line_selected.line_dest !== item.line_dest);
        }

        const flagPageLines = [];
        const file_names = [];
        let line_sign, line_dest;

        for (const line_selected of this.lines_selected) {
            line_sign = line_selected.line_sign;
            line_dest = line_selected.line_dest;

            for (const lang of ['he', 'en']) {
                flagPageLines.push(new FlagPageLine().deserialize({
                    only_numbers: false,
                    line_signs: [line_sign],
                    line: line_selected,
                    lang: lang,
                    show_operator: false,
                }));
            }

            file_names.push(`${line_sign}_${line_dest}_ע`, `${line_sign}_${line_dest}_א`);
        }
        this.file_names = file_names;
        this.flagPageLines = flagPageLines;

        const self = this;
        setTimeout(function () {
            self.download_loading = false;
        }, 4000);
    }

    onChangeLineDest() {
        this.table = this.lines.filter((line: Line) => {
            return line.line_dest.indexOf(this.line_dest) > -1;
        });
        this.lines_selected = [];
        this.file_names = [];
        this.flagPageLines = [];
    }

    getSetting(): FlagGeneralSetting {
        return {
            key: this.template_key,
            zip_name: this.line_sign.toString(),
            file_names: this.file_names,
            style: this.style,
        };
    }

}
