import {Shift} from '../../../shared/models/shift.model';
import {translateEnum} from '../../../shared/enum/translateEnum';

export const download = (trafficChanges: any,exp) => {
    const data = [];
    let header: string[] = [];
    debugger;
    let max=0;
 
     

   let go=true;
   let index=0;
    while(go)
    {
    if(trafficChanges.brails.length>index ||  trafficChanges.flags.length>index|| trafficChanges.head_stop.length>index ||
        trafficChanges.others.length>index || trafficChanges.strips.length>index )
        {
            let row = {
                ['ברייל']: trafficChanges.brails[index]!=undefined?trafficChanges.brails[index].to_do:'',
                ['כמות']: trafficChanges.brails[index]!=undefined?trafficChanges.brails[index].count:'',
                [' ']: '  ',
                ['סטריפ']: trafficChanges.strips[index]!=undefined?trafficChanges.strips[index].to_do :'',
                [' כמות']: trafficChanges.strips[index]!=undefined?trafficChanges.strips[index].count :'',
                ['  ']: '  ',
                ['דגל']: trafficChanges.flags[index]!=undefined?trafficChanges.flags[index].to_do:'',
                ['כמות ']: trafficChanges.flags[index]!=undefined?trafficChanges.flags[index].count:'',
                ['   ']: '  ',
                ['ראש תחנה']: trafficChanges.head_stop[index]!=undefined?trafficChanges.head_stop[index].to_do:'',
                ['הערות']: trafficChanges.head_stop[index]!=undefined?trafficChanges.head_stop[index].comment:'',
                ['  כמות']: trafficChanges.head_stop[index]!=undefined?trafficChanges.head_stop[index].count:'',
                ['    ']: '  ',
                ['אחר']:trafficChanges.others[index]!=undefined?trafficChanges.others[index].to_do:''
            }
            data.push(row);
            index++;
        }
        else
            go=false;
    }
 


    return {header, data};
};
