import {Option} from '../../shared/interfaces/option';
import {translateEnum} from '../../shared/enum/translateEnum';
import {NEXT_BUS_STATUSES} from '../../shared/enum/next_bus';

export const nextBusStatuses: Option[] = [
    {id: NEXT_BUS_STATUSES.VALID, name: translateEnum.STATUS_VALID},
    {id: NEXT_BUS_STATUSES.NOT_EXIST, name: translateEnum.STATUS_NOT_EXIST},
    {id: NEXT_BUS_STATUSES.INVALID, name: translateEnum.STATUS_INVALID}
];

export const nextBusSets: Set<number>[] = [
    new Set<number>([NEXT_BUS_STATUSES.VALID]),
    new Set<number>([NEXT_BUS_STATUSES.NOT_EXIST, NEXT_BUS_STATUSES.INVALID])
];
