import {Deserializable} from './deserializable.model';
import {getDateTime} from '../../../app/global.component';

export class Comment implements Deserializable {

    date: string;
    datetime: string;
    report: string;
    user: string;
    text: string;

    deserialize(input: any) {
        Object.assign(this, input);
        this.datetime = getDateTime(input.date);
        return this;
    }

    userName(users) {
        const user = users.find(item => item.id === this.user);
        return user ? user.name : '';
    }
}

