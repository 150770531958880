import {Deserializable} from './deserializable.model';
import {getDateTime} from '../../../app/global.component';

export class LineLog implements Deserializable {

    id: string;
    user: string;
    created_at: string;
    date: string;
    file: string;

    deserialize(input: any) {
        Object.assign(this, input);
        this.date = getDateTime(input.created_at);
        return this;
    }

}
