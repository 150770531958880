import { AgmMap, MapsAPILoader } from "@agm/core";
import { Component, Input, NgZone, OnInit, ViewChild } from "@angular/core";

declare var google: any;

interface Marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}

interface Location {
  lat: number;
  lng: number;
  viewport?: Object;
  zoom: number;
  address_level_1?: string;
  address_level_2?: string;
  address_country?: string;
  address_zip?: string;
  address_state?: string;
  marker?: Marker;
}

@Component({
  selector: "app-map",
  templateUrl: "map.component.html",
  styleUrls: ["map.component.css"],
})
export class MapComponent implements OnInit {
  geocoder: any;

  @Input()
  public lat: number = null;
  @Input()
  public lng: number = null;
  @Input()
  public userLat: number = null;
  @Input()
  public userLng: number = null;

  public location: Location;
  public userLocation: Location;

  @ViewChild(AgmMap, { static: false }) map: AgmMap;

  zoom: number = 16;
  user_icon: string = "assets/img/png/icons8-boy-48.png?raw=true";

  constructor(public mapsApiLoader: MapsAPILoader, private zone: NgZone) {
    this.mapsApiLoader = mapsApiLoader;
    this.zone = zone;
    this.mapsApiLoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
    });
  }

  ngOnInit() {
    this.setLocation();
    this.location.marker.draggable = true;

    if (this.userLat && this.userLng) {
      this.userLocation = {
        lat: this.userLat,
        lng: this.userLng,
        marker: {
          lat: this.userLat,
          lng: this.userLng,
          draggable: true,
        },
        zoom: this.zoom,
      };

      this.userLocation.marker.draggable = true;
    }
  }

  ngOnChanges(changes): void {
    if (
      (changes.lat && changes.lat.currentValue) ||
      (changes.lng && changes.lng.currentValue)
    ) {
      this.lat = changes.lat.currentValue;
      this.lng = changes.lng.currentValue;
      this.setLocation();
    }
  }

  setLocation() {
    this.location = {
      lat: this.lat,
      lng: this.lng,
      marker: {
        lat: this.lat,
        lng: this.lng,
        draggable: true,
      },
      zoom: this.zoom,
    };
  }
}
