import { Component, Input, OnInit } from '@angular/core';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { BaseComponent } from '../../../base.component';
import { translateEnum } from '../../../shared/enum/translateEnum';
import { Photo } from '../../../shared/models/photo.model';

@Component({
    selector: 'images',
    templateUrl: 'images.component.html',
    styleUrls: ['../../../app.component.css', 'images.component.css'],
    providers: [
        {provide: CarouselConfig}
    ]
})
export class ImagesComponent extends BaseComponent implements OnInit {

    translateEnum = translateEnum;
    photos_table: Photo[][] = [];

    @Input() photos: Photo[] = [];
    @Input() loading: boolean = false;
    @Input() onSelected: Function;

    @Input()
    set setPhotos(photos: any) {
        this.photos = photos;
        this.updateTable();
    }

    @Input()
    public changePage: Function;

    @Input()
    public totalRows: number = 0;

    @Input()
    public page: number = 1;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.updateTable = this.updateTable.bind(this);
        this.onCheckbox = this.onCheckbox.bind(this);
    }

    updateTable() {
        const result = [];
        let row = [];

        if (this.photos) {

            for (let j = 0; j < this.photos.length; j++) {

                if (j % 2 === 0) {
                    row.push(this.photos[j]);
                } else {
                    row.push(this.photos[j]);
                    result.push(row);
                    row = [];
                }
            }
        }

        if (row.length > 0) {
            result.push(row);
        }

        this.photos_table = result;
    }

    download(photo) {
        return () => new Promise(() => {
            photo.downloadPhoto();
        });
    }

    onCheckbox(checked: boolean, item: Photo) {
        item.selected = checked;
        if (this.onSelected) {
            this.onSelected();
        }
    }

}
