import { Component, Input } from '@angular/core';
import { ApiService } from '../../../../core/service/api.service';
import { translateEnum } from '../../../../shared/enum/translateEnum';
import { Report } from '../../../../shared/models/report.model';

@Component({
    selector: 'audit-button',
    templateUrl: 'audit-button.component.html',
    styleUrls: ['audit-button.component.css', '../../../../app.component.css']
})

export class AuditButtonComponent {

    readonly translateEnum = translateEnum;

    @Input() public report: Report = null;
    @Input() public show: boolean = false;

    public loading: boolean = false;
    public error: string = null;
    public comment: string = '';

    constructor(private api: ApiService) {
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        if (this.loading) {
            return;
        }

        this.loading = true;
        this.error = null;

        const self = this;

        this.comment = this.comment.trim();
        const data = {
            comment: this.comment.length ? this.comment : undefined,
        };

        this.api.postAudit(this.report.id, data)
            .subscribe(
                r => {
                    self.comment = '';
                    self.report.getAuditsByReport();
                },
                r => {
                    self.error = r.message;
                },
                () => {
                    self.loading = false;
                    document.getElementById('audit-button').click();
                });
    }

    keyUp() {
        this.comment = this.comment.replace(/[\r\n]+/, '');
    }

}
