import {Component, Input} from '@angular/core';
import {translateEnum} from '../../../shared/enum/translateEnum';
import {Upload} from '../../../shared/interfaces/upload';
import {Header} from '../../../shared/interfaces/header';
import {chunk} from '../../../../app/global.component';
import {UploadXlsx} from '../../../shared/interfaces/upload';
import {FlagBrailStyle} from '../../../shared/interfaces/flag';

@Component({
    selector: 'flag-brail',
    templateUrl: 'flag-brail.component.html',
    styleUrls: ['flag-brail.component.css', '../../../app.component.css']
})

export class FlagBrailComponent {

    readonly translateEnum = translateEnum;

    @Input() public zoom: number = 40;
    @Input() public show: boolean = false;

    template_key: string = 'flag-brail';
    doc: Upload[] = [];
    header: Header[] = [];
    codes: string[][] = [];
    upload_error: string = null;
    cm_to_pt: number = 28.3465;
    row_count: number = 14;
    column_count: number = 12;

    page_count: number = this.row_count * this.column_count;
    page_width: number = 300 * this.cm_to_pt * 2;
    //page_width: number = 8503.93701 * 2;
    page_height: number = 150 * this.cm_to_pt * 2;
    //page_height: number = 4251.968505 * 2;

    file_names: string[] = [];
    style: FlagBrailStyle = {
        row_count: this.row_count,
        column_count: this.column_count,
        page_width: this.page_width,
        page_height: this.page_height,
        orientation: 'l',
        //cell_margin: 25.5118 * 2,
        cell_margin: 0.9 * this.cm_to_pt * 2,

        cell_height_margin: 1.75 * this.cm_to_pt,
        cell_width_margin: 2.15 * this.cm_to_pt,

        //cell_width: 521.5748 * 2,
        cell_width:  18.45 * this.cm_to_pt * 2,
        //cell_height: 294.8031 * 2,
        cell_height: 10.45 * this.cm_to_pt * 2,
        //font_height: 212.5984253 * 2,
        font_height: 7.5 * this.cm_to_pt * 2,
        //dot_width: 4.5184252 * 2,
        dot_width: 0.16 * this.cm_to_pt * 2,
        //dot_height: 4.94929134 * 2,
        dot_height: 0.175 * this.cm_to_pt * 2,
        //dot_margin_width: 2.08062992 * 2,
        dot_margin_width: 0.073 * this.cm_to_pt * 2,
        //dot_margin_height: 1.58740155 * 2,
        dot_margin_height: 0.055 * this.cm_to_pt * 2,

        dot_frame_size: 0.6 * this.cm_to_pt * 2,

        dot_frame_top_left_width: 1.845 * this.cm_to_pt * 2,
        dot_frame_top_left_height: 1.48 * this.cm_to_pt * 2,

        dot_frame_top_right_width: 297.555 * this.cm_to_pt * 2,
        dot_frame_top_right_height: 1.48 * this.cm_to_pt * 2,

        dot_frame_bottom_left_width: 1.845 * this.cm_to_pt * 2,
        dot_frame_bottom_left_height: 147.92 * this.cm_to_pt * 2,

        dot_frame_bottom_right_width: 297.555 * this.cm_to_pt * 2,
        dot_frame_bottom_right_height: 147.92 * this.cm_to_pt * 2,
    };

    constructor() {
        this.getSetting = this.getSetting.bind(this);
    }

    // handle upload file data
    onUpload({header, doc, file_name}: UploadXlsx) {
        this.codes = [];
        this.header = header.map((key: string) => {
            return {title: key, key};
        });
        this.doc = doc;
        this.upload_error = !doc.length && this.translateEnum.EMPTY_FILE;
        let codes = this.doc.reduce((acc1, row) => {
            const cells: string[] = Object.values(row);
            return acc1.concat(
                cells.reduce((acc2, cell) => {
                    let regex = cell.match(/^[^123456א7890]*/);
                    let val = null;
                    if (regex && !!regex[0]) {
                        regex = cell.match(/\|(.*)\|/);
                        if (regex && regex.length > 1 && !!regex[1]) {
                            val = regex[1];
                        }
                    } else {
                        val = cell;
                    }
                    val = val && val.trim();
                    if (val) {
                        if (val[val.length - 1] === 'א') {
                            val = val[val.length - 1] + val.substr(0, val.length - 1);
                        }
                        if (val.length < 4) {
                            acc2.push(val);
                        }
                    }
                    return acc2;
                }, []));
        }, []);

        codes.sort(function (string1, string2) {
            const num1 = +string1.replace(/[^0-9.]/g, '');
            const num2 = +string2.replace(/[^0-9.]/g, '');
            return num1 === num2 ? string1.length - string2.length : num1 - num2;
        });

        codes = chunk(codes, this.page_count);
        codes = codes.map(page => {
            let rows = chunk(page, this.row_count);
            if (rows[rows.length - 1].length < this.row_count) {
                rows[rows.length - 1] = rows[rows.length - 1]
                    .concat(new Array(this.row_count - rows[rows.length - 1].length).fill(null));
            }
            if (rows.length < this.column_count) {
                const empty = new Array(this.row_count).fill(null);
                rows = rows.concat(new Array(this.column_count - rows.length).fill(empty));
            }
            return rows;
        });
        this.codes = codes;
        const name = file_name.substr(0, file_name.lastIndexOf('.'));
        this.file_names = codes.map((page, index) => `${name}${codes.length === 1 ? '' : (index + 1)}.pdf`);
    }

    getSetting(index: number) {
        return () => ({
            key: `${this.template_key}${index + 1}`,
            file_name: this.file_names[index],
            style: this.style,
        });
    }

}
