import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { translateEnum  } from '../../../shared/enum/translateEnum';
import { Observable } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'warning',
  templateUrl: 'warning.component.html',
  styleUrls: ['../../../app.component.css','./warning.component.css'],
})

export class WarningComponent implements OnInit, OnDestroy{

  readonly translateEnum : typeof translateEnum = translateEnum ;
  disable: boolean;
  error: string;

  @Input()
  public warningModal: any;

  @Input()
  public confirm: string;

  @Input()
  public header: string;

  @Input()
  public content: string;

  @Input()
  public data: any;

  @Input()
  public action: Function;

  @Input() onSuccess: Observable<void>;
  successSubscription: any;

  @Input() onError: Observable<void>;
  errorSubscription: any;

  @Input() public showInput: boolean = false;
  @Input() public placeholderInput: string | null = null;
  @Input() public validateInput: Function | null = null;
  public valueInput = new FormControl('', [Validators.required]);

  constructor() {
    this.close = this.close.bind(this);
    this.reset();
  }

  ngOnInit() {
    if (this.onSuccess) {
      this.successSubscription = this.onSuccess.subscribe(() => this.successAction());
    }
    if (this.onError) {
      this.errorSubscription = this.onError.subscribe((response) => this.errorAction(response));
    }
  }

  close() {
    this.warningModal.hide();
    this.reset();
  }

  reset() {
    this.disable = false;
    this.error = null;
    this.valueInput.reset();
  }

  onPaste(event) {
    event.preventDefault();
  }

  request() {
    if (this.disable)
      return;

    if (this.validateInput) {
      let errors = this.valueInput.errors;
      if (errors && errors.required) {
        return;
      }

      let validateInput = this.validateInput(this.valueInput.value.trim());
      if (validateInput) {
        this.valueInput.setErrors({
          ...errors,
          ...{custom: validateInput}
        });
        return;
      }
    }

    this.error = null;
    this.disable = true;
    this.action(this.data);
  }

  successAction() {
    this.close();
  }

  errorAction(error) {
    this.disable = false;
    this.error = error;
  }

  ngOnDestroy() {
    if (this.onSuccess && this.successSubscription) {
      this.successSubscription.unsubscribe();
    }
    if (this.onError && this.errorSubscription) {
      this.errorSubscription.unsubscribe();
    }
  }

}
