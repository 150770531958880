import {Component, Input} from '@angular/core';

@Component({
    selector: 'off',
    templateUrl: 'off.component.html',
    styleUrls: ['../chars.component.css'],
})
export class OffComponent {
    @Input() public show: boolean = false;
    @Input() public color: string = 'black';
}
