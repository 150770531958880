import {Deserializable} from './deserializable.model';
import {Task} from './task.model';

export class Tasks implements Deserializable {

    deserialize(input: any) {
        this.setTasks(input);
        return this;
    }

    setTasks(tasks) {
        if (tasks) {
            Object.assign(this, Object.keys(tasks).reduce(
                (acc: {}, stop_code: string) => {
                    acc[stop_code] = tasks[stop_code].map(task => new Task().deserialize(task));
                    return acc;
                }, {}));
        } else {
            Object.assign({});
        }
    }

    getTasks(with_status: boolean, stop_codes: number[] = null) {
        return this.getStopCodes().reduce((acc: {}, stop_code: number) => {
            if (!stop_codes || stop_codes.indexOf(stop_code) > -1) {
                if (with_status) {
                    acc[stop_code] = this[stop_code].map((task: Task) => task.getTask());
                } else {
                    acc[stop_code] = this[stop_code].map((task: Task) => task.to_do);
                }
            }
            return acc;
        }, {});
    }

    getStopCodes() {
        return Object.keys(this).map((stop_code: string) => +stop_code);
    }

    getStopTasks(stop_code) {
        return this[stop_code.toString()] || [];
    }

    setStopTasks(stop_code: number, tasks: Task[]) {
        this[stop_code] = tasks;
    }

    deleteStopTasks(stop_code) {
        delete this[stop_code];
    }

    copyTasks() {
        const tasks = this.getTasks(true);
        return new Tasks().deserialize(tasks);
    }
}
