import { Component, Input } from '@angular/core';
import { ReportPdfService } from '../../../../core/service/report-pdf/report-pdf.service';
import { translateEnum } from '../../../../shared/enum/translateEnum';
import { Report } from '../../../../shared/models/report.model';
import { User } from '../../../../shared/models/user.model';

@Component({
    selector: 'pdf-button',
    templateUrl: 'pdf-button.component.html',
    styleUrls: ['../../../../app.component.css']
})

export class PdfButtonComponent {
    readonly translateEnum: typeof translateEnum = translateEnum;

    @Input() public users: User[] = [];
    @Input() public report: Report = null;
    @Input() public show: boolean = false;

    public loading: boolean = false;
    public error: string = null;

    constructor() {
        this.onClick = this.onClick.bind(this);
    }

    async onClick() {
        this.loading = true;
        try {
            const reportPdfService = new ReportPdfService(this.report, this.users);
            await reportPdfService.download();
        } catch (e) {
            console.log(e);
        } finally {
            this.loading = false;
        }
    }
}
