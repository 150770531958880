import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { UsersService } from '../../../../core/service/users.service';
import { UserService } from '../../../../core/service/user.service';
import { ExportsService } from '../../../../core/service/exports.service';
import { download } from '../../../../core/service/download/download.service';
import { ApiService } from '../../../../core/service/api.service';
import { User } from '../../../../shared/models/user.model';
import { Export } from '../../../../shared/models/export.model';
import { translateEnum } from '../../../../shared/enum/translateEnum';
import { EXPORT_TYPE } from '../../../../shared/enum/export';

@Component({
    selector: 'export-all',
    templateUrl: 'export-all.component.html',
    styleUrls: ['export-all.component.css', '../export.component.css', '../../../../app.component.css'],
})

export class ExportAllComponent implements OnInit {

    @Input() public create: Function;
    @Input() public edit: Function;
    @Input() public close: Function;
    @Input() public getFields: Function;
    @Input() public type: EXPORT_TYPE;

    disabled: boolean = false;
    users: User[];
    exports: Export[] = [];
    error: string = null;
    usersSubject: Subject<User[]> = new Subject<User[]>();
    exportsSubject: Subject<Export[]> = new Subject<Export[]>();
    translateEnum = translateEnum;

    constructor(private api: ApiService,
                private usersService: UsersService,
                public userService: UserService,
                private exportsService: ExportsService) {
        this.delete = this.delete.bind(this);
        this.getExports = this.getExports.bind(this);
        this.getUsers();
    }

    ngOnInit(): void {
        this.getExports();
    }

    // get all users from users service
    getUsers() {
        this.users = [];
        if (this.usersService) {
            this.usersService.getUsers(false).subscribe(users => {
                this.users = users;
                this.usersSubject.next(users);
            });
            this.usersService.refresh();
        }
    }

    // get all exports from exports service
    getExports() {
        this.exports = [];
        if (this.exportsService) {
            this.exportsService.getExports(false).subscribe(exports => {
                this.exports = exports.filter(exp => exp.type === this.type);
                this.exportsSubject.next(exports);
            });
            this.exportsService.refresh();
        }
    }

    download(exp: Export) {
        debugger;
      
            if (this.disabled) {
                return;
            }
            this.disabled = true;
            exp.setLoading(true);

            const fields = this.getFields();
            download(this.api, this.users, exp, fields);

            this.disabled = false;
            exp.setLoading(false);

            debugger;
      
    }

    delete(exp: Export) {
        const self = this;
        return () => new Promise(() => {
            exp.setLoading(true);
            self.api.deleteExport(exp.id)
                .subscribe(
                    r => {
                        self.exportsService.getExports(true);
                    },
                    r => {
                        console.log(r.error);
                        self.error = r.error.message;
                    });
        });
    }

}
