import {Component, Input} from '@angular/core';
import {FLAG_PDF_TYPE} from '../../../../shared/enum/flag';

@Component({
    selector: 'flag-brail-header',
    templateUrl: 'flag-brail-header.component.html',
    styleUrls: ['flag-brail-header.component.css', '../../../../app.component.css']
})

export class FlagBrailHeaderComponent {

    readonly FLAG_PDF_TYPE = FLAG_PDF_TYPE;

    @Input() file_name: string;
    @Input() disabled: boolean;
    @Input() getSetting: Function;

}
