import {translateEnum} from '../../../shared/enum/translateEnum';
import {User} from '../../../shared/models/user.model';
import {Report} from '../../../shared/models/report.model';

export const download = (reports: Report[], users: User[]) => {
    const data = [];
    let row;

    for (const report of reports) {
        for (const comment of report.comments) {
            row = {
                [translateEnum.STOP_CODE]: report.stop.stop_code,
                [translateEnum.COMMENT_DATE]: comment.datetime,
                [translateEnum.USER_NAME]: comment.userName(users),
                [translateEnum.COMMENT]: comment.text,
            };
            data.push(row);
        }
    }

    const header: string[] = [
        translateEnum.STOP_CODE,
        translateEnum.COMMENT_DATE,
        translateEnum.USER_NAME,
        translateEnum.COMMENT
    ];

    return {header, data};
};
