import {Component, Input} from '@angular/core';
import {isNumeric} from '../../../../../app/global.component';
import {FlagBrailStyle} from '../../../../shared/interfaces/flag';

@Component({
    selector: 'flag-brail-cell',
    templateUrl: 'flag-brail-cell.component.html',
    styleUrls: ['flag-brail-cell.component.css', '../../../../app.component.css']
})

export class FlagBrailCellComponent {

    @Input() code: string = null;
    @Input() style: FlagBrailStyle;
    letters: string[] = [];
    dots: string[] = [];

    ngOnChanges(changes): void {
        if (changes.code && changes.code.currentValue) {
            this.letters = (changes.code.currentValue || '').split('');
            let first_number = false;
            const dots = [];
            for (const letter of this.letters) {
                if (!first_number && isNumeric(letter)) {
                    first_number = true;
                    dots.push('#');
                }
                dots.push(letter);
            }
            this.dots = dots;
        }
    }
}
