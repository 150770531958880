import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { NativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatTabsModule } from "@angular/material/tabs";
import { IvyCarouselModule } from "angular-responsive-carousel";
import { AlertModule } from "ngx-bootstrap/alert";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { BaseModule } from "../base/base.module";
import { ReportsModule } from "../reports/reports.module";
import { BaseComponent } from "./base.component";
import { FlagBrailCellComponent } from "./flag-brail/flag-brail-cell/flag-brail-cell.component";
import { FlagBrailDotComponent } from "./flag-brail/flag-brail-dot/flag-brail-dot.component";
import { FlagBrailDotsComponent } from "./flag-brail/flag-brail-dots/flag-brail-dots.component";
import { FlagBrailHeaderComponent } from "./flag-brail/flag-brail-header/flag-brail-header.component";
import { FlagBrailComponent } from "./flag-brail/flag-brail.component";
import { FlagHeadComponent } from "./flag-head/flag-head.component";
import { FlagLineComponent } from "./flag-line/flag-line.component";
import { FlagPageComponent } from "./flag-page/flag-page.component";
import { FlagPdfButtonComponent } from "./flag-pdf-button/flag-pdf-button.component";
import { FlagRoutingModule } from "./flag-routing.module";
import { FlagStopComponent } from "./flag-stop/flag-stop.component";
import { FlagStripLineComponent } from "./flag-strip/flag-strip-line/flag-strip-line.component";
import { FlagStripComponent } from "./flag-strip/flag-strip.component";
import { FlagComponent } from "./flag.component";

@NgModule({
  imports: [
    FormsModule,
    FlagRoutingModule,
    BsDropdownModule,
    BsDatepickerModule.forRoot(),
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    AlertModule.forRoot(),
    MatDatepickerModule,
    NativeDateModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatGridListModule,
    IvyCarouselModule,
    ReportsModule,
    MatExpansionModule,
    BaseModule,
  ],
  declarations: [
    BaseComponent,
    FlagComponent,
    FlagLineComponent,
    FlagStopComponent,
    FlagStripComponent,
    FlagStripLineComponent,
    FlagHeadComponent,
    FlagPageComponent,
    FlagPdfButtonComponent,
    FlagBrailComponent,
    FlagBrailCellComponent,
    FlagBrailDotsComponent,
    FlagBrailDotComponent,
    FlagBrailHeaderComponent,
  ],
  bootstrap: [],
  exports: [
    FlagComponent
  ]
})
export class FlagModule {}
