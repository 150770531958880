import {Deserializable} from './deserializable.model';
import {translateEnum} from '../../shared/enum/translateEnum';
import {Task} from './task.model';
import {TASK_STATUSES} from '../../shared/enum/task';
import {TaskInput} from '../../shared/interfaces/task';

export class Stop implements Deserializable {

    readonly translateEnum: typeof translateEnum = translateEnum;
    id: number = 0;
    stop_code: number = 0;
    stop_desc: string = '';
    stop_lat: number = 0;
    stop_lon: number = 0;
    stop_name: string = '';
    comment: string = '';
    name: string = '';
    option_name: string = '';
    is_reported: boolean = false;
    street: string = '';
    city: string = '';
    station: string = '';
    floor: string = '';
    address: string = '';
    tasks: Task[] = [];
    operatorInCharge: string=''
    areas:string ='';
    stops:any;

    deserialize(input: any) {
        Object.assign(this, input);
        this.name = this.stop_code.toString();
        if (this.stop_code) {
            this.option_name = this.stop_code + ' - ' + this.stop_name;
        }
        this.is_reported = false;
        this.street = this.getStreet();
        this.city = this.getCity();
        this.station = this.getStation();
        this.floor = this.getFloor();
        this.address = this.getAddress();
        return this;
    }

    getAddress() {
        const result = [];

        if (this.street.length > 0) {
            result.push('רחוב: ' + this.street);
        }
        if (this.city.length > 0) {
            result.push('עיר: ' + this.city);
        }
        if (this.station.length > 0) {
            result.push('רציף: ' + this.station);
        }
        if (this.floor.length > 0) {
            result.push('קומה: ' + this.floor);
        }

        if (result.length === 0 && this.stop_desc.length > 0) {
            return this.stop_desc;
        }

        return result.join(', ');
    }

    getStreet() {
        if (this.stop_desc.length) {
            const from = this.stop_desc.indexOf('רחוב:');
            const to = this.stop_desc.indexOf('עיר:');
            if (from > -1 && to > -1) {
                return (this.stop_desc.substring(from + 5, to)).trim();
            }
        }
        return '';
    }

    getCity() {
        if (this.stop_desc.length) {
            const from = this.stop_desc.indexOf('עיר:');
            const to = this.stop_desc.indexOf('רציף:');
            if (from > -1 && to > -1) {
                return (this.stop_desc.substring(from + 4, to)).trim();
            }
        }
        return '';
    }

    getStation() {
        if (this.stop_desc.length) {
            const from = this.stop_desc.indexOf('רציף:');
            const to = this.stop_desc.indexOf('קומה:');
            if (from > -1 && to > -1) {
                return (this.stop_desc.substring(from + 5, to)).trim();
            }
        }
        return '';
    }

    getFloor() {
        if (this.stop_desc.length) {
            const from = this.stop_desc.indexOf('קומה:');
            if (from > -1) {
                return (this.stop_desc.substring(from + 5)).trim();
            }
        }
        return '';
    }

    setComment(comment) {
        this.comment = comment;
    }

    setTasks(tasks: Task[]) {
        this.tasks = tasks;
    }

    getTasks(with_status: boolean): TaskInput[] | string[] {
        if (with_status) {
            return this.getTasksWithStatus();
        }
        return this.getTasksWithoutStatus();
    }

    getTasksWithStatus(): TaskInput[] {
        return this.tasks.map((task: Task) => task.getTask());
    }

    getTasksWithoutStatus(): string[] {
        return this.tasks.map((task: Task) => task.to_do);
    }

    copyTasks(): Task[] {
        const stop_tasks: TaskInput[] = this.getTasksWithStatus();
        return stop_tasks.map((task: TaskInput) => new Task().deserialize(task));
    }

    setReported(status) {
        this.is_reported = status;
    }

    stopLocation() {
        if (this.stop_lon && this.stop_lat) {
            return [this.stop_lat, this.stop_lon].join(' , ');
        }
        return '';
    }

}
