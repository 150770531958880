import { Component, Input } from '@angular/core';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { translateEnum } from '../../../../shared/enum/translateEnum';
import { Report } from '../../../../shared/models/report.model';
import { User } from '../../../../shared/models/user.model';

@Component({
    selector: 'view-form',
    templateUrl: 'view-form.component.html',
    styleUrls: ['./view-form.component.css', '../../../../app.component.css'],
    providers: [{provide: CarouselConfig}]
})

export class ViewFormComponent {

    readonly translateEnum = translateEnum;

    @Input() public report: Report;
    @Input() public users: User[] = [];

    constructor() {
        debugger;
    }

    ngOnChanges(changes): void {
        if (changes.report && changes.report.currentValue) {
            this.report = changes.report.currentValue;
        }
        if (changes.users && changes.users.currentValue) {
            this.users = changes.users.currentValue;
        }
    }

    showMap() {
        return this.report && this.report.stop && this.report.stop.stop_lat && this.report.stop.stop_lon;
    }
}
