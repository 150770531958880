import { Component, Input } from '@angular/core';
import { Shift } from '../../../../../app/shared/models/shift.model';
import { ApiService } from '../../../../core/service/api.service';
import { translateEnum } from '../../../../shared/enum/translateEnum';
import { Report } from '../../../../shared/models/report.model';

@Component({
    selector: 'task-button',
    templateUrl: 'task-button.component.html',
    styleUrls: ['task-button.component.css', '../../../../app.component.css']
})

export class TaskButtonComponent {

    readonly translateEnum = translateEnum;

    @Input() public report: Report = null;
    @Input() public show: boolean = false;

    public loading: boolean = false;
    public error: string = null;
    public comment: any = '';
    public options:any=[];
    public filteredOptions:any=[];
    public index:number;

    constructor(private api: ApiService) {

        this.GetAllShifts();

        this.onClick = this.onClick.bind(this);
    }

    async GetAllShifts()
    {
      //  this.options = await (this.api.getAllShifts().toPromise());
      //  for(var i=0;i< this.options.length;i++)
      //  {
      //      this.filteredOptions.push(this.options[i].name);
      //  }

    }

    onClick() {
        debugger;
        if (this.loading) {
            return;
        }

        this.loading = true;
        this.error = null;

        const self = this;

        this.comment = this.comment.trim();
        debugger;
        const data = {
            comment: this.comment ,
            shift: this.index>-1?this.options[this.index].id:undefined,
            report: this.report.id,
            stop_code: this.report.stop.stop_code,
            name:JSON.parse(localStorage.USER).first_name +' '+ JSON.parse(localStorage.USER).last_name,
            projectId:localStorage.projectId
        };

        this.api.postAddShift( data)
            .subscribe(
                r => {
                    debugger;
                    alert('פעולה לביצוע התווספה')
                },
                r => {
                    alert('פעולה לביצוע התווספה')

            this.loading=false;
                },
                () => {
                    self.loading = false;
                    document.getElementById('task-button').click();
                });
    }

    keyUp(val) {
        let start=0;
        let end=0;
        let text=val;
        this.index=-1;
        if(val.key!="Backspace" && val.key!="Alt" && val.key!="Ctrl" && val.key!="Shift" && val.key.length>0)
        {
         //   this.comment = this.comment.replace(/[\r\n]+/, '');
            for(var i=0;i<this.filteredOptions.length;i++)
            {
                if(this.filteredOptions[i].startsWith(this.comment))
                {
                    this.index=i;
                    start = this.comment.length;
                    text = this.filteredOptions[i];
                    end  =this.filteredOptions[i].length;
                    (document.getElementById('textarea1') as HTMLTextAreaElement).value = text;
                    (document.getElementById('textarea1') as HTMLTextAreaElement).setSelectionRange(start,end);
                    this.comment=text;
                    break;
                }
            }
          
        }
       

    }

}
