import {Shift} from '../../../shared/models/shift.model';
import {translateEnum} from '../../../shared/enum/translateEnum';

export const download = (shifts: Shift[]) => {
    const data = [];

    for (const shift of shifts) {
        const row = {
            [translateEnum.PROJECT_NAME]: shift.name,
            [translateEnum.ENTER_DATE]: shift.enter_date,
            [translateEnum.ACTION_DATE]: shift.action_range,
            [translateEnum.TOTAL_STOPS_COUNT]: shift.total_stops_count,
            [translateEnum.COMPLETED_STOPS_COUNT]: shift.completed_stops_count,
        };

        data.push(row);
    }

    const header: string[] = [
        translateEnum.PROJECT_NAME,
        translateEnum.ENTER_DATE,
        translateEnum.ACTION_DATE,
        translateEnum.TOTAL_STOPS_COUNT,
        translateEnum.COMPLETED_STOPS_COUNT
    ];

    return {header, data};
};
