import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { translateEnum } from '../../../../shared/enum/translateEnum';
import { HeaderKey } from '../../../../shared/interfaces/header';
import { Upload, UploadXlsx } from '../../../../shared/interfaces/upload';
import { Observable } from 'rxjs';
import * as XLSX from 'xlsx';

@Component({
    selector: 'upload-xlsx',
    templateUrl: '../upload.component.html',
    styleUrls: ['../upload.component.css'],
})
export class UploadXlsxComponent implements OnInit {

    readonly translateEnum: typeof translateEnum = translateEnum;
    @Input() elementId: string;
    @Output() result = new EventEmitter<UploadXlsx>();
    @Input() reset: Observable<any>;
    reset_subscription: any;

    fileName: string = translateEnum.CHOOSE_FILE;
    loading: boolean = false;
    accept: string = '.xls,.xlsx';

    ngOnInit() {
        const self = this;
        if (this.reset) {
            this.reset_subscription = this.reset.subscribe(() => self.fileName = translateEnum.CHOOSE_FILE);
        }
    }

    ngAfterViewInit() {
        const self = this;
        setTimeout(function () {
            document.getElementById(self.elementId).addEventListener('change', function () {
                self.loading = true;
                for(var i=0;i<(<HTMLInputElement>this).files.length;i++)
                {

                
                const file = (<HTMLInputElement>this).files[i];
                const fileReader = new FileReader();

                if (file) {
                    fileReader.readAsBinaryString(file);
                    debugger;
                    let fileName = file.name;

                    fileReader.onload = function (fileLoadedEvent) {
                        try {
                          const data = fileReader.result;
                          const workbook = XLSX.read(data, { type: "binary" });
                          workbook.SheetNames.forEach(function (sheetName) {
                            // Add type assertion for doc
                            const doc: Upload[] = XLSX.utils.sheet_to_json(
                              workbook.Sheets[sheetName],
                              { header: "A" }
                            );
                            // Grad the headres from the first element of the array (object which its keys represent the header).
                            const header = Object.keys(doc) as HeaderKey[];
                                debugger;
                            self.result.emit({ header, doc, file_name: fileName });
                          });
                        } catch (err) {
                            self.loading = false;
                        }
                        self.loading = false;
                      };                      

                    fileReader.onerror = function (err) {
                        self.loading = false;
                    };

                } else {
                    self.loading = false;
                }
            }
            }, false);
        }, 200);
        
    }

    ngOnDestroy() {
        if (this.reset && this.reset_subscription) {
            this.reset_subscription.unsubscribe();
        }
    }
}
