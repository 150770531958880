import {Component, Input} from '@angular/core';
import {CarouselConfig} from 'ngx-bootstrap/carousel';
import { Photo } from '../../../shared/models/photo.model';

@Component({
    selector: 'mini-image',
    templateUrl: 'mini-image.component.html',
    styleUrls: ['../../../app.component.css', 'mini-image.component.css'],
    providers: [{provide: CarouselConfig}]
})
export class MiniImageComponent {

    @Input() photo: Photo;

    constructor() {
    }
}
