import {Option} from '../../shared/interfaces/option';
import {translateEnum} from '../../shared/enum/translateEnum';
import {BRAIL_STATUSES} from '../../shared/enum/brail';

export const brailStatuses: Option[] = [
    {id: BRAIL_STATUSES.VALID, name: translateEnum.STATUS_VALID},
    {id: BRAIL_STATUSES.NOT_EXIST, name: translateEnum.STATUS_NOT_EXIST},
    {id: BRAIL_STATUSES.INVALID, name: translateEnum.STATUS_INVALID}
];

export const brailSets: Set<number>[] = [
    new Set<number>([BRAIL_STATUSES.VALID]),
    new Set<number>([BRAIL_STATUSES.NOT_EXIST, BRAIL_STATUSES.INVALID])
];

