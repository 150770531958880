import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Audit } from '../../shared/models/audit.model';
import { History } from '../../shared/models/history.model';
import { Line } from '../../shared/models/line.model';
import { Path } from '../../shared/models/path.model';
import { Permissions } from '../../shared/models/permissions.model';
import { Photo } from '../../shared/models/photo.model';
import { Report } from '../../shared/models/report.model';
import { Shift } from '../../shared/models/shift.model';
import { Stop } from '../../shared/models/stop.model';
import { User } from '../../shared/models/user.model';
import { environment } from '../../../environments/environment';
import { Build } from '../../shared/classes/build';
import { ShiftApi } from '../../shared/interfaces/api';
import { Option } from '../../shared/interfaces/option';
import { PermissionJson } from '../../shared/interfaces/permission';
import { Comment } from '../../shared/models/comment.model';
import { Export } from '../../shared/models/export.model';
import { LineLog } from '../../shared/models/line-log.model';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    headers: HttpHeaders;
    BASE_URL: string;

    constructor(private http: HttpClient) {
        this.BASE_URL = environment.apiUrl;
    }

    getHeaders() {
        this.headers = new HttpHeaders().set('x-access-token', localStorage.getItem('TOKEN'));
        return this.headers;
    }

    login(email: string, password: string): Observable<User> {
        return this.http.post<User>(this.BASE_URL + 'auth', {
            email: email,
            password: password
        }).pipe(map((res: any) => new User().deserialize({user: res.user, token: res.token})));
    }

    dropdown(options) {

        if (options && options.length > 0) {

            let i;
            const result = [];

            for (i = 0 ; i < options.length ; i++) {
                result.push(options[i].id);
            }

            return result.join(',');
        }

        return null;
    }

    getReports(data): Observable<Report[]> {
        const params:any = {};
        debugger;
        if (data.from_date && data.from_date !== '') {
            params.from_date= data.from_date;
        }
        if (data.to_date && data.to_date !== '') {
            params.to_date= data.to_date;
        }
        if (data.stop_codes && data.stop_codes.length) {
            params.stop_codes= data.stop_codes.join(',');
        }
        if (data.user_ids) {
            params.user_ids= data.user_ids;
        }
        if (data.operators) {
            params.operators= data.operators;
        }
        if (data.search) {
            params.search= data.search;
        }
        if (data.areas) {
            params.areas= data.areas;
        }
        if (data.shift_id) {
            params.shift_id= data.shift_id;
        }
        if (data.full_list) {
            params.full_list=  1;
        }
        if (data.new_flag_required) {
            params.new_flag_required =true;
        }
        const flag = this.dropdown(data.flag);
        if (flag) {
            params.flag= flag;
        }
        const brail = this.dropdown(data.brail);
        if (brail) {
            params.brail= brail;
        }
        const poster = this.dropdown(data.static_poster);
        if (poster) {
            params.static_poster= poster;
        }
        const next = this.dropdown(data.next_bus);
        if (next) {
            params.next_bus= next;
        }
        if (data.stop_type) {
            params.stop_type= data.stop_type.id;
        }

        const page = data.page ? data.page : 1;
        const limit = data.limit ? data.limit : 50;

        params.skip =  (page - 1) * limit;
        params.limit = limit ;
        
        let d={d:params}

     //   return this.http.get(this.BASE_URL + 'reports?' + params.join('&'), {headers: this.getHeaders()}).pipe(
        return this.http.post<any>(this.BASE_URL + 'reports/getreports', d, {headers: this.getHeaders()}).pipe(
            map((res: any) => res.reports.map(
                (report: Report, index) => new Report(this).deserialize({report: report, index: index})
            )));
    }

    postReport(data: {}): Observable<Report> {
        return this.http.post<any>(this.BASE_URL + 'reports', data, {headers: this.getHeaders()}).pipe(
            map((res: any) => new Report(this).deserialize({report: res.report})));
    }

    postAddShift(data: {}): Observable<Report> {
        return this.http.post<any>(this.BASE_URL + 'shifts/addShift', data, {headers: this.getHeaders()}).pipe(
            map((res: any) => new Report(this).deserialize({report: res.report})));
    }

    putAddShift(data: {}): Observable<Report> {
        return this.http.put<any>(this.BASE_URL + 'shifts', data, {headers: this.getHeaders()}).pipe(
            map((res: any) => new Report(this).deserialize({report: res.report})));
    }

    deleteTask(data: {}): Observable<any> {
        const headers = this.getHeaders();
        const httpOptions = {
            headers: headers ,body: data
        };
        return this.http.delete<any>(this.BASE_URL + 'shifts/' ,httpOptions);
    }
 

   

    updateReport(id: string, data: any): Observable<any> {
        const headers = this.getHeaders();
        headers.set('Content-Type', 'application/json');

        return this.http.patch<any>(this.BASE_URL + 'reports/' + id, data, {headers}).pipe(
            map((res: any) => new Report(this).deserialize({report: res.report})));
    }

    getReport(id): Observable<Report> {
        const headers = this.getHeaders();

        return this.http.get(this.BASE_URL + 'reports/' + id, {headers}).pipe(
            map((res: any) => new Report(this).deserialize({report: res.report, index: 0})));
    }


    getTrafficChanges(id): Observable<any[]> {
        const headers = this.getHeaders();

        return this.http.get(this.BASE_URL + 'traffic?id='+id , {headers}).pipe(
            map((res: any) => {
                    return res.traffic;
               
            }));
    }

    getTraffic(id): Observable<any[]> {
        const headers = this.getHeaders();

        return this.http.get(this.BASE_URL + 'traffic/get_traffic_change?id='+id , {headers}).pipe(
            map((res: any) => {
                    return res.traffic;
               
            }));
    }
    

    getTrafficChangesFile(id): Observable<any[]> {
        const headers = this.getHeaders();

        return this.http.get(this.BASE_URL + 'traffic/trafficChanges?id='+id , {headers}).pipe(
            map((res: any) => {
                    return res.traffic;
               
            }));
    }

    getTrafficTotalFile(id): Observable<any[]> {
        const headers = this.getHeaders();

        return this.http.get(this.BASE_URL + 'traffic/trafficTotals?id='+id , {headers}).pipe(
            map((res: any) => {
                    return res.traffic;
               
            }));
    }
    

    getTrafficFiles(): Observable<any[]> {
        const headers = this.getHeaders();

        return this.http.get(this.BASE_URL + 'trafficFile/' , {headers}).pipe(
            map((res: any) => {
                    return res.trafficFile;
               
            }));
    }

    users(
        first_name: string = null,
        last_name: string = null,
        phone: string = null,
        email: string = null,
    ): Observable<any[]> {

        const headers = this.getHeaders();

        let query = '';
        const params = [];
        if (first_name && first_name != '') {
            params.push('first_name=' + first_name);
        }
        if (last_name && last_name != '') {
            params.push('last_name=' + last_name);
        }
        if (phone && phone != '') {
            params.push('phone=' + phone);
        }
        if (email && email != '') {
            params.push('email=' + email);
        }

        if (params.length > 0) {
            query = '?' + params.join('&');
        }

        return this.http.get<any>(this.BASE_URL + 'users' + query, {headers}).pipe(
            map((res: any) => {
                    return res.users;
               
            }));

          
    }

    user(id: string, token: string): Observable<User> {
        return this.http.get<any>(`${this.BASE_URL}users/${id}`, {headers: this.getHeaders()}).pipe(
            map((res: any) => new User().deserialize({user: res.user, token: token})));
    }

    postShifts(data: ShiftApi): Observable<any> {

        const headers = this.getHeaders();
        headers.set('Content-Type', 'application/json');

        const params = {
            user_id: data.user_id,
            from_date: data.from_date,
            to_date: data.to_date,
            stop_codes: data.stop_codes,
            tasks: data.tasks.getTasks(false),
            jobTypes: data.jobTypes
        };

        if (data.name && data.name.length > 0) {
            params['name'] = data.name;
        }

        return this.http.post<any>(this.BASE_URL + 'shifts', params, {headers});
    }
    debugger;
    postShiftsValidation(data: ShiftApi): Observable<any> {

        const headers = this.getHeaders();
        headers.set('Content-Type', 'application/json');

        const params = {
            user_id: data.user_id,
            from_date: data.from_date,
            to_date: data.to_date,
            stop_codes: data.stop_codes,
            tasks: data.tasks.getTasks(false),
            jobTypes: data.jobTypes
        };

        if (data.name && data.name.length > 0) {
            params['name'] = data.name;
        }
        debugger;
        return this.http.post<any>(this.BASE_URL + 'shifts/Validation', params, {headers});
    }


    postTrafficChanges(data: any,executer:string,mapname:string,sifrur:string,todate:string,user:User): Observable<any> {

        const headers = this.getHeaders();
        headers.set('Content-Type', 'application/json');
        let contractors=''
        if(user!=null)
        contractors=user.name;
    
        const params = {
            data: data,
            execution_date:todate,
            sifrur:sifrur,
            mapname:mapname,
            executer:executer,
            contractors:contractors
        };

        return this.http.post<any>(this.BASE_URL + 'traffic', params, {headers});
    }

    updateTrafficStatus(reportid:string,action:string,status: string,traffic_id:string,name:string): Observable<any> {

        const headers = this.getHeaders();
        headers.set('Content-Type', 'application/json');

        const params = {
            status: status,
            action:action,
            id:traffic_id,
            reportid:reportid,
            name:name
        };

        return this.http.post<any>(this.BASE_URL + 'traffic/update_traffic_status', params, {headers});
    }

    getShifts(data: {
        from_action_date?: string,
        to_action_date?: string,
        user?: User,
        project_name?: string,
        statuses?: Option[],
        stopcode?:string,
        selected_operators?: Option[],
        selected_areas?: Option[],
        min_total_stops_count?: number,
        max_total_stops_count?: number,
        min_completed_stops_count?: number,
        max_completed_stops_count?: number,
        from_enter_date?: string,
        to_enter_date?: string,
        from_control_date?: string,
        to_control_date?: string,
        limit?: number,
        page?: number,
    }): Observable<any> {
        const params = [];

        if (data.from_action_date && data.from_action_date !== '') {
            params.push('from_date=' + data.from_action_date);
        }
        if (data.to_action_date && data.to_action_date !== '') {
            params.push('to_date=' + data.to_action_date);
        }
        if (data.user) {
            params.push('user_id=' + data.user.id);
        }
        if (data.stopcode) {
            params.push('stopcode=' + data.stopcode);
        }
        if (data.project_name) {
            data.project_name = data.project_name.trim();
            if (data.project_name.length > 0) {
                params.push('name=' + data.project_name);
            }
        }

        if (data.statuses && data.statuses.length) {
            const concat = data.statuses.flat().map(status => status.id);
            params.push('status=' + concat.join(','));
        }

        if (data.limit && data.page) {
            params.push(`skip=${(data.page - 1) * data.limit}`);
            params.push(`limit=${data.limit + 1}`);
        }

        const query = params.length ? '?' + params.join('&') : '';

        return this.http.get(this.BASE_URL + 'shifts' + query, {headers: this.getHeaders()}).pipe(
            map((res: any) => res.shifts.map((shift: Shift) => new Shift().deserialize(shift))));
    }

    getShift(id: string): Observable<Shift> {
        return this.http.get(`${this.BASE_URL}shifts?shift_id=${id}`, {headers: this.getHeaders()}).pipe(
            map((res: any) => {
                if (res.shifts.length) {
                    return new Shift().deserialize(res.shifts[0]);
                }
                throw new Error('shift not found');
            }));
    }

    getAllShifts(): Observable<Shift> {
        return this.http.get(`${this.BASE_URL}shifts`, {headers: this.getHeaders()}).pipe(
            map((res: any) => res.shifts.map((shift: Shift) => new Shift().deserialize(shift))));
    }
    


    getProjectsFiles(fields): Observable<any> {
        return this.http.get(`${this.BASE_URL}projects?project_name=`+fields.project_name+'&from_action_date='+fields.from_action_date+'&to_action_date='+fields.to_action_date, {headers: this.getHeaders()}).pipe(
            map((res: any) => {
                    return res.projectFiles;
               
            }));
    }
    

    getProjects(id): Observable<any> {
        return this.http.get(`${this.BASE_URL}projects/getProjects?id=`+id, {headers: this.getHeaders()}).pipe(
            map((res: any) => {
                if (res.projects.length) {
                    return res.projects;
                }
                throw new Error('projects not found');
            }));
    }

    downloadProjects(id): Observable<any> {
        return this.http.get(`${this.BASE_URL}projects/downloadProjects?id=`+id, {headers: this.getHeaders()}).pipe(
            map((res: any) => {
                if (res.projects.length) {
                    return res.projects;
                }
                throw new Error('projects not found');
            }));
    }

    downloadTotals(id): Observable<any> {
        return this.http.get(`${this.BASE_URL}projects/projectTotals?id=`+id, {headers: this.getHeaders()}).pipe(
            map((res: any) => {
               
                    return res.projects;
                
                throw new Error('projects not found');
            }));
    }
    
    getReportByStopCode(id): Observable<any> {
        return this.http.get(`${this.BASE_URL}projects/getReport?stopcode=`+id, {headers: this.getHeaders()}).pipe(
            map((res: any) => {
                    return res.report;
                
                throw new Error('projects not found');
            }));
    }

    

    photos(page, from_date, to_date, stop_code, user_ids, stop_type): Observable<Photo[]> {
        const headers = this.getHeaders() ;

        let query = '';
        const params = [];
        if (from_date && from_date != '') {
            params.push('from_date=' + from_date);
        }
        if (to_date && to_date != '') {
            params.push('to_date=' + to_date);
        }
        if (stop_code && stop_code != '') {
            params.push('stop_code=' + stop_code);
        }
        if (user_ids) {
            params.push('user_ids=' + user_ids);
        }
        if (stop_type) {
            params.push('stop_type=' + stop_type.id);
        }

        params.push('full_list=1');
        params.push('skip=' + (page - 1) * 10);
        params.push('limit=11');

        if (params.length > 0) {
            query = '?' + params.join('&');
        }

        return this.http.get(this.BASE_URL + 'photos' + query, {headers}).pipe(
            map((res: any) => res.photos.map(
                (photo: Photo, index) => new Photo(this).deserialize(photo)
            )));
    }

    photo(url: string) {
        const headers = this.getHeaders();
        return this.http.get(this.BASE_URL + 'photos/file?url=' + url, {headers, responseType: 'blob' });
    }

    addUser(first_name: string,
            last_name: string,
            email: string,
            phone: string,
            role: string,
            permissions: Permissions,
            password: string): Observable<any> {

        const headers = this.getHeaders();
        headers.set('Content-Type', 'application/json');

        let permissions_json:PermissionJson;
        if(permissions!=undefined)
        {
        permissions_json = {...permissions.toJson()};
        permissions_json.lines_csv_upload = undefined;
        }

        return this.http.post<any>(this.BASE_URL + 'users', {
            first_name: first_name,
            last_name: last_name,
            email: email,
            phone: phone,
            role: role,
            password: password,
            permissions: permissions_json
        }, {headers});
    }

    editUser(id: string,
             first_name: string,
             last_name: string,
             email: string,
             phone: string,
             role: string,
             permissions: Permissions,
             password: string = null): Observable<any> {

        const headers = this.getHeaders();
        headers.set('Content-Type', 'application/json');

        let permissions_json:PermissionJson;
        if(permissions!=undefined)
        {
        permissions_json = {...permissions.toJson()};
        permissions_json.lines_csv_upload = undefined;
        }

        const params = {
            first_name: first_name,
            last_name: last_name,
            email: email,
            phone: phone,
            role: role,
            permissions: permissions_json
        };

        if (password) {
            params['password'] = password;
        }

        return this.http.patch<any>(this.BASE_URL + 'users/' + id, params, {headers});
    }

    deleteUser(id: string): Observable<any> {
        const headers = this.getHeaders();
        return this.http.delete<any>(this.BASE_URL + 'users/' + id, {headers});
    }

    

    deleteReport(id: string): Observable<any> {
        const headers = this.getHeaders();
        return this.http.delete<any>(this.BASE_URL + 'reports/' + id, {headers});
    }

    updateSchedule(id: string, data: ShiftApi): Observable<any> {
        const headers = this.getHeaders();
        headers.set('Content-Type', 'application/json');

        const params = {};

        if (data.stop_codes) {
            params['stop_codes'] = data.stop_codes;
        }

        if (data.user_id) {
            params['user_id'] = data.user_id;
        }

        if (data.from_date) {
            params['from_date'] = data.from_date;
        }

        if (data.to_date) {
            params['to_date'] = data.to_date;
        }

        if (data.status !== undefined) {
            params['status'] = data.status.toString();
        }

        if (data.name) {
            params['name'] = data.name;
        }

        if (data.tasks) {
            params['tasks'] = data.tasks.getTasks(true);
        }

        return this.http.patch<any>(this.BASE_URL + 'shifts/' + id, params, {headers});
    }

    getStops(stop_code: any): Observable<any> {

        const headers = this.getHeaders();

        let query = '';
        const params = [];
        if (stop_code) {
            stop_code = (typeof stop_code === 'string') ? stop_code.trim() : stop_code;
            if (stop_code != '') {
                params.push('stop_code=' + stop_code);
            }
        }

        params.push('limit=5');

        if (params.length > 0) {
            query = '?' + params.join('&');
        }

        return this.http.get(this.BASE_URL + 'stops' + query, {headers}).pipe(
            map((res: any) => res.stops.map((stop: Stop) => new Stop().deserialize(stop))));
    }

    deleteShift(id: string): Observable<any> {
        const headers = this.getHeaders();
        return this.http.delete<any>(this.BASE_URL + 'shifts/' + id, {headers});
    }

    getHistory(id): Observable<History[]> {
        const headers = this.getHeaders();

        return this.http.get(`${this.BASE_URL}reports/${id}/history`, {headers}).pipe(
            map((res: any) => res.history.map((history: History, index) => new History(index).deserialize(history))));
    }

    getBuild(): Observable<Build> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Cache-Control': 'no-cache'
            })
        };
        return this.http.get<Build>('assets/build.json', httpOptions).pipe(map((res: Build) => res));
    }

    getAuditsByReport(id): Observable<Audit[]> {
        const headers = this.getHeaders();

        return this.http.get(`${this.BASE_URL}reports/${id}/audit`, {headers}).pipe(
            map((res: any) => res.audits.map((audit: Audit, index) => new Audit(index).deserialize(audit))));
    }

    getAuditsByShift(shift_id): Observable<Audit[]> {
        const headers = this.getHeaders();

        return this.http.get(`${this.BASE_URL}audit?shift_id=${shift_id}`, {headers}).pipe(
            map((res: any) => res.audits.map((audit: Audit, index) => new Audit(index).deserialize(audit))));
    }

    getComments(ids: string[]): Observable<Comment[]> {
        return this.http.get(`${this.BASE_URL}comments?report_ids=${ids.join(',')}`, {headers: this.getHeaders()}).pipe(
            map((res: any) => res.comments.map((comment: Comment) => new Comment().deserialize(comment))));
    }

    postAudit(id: string, data = {}): Observable<any> {
        const headers = this.getHeaders();
        return this.http.post<any>(`${this.BASE_URL}reports/${id}/audit`, data, {headers}).pipe(
            map((res: any) => new Audit(0).deserialize(res.audit)));
    }

    getPaths(stop_id: number): Observable<Path[]> {
        const headers = this.getHeaders();
        return this.http.get<any>(`${this.BASE_URL}routes?stop_id=${stop_id}`, {headers}).pipe(
            map((res: any) => res.routes.map((path: any) => new Path().deserialize(path))));
    }

    getLinesByStopCode(stop_code: number): Observable<Line[]> {
        return this.http.get<any>(`${this.BASE_URL}lines?stop_code=${stop_code}`, {headers: this.getHeaders()}).pipe(
            map((res: any) => res.lines.map((line: any) => new Line().deserialize(line))));
    }

    getLinesByLineSign(line_sign: number): Observable<Line[]> {
        return this.http.get<any>(`${this.BASE_URL}lines?line_sign=${line_sign}`, {headers: this.getHeaders()}).pipe(
            map((res: any) => res.lines.map((line: any) => new Line().deserialize(line))));
    }

    getLinesByMultyLineSign(line_sign: string,line_sign2: string,line_sign3: string,line_sign4: string): Observable<Line[]> {
        return this.http.get<any>(`${this.BASE_URL}lines?line_sign=${line_sign}&line_sign2=${line_sign2}&line_sign3=${line_sign3}&line_sign4=${line_sign4}`, {headers: this.getHeaders()}).pipe(
            map((res: any) => res.lines.map((line: any) => new Line().deserialize(line))));
    }

    getExports(): Observable<Export[]> {
        return this.http.get<any>(`${this.BASE_URL}exports`, {headers: this.getHeaders()}).pipe(
            map((res: any) => res.export.map((exp: Export) => new Export().deserialize(exp))));
    }

    postExport({name, columns, user_id, type}): Observable<Export> {
        return this.http.post<any>(`${this.BASE_URL}exports`, {name, columns, user_id, type}, {headers: this.getHeaders()}).pipe(
            map((res: any) => new Export().deserialize(res.export)));
    }

    patchExport({id, name, columns}): Observable<Export> {
        return this.http.patch<any>(`${this.BASE_URL}exports/${id}`, {name, columns}, {headers: this.getHeaders()}).pipe(
            map((res: any) => new Export().deserialize(res.export)));
    }

    deleteExport(id): Observable<any> {
        return this.http.delete<any>(`${this.BASE_URL}exports/${id}`, {headers: this.getHeaders()}).pipe(
            map((res: any) => res));
    }

    linesCsvUpload(formData: FormData): Observable<any> {
        return this.http.post<any>(`${this.BASE_URL}lines/upload`, formData, {headers: this.getHeaders()}).pipe(
            map((res: any) => res));
    }
    stopsFileUpload(formData: FormData): Observable<any> {
        return this.http.post<any>(`${this.BASE_URL}stops/upload`, formData, {headers: this.getHeaders()}).pipe(
            map((res: any) => res));
    }
    imageAnalyzerUpload(formData: FormData): Observable<any> {
        return this.http.post<any>(`${this.BASE_URL}imagesAnalyzer`, formData, {headers: this.getHeaders()}).pipe(
            map((res: any) => res));
    }

    imageAnalyzerUploadFromProject(data: {} ): Observable<any> {
        return this.http.post<any>(`${this.BASE_URL}imagesAnalyzer/ProjectUpload`, data, {headers: this.getHeaders()}).pipe(
            map((res: any) => res));
    }

    reportValidationUpload(formData: FormData): Observable<any> {
        return this.http.post<any>(`${this.BASE_URL}reportValidation`, formData, {headers: this.getHeaders()}).pipe(
            map((res: any) => res));
    }

    projectsFileUpload(formData: FormData): Observable<any> {
        return this.http.post<any>(`${this.BASE_URL}projects/upload`, formData, {headers: this.getHeaders()}).pipe(
            map((res: any) => res));
    }

    checkAiStatus(data: {} ): Observable<string> {
        return this.http.post<any>(`${this.BASE_URL}imagesAnalyzer/checkAiStatus`, data, {headers: this.getHeaders()}).pipe(
            map((res: any) => res));
    }

    turnOnAi(data: {} ): Observable<Stop[]> {
        return this.http.post<any>(`${this.BASE_URL}imagesAnalyzer/startVM`, data, {headers: this.getHeaders()}).pipe(
            map((res: any) => res));
    }

    turnOffAi(data: {} ): Observable<Stop[]> {
        return this.http.post<any>(`${this.BASE_URL}imagesAnalyzer/stopVM`, data, {headers: this.getHeaders()}).pipe(
            map((res: any) => res));
    }

    getStopsOperatorsInCharge(data: {} ): Observable<Stop[]> {
        return this.http.post<any>(`${this.BASE_URL}stops/getStopsOperatorsInCharge`, data, {headers: this.getHeaders()}).pipe(
            map((res: any) => res));
    }
    
    getAreas(data: {} ): Observable<Stop[]> {
        return this.http.post<any>(`${this.BASE_URL}stops/getAreas`, data, {headers: this.getHeaders()}).pipe(
            map((res: any) => res));
    }

    stopsDeleteDiff(data: {}): Observable<any> {
        return this.http.post<any>(this.BASE_URL + 'stops/removeDiff', data, {headers: this.getHeaders()}).pipe(
            map((res: any) => new Report(this).deserialize({report: res.report})));
    }

    getLinesCsvDuplications(formData: FormData): Observable<any> {
        return this.http.post<any>(`${this.BASE_URL}lines/getDuplications`, formData, {headers: this.getHeaders()});
    }
    patchMultiLineRows(rows: { [key: string]: string }[]): Observable<any> {
        return this.http.patch<any>(`${this.BASE_URL}lines/multi`, rows, {headers: this.getHeaders()});
    }

    linesLogs(): Observable<LineLog[]> {
        return this.http.get<any>(`${this.BASE_URL}lines/logs`, {headers: this.getHeaders()}).pipe(
            map((res: any) => res.logs.map((log: LineLog) => new LineLog().deserialize(log))));
    }

    putToFinal(): Observable<any> {
        return this.http.get<any>(`${this.BASE_URL}lines/putToFinal`, {headers: this.getHeaders()});
    }

}
