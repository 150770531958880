import {FlagAlignElement} from '../../../shared/interfaces/flag';

/**
 * Align element before canvas parse
 * @param el - element id
 * @returns {string} - element zoom
 */
export function alignElement(el: HTMLElement): FlagAlignElement {
    el.style.setProperty('top', '0');
    el.style.setProperty('left', '0');
    el.style.setProperty('z-index', '2000');
    const position = el.style.getPropertyValue('position');
    el.style.setProperty('position', 'fixed');
    const zoom = el.style.getPropertyValue('zoom');
    el.style.setProperty('zoom', '100%');
    return {zoom, position};
}

/**
 * Rollback element after canvas parse
 * @param el - element id
 * @param zoom - zoom
 */
export function rollbackElement(el: HTMLElement, prev_style: FlagAlignElement) {
    el.style.setProperty('top', 'unset');
    el.style.setProperty('left', 'unset');
    el.style.setProperty('position', prev_style.position);
    el.style.setProperty('z-index', 'unset');
    el.style.setProperty('zoom', prev_style.zoom);
}
