import {Deserializable} from "./deserializable.model";
import {Line} from "../../shared/models/line.model";
import {Operator} from "../../shared/models/operator.model";

export class FlagPageLine implements Deserializable {

	id: string;
	line_dest_head: string | null;
	line_dest_sub: string | null;
	line_signs: string[];
	operator: Operator;
	show_operator: boolean;
	is_train: boolean;
	only_numbers: boolean;
	lang: string;

	deserialize(input: {
		only_numbers: boolean,
		line_signs: string[],
		line: Line,
		lang: string,
		show_operator: boolean
	}) {
		Object.assign(this, {
			id: input.line.id,
			line_dest_head: input.line[`line_dest_head_${input.lang}`],
			line_dest_sub: input.line[`line_dest_sub_${input.lang}`],
			line_signs: input.line_signs,
			operator: new Operator().deserialize({operator_name: input.line.operator_name, lang: input.lang}),
			show_operator: input.show_operator,
			is_train: input.line.is_train,
			only_numbers: input.only_numbers,
			lang: input.lang,
		});

		return this;
	}
}
