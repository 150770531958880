import {Injectable} from '@angular/core';
import {BuildService} from './build.service';
import { ApiService } from '../api.service';

@Injectable({providedIn: 'root'})
export class BuildApiService {

    constructor(private api: ApiService, private buildService: BuildService) {
    }

    fetchBuild(): Promise<any> {
        const self = this;
        return new Promise(
            (resolve) => {
                this.api.getBuild().subscribe(
                    r => {
                        self.buildService.build = r;
                        resolve(true);
                    },
                    r => {
                        console.log(r);
                        resolve(true);
                    });
            }
        );
    }
}
