import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import 'moment/locale/he';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
    selector: 'datepicker',
    templateUrl: 'datepicker.component.html',
    styleUrls: ['datepicker.component.css'],
    providers: [
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
        {provide: MAT_DATE_LOCALE, useValue: 'he'},
    ],
})

export class Datepicker implements OnInit, OnDestroy {

    @Input() header: string;
    @Input() error: string;
    @Output() result = new EventEmitter<any>();

    @Input() reset: Observable<any>;

    date: any;
    @Output() dateChange = new EventEmitter<MatDatepickerInputEvent<any>>();

    constructor(private changes: ChangeDetectorRef) {

    }

    updateDate(event) {
        let result = null;

        if (event) {

            let day = event._d.getDate();
            let month = event._d.getMonth() + 1;
            const year = event._d.getFullYear();

            if (month < 10) {
                month = '0' + month;
            }

            if (day < 10) {
                day = '0' + day;
            }

            result = year + '-' + month + '-' + day;
        }

        this.result.emit(result);
        this.error = null;
    }

    ngOnInit() {
        this.reset.pipe(take(1)).subscribe((date: string) => {
            this.date = date
            this.changes.detectChanges();
        });
    }

    ngOnDestroy() {
    }

}
