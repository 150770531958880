import { Component, Input } from '@angular/core';
import { translateEnum } from '../../../../shared/enum/translateEnum';

@Component({
    selector: 'search-button',
    templateUrl: 'search-button.component.html',
    styleUrls: ['search-button.component.css', '../../../../app.component.css'],
})
export class SearchButtonComponent {
    readonly translateEnum = translateEnum;

    @Input() public disabled: boolean = false;
    @Input() public search: Function;
    @Input() public source: string;

    keypress(event) {
        if (event.keyCode === 13) {
            if (event.path && event.path.length) {
                let do_search = false;
                for (let i = 0; i < event.path.length; i++) {
                    const path = event.path[i];
                    if (path) {
                        if (path.classList && path.classList.value && this.source === 'project' &&
                            path.classList.value === 'mat-expansion-panel-content ng-trigger ng-trigger-bodyExpansion') {
                            do_search = true;
                            break;
                        }
                        if (path.getAttribute && path.getAttribute('aria-labelledby') && this.source === 'project') {
                            if (path.getAttribute('aria-labelledby') === 'projectLabel') {
                                do_search = true;
                            } else {
                                return;
                            }
                        }
                        if (path.classList && path.classList.value &&
                            path.classList.value === 'app header-fixed sidebar-lg-show sidebar-fixed') {
                            if (['projects', 'reports', 'photos', 'users', 'schedules'].indexOf(this.source) > -1) {
                                do_search = true;
                            } else {
                                return;
                            }
                        }
                    }
                }
                if (do_search) {
                    this.search();
                    event.stopPropagation();
                }
            }
        }
    }
}
