import {Component, Input} from '@angular/core';

@Component({
    selector: 'icon-button',
    templateUrl: 'icon-button.component.html',
    styleUrls: ['icon-button.component.css', '../../../app.component.css'],
})
export class IconButtonComponent {
    @Input() public name: string = '';
    @Input() public siteColor: boolean = false;
    @Input() public disabled: boolean = false;
    @Input() public loading: boolean = false;
}
