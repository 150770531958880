import {Deserializable} from './deserializable.model';
import {TASK_STATUSES} from '../../shared/enum/task';
import {Option} from '../../shared/interfaces/option';
import {taskStatuses} from '../../shared/options/task';
import {TaskInput} from '../../shared/interfaces/task';

export class Task implements Deserializable {

    to_do: string = '';
    status: TASK_STATUSES = TASK_STATUSES.PENDING;
    option: Option = taskStatuses[TASK_STATUSES.PENDING];
    status_name: string = '';

    deserialize(input: TaskInput) {
        Object.assign(this, input);
        this.setStatus(input.status);
        return this;
    }

    public getTask() {
        return {
            to_do: this.to_do,
            status: this.status,
        };
    }

    public setStatus(status: TASK_STATUSES) {
        this.status = status;
        this.option = taskStatuses[status];
        this.status_name = this.option.name;
    }

    public toString() {
        return `${this.to_do} - ${this.status_name}`;
    }

}
