import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseModalComponent } from '../../../base-modal.component';
import { translateEnum } from '../../../shared/enum/translateEnum';
import { Stop } from '../../../shared/models/stop.model';

@Component({
    selector: 'upload-result-message',
    templateUrl: 'upload-result-message.component.html',
    styleUrls: ['./upload-result-message.component.css', '../../../app.component.css']
})

export class UploadResultMessageComponent extends BaseModalComponent {

    @Input() data: Observable<any>;
    readonly translateEnum = translateEnum;

    title: string;
    body: string;
    success: string;

    constructor() {
        super();
        this.child = this;
    }

    ngOnInit(): void {
        if(this.data)
            this.data.pipe(take(1)).subscribe(data => this.open(data));
    }

    open(data) {
        const success_codes = data.shift.stops.map((stop: Stop) => stop.stop_code);
        const fail_codes = data.stop_codes.filter(code => !success_codes.includes(+code));

        if (fail_codes.length) {
            this.success = null;
            this.title = this.translateEnum.UPLOAD_RESULT_MESSAGE
                .replace('[total]', data.stop_codes.length.toString())
                .replace('[fail]', fail_codes.length.toString());

            this.body = fail_codes.join(', ');
        } else {
            this.success = this.translateEnum.UPLOAD_CODES_SUCCESS;
        }
    }

}
