import {Component, Input} from '@angular/core';
import flagStyle from '../flag-style';
import {FlagPage} from '../../../shared/models/flag-page.model';

@Component({
    selector: 'flag-stop',
    templateUrl: 'flag-stop.component.html',
    styleUrls: ['flag-stop.component.css']
})

export class FlagStopComponent {

    public flagStyle = flagStyle;

    @Input() public flagPage: FlagPage;
    @Input() public headOnly: boolean;

    fontSize: number = flagStyle.enFlagStopRowTextHeaderFontSize;

    flagStopRow() {
        if (this.headOnly) {
            return flagStyle.flagStopRow;
        }
        return {
            ...flagStyle.flagStopRow,
            ...flagStyle.borderBottom
        };
    }

    flagStopRowImage() {
        const main = flagStyle[this.flagPage.lang + 'FlagStopRowImage'];
        if (this.headOnly) {
            return {
                ...main,
                ...flagStyle.flagStopRowImageHeadOnly,
            };
        }
        return {
            ...main,
            ...flagStyle.borderBottom
        };
    }

    scale() {
        if (this.flagPage) {

            const parent = document.getElementById(`flag-stop-row-text-${this.flagPage.lang}`);
            const text = document.getElementById(`flag-stop-row-text-header-${this.flagPage.lang}`);

            if (parent && text) {
                if (text.scrollHeight > parent.clientHeight - 140 && this.fontSize > 50) {

                    this.fontSize -= 5;
                    text.style.fontSize = this.fontSize + 'pt';

                    const self = this;
                    setTimeout(function () {
                        self.scale();
                    }, 500);

                }
            }
        }
    }

    ngOnChanges(changes): void {
        if (changes.flagPage && changes.flagPage.currentValue) {
            const flagPage = changes.flagPage.currentValue;
            if (flagPage) {
                this.fontSize = flagStyle[flagPage.lang + 'FlagStopRowTextHeaderFontSize'];
            }
        }
    }

    ngAfterViewInit(): void {
        const self = this;
        setTimeout(function () {
            self.scale();
        }, 500);
    }

}
