import { Component,Input, OnInit ,OnDestroy} from '@angular/core';
import { ApiService } from '../../../core/service/api.service';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';



@Component({
    selector: 'stops-diff',
    templateUrl: 'stops-diff.component.html',
    styleUrls: ['./stops-diff.component.css', '../../../app.component.css']
})
export class StopsDiffComponent implements OnInit,OnDestroy  {

    private eventsSubscription: Subscription;
    @Input() resObservable: Observable<any>;
    resTable$: Observable<any>;
    resTable: any;

    constructor(private api: ApiService)  {
       
    }
    ngOnDestroy(): void {
        this.eventsSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.resTable$ = this.resObservable.pipe(tap(data => {
            console.log({data});
        }));
        this.resObservable.subscribe({
            next: (data) => {
                debugger;
                for(let i=0;i<data.length;i++)
                    data[i].checked=false;
                this.resTable = data;

            }
        })
    }

    deleteNotRelevantStops():void{
        debugger;
        this.api.stopsDeleteDiff(this.resTable
            ).subscribe(
            (res) => {
                debugger;
               this.ngOnDestroy();
                
            },
            (err) => {
                this.ngOnDestroy();
            })
    }

    updateCheck(val):void{
        for(let i=0;i<this.resTable.length;i++)
            if(this.resTable[i]._id==val.id)
                this.resTable[i].checked=val.checked;
    }
    

}



