import { Component, Input } from "@angular/core";
import { BaseModalComponent } from "../../../base-modal.component";
import { UserService } from "../../../core/service/user.service";
import { EXPORT_TYPE } from "../../../shared/enum/export";
import { translateEnum } from "../../../shared/enum/translateEnum";
import { Option } from "../../../shared/interfaces/option";
import { Export } from "../../../shared/models/export.model";
import { User } from "../../../shared/models/user.model";
import {
  exportProject,
  exportProjects,
  exportReports,
} from "../../../shared/options/export";

@Component({
  selector: "export",
  templateUrl: "export.component.html",
  styleUrls: ["export.component.css", "../../../app.component.css"],
})
export class ExportComponent extends BaseModalComponent {
  exportReports = exportReports;
  exportProjects = exportProjects;
  exportProject = exportProject;

  @Input() public getFields: Function = null;
  @Input() public type: EXPORT_TYPE;
  @Input() public onClose: Function = null;

  user: User;
  export: Export = null;
  create_page: boolean = false;
  options: Option[] = [];
  translateEnum = translateEnum;

  constructor(public userService: UserService) {
    super();
    this.child = this;
    this.user = this.userService.getUser();

    this.create = this.create.bind(this);
    this.edit = this.edit.bind(this);
    this.show = this.show.bind(this);
    this.close = this.close.bind(this);
  }

  ngOnChanges(changes): void {
    if (changes.type && changes.type.currentValue) {
      switch (changes.type.currentValue) {
        case EXPORT_TYPE.REPORTS:
          this.options = exportReports;
          break;
        case EXPORT_TYPE.PROJECTS:
          this.options = exportProjects;
          break;
        case EXPORT_TYPE.PROJECT:
          this.options = exportProject;
          break;
      }
    }
  }

  create() {
    this.export = new Export().deserialize({
      user_id: this.user.id,
      type: this.type,
    });
    this.create_page = true;
    this.top("export-modal");
  }

  edit(exp: Export) {
    if (exp.custom) {
      this.export = new Export().deserialize({
        id: exp.id,
        name: exp.name,
        columns: [...exp.columns],
        type: exp.type,
      });
      this.create_page = true;
      this.top("export-modal");
    }
  }

  show() {
    this.create_page = false;
  }

  close() {
    if (this.onClose) {
      this.onClose();
    }

    if(this.modal)
      this.closeModal(this.modal);
  }
}
