import * as moment from 'moment';

/**
 * Convert FROM DATE string to FROM DATE ISO8601 string
 * @param date
 * @returns {string}
 */
export function getISO8601FromDate(date: string): string {
    return date ? date + 'T00:00:00.000Z' : date;
}

/**
 * Convert TO DATE string to TO DATE ISO8601 string
 * @param date
 * @returns {string}
 */
export function getISO8601ToDate(date: string): string {
    return date ? date + 'T23:59:59.999Z' : date;
}

/**
 * Convert FROM DATE Object to FROM DATE ISO8601 string
 * @param date
 * @returns {any}
 */
export function dateObjectISO8601From(date: Date): string {
    if (date) {
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);

        return getISO8601FromDate(date.getFullYear() + '-' + month + '-' + day);
    }
    return '';
}

/**
 * Convert TO DATE Object to TO DATE ISO8601 string
 * @param date
 * @returns {any}
 */
export function dateObjectISO8601To(date: Date): string {
    if (date) {
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);

        return getISO8601ToDate(date.getFullYear() + '-' + month + '-' + day);
    }
    return '';
}

/**
 * Calculate number of days between two dates
 * @param dateFrom
 * @param dateTo
 * @returns {number}
 */
export function datesDifference(dateFrom: Date, dateTo: Date): number {
    const timeDiff = Math.abs(dateTo.getTime() - dateFrom.getTime());
    return Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
}

/**
 * Go to top of input element id
 * @param idElement
 */
export function top(idElement: string): void {
    const selector = document.querySelector('#' + idElement);
    if (selector instanceof HTMLElement) {
        selector.scrollIntoView();
    }
}

/**
 * Get date only
 * @param date
 */
export const getDate = (date: string) => {
    if (date) {
        return moment(date).format('DD/MM/YYYY');
    }
    return '';
};

/**
 * Get date and time only
 * @param date
 */
export const getDateTime = (date: string) => {
    if (date) {
        return moment(date).format('DD/MM/YYYY HH:mm:ss');
    }
    return '';
};

export const uniqueID = () => {
    return Math.random().toString(36).slice(2);
};

export const compareNumber = (a, b, key) => {
    if (a[key] === b[key]) {
        return 0;
    } else {
        return a[key] < b[key] ? -1 : 1;
    }
};

export const compareString = (a, b, key) => {
    return a[key].localeCompare(b[key]);
};

export const chunk = (arr, size) => {
    if (arr) {
        arr = Array.from({length: Math.ceil(arr.length / size)}, (v, i) =>
            arr.slice(i * size, i * size + size));
    }
    return arr;
};

export const letterToNumber = (letter: string): number => {
    return letter.split('').reduce((r, a) => r * 26 + parseInt(a, 36) - 9, 0);
};

export const uid = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

export const isNumeric = (value) => {
    return /^-?\d+$/.test(value);
};

export const datesCompare = (date1: string, date2: string) => {
    const result = new Date(date1).valueOf() - new Date(date2).valueOf();
    return result > 0 ? 1 : (result < 0 ? -1 : 0);
};

export const hideModal = (element_id: string) => {
    const element = document.getElementById(element_id);
    if (element) {
        element.style['max-width'] = '0';
        element.style.height = '0';
        element.style.overflow = 'auto';
    }
};

export const showModal = (element_id: string) => {
    const element = document.getElementById(element_id);
    if (element) {
        element.style['max-width'] = '90%';
        element.style.height = '';
        element.style.overflow = '';
    }
};

