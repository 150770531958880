import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: 'base.component.html',
})
export class BaseComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {

  }
}
