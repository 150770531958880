import {Deserializable} from './deserializable.model';
import {translateEnum} from '../../shared/enum/translateEnum';
import {Permission} from './permission.model';
import {READ_WRITE_PERMISSION, READ_PERMISSION, NO_PERMISSION} from './authority.model';
import {ADMIN_ROLE, WORKER_ROLE, OPERATOR_ROLE} from './role.model';
import {PermissionJson} from '../../shared/interfaces/permission';

export const REPORTS_PERMISSIONS = 'reports';
export const LINES_CSV_UPLOAD_PERMISSIONS = 'lines_csv_upload';
export const STOPS_FILE_UPLOAD_PERMISSIONS = 'stops_file_upload';
export const TRAFFIC_CHANGE_PERMISSIONS = 'traffic_change_permissions';
export const PROJECTS_PERMISSIONS = 'projects';
export const TRAFFIC_CHANGE = 'traffic_change';
export const IMAGE_ANALYZER = 'image_analyzer';
export const FLAG_PERMISSIONS = 'flag';
export const SCHEDULES_PERMISSIONS = 'schedules';
export const UPLOAD_REPORT_PERMISSIONS = 'upload_report';
export const IMAGES_PERMISSIONS = 'images';
export const USERS_PERMISSIONS = 'users';


export const PERMISSIONS_KEYS: string[] = [
    REPORTS_PERMISSIONS,
    LINES_CSV_UPLOAD_PERMISSIONS,
    STOPS_FILE_UPLOAD_PERMISSIONS,
    PROJECTS_PERMISSIONS,
    TRAFFIC_CHANGE,
    IMAGE_ANALYZER,
    FLAG_PERMISSIONS,
    SCHEDULES_PERMISSIONS,
    UPLOAD_REPORT_PERMISSIONS,
    IMAGES_PERMISSIONS,
    USERS_PERMISSIONS,

];

export class Permissions implements Deserializable {

    reports: Permission;
    lines_csv_upload: Permission;
    stops_file_upload: Permission;
    projects: Permission;
    flag: Permission;
    schedules: Permission;
    upload_report: Permission;
    images: Permission;
    users: Permission;
    traffic_change: Permission;
    image_analyzer: Permission;

    static defaultPermissions(role = null) {
        switch (role) {
            case ADMIN_ROLE:
                return {
                    reports: READ_WRITE_PERMISSION,
                    lines_csv_upload: NO_PERMISSION,
                    stops_file_upload: NO_PERMISSION,
                    projects: READ_WRITE_PERMISSION,
                    flag: READ_WRITE_PERMISSION,
                    schedules: READ_WRITE_PERMISSION,
                    upload_report: READ_WRITE_PERMISSION,
                    images: READ_WRITE_PERMISSION,
                    users: READ_WRITE_PERMISSION,
                    traffic_change: READ_WRITE_PERMISSION,
                    image_analyzer: READ_WRITE_PERMISSION,
                    
                };
            case WORKER_ROLE:
                return {
                    reports: READ_PERMISSION,
                    lines_csv_upload: NO_PERMISSION,
                    stops_file_upload: NO_PERMISSION,
                    projects: READ_PERMISSION,
                    flag: NO_PERMISSION,
                    schedules: READ_PERMISSION,
                    upload_report: NO_PERMISSION,
                    images: READ_PERMISSION,
                    users: NO_PERMISSION,
                    traffic_change: NO_PERMISSION,
                    image_analyzer: NO_PERMISSION,
                };
            case OPERATOR_ROLE:
                return {
                    reports: READ_PERMISSION,
                    lines_csv_upload: NO_PERMISSION,
                    stops_file_upload: NO_PERMISSION,
                    projects: READ_PERMISSION,
                    flag: NO_PERMISSION,
                    schedules: NO_PERMISSION,
                    upload_report: NO_PERMISSION,
                    images: READ_PERMISSION,
                    users: NO_PERMISSION,
                    traffic_change: NO_PERMISSION,
                    image_analyzer: NO_PERMISSION,
                };
            default:
                return {
                    reports: NO_PERMISSION,
                    lines_csv_upload: NO_PERMISSION,
                    stops_file_upload: NO_PERMISSION,
                    projects: NO_PERMISSION,
                    flag: NO_PERMISSION,
                    schedules: NO_PERMISSION,
                    upload_report: NO_PERMISSION,
                    images: NO_PERMISSION,
                    users: NO_PERMISSION,
                    traffic_change: NO_PERMISSION,
                    image_analyzer: NO_PERMISSION,
                };
        }
    }

    deserialize(input: any) {
        this.reports = new Permission().deserialize({id: input.reports, title: translateEnum.REPORTS});
        this.lines_csv_upload = new Permission().deserialize({id: input.lines_csv_upload, title: translateEnum.LINES_CSV_UPLOAD});
        this.stops_file_upload = new Permission().deserialize({id: input.lines_csv_upload, title: ''});
        this.projects = new Permission().deserialize({id: input.projects, title: translateEnum.PROJECTS});
        this.flag = new Permission().deserialize({id: input.flag, title: translateEnum.CREATE_FLAG});
        this.schedules = new Permission().deserialize({id: input.schedules, title: translateEnum.STATION_MAINTENANCE});
        this.upload_report = new Permission().deserialize({id: input.upload_report, title: translateEnum.UPLOAD_REPORT});
        this.images = new Permission().deserialize({id: input.images, title: translateEnum.IMAGES});
        this.users = new Permission().deserialize({id: input.users, title: translateEnum.USERS});
        this.traffic_change = new Permission().deserialize({id: input.traffic_change, title: translateEnum.TRAFFIC_CHANGE});
        this.image_analyzer = new Permission().deserialize({id: input.image_analyzer, title: translateEnum.IMAGE_ANALYZER});
        return this;
    }

    toJson() {
        debugger;
        const result: PermissionJson = Permissions.defaultPermissions();
        let permission;
        for (let i = 0; i < PERMISSIONS_KEYS.length; i++) {
            permission = PERMISSIONS_KEYS[i];
            result[permission] = this[permission].id;
        }
        return result;
    }

    firstRouteByPermissions() {
        let permission;
        let route = null;

        for (let i = 0; i < PERMISSIONS_KEYS.length; i++) {
            permission = PERMISSIONS_KEYS[i];

            if (
                (permission === UPLOAD_REPORT_PERMISSIONS && this[permission].write) ||
                (permission === LINES_CSV_UPLOAD_PERMISSIONS && this[permission].write) ||
                (permission === STOPS_FILE_UPLOAD_PERMISSIONS && this[permission].write) ||
                (permission !== UPLOAD_REPORT_PERMISSIONS && permission !== LINES_CSV_UPLOAD_PERMISSIONS && permission !== STOPS_FILE_UPLOAD_PERMISSIONS&& this[permission].read)
            ) {
                route = permission;
                break;
            }
        }

        return route;
    }
}
