import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { ApiService } from "../../core/service/api.service";
import { Line } from "../../shared/models/line.model";
import { Report } from "../../shared/models/report.model";
import { Route } from "../../shared/models/route.model";
import { flagStatuses } from "../../shared/options/flag";
import { ReportFlag } from "./reports";

@Injectable({
  providedIn: "root",
})
export class ReportsService {
  constructor(private apiService: ApiService, private http: HttpClient) {}

  getReports(data: ReportFlag): Observable<Report[]> {
    const params = [];
    if (data.from_date && data.from_date !== "") {
      params.push("from_date=" + data.from_date);
    }
    if (data.to_date && data.to_date !== "") {
      params.push("to_date=" + data.to_date);
    }
    if (data.stop_codes && data.stop_codes.length) {
      params.push("stop_codes=" + data.stop_codes.join(","));
    }
    if (data.user_ids) {
      params.push("user_ids=" + data.user_ids);
    }
    if (data.full_list) {
      params.push("full_list=" + 1);
    }
    if (data.new_flag_required) {
      params.push("new_flag_required=" + true);
    }
    const flag = this.apiService.dropdown(data.flag);
    if (flag) {
      params.push("flag=" + flag);
    }
    const brail = this.apiService.dropdown(data.brail);
    if (brail) {
      params.push("brail=" + brail);
    }
    const poster = this.apiService.dropdown(data.static_poster);
    if (poster) {
      params.push("static_poster=" + poster);
    }
    const next = this.apiService.dropdown(data.next_bus);
    if (next) {
      params.push("next_bus=" + next);
    }
    if (data.stop_type) {
      params.push("stop_type=" + data.stop_type.id);
    }

    const page = 1;
    const limit = 50;

    params.push("skip=" + (page - 1) * limit);
    params.push(`limit=${limit + 1}`);
debugger;
    return this.http
      .get(this.apiService.BASE_URL + "reports?" + params.join("&"), {
        headers: this.apiService.getHeaders(),
      })
      .pipe(
        map((res: any) =>
          res.reports.map((report: Report, index) =>
            new Report(this.apiService).deserialize({
              report,
              index: index,
            })
          )
        )
      );
  }

  getCurrentReport(stopCode: number): Observable<Route[]> {
    return this.http
      .get(
        [this.apiService.BASE_URL + "reports", "currentReports", stopCode].join(
          "/"
        ),
        {
          headers: this.apiService.getHeaders(),
        }
      )
      .pipe(
        map((routes: Route[]) =>
          routes.map((route: Route & Line, index: number) => {         
            route.route = `${route.line_sign && `|${route.line_sign}| -`} ${
              route.line_dest
            }`;
            return new Route().deserialize({
              data: route,
              options: flagStatuses,
              index,
            });
          })
        )
      );
  }
}
