import {Report} from '../../../shared/models/report.model';
import {translateEnum} from '../../../shared/enum/translateEnum';
import {User} from '../../../shared/models/user.model';

export const download = (reports: Report[], users: User[]) => {
    let maxFlags = 0;
    let maxBarils = 0;
    const data = [];

    for (const report of reports) {
        const row = {
            [translateEnum.STOP_CODE_STATION]: report.stop.stop_code,
            [translateEnum.STOP_NAME]: report.stop.stop_name,
            [translateEnum.STOP_TYPE]: report.stopType,
            [translateEnum.SCHEDULE_PERFORMER]: report.userName(users),
            [translateEnum.CITY]: report.stop.city,
            [translateEnum.ACTION_DATE]: report.userDate,
            [translateEnum.STATIC_POSTER_STATUS]: report.static_poster && report.static_poster.statusName,
            [translateEnum.STATIC_POSTER_REASON]: report.static_poster && report.static_poster.reason,
        };

        let index = 0;
        for (const flag of report.flag) {
            if (flag.is_head) {
                row[translateEnum.STOP_HEAD] = flag.cell;
            } else {
                row[`${translateEnum.ROUTE_DESC} ${++index}`] = flag.cell;
            }
        }
        maxFlags = Math.max(maxFlags, index);

        index = 0;
        for (const brail of report.brail) {
            row[`${translateEnum.BRAIL_DESC} ${++index}`] = brail.cell;
        }
        maxBarils = Math.max(maxBarils, index);

        row[translateEnum.WORKER_COMMENTS] = report.comments_string;

        data.push(row);
    }

    const header: string[] = [
        translateEnum.STOP_CODE_STATION,
        translateEnum.STOP_NAME,
        translateEnum.STOP_TYPE,
        translateEnum.SCHEDULE_PERFORMER,
        translateEnum.CITY,
        translateEnum.ACTION_DATE,
        translateEnum.STATIC_POSTER_STATUS,
        translateEnum.STATIC_POSTER_REASON,
        translateEnum.STOP_HEAD
    ];

    for (let i = 1; i <= maxFlags; i++) {
        header.push(`${translateEnum.ROUTE_DESC} ${i}`);
    }

    for (let i = 1; i <= maxBarils; i++) {
        header.push(`${translateEnum.BRAIL_DESC} ${i}`);
    }

    header.push(translateEnum.WORKER_COMMENTS);

    return {header, data};
};
