import {Deserializable} from './deserializable.model';

export class Path implements Deserializable {

    id: string;
    route_id: number;
    route_short_name: string;
    route_long_name: string;
    value: string;

    deserialize(input: any) {
        Object.assign(this, input.data);
        this.value = `${input.route_long_name} - |${input.route_short_name}|`;
        return this;
    }

}
