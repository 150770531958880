import {Option} from '../../shared/interfaces/option';
import {translateEnum} from '../../shared/enum/translateEnum';
import {OPERATOR_NAME} from '../../shared/enum/operator';

export const operatorNames: Option[] = [
    {id: OPERATOR_NAME.OPERATOR_EGGED_TAAVURA, name: translateEnum.OPERATOR_EGGED_TAAVURA},
    {id: OPERATOR_NAME.OPERATOR_EGGED, name: translateEnum.OPERATOR_EGGED},
    {id: OPERATOR_NAME.OPERATOR_AFIKIM, name: translateEnum.OPERATOR_AFIKIM},
    {id: OPERATOR_NAME.OPERATOR_GB_TOURS, name: translateEnum.OPERATOR_GB_TOURS},
    {id: OPERATOR_NAME.OPERATOR_GALEEM, name: translateEnum.OPERATOR_GALEEM},
    {id: OPERATOR_NAME.OPERATOR_DANBR7, name: translateEnum.OPERATOR_DANBR7},
    {id: OPERATOR_NAME.OPERATOR_DANBADAROM, name: translateEnum.OPERATOR_DANBADAROM},
    {id: OPERATOR_NAME.OPERATOR_DANNORTH, name: translateEnum.OPERATOR_DANNORTH},
    {id: OPERATOR_NAME.OPERATOR_DAN, name: translateEnum.OPERATOR_DAN},
    {id: OPERATOR_NAME.OPERATOR_SHUFAT_BUS, name: translateEnum.OPERATOR_SHUFAT_BUS},
    {id: OPERATOR_NAME.OPERATOR_ATA_BUSES, name: translateEnum.OPERATOR_ATA_BUSES},
    {id: OPERATOR_NAME.OPERATOR_CENTER_BUSES, name: translateEnum.OPERATOR_CENTER_BUSES},
    {id: OPERATOR_NAME.OPERATOR_SOUTH_BUSES, name: translateEnum.OPERATOR_SOUTH_BUSES},
    {id: OPERATOR_NAME.OPERATOR_MOUNTOFOLIVES_BUS, name: translateEnum.OPERATOR_MOUNTOFOLIVES_BUS},
    {id: OPERATOR_NAME.OPERATOR_SURBAHER_BUS, name: translateEnum.OPERATOR_SURBAHER_BUS},
    {id: OPERATOR_NAME.OPERATOR_RAMALLAH_BUS, name: translateEnum.OPERATOR_RAMALLAH_BUS},
    {id: OPERATOR_NAME.OPERATOR_SHUFAT, name: translateEnum.OPERATOR_SHUFAT},
    {id: OPERATOR_NAME.OPERATOR_CALLKAV, name: translateEnum.OPERATOR_CALLKAV},
    {id: OPERATOR_NAME.OPERATOR_GOLAN_BUS, name: translateEnum.OPERATOR_GOLAN_BUS},
    {id: OPERATOR_NAME.OPERATOR_METROPOLINE, name: translateEnum.OPERATOR_METROPOLINE},
    {id: OPERATOR_NAME.OPERATOR_NTT_BUSES, name: translateEnum.OPERATOR_NTT_BUSES},
    {id: OPERATOR_NAME.OPERATOR_NATEEV_EXPRESS, name: translateEnum.OPERATOR_NATEEV_EXPRESS},
    {id: OPERATOR_NAME.OPERATOR_SUPERBUS, name: translateEnum.OPERATOR_SUPERBUS},
    {id: OPERATOR_NAME.OPERATOR_KAVIM, name: translateEnum.OPERATOR_KAVIM},
    {id: OPERATOR_NAME.OPERATOR_NAZARETH_UNBS, name: translateEnum.OPERATOR_NAZARETH_UNBS},
    {id: OPERATOR_NAME.OPERATOR_BETSHEMESH, name: translateEnum.OPERATOR_BETSHEMESH},
    {id: OPERATOR_NAME.OPERATOR_ELECTRA_AFIKIM, name: translateEnum.OPERATOR_ELECTRA_AFIKIM},
    {id: OPERATOR_NAME.OPERATOR_TNUFA, name: translateEnum.OPERATOR_TNUFA},
    {id: OPERATOR_NAME.OPERATOR_EXTRA, name: translateEnum.OPERATOR_EXTRA},
    {id: OPERATOR_NAME.OPERATOR_EXTRA_JERUSALEM, name: translateEnum.OPERATOR_EXTRA_JERUSALEM},
    {id: OPERATOR_NAME.EGED_DERECH, name: translateEnum.EGED_DERECH}

];
