import {Component, Input} from '@angular/core';
import {FlagPageLine} from '../../../../shared/models/flag-page-line.model';
import flagStyle from '../../flag-style';

@Component({
	selector: 'flag-strip-line',
	templateUrl: 'flag-strip-line.component.html',
	styleUrls: ['flag-strip-line.component.css']
})

export class FlagStripLineComponent {

	public flagStyle = flagStyle;

	@Input() public zoom: number;
	@Input() public flagPageLine: FlagPageLine | null;
	@Input() public lang: string;
	@Input() public width: number;
	@Input() public height: number;

	line_signs: string | null = null;

	fontSizeHead: number = flagStyle.heFlagLineRowTextFullNameHeadFontSize;
	fontSizeSub: number = flagStyle.enFlagLineRowTextFullNameSubFontSize;
	fontSizeNumber: number = flagStyle.flagLineRowTextFullNumberOneRowFontSize;

	scaleText() {
		if (this.flagPageLine && !this.flagPageLine.only_numbers) {

			const parent = document.getElementById(`flag-strip-line-text-full-${this.lang}-${this.flagPageLine.id}`);

			if (parent) {
				const head = document.getElementById(`flag-strip-line-text-full-head-${this.lang}-${this.flagPageLine.id}`);
				const sub = document.getElementById(`flag-strip-line-text-full-sub-${this.lang}-${this.flagPageLine.id}`);
				const number = document.getElementById(`flag-strip-line-text-full-number-${this.lang}-${this.flagPageLine.id}`);
				const parentHeight = parent.offsetHeight;
				const headSubHeight = head.offsetHeight + sub.offsetHeight;
				const parentWidth = parent.offsetWidth;
				const numberTitleWidth = Math.max(head.offsetWidth, sub.offsetWidth) + number.offsetWidth;
				const self = this;

				if (parentHeight <= headSubHeight || parentWidth <= numberTitleWidth) {
					if (sub.offsetHeight && this.fontSizeSub > 62) {
						this.fontSizeSub -= 2;
						sub.style.fontSize = this.fontSizeSub + 'pt';
						sub.style.lineHeight = (this.fontSizeSub - 16) + 'pt';
					}

					if (this.fontSizeHead > 70) {
						this.fontSizeHead -= 5;
						head.style.fontSize = this.fontSizeHead + 'pt';
						head.style.lineHeight = (this.fontSizeHead - 16) + 'pt';

						setTimeout(function () {
							self.scaleText();
						}, 500);
					}
				}
			}
		}
	}

	ngOnChanges(changes): void {
		if (changes.flagPageLine && changes.flagPageLine.currentValue) {

			const flagPageLine = changes.flagPageLine.currentValue;
			this.line_signs = flagPageLine ? flagPageLine.line_signs.join(',') : null;
		}
		if (changes.lang && changes.lang.currentValue) {
			const lang = changes.lang.currentValue;
			if (lang) {
				this.fontSizeHead = flagStyle[lang + 'FlagLineRowTextFullNameHeadFontSize'];
				this.fontSizeSub = flagStyle[lang + 'FlagLineRowTextFullNameSubFontSize'];
			}
		}
		this.fontSizeNumber = flagStyle.flagLineRowTextFullNumberOneRowFontSize;
	}

	ngAfterViewInit(): void {
		const self = this;
		setTimeout(function () {
			self.scaleText();
		}, 500);
	}

}
