import { translateEnum } from './shared/enum/translateEnum';

export const navItems = {
  reports: {
    name: 'דוחות',
    url: '/reports',
    icon: 'fa fa-star fa-lg'
  },
  lines_csv_upload: {
    name: 'עדכון נספח',
    url: '/lines_csv_upload',
    icon: 'fa fa-file fa-lg'
  },
  projects: {
    name: 'בקרת פרויקטים',
    url: '/projects',
    icon: 'fa fa-file fa-lg'
  },
  stops_file_upload: {
    name: 'עידכון מפעיל אחראי ',
    url: '/stops_file_upload',
    icon: 'fa fa-file fa-lg'
  },
  flag: {
    name: 'יצירת תמרורים',
    url: '/flag',
    icon: 'fa fa-flag-o fa-lg'
  },
  schedules: {
    name: 'תחזוקת תחנות',
    url: '/schedules',
    icon: 'fa fa-calendar'
  },
  upload_report: {
    name: translateEnum.UPLOAD_REPORT,
    url: '/upload_report',
    icon: 'fa fa-upload fa-lg'
  },
  traffic_change: {
    name: translateEnum.TRAFFIC_CHANGE,
    url: '/traffic_change',
    icon: 'fa fa-upload fa-lg'
  },
  image_analyzer: {
    name: 'פיענוח תמונה',
    url: '/image_analyzer',
    icon: 'fa fa-upload fa-lg'
  },
  reports_validation: {
    name: 'ולידציית דוחות',
    url: '/reports_validation',
    icon: 'fa fa-file fa-lg'
  },
  images: {
    name: 'תמונות',
    url: '/images',
    icon: 'fa fa-picture-o fa-lg'
  },
  users: {
    name: 'משתמשים',
    url: '/users',
    icon: 'fa fa-user-o fa-lg'
  },

};
