import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from './api.service';
import {translateEnum} from '../../shared/enum/translateEnum';
import {ShiftApi} from '../../shared/interfaces/api';
import {Upload} from '../../shared/interfaces/upload';
import {isNumeric} from '../../../app/global.component';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UploadsService {

    constructor(private api: ApiService, public router: Router) {
    }

    validation(name, user, from_date, to_date, stop_codes, doc: Upload[] | null = null) {
        debugger;
        const validation_errors = {
            name: null,
            user: null,
            from_date: null,
            to_date: null,
            stop_codes: null,
        };

        if (!name || name.length === 0) {
            validation_errors.name = translateEnum.FIELD_REQUIRED;
        }

        if (!user) {
            validation_errors.user = translateEnum.FIELD_REQUIRED;
        }

        if (!from_date) {
            validation_errors.from_date = translateEnum.FIELD_REQUIRED;
        }

        if (!to_date) {
            validation_errors.to_date = translateEnum.FIELD_REQUIRED;
        }

        if (from_date && to_date && from_date > to_date) {
            validation_errors.to_date = translateEnum.INVALID_DATES;
        }

        if (!stop_codes || stop_codes.length === 0) {
            validation_errors.stop_codes = translateEnum.MISSING_CODES;
        } else if (doc) {
            validation_errors.stop_codes = doc.reduce((acc: string | false, upload: Upload) => {
                return acc ? acc : !isNumeric(upload.A) && translateEnum.INVALID_STOP_CODE.replace('[stop_code]', upload.A);
            }, false);
        }

        for (const prop in validation_errors) {
            if (validation_errors[prop]) {
                return validation_errors;
            }
        }

        // validation succeed
        return {};
    }

    submit(data: ShiftApi) {
        this.api.postShifts(data).subscribe(
            r => {
                // finish upload
                data.on_success(r.shift);
            },
            r => {
                // finished - show error message
                data.on_error(r.error);
            });
    }

    
    submitValidation(data: ShiftApi) : Observable<any>{
        return this.api.postShiftsValidation(data)
    }
}
