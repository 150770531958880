import {Component, OnInit} from '@angular/core';
import { UserService } from '../../core/service/user.service';
import { translateEnum } from '../../shared/enum/translateEnum';
import { FLAG_PERMISSIONS } from '../../shared/models/permissions.model';
import flagStyle from './flag-style';

@Component({
    templateUrl: 'flag.component.html',
    styleUrls: ['./flag.component.css', '../../app.component.css']
})
export class FlagComponent implements OnInit {

    public flagStyle = flagStyle;
    readonly translateEnum = translateEnum;

    zoom: number = 60;
    tab = 0;

    constructor(public userService: UserService) {
        debugger;
        this.userService.pagePermission(FLAG_PERMISSIONS);
    }

    onResize(event) {
        debugger;
        if (window.innerWidth > 1415) {
            this.zoom = 45;
        } else if (window.innerWidth > 900) {
            this.zoom = 40;
        } else if (window.innerWidth > 650) {
            this.zoom = 30;
        } else if (window.innerWidth > 520) {
            this.zoom = 25;
        } else if (window.innerWidth > 400) {
            this.zoom = 17;
        } else if (window.innerWidth > 350) {
            this.zoom = 15;
        } else {
            this.zoom = 10;
        }
    }

    ngOnInit(): void {
        this.onResize(null);
    }
}
