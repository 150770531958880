import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { AlertModule } from "ngx-bootstrap/alert";
import { ModalModule } from "ngx-bootstrap/modal";
import { BaseModule } from "../base/base.module";
import { BaseComponent } from "./base.component";
import { LinesCsvUploadRoutingModule } from "./lines-csv-upload-routing.module";
import { LinesCsvUploadComponent } from "./lines-csv-upload.component";
import { LinesLogsComponent } from "./lines-logs/lines-logs.component";
import { EditTableComponent } from './edit-table/edit-table.component';

@NgModule({
  imports: [
    LinesCsvUploadRoutingModule,
    ReactiveFormsModule,
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    AlertModule,
    ModalModule,
    BaseModule,
  ],
  declarations: [BaseComponent, LinesCsvUploadComponent, LinesLogsComponent,
        EditTableComponent],
  exports: [],
})
export class LinesCsvUploadModule {}
