import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { translateEnum } from '../../shared/enum/translateEnum';
import { Export } from '../../shared/models/export.model';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { DOWNLOAD_TYPE } from '../../shared/enum/report';
import { EXPORT_TYPE } from '../../shared/enum/export';

@Injectable({
    providedIn: 'root'
})
export class ExportsService {

    translateEnum = translateEnum;
    observe: Subject<Export[]> = new Subject();
    data: Export[] = [];
    load: boolean = false;

    constructor(private api: ApiService, public router: Router, public userService: UserService) {
    }

    loadExports() {
        this.load = true;

        const user = this.userService.getUser();
        let reports_array = ['REPORTS_GENERAL'];
        if (user.isAdmin) {
            reports_array = [
                ...reports_array,
                ...['REPORTS_HEAD', 'REPORTS_FLAG', 'REPORTS_STATIC', 'REPORTS_BRAIL', 'REPORTS_SHEDS', 'REPORTS_COMMENT','REPORTS_OPERATOR_IN_CHARGE']
            ];
        }
        const reports_exports = reports_array.map(key => {
            return new Export().deserialize({
                name: translateEnum[`DOWNLOAD_BUTTON_${key}`],
                columns: [],
                loading: false,
                report_type: DOWNLOAD_TYPE[key],
                custom: false,
                type: EXPORT_TYPE.REPORTS
            });
        });

        reports_exports.push(new Export().deserialize({
            name: translateEnum[`DOWNLOAD_BUTTON_PROJECTS_GENERAL`],
            columns: [],
            loading: false,
            report_type: DOWNLOAD_TYPE.PROJECTS_GENERAL,
            custom: false,
            type: EXPORT_TYPE.PROJECTS
        }));

        reports_exports.push(new Export().deserialize({
            name: translateEnum[`DOWNLOAD_BUTTON_PROJECT_GENERAL`],
            columns: [],
            loading: false,
            report_type: DOWNLOAD_TYPE.PROJECT_GENERAL,
            custom: false,
            type: EXPORT_TYPE.PROJECT
        }));

        this.api.getExports()
            .subscribe(
                async r => {
                    const data = [...reports_exports, ...r];
                    this.observe.next(data);
                    this.data = data;
                },
                r => {
                    console.log(r.error);
                });
    }

    getExports(request) {
        if (!this.load || request) {
            this.loadExports();
        }
        return this.observe.asObservable();
    }

    refresh() {
        this.observe.next(this.data);
    }

    setLoadingById(id: string, loading: boolean) {
        const ex = this.data.find((exp: Export) => exp.id === id);
        if (ex) {
            ex.setLoading(loading);
        }
    }
}
