import {Deserializable} from './deserializable.model';
import {Stop} from './stop.model';
import {Tool} from './tool.model';
import {Photo} from './photo.model';
import {History} from './history.model';
import {ApiService} from '../../core/service/api.service';
import {stopTypes} from '../options/stop';
import {STOP_TYPE_CODES} from '../../shared/enum/stop';
import {User} from './user.model';
import {Audit} from './audit.model';
import {Path} from './path.model';
import {BaseComponent} from '../../../app/base.component';
import {Route} from '../../shared/models/route.model';
import {flagStatuses} from '../options/flag';
import {brailStatuses} from '../options/brail';
import {staticPosterStatuses} from '../options/static_poster';
import {nextBusStatuses} from '../options/next_bus';
import {Option} from '../../shared/interfaces/option';
import {Comment} from '../../shared/models/comment.model';
import {datesCompare} from '../../../app/global.component';
import {Shift} from '../../shared/models/shift.model';
import {Task} from './task.model';
import {Tasks} from '../../shared/models/tasks.model';

export class Report extends BaseComponent implements Deserializable {

    index: number;
    id: string = '';
    created_at: string;
    date: string;
    trafficstatus:string;
    trafficaction:string;
    userDate: string;
    ext_id: number;
    flag: Route[];
    brail: Route[];
    images: any;
    merchant: string;
    stop: Stop;
    stop_type: number;
    stopType: string;
    user: string;
    userObject: User;
    static_poster: Tool;
    next_bus: Tool;
    comments: Comment[] = [];
    comments_string: string = null;
    usr_lat: number;
    usr_lng: number;
    history: History[];
    audits: Audit[] = [];
    last_audit: Audit = null;
    paths: Path[];
    new_flag_required: boolean;
    is_wrong: boolean;
    num_of_stations: number;
    shift: string;
    shift_object: Shift;
    tasks: Task[];

    constructor(private api: ApiService) {
        super();
    }

    deserialize(input: any) {
        const report = input.report;
        Object.assign(this, report);
        this.stop = new Stop().deserialize(report.stop);
        this.static_poster = new Tool().deserialize({data: report.static_poster, options: staticPosterStatuses});
        this.next_bus = new Tool().deserialize({data: report.next_bus, options: nextBusStatuses});
        this.flag = report.flag ? report.flag.map((route, index) => new Route().deserialize({
            data: route,
            options: flagStatuses,
            index
        })) : [];
        this.brail = report.brail ? report.brail.map((route, index) => new Route().deserialize({
            data: route,
            options: brailStatuses,
            index
        })) : [];
        this.userDate = this.getDateTime(this.date);
        this.stopType = this.getStopType();
        this.images = this.photos(report.images);
        this.history = [];
        this.is_wrong = this.isWrongReport();
        return this;
    }

    public photos(images) {
        const result = [];

        if (images) {

            const count = images.length;
            let photo;

            for (let j = 0; j < count; j++) {
                photo = new Photo(this.api).deserialize(images[j]);
                photo.setReport(this);
                result.push(photo);
            }
        }

        return result;

    }

    userName(users) {
        const user = users.find(item => item.id === this.user);
        return user ? user.first_name+' '+user.last_name : '';
    }

    getStopTypeInterface() {
        const stop_type = this.stop_type;
        return stopTypes.find((type: Option) => type.id === stop_type);
    }

    getStopType() {
        const type = this.getStopTypeInterface();
        if (type) {
            if (this.isMultipleSheds()) {
                return `${type.name} - ${this.num_of_stations}`;
            }
            return type.name;
        }
        return '';
    }

    setStopType(type: Option) {
        if (type) {
            this.stop_type = type.id;
            this.stopType = type.name;
        } else {
            this.stop_type = null;
            this.stopType = '';
        }
    }

    setNumOfStations(num_of_stations: number) {
        this.num_of_stations = num_of_stations;
    }

    setUser(user: User) {
        if (user) {
            this.user = user.id;
            this.userObject = user;
        } else {
            this.user = null;
            this.userObject = null;
        }
    }

    setIndex(index) {
        this.index = index;
    }

    showStaticPosterStatus() {
        return this.static_poster && this.static_poster.statusName;
    }

    showStaticPosterReason() {
        return this.static_poster && this.static_poster.reason;
    }

    showNextBusStatus() {
        return this.next_bus && this.next_bus.statusName;
    }

    showNextBusReason() {
        return this.next_bus && this.next_bus.reason;
    }

    getHistory() {
        const self = this;
        self.api.getHistory(this.id).subscribe(
            r => {
                self.history = r;
            },
            r => {
                console.log(r);
                self.history = [];
            });
    }

    getAuditsByReport() {
        const self = this;
        self.api.getAuditsByReport(this.id).subscribe(
            r => {
                self.setAudits(r);
            },
            r => {
                console.log(r);
                self.setAudits([]);
            });
    }

    setAudits(audits: Audit[] = []) {
        this.audits = audits;
        this.audits.sort((a: Audit, b: Audit) => {
            return datesCompare(a.created_at, b.created_at);
        });
        this.last_audit = audits.length ? audits[audits.length - 1] : null;
    }

    setShift(shift: Shift) {
        this.shift_object = shift;
        this.setTasks(shift.tasks);
    }

    setTasks(tasks: Tasks) {
        if (tasks) {
            this.tasks = tasks.getStopTasks(this.stop.stop_code);
        }
    }

    getTasksString() {
        if (this.tasks) {
            return this.tasks.map((task: Task) => {
                return task.toString();
            }).join('\n');
        }
        return '';
    }

    setComments(comments: Comment[] | null) {
        if (!comments) {
            comments = [];
        }
        this.comments = comments.sort((comment1, comment2) => new Date(comment2.date).valueOf() - new Date(comment1.date).valueOf());
        if (this.comments.length) {
            this.comments_string = this.comments.map(comment => comment.text).join(' , ');
        }
    }

    getComments() {
        const self = this;
        self.api.getComments([this.id]).subscribe(
            r => {
                self.setComments(r);
            },
            r => {
                console.log(r);
                self.setComments([]);
            });
    }

    getPaths() {
        const self = this;
        self.api.getPaths(this.stop.id).subscribe(
            r => {
                self.paths = r;
            },
            r => {
                console.log(r);
                self.paths = [];
            });
    }

    isWrongReport() {
        return !!(
            this.new_flag_required ||
            (this.static_poster && this.static_poster.is_wrong) ||
            (this.flag && this.flag.find((route: Route) => route.is_wrong)) ||
            (this.brail && this.brail.find((route: Route) => route.is_wrong))
        );
    }

    isMultipleSheds() {
        return this.stop_type === STOP_TYPE_CODES.SHEDS;
    }

}
