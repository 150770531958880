import jsPDF from 'jspdf';
import html2canvas from 'html2canvas'
import 'jspdf-autotable';
import { translateEnum } from "../../../shared/enum/translateEnum";
import { Report } from "../../../shared/models/report.model";
import { Stop } from "../../../shared/models/stop.model";
import { User } from "../../../shared/models/user.model";
import { siteLogoBase64 } from '../../../shared/base64/site-logo-base46';
import { arimoBold } from '../../../shared/fonts/Arimo-Bold';
import { arimoNormal } from '../../../shared/fonts/Arimo-Normal';
import { getOrientation } from "get-orientation";
import { Photo } from "../../../shared/models/photo.model";
(window as any).getOrientation = getOrientation;

const TOP_LEFT = 0; // 1 - Horizontal (Default)
const TOP_RIGHT = 0; // 2 - Mirror Horizontal
const BOTTOM_RIGHT = -180; // 3 - Rotate 180
const BOTTOM_LEFT = 180; // 4 - Mirror vertical
const LEFT_TOP = 270; // 5 - Mirror horizontal and rotate 270 CW
const RIGHT_TOP = -90; // 6 - Rotate 90 CW
const RIGHT_BOTTOM = 90; // 7 - Mirror horizontal and rotate 90 CW
const LEFT_BOTTOM = -270; // 8 - Rotate 270 CW
const ORIENTATION = [TOP_LEFT, TOP_LEFT, TOP_RIGHT, BOTTOM_RIGHT, BOTTOM_LEFT, LEFT_TOP, RIGHT_TOP, RIGHT_BOTTOM, LEFT_BOTTOM];

export class ReportPdfService {

	translateEnum = translateEnum;
	siteLogoBase64 = siteLogoBase64;
	arimoBold = arimoBold;
	arimoNormal = arimoNormal;

	report: Report;
	stop: Stop;
	users: User[];
	pdf: jsPDF;

	pageWidth: number;
	pageHeight: number;
	pageCenter: number;
	pageMargin: number = 50;

	mapHeight: number = 180;
	fontHeight: number = 8;

	mapStartY: number = 250;
	dataStartY: number = this.mapStartY + this.mapHeight + 2 * this.fontHeight;

	constructor(report, users) {
		this.report = report;
		this.stop = report.stop;
		this.users = users;
		this.pdf = new jsPDF('p', 'pt', 'a4');

		this.pageWidth = this.pdf.internal.pageSize.getWidth();
		this.pageHeight = this.pdf.internal.pageSize.getHeight();
		this.pageCenter = this.pageWidth / 2;
	}

	async download() {
		let margin = 50;
		let bodyStartY = 250;
		let mapHeight = 180;
		let regularFontHeight = 8;
		let secondBodyStartY = bodyStartY + mapHeight + 2 * regularFontHeight;

		this.addFonts();
		await this.googleMap(margin, bodyStartY, mapHeight, secondBodyStartY);
		this.actionDat(margin, secondBodyStartY, regularFontHeight);

		let headerMetaData = this.header(margin);

		this.title(headerMetaData.y);
		this.stopLocation(bodyStartY, regularFontHeight, this.pageWidth, margin);
		let stopDataMetaData = this.stopData(secondBodyStartY, this.pageWidth, margin, regularFontHeight);

		if (this.report.flag && this.report.flag.length > 0) {
			let y = stopDataMetaData.y + 2 * regularFontHeight;
			this.createTable(this.report.flag, y, margin - 3, translateEnum.ROUTE_DESC);
		}
		let y;
		if (this.report.brail && this.report.brail.length > 0) {
			y = + (this.pdf as any).lastAutoTable.finalY + 2 * regularFontHeight;
			this.createTable(this.report.brail, y, margin - 3, translateEnum.BRAIL_DESC);
		}

		if (this.report.images && this.report.images.length > 0) {
			let imageWidth = this.pageWidth - margin * 2;
			let imageHeight = this.pageHeight - margin * 2;
			let imagesLength = this.report.images.length;

			for (let i = 0 ; i < imagesLength ; i++) {
				const photo = this.report.images[i];
				if (!photo.fail) {
					await this.readFile(photo, imageWidth, imageHeight, this.pdf, margin);
				}
			}
		}

		this.numberOfPages();

		this.pdf.save(`stop-${this.report.stop.stop_code}-${this.report.date}.pdf`);
	}

	numberOfPages() {

		const siteLogoBase64 = this.siteLogoBase64();
		const imageWidth = 30;
		const imageHeight = 20;
		const imageX = this.pageCenter - imageWidth / 2;
		const imageY = this.pageHeight - 31;

		// @ts-ignore
		const pageCount = this.pdf.internal.getNumberOfPages();
		for(let i = 0; i < pageCount; i++) {
			this.pdf.setPage(i);

			// @ts-ignore
			let pageNumber = this.pdf.internal.getCurrentPageInfo().pageNumber;

			this.pdf.text(
				translateEnum.PAGE_NUMBER.replace('[page]', pageNumber).replace('[total]', pageCount),
				this.pageWidth / 2 - 35,
				this.pageHeight - 36
			);
			this.pdf.addImage(siteLogoBase64, "JPEG", imageX, imageY, imageWidth, imageHeight, null, 'NONE');
		}
	}

	readFile(photo: Photo, pageWidth: number, pageHeight: number, pdf, margin: number) {
		return new Promise(async (resolve, reject) => {

			let reader = new FileReader();
			reader.onloadend = async function () {
				let img = new Image();
				img.src = reader.result as string;

				// @ts-ignore
				await img.decode();
				let imageWidth = img.width;
				let imageHeight = img.height;
				pdf.addPage();

				if (imageWidth > pageWidth || imageHeight > pageHeight) {
					let scaleY = imageHeight / pageHeight;
					let scaleX = imageWidth / pageWidth;
					let maxScale = Math.max(scaleX, scaleY);

					imageWidth = imageWidth / maxScale;
					imageHeight = imageHeight / maxScale;
				}

				if ([0, 180, -180].indexOf(ORIENTATION[photo.orientation_source]) > -1) {
					pdf.addImage(img, 'JPEG', (pageWidth - imageWidth) / 2 + 50, 50, imageWidth, imageHeight, null, 'NONE');
				} else {
					pdf.addImage(img, 'JPEG', (pageWidth - imageWidth + margin * 2) / 2, -pageHeight + imageHeight / 2, imageHeight, imageWidth, null, 'NONE', ORIENTATION[photo.orientation_source]);
				}

				resolve();

			};

			reader.onerror = err => reject(err);

			if (photo.blob === null) {
				await photo.downloadImageData();
			}

			reader.readAsDataURL(photo.blob);
		});
	}

	addFonts() {

		this.pdf.addFileToVFS('Arimo-Bold-normal.ttf', arimoBold());
		this.pdf.addFont('Arimo-Bold-normal.ttf', 'Arimo-Bold-normal', 'bold');

		this.pdf.addFileToVFS('Arimo-Regular-normal.ttf', arimoNormal());
		this.pdf.addFont('Arimo-Regular-normal.ttf', 'Arimo-Regular-normal', 'regular');
	}

	async googleMap(margin, bodyStartY, mapHeight, secondBodyStartY) {
		let canvas = await html2canvas(document.getElementById('googleMapId'),
			{
				useCORS: true,
				width: 1000,
				height: 1000,
				allowTaint: true,
				scrollX: 0,
				scrollY: 0
			});

		let pageData = canvas.toDataURL('image/png');

		this.pdf.addImage(pageData, 'PNG', margin, bodyStartY, 600, 600, null, 'NONE');

		//google map lan lon
		if (this.stop) {
			this.pdf.setFont("Arimo-Regular-normal", "regular");
			this.pdf.setFontSize(10);
			let lonlan = this.stop.stopLocation();
			let metadataLonlan = this.textMetaData(lonlan, 10, 'normal');
			let xLonlan = margin + (mapHeight - metadataLonlan.width) / 2 + 2;

			this.pdf.setR2L(false); //RTL
			this.pdf.text(lonlan, xLonlan, secondBodyStartY);
		}
	}

	actionDat(margin, secondBodyStartY, regularFontHeight) {
		this.pdf.setFont("Arimo-Regular-normal", "regular");
		this.pdf.setFontSize(10);
		let content = translateEnum.ACTION_DATE + ": " + this.report.userDate;

		this.pdf.setR2L(true); //RTL
		this.pdf.text(content, margin + 16, secondBodyStartY + 2 * regularFontHeight);
	}

	header(margin) {
		//logo
		let siteLogoBase64 = this.siteLogoBase64();
		let imageWidth = 75;
		let imageHeight = 45;
		let imageX = this.pageCenter - imageWidth / 2;
		let imageY = margin;
		this.pdf.addImage(siteLogoBase64, "JPEG", imageX, imageY, imageWidth, imageHeight, null, 'NONE');

		//header
		this.pdf.setFont("Arimo-Bold-normal", "bold");
		this.pdf.setFontSize(20);
		let headerText = translateEnum.COMPANY_FULL_NAME;
		let x = this.textCenter(headerText, 20, 'bold');
		let y = imageY + imageHeight + 29;

		this.pdf.setR2L(true); //RTL
		this.pdf.text(headerText, x, y);

		return {x: x, y: y};
	}

	title(nextY) {
		//sub header
		this.pdf.setFont("Arimo-Bold-normal", "bold");
		this.pdf.setFontSize(14);
		let subHeaderText = 'תחנה ' + this.stop.stop_code + " )מק״ט(";
		let x = this.textCenter(subHeaderText, 14, 'bold');
		let y = nextY + 50;

		this.pdf.setR2L(true); //RTL
		this.pdf.text(subHeaderText, x, y);

		//sub header underline
		let subHeaderMetaData = this.textMetaData(subHeaderText, 14, 'bold');
		let x1 = x;
		let x2 = x1 + subHeaderMetaData.width;
		let y1 = y + 1;
		let y2 = y1;
		this.pdf.setLineWidth(1.5);
		this.pdf.line(x1, y1, x2, y2);

		return {x: x, y: y};
	}

	stopLocation(bodyStartY, regularFontHeight, width, margin) {
		this.pdf.setFont("Arimo-Regular-normal", "regular");
		this.pdf.setFontSize(10);
		let y = bodyStartY - regularFontHeight;

		let contents = [
			{
				key: translateEnum.STOP_NAME,
				keyWidth: 45,
				value: this.stop.stop_name,
			},
			{
				key: translateEnum.STOP_TYPE,
				keyWidth: 44.5,
				value: this.report.stopType,
			},
			{
				key: translateEnum.STOP_ADDRESS,
				keyWidth: 58.5,
				value: this.stop.address,
			}
		];
		return this.setTextContents(contents, y, width, margin, regularFontHeight);
	}

	stopData(secondBodyStartY, width, margin, regularFontHeight) {
		//stop data font - second section
		let contents = [
			{
				key: translateEnum.PERFORMER,
				keyWidth: 27,
				value: this.report.userName(this.users),
			}
		];
		if (this.report.showStaticPosterStatus()) {
			contents.push(
				{
					key: translateEnum.STATIC_POSTER_STATUS,
					keyWidth: 76,
					value: this.report.static_poster.statusName,
				});
		}
		if (this.report.showStaticPosterReason()) {
			contents.push(
				{
					key: translateEnum.STATIC_POSTER_REASON,
					keyWidth: 74,
					value: this.report.static_poster.reason,
				});
		}
		if (this.report.showNextBusStatus()) {
			contents.push(
				{
					key: translateEnum.NEXT_BUS_STATUS,
					keyWidth: 62,
					value: this.report.next_bus.statusName,
				});
		}
		if (this.report.showNextBusReason()) {
			contents.push(
				{
					key: translateEnum.NEXT_BUS_REASON,
					keyWidth: 65,
					value: this.report.next_bus.reason,
				});
		}
		if (this.report.comments_string) {
			contents.push(
				{
					key: translateEnum.WORKER_COMMENTS,
					keyWidth: 72,
					value: this.report.comments_string,
				});
		}
		if (this.report.new_flag_required) {
			contents.push(
				{
					key: translateEnum.FLAG_REQUIRED,
					keyWidth: 42,
					value: translateEnum.YES,
				});
		}
		return this.setTextContents(contents, secondBodyStartY, width, margin, regularFontHeight);
	}

	textMetaData(text, fontSize, fontWeight) {
		let tag = document.createElement("div");
		tag.style.position = "absolute";
		tag.style.left = "-999em";
		tag.style.whiteSpace = "nowrap";
		tag.style.fontFamily = "'Arimo', sans-serif";
		tag.style.fontSize = fontSize + "px";
		tag.style.fontWeight = fontWeight;
		tag.innerHTML = text;
		document.body.appendChild(tag);
		let width = tag.clientWidth;
		let height = tag.clientHeight;
		document.body.removeChild(tag);
		return {width: width, height: height};
	}

	textCenter(text, fontSize, fontWeight) {
		let metaData = this.textMetaData(text, fontSize, fontWeight);
		return this.pageCenter - metaData.width / 2;
	}

	setTextContents(contents, y, width, margin, regularFontHeight) {
		let metadata, x, key, value, content;

		for (let i = 0 ; i < contents.length ; i++) {
			content = contents[i];
			y = y + 2 * regularFontHeight;

			//set key
			key = content.key + ": ";
			x = width - margin - content.keyWidth;

			this.pdf.setR2L(true); //RTL
			this.pdf.text(key, x, y);

			//set value
			value = (content.value).trim();
			metadata = this.textMetaData(value, 10, 'normal');
			x = width - margin - metadata.width - content.keyWidth;

			if ((/[\u0590-\u05FF]/).test(value)) {
				this.pdf.setR2L(true); //RTL
			} else {
				this.pdf.setR2L(false); //RTL
			}

			this.pdf.text(value, x, y);
		}
		return {y: y};
	}

	parseTableColumnText(text) {
		let result = '';

		text.split('').map(letter => {
			if (letter == '>') {
				letter = '<';
			}
			else if (letter == '<') {
				letter = '>';
			}
			result += letter;
		});

		return result;
	}

	createTable(list, y, margin, headerName) {
		this.pdf.setR2L(true);

		let body = [];
		let cell, route;

		//build table data
		for (let i = 0 ; i < list.length ; i++) {
			cell = list[i];
			route = this.parseTableColumnText(cell.route);
			body.push([cell.statusName, route]);
		}

		//config table setting
		this.pdf.setFont("Arimo-Regular-normal", "regular");
		(this.pdf as any).autoTable({
			startY: y,
			margin: margin,
			head: [[translateEnum.STATUS, headerName]],
			body: body,
			styles: {
				font: "Arimo-Regular-normal",
				fontStyle: "regular",
				halign: 'right',
				minCellWidth: 45,
			},
			columnStyles: {
				name: {
					font: 'Arimo-Regular-normal',
				},
			},
		});
	}


}
