import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseModalComponent } from '../../../../base-modal.component';
import { ApiService } from '../../../../core/service/api.service';
import { UsersService } from '../../../../core/service/users.service';
import { translateEnum } from '../../../../shared/enum/translateEnum';
import { ShiftApi } from '../../../../shared/interfaces/api';
import { Option } from '../../../../shared/interfaces/option';
import { ValidationErrors } from '../../../../shared/interfaces/validation-errors';
import { Shift } from '../../../../shared/models/shift.model';
import { User } from '../../../../shared/models/user.model';
import { scheduleStatuses } from '../../../../shared/options/schedule';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'edit-schedule',
    templateUrl: 'edit-schedule.component.html',
    styleUrls: ['../../../../app.component.css']
})
export class EditScheduleComponent extends BaseModalComponent {

    translateEnum = translateEnum;
    readonly scheduleStatuses = scheduleStatuses;

    @Input() public close: Function = null;

    shift: Shift;

    disabled: boolean = false;
    error: string = null;
    validation_errors: ValidationErrors = {};

    resetFromDateSubject: Subject<any> = new Subject<any>();
    resetToDateSubject: Subject<any> = new Subject<any>();

    from_date: string;
    to_date: string;
    name: string;

    users: User[] = [];
    user: User;

    status: Option;

    /** events sent from OUTER -> into COMPONENT to update list **/
    usersSubject: Subject<any> = new Subject<any>();

    /** events sent from OUTER -> into COMPONENT to update item **/
    userSubject: Subject<any> = new Subject<any>();


    constructor(private api: ApiService, private cd: ChangeDetectorRef, private usersService: UsersService) {
        super();
        this.child = this;
        this.onSubmit = this.onSubmit.bind(this);
        this.exitModal = this.exitModal.bind(this);
    }

    open(shift: Shift) {
        this.shift = shift;
        this.from_date = shift.from_date;
        this.to_date = shift.to_date;
        this.name = shift.name;

        this.resetFromDateSubject.next(shift.fromDateObject);
        this.resetToDateSubject.next(shift.toDateObject);

        this.disabled = false;
        this.error = null;
        this.validation_errors = {};

        this.getUsers();
        this.user = shift.user;
        this.userSubject.next(this.user);

        this.status = scheduleStatuses.find(status => status.id === shift.status);
    }

    onSubmit() {
        this.error = null;
        this.validation_errors = {};

        if (!this.user) {
            this.validation_errors.user = this.translateEnum.FIELD_REQUIRED;
        }

        if (!this.from_date || !this.to_date) {
            if (!this.from_date) {
                this.validation_errors.from_date = this.translateEnum.FIELD_REQUIRED;
            }
            if (!this.to_date) {
                this.validation_errors.to_date = this.translateEnum.FIELD_REQUIRED;
            }
        } else if (this.from_date > this.to_date) {
            this.error = this.translateEnum.INVALID_DATES;
        }

        this.name = this.name.trim();
        if (!this.name.length) {
            this.validation_errors.name = this.translateEnum.FIELD_REQUIRED;
        }

        if (Object.keys(this.validation_errors).length || this.error) {
            return;
        }

        this.disabled = true;

        const self = this;
        const data: ShiftApi = {
            from_date: this.from_date,
            to_date: this.to_date,
            user_id: this.user.id,
            status: this.status.id,
            name: this.name,
        };

        this.api.updateSchedule(this.shift.id, data)
            .subscribe(
                r => {
                    self.shift.setName(self.name);
                    self.shift.setStatus(self.status.id);
                    self.shift.setUser(self.user);
                    self.shift.setDates(data.from_date, data.to_date);
                    self.exitModal();
                    if (self.observe) {
                        self.observe(self.shift);
                        self.cd.markForCheck();
                    }
                },
                r => {
                    self.error = r.error.message;
                    self.disabled = false;
                });
    }

    onFromDate(date) {
        this.from_date = this.getISO8601FromDate(date);
    }

    onToDate(date) {
        this.to_date = this.getISO8601ToDate(date);
    }

    /** get all users from server **/
    getUsers() {
        const self = this;
        self.users = [];
        if (this.usersService) {
            this.usersService.getUsers(false).subscribe(users => {
                self.users = users;
                self.usersSubject.next(users);
            });
            this.usersService.refresh();
        }
    }

    /** set selected user from single-selection component event **/
    selectUser(data) {
        this.user = data;
    }

    /** set selected status from single-selection component event **/
    selectStatus(data) {
        this.status = data;
    }

    exitModal() {
        if(this.modal)
            this.closeModal();
        if (this.close) {
            this.close();
        }
    }
}
