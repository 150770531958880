import {Deserializable} from './deserializable.model';
import {getDateTime} from '../../global.component';

export class History implements Deserializable {

    index: number;
    changedBy: string;
    date: string;
    comment: string;

    constructor(index) {
        this.index = ++index;
    }

    deserialize(input: any) {
        Object.assign(this, input);
        this.date = getDateTime(input.changedAt);
        return this;
    }

}
