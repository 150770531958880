import {Component, Input} from '@angular/core';
import {translateEnum} from '../../../shared/enum/translateEnum';
import {Photo} from '../../../shared/models/photo.model';

@Component({
    selector: 'photos-carousel',
    templateUrl: 'photos-carousel.component.html',
    styleUrls: ['photos-carousel.component.css', '../../../app.component.css']
})

export class PhotosCarouselComponent {

    readonly translateEnum = translateEnum;

    @Input() public photos: Photo[] = [];

    photo: Photo = null;
    selected: number = 0;
    imageRes: string[]=[];
    imageResType:Number;
    db: string[]=[];


    constructor() {
        this.getDownloadPhotos = this.getDownloadPhotos.bind(this);
        this.resetSelected = this.resetSelected.bind(this);
        this.onCheckbox = this.onCheckbox.bind(this);
        this.activeSlideChange = this.activeSlideChange.bind(this);
    }

    getDownloadPhotos() {
        if (this.selected) {
            return this.photos.filter((photo: Photo) => photo.selected);
        }
        return this.photos;
    }

    public printImageAnalyze(res: any):void {
        debugger;
        this.imageRes=res.imageRes;
        this.imageResType=res.imageResType;
        this.db=res.db;
    }


    resetSelected() {
        this.selected = 0;
        this.photos.forEach((photo: Photo) => {
            photo.selected = false;
        });
    }

    onCheckbox(status) {
        this.photo.selected = status;
        this.selected = this.photos.filter((photo: Photo) => photo.selected).length;
    }

    download(photo) {
        return () => new Promise(() => {
            photo.downloadPhoto();
        });
    }

    activeSlideChange(event) {
        this.photo = this.photos && event < this.photos.length && this.photos[event];
    }
}
