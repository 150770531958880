import {Component, Input} from '@angular/core';

@Component({
    selector: 'approve',
    templateUrl: 'approve.component.html',
    styleUrls: ['approve.component.css', '../chars.component.css'],
})
export class ApproveComponent {
    @Input() public show: boolean = false;
}
