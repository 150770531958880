import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as saveAs from 'file-saver';
import * as JSZip from 'jszip';
import { BaseComponent } from '../../../base.component';
import { translateEnum } from '../../../shared/enum/translateEnum';
import { Photo } from '../../../shared/models/photo.model';
import { ApiService } from '../../../core/service/api.service';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'download-images',
    templateUrl: 'download-images.component.html',
    styleUrls: ['./download-images.component.css', '../../../app.component.css']
})
export class DownloadImagesComponent extends BaseComponent {

    @Input() getPhotos: Function;
    @Input() hasPhotos: boolean | undefined = false;
    @Input() selected: number = 0;
    @Input() disabled: boolean = false;
    @Input() hover: string = null;
    @Input() isDownloading: Function;
    @Input() resetSelected: Function;
    @Input() origin="";

    @Output() imageAnalyze = new EventEmitter<any>();
    


    translateEnum = translateEnum;
    photos: Photo[] = [];
    disabled_download: boolean = false;
    photo_names: string[] = [];
    count: number = 0;
    zip: JSZip;
    imageRes: string[]=[];
    db: string[]=[];
    form: FormGroup;
    imageSrc: string;


    constructor(private api: ApiService) {
        super();
    }

    callIsDownloading(status: boolean) {
        if (this.isDownloading) {
            this.isDownloading(status);
        }
        this.disabled_download = status;
    }


    imagetest(type)
    {
        let type3 =false;
        debugger;
        const formData = new FormData();
        formData.append('imagelink',  document.getElementsByClassName('item active')[0].getElementsByTagName('a')[0].href);
        formData.append('type',type)
        this.api.imageAnalyzerUpload(formData).subscribe(
            (res) => {
               debugger;
           
                this.imageRes=this.imageRes=JSON.parse(res.image);
              //  this.imageRes.pop();
             //   this.imageRes.pop();
                this.db=res.db;
                debugger;  
                res.db.forEach((dbElement:any) => 
                {
                    dbElement.platform=false;
                        this.imageRes.forEach((imageElement) => 
                        {
                            if(type==1)
                            {
                                if( dbElement.line_number.toString()==imageElement.trim())
                                {
                                    dbElement.platform=true;  
                                }
                            }
                            else if(type==2)
                            {
                                if( dbElement.braille.toString()==imageElement.trim())
                                {
                                    dbElement.platform=true;  
                                }
                            }
                            else if(type==3)
                            {
                                if( imageElement.includes(dbElement.stop_code))
                                {
                                    type3=true;
                                    dbElement.platform=true;
                                    res.platform=[];
                                    res.platform.push(true);
                                    res.db =[];
                                    res.db .push(dbElement)
                                    res.imageRes=[];
                                    res.imageRes.push(dbElement.stop_code)
                                    res.imageResType=type;
                                }
                                else if(type3==false)
                                {
                                    type3=false;
                                    dbElement.platform=false;
                                    res.platform=[];
                                    res.platform.push(false);
                                    res.db =[];
                                    res.db .push(dbElement.stop_code)
                                    res.imageRes=[];
                                    res.imageRes.push('')
                                    res.imageResType=type;
                                }
                            }
                                
                        }
                           
                )}              
                );
                if(type!=3)
                {
                res.imageRes=this.imageRes;
                res.imageResType=type;
                }
                this.imageAnalyze.emit(res);
            },
            (err) => {
                console.log({err});
            })
            .add(() => {
            });
    


    }

    async zoomin() {
        debugger;
        const images = document.getElementsByClassName('photo');
        for(let i =0;i<images.length;i++)
            images[i].classList.add('zoomed');

        const carousel = document.getElementsByClassName('active')
        for(let i =0;i<images.length;i++)
            carousel[i].classList.add('carousel-inner-zoom');
    }
    
    async zoomout() {
        debugger;
        const images = document.getElementsByClassName('photo');
        for(let i =0;i<images.length;i++)
            images[i].classList.remove('zoomed');

        const carousel = document.getElementsByClassName('active')
        for(let i =0;i<images.length;i++)
            carousel[i].classList.add('carousel-inner-zoom');
    }
    async download() {
        this.callIsDownloading(true);
        this.photos = [];
        this.photos = await this.getPhotos();

        if (this.photos.length === 0) {
            this.callIsDownloading(false);
            return;
        }

        if (this.photos.length === 1) {
            await this.photos[0].downloadPhoto();
            this.callIsDownloading(false);
            return;
        }

        this.zip = new JSZip();
        this.count = 0;
        this.photo_names = [];

        for (const photo of this.photos) {
            if (photo.fail) {
                this.createZip(null);
                continue;
            }

            const file_name = this.getFileName(photo);

            if (photo.blob === null) {
                await photo.downloadImageData();
            }
            this.createZip({file_name, photo});
        }
    }

    createZip(data) {
        const self = this;

        if (data) {
            self.zip.file(data.file_name, data.photo.blob);
        }

        self.count++;

        if (self.count === self.photos.length) {

            if (Object.keys(self.zip.files).length > 0) {
                self.zip.generateAsync({type: 'blob'})
                    .then(function (blob) {
                        saveAs(blob, self.translateEnum.IMAGES_FILE_NAME);
                    }, function (err) {
                        console.log(err);
                    })
                    .finally(function () {
                        self.callIsDownloading(false);
                    });
            } else {
                self.callIsDownloading(false);
            }

        }
    }

    getFileName(photo) {

        // find duplicate file names
        const equal = this.photo_names.filter(name => name === photo.fullname);
        this.photo_names.push(photo.fullname);

        let array = [photo.name];
        if (equal.length > 0) {
            array = array.concat([' (', equal.length.toString(), ')']);
        }
        array.push(photo.ext);

        return array.join('');
    }

}
