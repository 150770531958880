import {Component, Input} from '@angular/core';

@Component({
    selector: 'ok',
    templateUrl: 'ok.component.html',
    styleUrls: ['ok.component.css', '../chars.component.css'],
})
export class OkComponent {
    @Input() public show: boolean = false;
    @Input() public color: string = 'black';
}
