import {translateEnum} from '../../../shared/enum/translateEnum';
import {User} from '../../../shared/models/user.model';
import {Report} from '../../../shared/models/report.model';

export const download = (reports: Report[], users: User[]) => {
    const data = [];
    let row;
    debugger;
    for (const report of reports) {
            row = {
                [translateEnum.STOP_CODE]: report.stop.stop_code,
                [translateEnum.STOP_NAME]: report.stop.stop_name,
                [translateEnum.STOP_ADDRESS]: report.stop.stop_desc,
                [translateEnum.OPERATOR]: report.stop.operatorInCharge,
                [translateEnum.AREA]: report.stop.areas,
            };
            data.push(row);
        
    }

    const header: string[] = [
        translateEnum.STOP_CODE,
        translateEnum.STOP_NAME,
        translateEnum.STOP_ADDRESS,
        translateEnum.OPERATOR,
        translateEnum.AREA
    ];

    return {header, data};
};
