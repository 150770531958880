import * as XLSX from 'xlsx';
import {DOWNLOAD_TYPE} from '../../../shared/enum/report';
import * as downloadReportsGeneral from './download-reports-general.service';
import * as downloadReportsFlag from './download-reports-flag.service';
import * as downloadReportsBrail from './download-reports-brail.service';
import * as downloadReportsComment from './download-reports-comment.service';
import * as downloadReportsOperatorInCharge from './download-reports-operator-in-charge.service';
import * as downloadReportsStatic from './download-reports-static.service';
import * as downloadReportsHead from './download-reports-head.service';
import * as downloadReportsSheds from './download-reports-sheds.service';
import * as downloadReportsManual from './download-reports-manual.service';
import * as downloadProjectsGeneral from './download-projects-general.service';
import * as downloadScheduale from './download-scheduale.service';

import * as downloadProjectsManual from './download-projects-manual.service';
import * as downloadProjectGeneral from './download-project-general.service';
import * as downloadProjectManual from './download-project-manual.service';
import * as downloadProjects from './download-projects.service';
import * as downloadTraffic from './download-traffic.service';
import * as downloadTrafficTotals from './download-traffic-totals.service';
import {ApiService} from "../../../core/service/api.service";
import {translateEnum} from '../../../shared/enum/translateEnum';
import {brailStatuses} from '../../../shared/options/brail';
import {flagStatuses} from '../../../shared/options/flag';
import {staticPosterStatuses} from '../../../shared/options/static_poster';
import {stopTypes} from '../../../shared/options/stop';
import {User} from '../../../shared/models/user.model';
import {STOP_TYPE_CODES} from '../../../shared/enum/stop';
import {Option} from '../../../shared/interfaces/option';
import {STATIC_POSTER_STATUSES} from '../../../shared/enum/static_poster';
import {FLAG_STATUSES} from '../../../shared/enum/flag';
import {BRAIL_STATUSES} from '../../../shared/enum/brail';
import {Export} from '../../../shared/models/export.model';
import {Comment} from '../../../shared/models/comment.model';
import {EXPORT_TYPE} from '../../../shared/enum/export';
import {Report} from '../../../shared/models/report.model';

export const download = async (api: ApiService, users: User[], exp: Export, fields) => {
    try {
        debugger;
        const limit = 1000;
        let hasMore = true;
        let page = 1;
        let result = [];
        let reports = [];
        debugger;
        fields['limit'] = limit;

        switch (exp.report_type) {
            case DOWNLOAD_TYPE.REPORTS_FLAG:
                if (fields.flag && fields.flag.length > 0) {
                    const valid_status = fields.flag.find((option: Option) => {
                        return option.id === FLAG_STATUSES.VALID;
                    });
                    if (valid_status) {
                        hasMore = false;
                    }
                } else {
                    fields.flag = [flagStatuses[1], flagStatuses[2], flagStatuses[3], flagStatuses[4]];
                }
                break;
            case DOWNLOAD_TYPE.REPORTS_BRAIL:
                if (fields.brail && fields.brail.length > 0) {
                    const valid_status = fields.brail.find((option: Option) => {
                        return option.id === BRAIL_STATUSES.VALID;
                    });
                    if (valid_status) {
                        hasMore = false;
                    }
                } else {
                    fields.brail = [brailStatuses[1], brailStatuses[2]];
                }
                break;
            case DOWNLOAD_TYPE.REPORTS_STATIC:
                if (fields.static_poster && fields.static_poster.length > 0) {
                    const valid_status = fields.static_poster.find((option: Option) => {
                        return option.id === STATIC_POSTER_STATUSES.VALID;
                    });
                    if (valid_status) {
                        hasMore = false;
                    }
                } else {
                    fields.static_poster = [staticPosterStatuses[1], staticPosterStatuses[2]];
                }
                break;
            case DOWNLOAD_TYPE.REPORTS_HEAD:
                if (fields.flag && fields.flag.length > 0) {
                    const valid_status = fields.flag.find((option: Option) => {
                        return option.id === FLAG_STATUSES.VALID;
                    });
                    if (valid_status) {
                        hasMore = false;
                    }
                } else {
                    fields.flag = [flagStatuses[1], flagStatuses[2], flagStatuses[3], flagStatuses[4]];
                }
                break;
            case DOWNLOAD_TYPE.REPORTS_SHEDS:
                if (fields['stop_type']) {
                    // check if user searched for other stop type already
                    if (fields['stop_type'].id !== STOP_TYPE_CODES.SHEDS) {
                        hasMore = false;
                    }
                } else {
                    fields['stop_type'] = stopTypes[STOP_TYPE_CODES.SHEDS];
                }
                break;
            case DOWNLOAD_TYPE.REPORTS_GENERAL:
            case DOWNLOAD_TYPE.REPORTS_MANUAL:
            case DOWNLOAD_TYPE.REPORTS_COMMENT:
            case DOWNLOAD_TYPE.REPORTS_OPERATOR_IN_CHARGE:
            case DOWNLOAD_TYPE.PROJECTS_GENERAL:
            case DOWNLOAD_TYPE.PROJECTS_MANUAL:
                break;
            case DOWNLOAD_TYPE.PROJECT_GENERAL:
            case DOWNLOAD_TYPE.PROJECT_MANUAL:
            case DOWNLOAD_TYPE.SCHEDUALE:

                hasMore = false;
                reports = fields;
                break;
            case DOWNLOAD_TYPE.DOWNLOAD_PROJECTS:
                hasMore = false;
                reports = fields;
                break;
            case DOWNLOAD_TYPE.TRAFFIC_CHANGES:
                    hasMore = false;
                    reports = fields;
                    break;
            case DOWNLOAD_TYPE.TRAFFIC_TOTALS:
                    hasMore = false;
                    reports = fields;
                    break;
                
            default:
                hasMore = false;
        }

        while (hasMore) {
            fields['page'] = page++;

            switch (exp.type) {
                case EXPORT_TYPE.REPORTS:
                case EXPORT_TYPE.PROJECT:
                    result = await (api.getReports(fields).toPromise());
                    break;
                case EXPORT_TYPE.PROJECTS:
                    result = await (api.getShifts(fields).toPromise());
                    break;
            }

            if (result.length > limit) {
                result.pop();
            } else {
                hasMore = false;
            }
            reports = reports.concat(result);
        }

        await getComments(api, exp.report_type, reports);
        debugger;
        let spread;
        let filename = '';
        switch (exp.report_type) {
            case DOWNLOAD_TYPE.REPORTS_GENERAL:
                spread = downloadReportsGeneral.download(reports, users);
                filename = translateEnum.DOWNLOAD_FILE_NAME_REPORTS_GENERAL;
                break;
            case DOWNLOAD_TYPE.REPORTS_FLAG:
                spread = downloadReportsFlag.download(reports, users);
                filename = translateEnum.DOWNLOAD_FILE_NAME_REPORTS_FLAG;
                break;
            case DOWNLOAD_TYPE.REPORTS_BRAIL:
                spread = downloadReportsBrail.download(reports, users);
                filename = translateEnum.DOWNLOAD_FILE_NAME_REPORTS_BRAIL;
                break;
            case DOWNLOAD_TYPE.REPORTS_COMMENT:
                spread = downloadReportsComment.download(reports, users);
                filename = translateEnum.DOWNLOAD_FILE_NAME_REPORTS_COMMENT;
                break;
            case DOWNLOAD_TYPE.REPORTS_OPERATOR_IN_CHARGE:
                debugger;
                spread = downloadReportsOperatorInCharge.download(reports, users);
                filename = translateEnum.DOWNLOAD_FILE_NAME_REPORTS_OPERATOR_IN_CHARGE;
                break;
            case DOWNLOAD_TYPE.REPORTS_STATIC:
                spread = downloadReportsStatic.download(reports, users);
                filename = translateEnum.DOWNLOAD_FILE_NAME_REPORTS_STATIC;
                break;
            case DOWNLOAD_TYPE.REPORTS_HEAD:
                spread = downloadReportsHead.download(reports, users);
                filename = translateEnum.DOWNLOAD_FILE_NAME_REPORTS_HEAD;
                break;
            case DOWNLOAD_TYPE.REPORTS_SHEDS:
                spread = downloadReportsSheds.download(reports);
                filename = translateEnum.DOWNLOAD_FILE_NAME_REPORTS_SHEDS;
                break;
            case DOWNLOAD_TYPE.REPORTS_MANUAL:
                spread = downloadReportsManual.download(reports, users, exp);
                filename = exp.name + '.xlsx';
                break;
            case DOWNLOAD_TYPE.SCHEDUALE:
                    spread = downloadScheduale.download(reports);
                    filename = translateEnum.DOWNLOAD_FILE_NAME_SCHEDUALE;
                    break;
                
            case DOWNLOAD_TYPE.PROJECTS_GENERAL:
                spread = downloadProjectsGeneral.download(reports);
                filename = translateEnum.DOWNLOAD_FILE_NAME_PROJECTS_GENERAL;
                break;
            case DOWNLOAD_TYPE.DOWNLOAD_PROJECTS:
                spread = downloadProjects.download(reports);
                filename = 'בקרת פרויקטים'+"_"+  new Date().toLocaleDateString()+"_"+  new Date().toLocaleTimeString()+".xlsx";
                break;
            case DOWNLOAD_TYPE.TRAFFIC_CHANGES:
                spread = downloadTraffic.download(reports,exp);
                filename = 'מפה לביצוע '+ exp.name+ ' '+exp.sifrur+"_"+  new Date().toLocaleDateString()+"_"+  new Date().toLocaleTimeString()+".xlsx";
                break;
            case DOWNLOAD_TYPE.TRAFFIC_TOTALS:
                    spread = downloadTrafficTotals.download(reports,exp);
                    filename = 'כתב כמויות '+exp.name+ ' '+exp.sifrur+"_"+  new Date().toLocaleDateString()+"_"+  new Date().toLocaleTimeString()+".xlsx";
                    break;
                
            case DOWNLOAD_TYPE.PROJECTS_MANUAL:
                spread = downloadProjectsManual.download(reports, exp);
                filename = exp.name + '.xlsx';
                break;
            case DOWNLOAD_TYPE.PROJECT_GENERAL:
                spread = downloadProjectGeneral.download(reports, users);
                filename = translateEnum.DOWNLOAD_FILE_NAME_PROJECT_GENERAL;
                break;
            case DOWNLOAD_TYPE.PROJECT_MANUAL:
                spread = downloadProjectManual.download(reports, exp, users);
                filename = exp.name + '.xlsx';
                break;
        }

        const workBook = XLSX.utils.book_new();
        const workSheet = XLSX.utils.json_to_sheet(spread.data, {header: spread.header});


       // Open all columns to maximum width
    const range = XLSX.utils.decode_range(workSheet['!ref']);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      let maxStringLength = 0;
      for (let R = range.s.r; R <= range.e.r; ++R) {
        const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
        const cell = workSheet[cellRef];
        if (cell && cell.t === 's') {
          const cellValue = XLSX.utils.format_cell(cell);
          maxStringLength = Math.max(maxStringLength, cellValue.length);
        }
      }
      workSheet['!cols'] = workSheet['!cols'] || [];
      workSheet['!cols'][C] = { wch: maxStringLength };
    }

    
    
    
    

        XLSX.utils.book_append_sheet(workBook, workSheet);
        XLSX.writeFile(workBook, filename);

    } catch (err) {
        console.log(err);
    }
};

const getComments = async (api: ApiService, report_type: DOWNLOAD_TYPE, reports: Report[]) => {
    let comments_array: Comment[] = [];
    if (report_type === DOWNLOAD_TYPE.REPORTS_COMMENT || report_type === DOWNLOAD_TYPE.REPORTS_OPERATOR_IN_CHARGE ||
        report_type === DOWNLOAD_TYPE.REPORTS_GENERAL ||
        report_type === DOWNLOAD_TYPE.REPORTS_MANUAL) {
        const ids: string[] = reports.map(report => report.id);
        for (let k = 0; k < ids.length; k += 280) {
            comments_array = comments_array.concat(await (api.getComments(ids.slice(k, k + 280)).toPromise()));
        }
    }

    if (comments_array.length) {
        const comments_json: { [key: string]: Comment[] } =
            comments_array.reduce((acc: { [key: string]: Comment[] }, comment: Comment) => {
                if (!acc[comment.report]) {
                    acc[comment.report] = [];
                }
                acc[comment.report].push(comment);
                return acc;
            }, {});
        for (const report of reports) {
            report.setComments(comments_json[report.id]);
        }
    }
};

