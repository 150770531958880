import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';

interface ITableData {
    [key: number]: {};
}
import { ApiService } from '../../core/service/api.service';
import { UserService } from '../../core/service/user.service';
import { translateEnum } from '../../shared/enum/translateEnum';
import { Header } from '../../shared/interfaces/header';
import { UploadCsv } from '../../shared/interfaces/upload';
import { LINES_CSV_UPLOAD_PERMISSIONS } from '../../shared/models/permissions.model';

@Component({
    templateUrl: 'lines-csv-upload.component.html',
    styleUrls: ['./lines-csv-upload.component.css', '../../app.component.css'],
})
export class LinesCsvUploadComponent implements OnInit {
    translateEnum = translateEnum;
    write_permission: boolean = false;
    form: FormGroup;
    table: ITableData[] = [];
    header: Header[] = [];
    loading: boolean = false; // Loading for pushing the csv to the DB
    tableHasBeenSent: boolean = false;
    error: string = null;
    success: boolean = null;
    lines_logs_subject: Subject<void> = new Subject<void>();
    reset_file_subject: Subject<void> = new Subject<void>();
    tableOfDuplications: ITableData[] = [];

    constructor(private api: ApiService, public userService: UserService, private formBuilder: FormBuilder) {
        this.userService.pagePermission(LINES_CSV_UPLOAD_PERMISSIONS);
        const user = this.userService.getUser();
        this.write_permission = user.permissions[LINES_CSV_UPLOAD_PERMISSIONS].write;
    }

    ngOnInit(): void {
        this.reset();
    }

    reset() {
        this.form = this.formBuilder.group({
            file: [''],
        });
        this.table = [];
        this.header = [];
        this.loading = false;
        this.error = null;
        this.success = null;
        this.reset_file_subject.next();
    }

    onUpload({header, table, file_name, file}: UploadCsv) {
        this.form.get('file').setValue(file);
        this.header = header;
        this.table = table;
    }

    onSubmit() {
        const self = this;
        self.loading = true;
        self.error = null;
        self.success = null;
        const formData = new FormData();
        formData.append('file', this.form.get('file').value);
        this.api.getLinesCsvDuplications(formData).subscribe(
            (res) => {
                self.success = true;
                this.tableHasBeenSent = true;
                this.tableOfDuplications = res;
            },
            (err) => {
                self.error = this.translateEnum.ERROR_MESSAGE.replace('[message]', err.error.message);
            })
            .add(() => {
                self.loading = false;
            });
    }

    openLinesLogs(modal, event) {
        event.preventDefault();
        this.lines_logs_subject.next();
        modal.show();
    }

    editRow(rowData: {[key: string]: string}) {
        this.api.patchMultiLineRows([rowData]);
        
    }

}
