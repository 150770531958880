import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AppComponent } from './app.component';
import { JwtInterceptor } from './auth/jwt.interceptor';
import { DefaultLayoutComponent } from './containers';
import { LoginPageModule } from './views/login/login.module';

export function fetchBuild(buildApiService: BuildApiService) {
    return () => buildApiService.fetchBuild();
}

const APP_CONTAINERS = [
    DefaultLayoutComponent
];

import {
    AppAsideModule,
    AppBreadcrumbModule, AppFooterModule, AppHeaderModule, AppSidebarModule
} from '@coreui/angular';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from "@angular/material/select";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AppRoutingModule } from './app.routing';
import { NeedAuthGuard } from './auth.guard';
import { BuildApiService } from './core/service/build/build.api.service';
import { ExportsService } from './core/service/exports.service';
import { UsersService } from './core/service/users.service';
import { FlagModule } from './views/flag/flag.module';
import { LinesCsvUploadModule } from './views/lines-csv-upload/lines-csv-upload.module';
import { PhotosModule } from './views/photos/photos.module';
import { ReportsModule } from './views/reports/reports.module';
import { UploadsModule } from './views/uploads/uploads.module';
import { UsersModule } from './views/users/users.module';
import { StopsFileUploadModule } from './views/stops-file-upload/stops-file-upload.module';

@NgModule({
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        AppRoutingModule,
        AppAsideModule,
        AppBreadcrumbModule.forRoot(),
        AppFooterModule,
        AppHeaderModule,
        AppSidebarModule,
        PerfectScrollbarModule,
        BsDropdownModule.forRoot(),
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        ReportsModule,
        LinesCsvUploadModule,
        StopsFileUploadModule,
        FlagModule,
        PhotosModule,
        UploadsModule,
        UsersModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        LoginPageModule,
        MatSelectModule,
        MatFormFieldModule,
        MatSnackBarModule
    ],
    declarations: [
        AppComponent,
        ...APP_CONTAINERS,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: fetchBuild,
            deps: [BuildApiService],
            multi: true
        },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        NeedAuthGuard,
        UsersService,
        ExportsService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
