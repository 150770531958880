import { Component, Input ,Output,EventEmitter,ElementRef, ViewChild} from '@angular/core';
import { Shift } from '../../../../../app/shared/models/shift.model';
import { ApiService } from '../../../../core/service/api.service';
import { translateEnum } from '../../../../shared/enum/translateEnum';
import { Report } from '../../../../shared/models/report.model';

@Component({
    selector: 'task-button-upgrade',
    templateUrl: 'task-button-upgrade.component.html',
    styleUrls: ['task-button-upgrade.component.css', '../../../../app.component.css']
})

export class TaskButtonUpgradeComponent {

    readonly translateEnum = translateEnum;
    @Output() getShifts = new EventEmitter<{ shift_id: string,stop_code: number }>();

    @Input() public report: Report = null;
    @Input() public show: boolean = false;


    @ViewChild('stripDestInput') stripDestInput: ElementRef;

    isDropdownOpen = false;
    selectedOption: string | undefined;


    public loading: boolean = false;
    public error: string = null;
    public comment: any = '';
    public task_id:number=0;
    public to_do: any = ''; 
    public charge: boolean = false;
    public options:any=[];
    public filteredOptions:any=[];
    public index:number;
    public selectId:any;
    public borderId:any;
    public eventData:{}
    
    public isEditMode: boolean = false;
    
    
    //STRIPE
    public stripAdd1: string = null;
    public stripAdd2: string = null;
    public stripAdd3: string = null;
    public stripAdd4: string = null;
    public stripAdd5: string = null;
    public stripAdd6: string = null;
    public stripAdd7: string = null;
    public stripAdd8: string = null;
    public stripAdd9: string = null;
    public stripAdd10: string = null;
    public stripImage: string = null;
    public stripDest: string = null;
    public stripDestList:any=[];
    public filterstripDestList:any=[];
    public stripComments: string = null;
    public stripCharge: boolean = false;
    
    //HEAD STOP
    public headstopMakat: number = null;
    public headstopName: string = null;
    public headstopNumber:string=null;
    public headstopComments: string = null;
    public headstopCharge: boolean = false;
    
    //FREE TEXT
    public freeText: string = null;

    //
    public borders: string = null; 

    //FLAGS
    public flagsCount: string = null;
    public flagsMakat : number = null;
    public flagsCharge: boolean = false;
    public flagsComments: string = null;


    //BRAIL
    public brailMakat: number = null;
    public brailName: string = null;
    public brailComments: string = null;
    public brailCharge: boolean = false;
    public brailList:any=[];
    public lineNumber1: string="";
    public lineNumber2: string="";
    public lineNumber3: string="";
    public lineNumber4: string="";
    public lineNumber5: string="";
    public lineNumber6: string="";
    public lineNumber7: string="";
    public lineNumber8: string="";
    public lineNumber9: string="";
    public lineNumber10: string="";


    
    //show-hide flags
    public showStripAdd2:boolean=false;
    public showStripAdd3:boolean=false;
    public showStripAdd4:boolean=false;
    public showStripAdd5:boolean=false;
    public showStripAdd6:boolean=false;
    public showStripAdd7:boolean=false;
    public showStripAdd8:boolean=false;
    public showStripAdd9:boolean=false;
    public showStripAdd10:boolean=false;
    public showStrip:boolean=false;
    public showStopHead:boolean=false;
    public showEmpty:boolean=false;
    
    public showBrail:boolean=false;
    public showBrailAdd2:boolean=false;
    public showBrailAdd3:boolean=false;
    public showBrailAdd4:boolean=false;
    public showBrailAdd5:boolean=false;
    public showBrailAdd6:boolean=false;
    public showBrailAdd7:boolean=false;
    public showBrailAdd8:boolean=false;
    public showBrailAdd9:boolean=false;
    public showBrailAdd10:boolean=false;


    public showAdd:boolean=false;
    public showRemove:boolean=false;
    public showFreeText:boolean=false;
    public showFlangs:boolean=false;
    public showFlangsManual:boolean=false;
    public showBorders:boolean=false;
    

    constructor(private api: ApiService,private elementRef: ElementRef) {

        this.GetAllShifts();

        this.onClick = this.onClick.bind(this);

    }

    async GetAllShifts()
    {
      //  this.options = await (this.api.getAllShifts().toPromise());
      //  for(var i=0;i< this.options.length;i++)
      //  {
      //      this.filteredOptions.push(this.options[i].name);
      //  }

    }


    onChange(event){
      
        this.stripAdd1="";
        this.stripAdd2="";
        this.stripAdd3="";
        this.stripAdd4="";
        this.stripAdd5="";
        this.stripAdd6="";
        this.stripAdd7="";
        this.stripAdd8="";
        this.stripAdd9="";
        this.stripAdd10="";
        this.stripDest="";

        this.lineNumber1="";
        this.lineNumber2="";
        this.lineNumber3="";
        this.lineNumber4="";
        this.lineNumber5="";
        this.lineNumber6="";
        this.lineNumber7="";
        this.lineNumber8="";
        this.lineNumber9="";
        this.lineNumber10="";





        this.showStripAdd2=false;
        this.showStripAdd3=false;
        this.showStripAdd4=false;

        this.headstopMakat =  parseInt(document.getElementsByClassName('title-chip')[0].textContent.replace('פירוט מק״ט','').trim());
        this.flagsMakat = this.headstopMakat ;

        
        if(event==1)
        {
            this.showBrail=false;
            this.showStopHead=true;
            this.onFocusStopHead();
            this.showStrip=false;
            this.showAdd=true;
            this.showRemove=false;
            this.showFreeText=false;
            this.showFlangs=false;
            this.showFlangsManual=false;
            this.showBorders=false;
        }
        else if(event==2)
        {
            this.showBrail=false;
            this.showStrip=true;
            this.showStopHead=false;
            this.showAdd=true;
            this.showRemove=false;
            this.showFreeText=false;
            this.showFlangs=false;
            this.showFlangsManual=false;
            this.showBorders=false;
        }
        else if(event==3)
        {
            this.showBrail=false;
            this.showStrip=false;
            this.showStopHead=true;
            this.onFocusStopHead();
            this.showAdd=false;
            this.showRemove=true;
            this.showFreeText=false;
            this.showFlangs=false;
            this.showFlangsManual=false;
            this.showBorders=false;

        }
        else if(event==4)
        {
            this.showBrail=false;
            this.showStrip=true;
            this.showStopHead=false;
            this.showAdd=false;
            this.showRemove=true;
            this.showFreeText=false;
            this.showFlangs=false;
            this.showFlangsManual=false;
            this.showBorders=false;
        }
        else if(event==5)
        {
            this.showBrail=true;
            this.showStrip=false;
            this.showStopHead=false;
            this.showFreeText=false;
            this.showFlangs=false;
            this.showFlangsManual=false;
            this.showBorders=false;
        }
        else if(event==6)
        {
            this.showBrail=true;
            this.showStrip=false;
            this.showStopHead=false;
            this.showFreeText=false;
            this.showFlangs=false;
            this.showFlangsManual=false;
            this.showBorders=false;
        }
        else if(event==7)
        {
            this.showBrail=false;
            this.showStrip=false;
            this.showStopHead=false;
            this.showFreeText=false;
            this.showFlangs=false;
            this.showFlangsManual=false;
            this.showBorders=false;
        }
        else if(event==8)
        {
            this.showBrail=false;
            this.showStrip=false;
            this.showStopHead=false;
            this.showFreeText=false;
            this.showFlangs=false;
            this.showFlangsManual=false;
            this.showBorders=false;
        }
        else if(event==9)
        {
            this.showBrail=false;
            this.showStrip=false;
            this.showStopHead=false;
            this.showFreeText=false;
            this.showFlangs=false;
            this.showFlangsManual=false;
            this.showBorders=false;
        }
        else if(event==10 || event==15)
        {
            this.showBrail=false;
            this.showStrip=false;
            this.showStopHead=false;
            this.showFreeText=false;
            this.showFlangs=true;
            this.onFocusFlags();
            this.showFlangsManual=false;
            this.showBorders=false;
        }
        else if(event==11 || event==16)
        {
            this.showBrail=false;
            this.showStrip=false;
            this.showStopHead=false;
            this.showFreeText=false;
            this.showFlangs=false;
            this.showFlangsManual=true;
            this.showBorders=false;
        }
        else if(event==12)
        {
            this.showBrail=false;
            this.showStrip=false;
            this.showStopHead=false;
            this.showFreeText=false;
            this.showFlangs=false;
            this.showFlangsManual=false;
            this.showBorders=false;
        }
        else if(event==13)
        {
            this.showBrail=false;
            this.showFreeText=true;
            this.showStrip=false;
            this.showStopHead=false;
            this.showFlangs=false;
            this.showFlangsManual=false;
            this.showBorders=false;
        }
        else if(event==14)
        {
            this.showBrail=false;
            this.showFreeText=false;
            this.showStrip=false;
            this.showStopHead=false;
            this.showFlangs=false;
            this.showFlangsManual=false;
            this.showBorders=true;
        }

        debugger;
      

    }

    brailenter(next)
    {
        debugger;
        if(next==1)
        {
                this.showBrailAdd2=true;
        }
        else if(next==2)
        {
                this.showBrailAdd3=true;
        }
        else if(next==3)
        {
                this.showBrailAdd4=true;
        }
        else if(next==4)
        {
                this.showBrailAdd5=true;
        }
        else if(next==5)
        {
                this.showBrailAdd6=true;
        }
        else if(next==6)
        {
                this.showBrailAdd7=true;
        }
        else if(next==7)
        {
                this.showBrailAdd8=true;
        }
        else if(next==8)
        {
                this.showBrailAdd9=true;
        }
        else if(next==9)
        {
                this.showBrailAdd10=true;
        }
       
    }

    ShowNext(next)
    {
        this.onFocus();
        if(next==1)
        {
                this.showStripAdd2=true;
        }
        else if(next==2)
        {
                this.showStripAdd3=true;
        }
        else if(next==3)
        {
                this.showStripAdd4=true;
        }
        else if(next==4)
        {
                this.showStripAdd5=true;
        }
        else if(next==5)
        {
                this.showStripAdd6=true;
        }
        else if(next==6)
        {
                this.showStripAdd7=true;
        }
        else if(next==7)
        {
                this.showStripAdd8=true;
        }
        else if(next==8)
        {
                this.showStripAdd9=true;
        }
        else if(next==9)
        {
                this.showStripAdd10=true;
        }
    }

    destChange()
    {
        this.stripImage=null;
    }

    onFocusStopHead(){
        this.api.getLinesByStopCode(this.headstopMakat).subscribe(
            async r => {
                debugger;
                if (r.length === 0) {
                    return;
                }
                this.headstopName = r[0].stop_name;
               debugger;
                
            },
            r => {
                
            });

        
    }




    onFocusFlags(){
        this.api.getLinesByStopCode(this.flagsMakat).subscribe(
            async r => {
                debugger;
                
                this.flagsCount = r.length.toString();
               
                
                
            },
            r => {
                
            });

        
    }


    filter(e)
    {
        let name = e.target.value;
        let tmp=[];
        this.filterstripDestList = this.stripDestList;
        for (var i=0;i<this.filterstripDestList.length;i++)
        {
            if(this.filterstripDestList[i].stop_name.includes(name))
                tmp.push(this.filterstripDestList[i]);
        }

        this.filterstripDestList = tmp;
    }

    chooseDest(e){
        debugger;
        let name = e.target.value;
        let dest;
        for (var i=0;i<this.filterstripDestList.length;i++)
        {
            if(this.filterstripDestList[i].stop_name==name)
                dest=this.filterstripDestList[i];
        }
        if(dest.operator_name=="אגד")
        this.stripImage="operator_egged"
    else if(dest.operator_name=="גלים")
        this.stripImage="operator_galeem"
    else if(dest.operator_name=="דן בדרום")
        this.stripImage="operator_danbadarom"   
    else if(dest.operator_name=="דן בצפון")
        this.stripImage="operator_dannorth"   
    else if(dest.operator_name=="דן")
        this.stripImage="operator_dan"   
    else if(dest.operator_name=="מטרופולין")
        this.stripImage="operator_metropoline"      
    else if(dest.operator_name=="אלקטרה אפיקים")
        this.stripImage="operator_electra_afikim" 
    else if(dest.operator_name=="קווים")
        this.stripImage="operator_kavim" 
    else if(dest.operator_name=="תנופה")
        this.stripImage="operator_tnufa" 
    else if(dest.operator_name=="סופרבוס")
        this.stripImage="operator_superbus" 
    else if(dest.operator_name=="נתיב אקספרס")
        this.stripImage="operator_nateev_express" 
    else if(dest.operator_name=="ש.א.מ")
        this.stripImage="bus" 
    else if(dest.operator_name=="נסיעות ותיירות")
        this.stripImage="bus" 
    else if(dest.operator_name=="גולן")
        this.stripImage="operator_golan_bus"   
    else if(dest.operator_name=="בית שמש אקספרס")
        this.stripImage="operator_betshemesh_he"
    else if(dest.operator_name=="הר זיתים")
        this.stripImage="operator_mountofolives_bus"
    else if(dest.operator_name=="דרום איחוד")
        this.stripImage="operator_south_buses"
        

        
    else
        this.stripImage="bus"
    
    

        
    }

    onFocus(){
        if(this.stripAdd1==null)
            this.stripAdd1=''
        if(this.stripAdd2==null)
            this.stripAdd2=''
        if(this.stripAdd3==null)
            this.stripAdd3=''
        if(this.stripAdd4==null)
            this.stripAdd4=''
            if(this.stripAdd5==null)
            this.stripAdd5=''
            if(this.stripAdd6==null)
            this.stripAdd6=''
            if(this.stripAdd7==null)
            this.stripAdd7=''
            if(this.stripAdd8==null)
            this.stripAdd8=''
            if(this.stripAdd9==null)
            this.stripAdd9=''
            if(this.stripAdd10==null)
            this.stripAdd10=''
        this.api.getLinesByMultyLineSign(this.stripAdd1,this.stripAdd2,this.stripAdd3,this.stripAdd4).subscribe(
            async r => {
                debugger;
             
                let tmp = [];
                let add=true;
                for (var i=0;i<r.length;i++)
                {
                    add=true;
                    for(var j=0;j<tmp.length;j++)
                    {
                        if(r[i].line_code==tmp[j].line_code)
                        {
                            add=false;
                        }
                        
                    }
                    if(add==true)
                        {
                            tmp.push(r[i]);
                        }
                   
                    
                }
                this.stripDestList=tmp;
                this.filterstripDestList=tmp;
                this.isDropdownOpen=true;
             


            },
            r => {
                
            });
    
    }

    onDelete()
    {
    //    alert()
    }

    onClick() {
        debugger;
        if (this.loading) {
            return;
        }
        debugger;
        if(this.selectId==undefined)
        {
            return
        }
        if(this.selectId=='1')
        {
            let charge='לא';
            if(this.headstopCharge==true)
                charge='כן';
            if(this.headstopComments==undefined || this.headstopComments==null)
                this.headstopComments="";
           // this.to_do = translateEnum.TASKS_ADD_HEAD_STOP + ' |'+this.headstopMakat +'| שם תחנה: ' +this.headstopName +'. לחיוב: '+charge;
           this.to_do = 'הוספה - |'+this.headstopMakat +'| - שם תחנה: ' +this.headstopName
           if(this.headstopNumber!=null && this.headstopNumber!=undefined && this.headstopNumber!='')
           {
                this.to_do = 'הוספה - |'+this.headstopMakat +'| - שם תחנה: ' +this.headstopName + '. מספר:'+this.headstopNumber;
           }

            this.charge=this.headstopCharge;
            this.comment =this.headstopComments;
            this.task_id=1;
        }
        else if(this.selectId=='2')
        {
            let charge='לא';
            if(this.stripCharge==true)
                charge='כן';
            if(this.stripComments==undefined || this.stripComments==null)
                this.stripComments="";
            let stripToAdd=''
            if(this.stripAdd1!=undefined && this.stripAdd1!='')
                stripToAdd=this.stripAdd1;
            if(this.stripAdd2!=undefined && this.stripAdd2!='')
                stripToAdd+=','+this.stripAdd2;
            if(this.stripAdd3!=undefined && this.stripAdd3!='')
                stripToAdd+=','+this.stripAdd3;
            if(this.stripAdd4!=undefined && this.stripAdd4!='')
                stripToAdd+=','+this.stripAdd4;
            if(this.stripAdd5!=undefined && this.stripAdd5!='')
                stripToAdd+=','+this.stripAdd5;
            if(this.stripAdd6!=undefined && this.stripAdd6!='')
                stripToAdd+=','+this.stripAdd6;
            if(this.stripAdd7!=undefined && this.stripAdd7!='')
                stripToAdd+=','+this.stripAdd7;
            if(this.stripAdd8!=undefined && this.stripAdd8!='')
                stripToAdd+=','+this.stripAdd8;
            if(this.stripAdd9!=undefined && this.stripAdd9!='')
                stripToAdd+=','+this.stripAdd9;
            if(this.stripAdd10!=undefined && this.stripAdd10!='')
                stripToAdd+=','+this.stripAdd10;

            //this.to_do = translateEnum.TASKS_ADD_STRIP+ ' |' +stripToAdd+ '| '+ this.stripDest +'. '+this.stripComments +'. לחיוב:'+charge;

            this.to_do = 'הוספה - |' +stripToAdd+ '| - '+ this.stripDest ;
            this.charge=this.stripCharge;
            this.comment =this.stripCharge;
            this.task_id=2;
        }
        else if(this.selectId=='3')
        {
            let charge='לא';
            if(this.headstopCharge==true)
                charge='כן';
            if(this.headstopComments==undefined || this.headstopComments==null)
                this.headstopComments="";
           // this.to_do = translateEnum.TASKS_REMOVE_HEAD_STOP + ' |'+this.headstopMakat +'| שם תחנה: ' +this.headstopName  +'. לחיוב: '+charge;
           this.to_do = 'הסרה - |'+this.headstopMakat +'| - שם תחנה: ' +this.headstopName  ;

            this.charge=this.headstopCharge;
            this.comment =this.headstopComments;
            this.task_id=3;
        }
        else if(this.selectId=='4')
        {
            let charge='לא';
            if(this.stripCharge==true)
                charge='כן';
            if(this.stripComments==undefined || this.stripComments==null)
                this.stripComments="";
                let stripToAdd=''
                if(this.stripAdd1!=undefined && this.stripAdd1!='')
                    stripToAdd=this.stripAdd1;
                if(this.stripAdd2!=undefined && this.stripAdd2!='')
                    stripToAdd+=','+this.stripAdd2;
                if(this.stripAdd3!=undefined && this.stripAdd3!='')
                    stripToAdd+=','+this.stripAdd3;
                if(this.stripAdd4!=undefined && this.stripAdd4!='')
                    stripToAdd+=','+this.stripAdd4;
                    if(this.stripAdd5!=undefined && this.stripAdd5!='')
                    stripToAdd+=','+this.stripAdd5;
                if(this.stripAdd6!=undefined && this.stripAdd6!='')
                    stripToAdd+=','+this.stripAdd6;
                if(this.stripAdd7!=undefined && this.stripAdd7!='')
                    stripToAdd+=','+this.stripAdd7;
                if(this.stripAdd8!=undefined && this.stripAdd8!='')
                    stripToAdd+=','+this.stripAdd8;
                if(this.stripAdd9!=undefined && this.stripAdd9!='')
                    stripToAdd+=','+this.stripAdd9;
                if(this.stripAdd10!=undefined && this.stripAdd10!='')
                    stripToAdd+=','+this.stripAdd10;
                
         //   this.to_do = translateEnum.TASKS_REMOVE_STRIP+ ' |' +stripToAdd+ '| '+ this.stripDest +'. '+this.stripComments +'. לחיוב:'+charge;
            this.to_do = 'הסרה - |' +stripToAdd+ '| - '+ this.stripDest ;

            this.charge=this.stripCharge;
            this.comment =this.stripCharge;
            this.task_id=2;
            
        }
        else if(this.selectId=='5')
        {
            let lines = this.lineNumber1 ;
            if(this.lineNumber2!="")
                lines+=','+ this.lineNumber2;
            if(this.lineNumber3!="")
                lines+=','+ this.lineNumber3;
            if(this.lineNumber4!="")
                lines+=','+ this.lineNumber4;
            if(this.lineNumber5!="")
                lines+=','+ this.lineNumber5;
            if(this.lineNumber6!="")
                lines+=','+ this.lineNumber6;
            if(this.lineNumber7!="")
                lines+=','+ this.lineNumber7;
            if(this.lineNumber8!="")
                lines+=','+ this.lineNumber8;
            if(this.lineNumber9!="")
                lines+=','+ this.lineNumber9;
            if(this.lineNumber10!="")
                lines+=','+ this.lineNumber10;

            let charge='לא';
            if(this.brailCharge==true)
                charge='כן';
            if(this.brailComments==undefined || this.brailComments==null)
                this.brailComments="";
          //  this.to_do = translateEnum.TASKS_ADD_BRAIL+ ' |' + lines +'| '+this.brailComments +'. לחיוב:'+charge;
          this.to_do = 'הוספה ברייל - |' + lines +'| ';
            this.charge=this.brailCharge;
            this.comment =this.brailComments;
            this.task_id=5;
        }
        
        else if(this.selectId=='6')
        {
            let lines = this.lineNumber1 ;
            if(this.lineNumber2!="")
                lines+=','+ this.lineNumber2;
            if(this.lineNumber3!="")
                lines+=','+ this.lineNumber3;
            if(this.lineNumber4!="")
                lines+=','+ this.lineNumber4;
            if(this.lineNumber5!="")
                lines+=','+ this.lineNumber5;
            if(this.lineNumber6!="")
                lines+=','+ this.lineNumber6;
            if(this.lineNumber7!="")
                lines+=','+ this.lineNumber7;
            if(this.lineNumber8!="")
                lines+=','+ this.lineNumber8;
            if(this.lineNumber9!="")
                lines+=','+ this.lineNumber9;
            if(this.lineNumber10!="")
                lines+=','+ this.lineNumber10;

            let charge='לא';
            if(this.stripCharge==true)
                charge='כן';
            if(this.brailComments==undefined || this.brailComments==null)
                this.brailComments="";
        //    this.to_do = translateEnum.TASKS_REMOVE_BRAIL+ ' |' + lines +'| '+this.brailComments +'. לחיוב:'+charge;
        this.to_do = 'הסרה ברייל - |' + lines +'| ';

            this.charge=this.stripCharge;
            this.comment =this.brailComments;
            this.task_id=6;
        }
        else if(this.selectId=='7')
        {
            this.to_do = translateEnum.TASKS_STATIC_POSTER;
            this.charge = undefined;
            this.comment ="";
            this.task_id=7;
        }
        else if(this.selectId=='8')
        {
            this.to_do = translateEnum.TASKS_POLIKARBONAT;
            this.charge = undefined;
            this.comment ="";
            this.task_id=8;
        }
        else if(this.selectId=='9')
        {
            this.to_do = translateEnum.TASKS_STATIC_COMPLAT;
            this.charge = undefined;
            this.comment ="";
            this.task_id=9;
        }
        else if(this.selectId=='10' || this.selectId=='15')
        {
            let border='לא';
            if(this.borderId==1)
                border='כן';
                
            let charge='לא';
            if(this.flagsCharge==true)
                charge='כן';

                let stripToAdd=''
                if(this.stripAdd1!=undefined && this.stripAdd1!='')
                    stripToAdd=this.stripAdd1;
                if(this.stripAdd2!=undefined && this.stripAdd2!='')
                    stripToAdd+=','+this.stripAdd2;
                if(this.stripAdd3!=undefined && this.stripAdd3!='')
                    stripToAdd+=','+this.stripAdd3;
                if(this.stripAdd4!=undefined && this.stripAdd4!='')
                    stripToAdd+=','+this.stripAdd4;    
                if(this.stripAdd5!=undefined && this.stripAdd5!='')
                    stripToAdd+=','+this.stripAdd5;
                if(this.stripAdd6!=undefined && this.stripAdd6!='')
                    stripToAdd+=','+this.stripAdd6;
                if(this.stripAdd7!=undefined && this.stripAdd7!='')
                    stripToAdd+=','+this.stripAdd7;
                if(this.stripAdd8!=undefined && this.stripAdd8!='')
                    stripToAdd+=','+this.stripAdd8;
                if(this.stripAdd9!=undefined && this.stripAdd9!='')
                    stripToAdd+=','+this.stripAdd9;
                if(this.stripAdd10!=undefined && this.stripAdd10!='')
                    stripToAdd+=','+this.stripAdd10;


            if(this.selectId=='10' )
                this.to_do = translateEnum.TASKS_FLAG_505 +'. כמות דגלים:'+ this.flagsCount + '. מסגרת?' + border   
                + ' - |' +stripToAdd+ '| יעד: '+ this.stripDest ;
            else
                this.to_do = translateEnum.TASKS_FLAG_505_8 +'. כמות דגלים:'+ this.flagsCount + '. מסגרת?' + border  
                + ' - |' +stripToAdd+ '| יעד: '+ this.stripDest  ;
            this.charge=this.flagsCharge;
            this.comment =this.flagsComments;
            this.task_id=10;
        }
        else if(this.selectId=='11' || this.selectId=='15')
        {
            let stripToAdd=''
            if(this.stripAdd1!=undefined && this.stripAdd1!='')
                stripToAdd=this.stripAdd1;
            if(this.stripAdd2!=undefined && this.stripAdd2!='')
                stripToAdd+=','+this.stripAdd2;
            if(this.stripAdd3!=undefined && this.stripAdd3!='')
                stripToAdd+=','+this.stripAdd3;
            if(this.stripAdd4!=undefined && this.stripAdd4!='')
                stripToAdd+=','+this.stripAdd4;    
                if(this.stripAdd5!=undefined && this.stripAdd5!='')
                stripToAdd+=','+this.stripAdd5;
            if(this.stripAdd6!=undefined && this.stripAdd6!='')
                stripToAdd+=','+this.stripAdd6;
            if(this.stripAdd7!=undefined && this.stripAdd7!='')
                stripToAdd+=','+this.stripAdd7;
            if(this.stripAdd8!=undefined && this.stripAdd8!='')
                stripToAdd+=','+this.stripAdd8;
            if(this.stripAdd9!=undefined && this.stripAdd9!='')
                stripToAdd+=','+this.stripAdd9;
            if(this.stripAdd10!=undefined && this.stripAdd10!='')
                stripToAdd+=','+this.stripAdd10;

            if(this.selectId=='11' )
                this.to_do = translateEnum.TASKS_MANUAL_505 +' מקט |'+ this.headstopMakat +'|';
            else
                this.to_do = translateEnum.TASKS_MANUAL_505_8 +' מקט |'+ this.headstopMakat +'|' ;

            this.charge = undefined;
            this.comment ="";
            this.task_id=11;
        }
        else if(this.selectId=='12')
        {
            this.to_do = translateEnum.TASKS_ADAPTERS;
            this.charge = undefined;
            this.comment ="";
            this.task_id=12;
        }
        else if(this.selectId=='13')
        {
            this.to_do = this.freeText;
            this.charge = undefined;
            this.comment ="";
            this.task_id=13;
        }
        else if(this.selectId=='14')
        {
            this.to_do = 'מסגרות: '+this.borders;
            this.charge = undefined;
            this.comment ="";
            this.task_id=14;
        }
       
        this.loading = true;
        this.error = null;

        const self = this;

        debugger;
        if(localStorage.editMode!=undefined && localStorage.editMode=='true')
        {
            this.isEditMode=true;
            this.eventData=JSON.parse(JSON.stringify(localStorage.eventData))
        }
        localStorage.editMode=undefined;
       

        if(this.isEditMode)
        {
            const data = {
                comment: this.comment ,
                to_do: this.to_do ,
                charge: this.charge ,
                shift: this.index>-1?this.options[this.index].id:undefined,
                report: this.report.id,
                stop_code: this.report.stop.stop_code,
                name:JSON.parse(localStorage.USER).first_name +' '+ JSON.parse(localStorage.USER).last_name,
                projectId:localStorage.projectId,
                isNew:false,
                eventData:this.eventData,
                task_id:this.task_id

            };
    
            this.api.putAddShift( data)
                .subscribe(
                    r => {
                      //  alert('פעולה לביצוע התעדכנה')
                        debugger;
                        self.loading = false;
                        document.getElementById('task-button').click();
                        this.getShifts.emit({ shift_id: this.report.shift,stop_code:this.report.stop.stop_code });
                        this.isEditMode=false;
                    },
                    r => {
                     //   alert('פעולה לביצוע התעדכנה')
                        debugger;
                        localStorage.removeItem('isNew');
                        self.loading = false;
                        document.getElementById('task-button').click();
                        this.getShifts.emit({ shift_id: this.report.shift,stop_code:this.report.stop.stop_code });
                        this.isEditMode=false;
                    }
                    );
        }
        else
        {
        const data = {
            comment: this.comment ,
            to_do: this.to_do ,
            charge: this.charge ,
            shift: this.index>-1?this.options[this.index].id:undefined,
            report: this.report.id,
            stop_code: this.report.stop.stop_code,
            name:JSON.parse(localStorage.USER).first_name +' '+ JSON.parse(localStorage.USER).last_name,
            projectId:localStorage.projectId,
            isNew:false,
            task_id:this.task_id
        };

        this.api.postAddShift( data)
            .subscribe(
                r => {
                   ;
               //     alert('פעולה לביצוע התווספה')
                    debugger;
                    self.loading = false;
                    document.getElementById('task-button').click();
                    this.getShifts.emit({ shift_id: this.report.shift,stop_code:this.report.stop.stop_code });
                    this.isEditMode=false;
                },
                r => {
               //     alert('פעולה לביצוע התווספה')
                    debugger;


            localStorage.removeItem('isNew');
            self.loading = false;
                        document.getElementById('task-button').click();
                        this.getShifts.emit({ shift_id: this.report.shift,stop_code:this.report.stop.stop_code });
                        this.isEditMode=false;
                });
            }



    }

    toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
      }
    
      selectOption(option: string, event: MouseEvent) {
        debugger;
      
        this.selectedOption = option;
        this.stripDest=option;
        this.isDropdownOpen = false;
        event.preventDefault();     // Prevent default click behavior
        event.stopPropagation();    // Stop event from propagating
      }

      openlist()
      {
        this.isDropdownOpen = true;
      }

    keyUp(val) {
        let start=0;
        let end=0;
        let text=val;
        this.index=-1;
        if(val.key!="Backspace" && val.key!="Alt" && val.key!="Ctrl" && val.key!="Shift" && val.key.length>0)
        {
         //   this.comment = this.comment.replace(/[\r\n]+/, '');
            for(var i=0;i<this.filteredOptions.length;i++)
            {
                if(this.filteredOptions[i].startsWith(this.comment))
                {
                    this.index=i;
                    start = this.comment.length;
                    text = this.filteredOptions[i];
                    end  =this.filteredOptions[i].length;
                    (document.getElementById('textarea1') as HTMLTextAreaElement).value = text;
                    (document.getElementById('textarea1') as HTMLTextAreaElement).setSelectionRange(start,end);
                    this.comment=text;
                    break;
                }
            }
          
        }
       

    }

    updateData(eventData:{})
    {
        this.isEditMode=true;
        this.eventData=eventData;
        debugger;
    }

}
