import {
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { translateEnum } from '../../../../shared/enum/translateEnum';
import { BaseComponent } from '../../../reports/base.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'single-selection',
    templateUrl: './single-selection.component.html',
    styleUrls: ['./single-selection.component.css']
})
export class SingleSelectionComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() public list: any = [];
    @Input() public value: any = null;
    @Input() public header: string = '';
    @Input() public enableClear: boolean;
    @Input() public enableSearch: boolean = true;
    @Input() public error: string = null;
    @Input() public multiple: boolean = false;
    @Input() public disabled: boolean = false;

    translateEnum = translateEnum;

    public filterText: string = '';

    /** control for the selected value */
    public selectedCtrl: FormControl = new FormControl();

    /** control for the MatSelect filter keyword */
    public filterCtrl: FormControl = new FormControl();

    /** list filtered by search keyword */
    public filteredList: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    @ViewChild('singleSelect', {static: false}) singleSelect: MatSelect;

    /** Subject that emits when the component has been destroyed. */
    protected _onDestroy = new Subject<void>();

    /** events sent from OUTER -> into COMPONENT to update list **/
    @Input() updateListAction: Observable<any>;
    updateListSubscription: any;

    /** events sent from OUTER -> into COMPONENT to update item **/
    @Input() updateItemAction: Observable<any>;
    updateItemSubscription: any;

    /** events send from COMPONENT -> to OUTER on selected value **/
    @Output() onSelectEvent = new EventEmitter<any>();

    constructor(private cd: ChangeDetectorRef) {
        super();
    }

    selectedValue(selected) {
        this.onSelectEvent.emit(selected.value);
    }

    clearValue() {
        this.selectedCtrl.reset();
        if (this.multiple) {
            this.onSelectEvent.emit([]);
        } else {
            this.onSelectEvent.emit(null);
        }
    }

    ngOnInit() {

        if (this.updateListAction) {
            this.updateListSubscription = this.updateListAction.subscribe((list: any) => this.updateList(list));
        }

        if (this.updateItemAction) {
            this.updateItemSubscription = this.updateItemAction.subscribe((item: any) => this.updateItem(item));
        }
        this.updateList(this.list);

        // listen for search field value changes
        this.filterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterList();
            });
    }

    ngOnChanges(changes): void {
        if (changes.value) {
            this.selectedCtrl.setValue(this.value);
        }
    }

    updateList(newList: any) {
        this.list = newList;
        if (this.list) {
            this.filteredList.next(this.list.slice());
        }
        this.cd.markForCheck();
    }

    updateItem(newItem: any) {
        if (newItem && !this.list.find(item => item.id === newItem.id)) {
            this.selectedCtrl.setValue(null);
            this.onSelectEvent.emit(null);
            return;
        }
        this.selectedCtrl.setValue(newItem);
    }

    ngAfterViewInit() {
        this.setInitialValue();
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();

        if (this.updateListAction && this.updateListSubscription) {
            this.updateListSubscription.unsubscribe();
        }

        if (this.updateItemAction && this.updateItemSubscription) {
            this.updateItemSubscription.unsubscribe();
        }
    }

    /**
     * Sets the initial value after the filteredList are loaded initially
     */
    protected setInitialValue() {
        this.filteredList
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
                // setting the compareWith property to a comparison function
                // triggers initializing the selection according to the initial value of
                // the form control (i.e. _initializeSelection())
                // this needs to be done after the filteredList are loaded initially
                // and after the mat-option elements are available
                this.singleSelect.compareWith = (a: any, b: any) => a && b && a.id === b.id;
            });
    }

    protected filterList() {
        if (!this.list) {
            return;
        }

        // get the search keyword
        let search = this.filterCtrl.value;
        if (!search) {
            this.filteredList.next(this.list.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the list
        this.filteredList.next(
            this.list.filter(item => item.name.toLowerCase().indexOf(search) > -1)
        );
    }

}
