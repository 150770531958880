import {Injectable} from '@angular/core';
import {Build} from '../../../../app/shared/classes/build';

@Injectable({providedIn: 'root'})
export class BuildService {
    public build: Build;

    constructor() {
        this.build = new Build();
    }
}
