import {Shift} from '../../../shared/models/shift.model';
import {translateEnum} from '../../../shared/enum/translateEnum';

export const download = (trafficChanges: any[],exp) => {
    const data = [];
    let header: string[] = [];
    debugger;
    let max=0;
    trafficChanges.forEach(element => 
                element.data.length>max?max=element.data.length:max=max
            );
 //           header.push(translateEnum.MAKAT);
  //          header.push(translateEnum.MAP_NAME);
  //          for(var i=1;i<max+1;i++)
   //             header.push('פעולה לביצוע '+i);

   debugger;
    let row={}
   for (const traffic of trafficChanges) {
    row = {
        [translateEnum.MAKAT]: traffic.stop_code,
        [translateEnum.MAP_NAME]: exp.name,
      
    };

        // Loop through traffic.data and dynamically assign ACTION properties
        for (let i = 0; i < traffic.data.length; i++) {
            row['פעולה ' + (i + 1)] = traffic.data[i].to_do;
        }


        row['LONG']= traffic.long;
        row['LAT']= traffic.lat;
        if(traffic.data.length>1)
        data.push(row);

    }

    

    
    

    return {header, data};
};
