import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { UserService } from "../../core/service/user.service";
import { translateEnum } from "../../shared/enum/translateEnum";
import { Authorities } from "../../shared/models/authorities.model";
import {
  LINES_CSV_UPLOAD_PERMISSIONS,
  STOPS_FILE_UPLOAD_PERMISSIONS,
  TRAFFIC_CHANGE_PERMISSIONS,
  Permissions,
  PERMISSIONS_KEYS,
} from "../../shared/models/permissions.model";
import { ADMIN_ROLE } from "../../shared/models/role.model";
import { Roles } from "../../shared/models/roles.model";

@Component({
  template: "<div></div>",
  styleUrls: ["../../app.component.css"],
})
export class UserComponent implements OnInit {
  readonly translateEnum = translateEnum;
  readonly PERMISSIONS_KEYS = PERMISSIONS_KEYS;
  readonly LINES_CSV_UPLOAD_PERMISSIONS = LINES_CSV_UPLOAD_PERMISSIONS;
  readonly STOPS_FILE_UPLOAD_PERMISSIONS = STOPS_FILE_UPLOAD_PERMISSIONS;
  readonly TRAFFIC_CHANGE_PERMISSIONS = TRAFFIC_CHANGE_PERMISSIONS;

  disabledUser: boolean;
  errorUser: string;

  validation_messages: any;
  header: string;

  userGroup: FormGroup;

  first_name_form: FormControl;
  last_name_form: FormControl;
  email_form: FormControl;
  phone_form: FormControl;
  password_form: FormControl;
  role_form: FormControl;
  permissionsGroup: FormGroup;

  permissions: Permissions;

  setPermission: boolean;

  authorities: Authorities;
  authoritiesArray: any[];

  roles: Roles;
  rolesArray: any[];

  roleSubject = new Subject<any>();

  /** events sent from OUTER -> into COMPONENT to field user permission **/
  permissionSubjects = {
    reports: new Subject<any>(),
    lines_csv_upload: new Subject<any>(),
    projects: new Subject<any>(),
    flag: new Subject<any>(),
    schedules: new Subject<any>(),
    upload_report: new Subject<any>(),
    images: new Subject<any>(),
    users: new Subject<any>(),
  };

  formsArray: string[] = [
    "first_name",
    "last_name",
    "email",
    "phone",
    "password",
    "role",
  ];

  @Input()
  public successAction: Function;

  @Input()
  writePermission: boolean = true;

  constructor(public userService: UserService) {
    this.roles = new Roles().deserialize({});
    this.rolesArray = this.roles.toArray();
    this.authorities = new Authorities().deserialize({});
    this.authoritiesArray = this.authorities.toArray();
    this.validation_messages = {};
    this.reset();

    for (let i = 0; i < this.formsArray.length; i++) {
      this.validation_messages[this.formsArray[i]] = [
        { type: "required", message: this.translateEnum.FIELD_REQUIRED },
      ];
    }

    this.validation_messages["email"].push({
      type: "pattern",
      message: this.translateEnum.INVALID_EMAIL,
    });
    this.validation_messages["phone"].push({
      type: "pattern",
      message: this.translateEnum.INVALID_PHONE,
    });

    for (let i = 0; i < PERMISSIONS_KEYS.length; i++) {
      this.validation_messages[PERMISSIONS_KEYS[i]] = [
        { type: "required", message: this.translateEnum.FIELD_REQUIRED },
      ];
    }
  }

  resetForm() {
    this.first_name_form = new FormControl("", Validators.required);
    this.last_name_form = new FormControl("", Validators.required);
    this.email_form = new FormControl(
      "",
      Validators.compose([
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"),
      ])
    );
    this.phone_form = new FormControl(
      "",
      Validators.compose([Validators.required, Validators.pattern("^[0-9-]+")])
    );
    this.role_form = new FormControl(null, Validators.required);

    const permissionFormGroup = {};
    for (let i = 0; i < PERMISSIONS_KEYS.length; i++) {
      permissionFormGroup[PERMISSIONS_KEYS[i]] = new FormControl(
        null,
        Validators.required
      );
    }

    this.permissionsGroup = new FormGroup(permissionFormGroup);

    this.userGroup = new FormGroup({
      first_name: this.first_name_form,
      last_name: this.last_name_form,
      email: this.email_form,
      phone: this.phone_form,
      password: this.password_form,
      role: this.role_form,
      permissions: this.permissionsGroup,
    });
  }

  closeModal(modal) {
    modal.hide();
    this.reset();
  }




  reset() {
    this.disabledUser = false;
    this.errorUser = null;
    this.permissions = new Permissions().deserialize(
      Permissions.defaultPermissions(ADMIN_ROLE)
    );
  }

  ngOnInit() {
    this.resetForm();
  }

  roleSelected(role) {
    const defaultPermission = Permissions.defaultPermissions(role.id);
    const permissions = this.permissions;
    const subjects = this.permissionSubjects;
    let authorityId, authority;

    for (const key in defaultPermission) {
      if (defaultPermission.hasOwnProperty(key)) {
        authorityId = defaultPermission[key];
        authority = this.authorities.getAuthority(authorityId);

        permissions[key].setPermission(authorityId);
        subjects[key].next(authority);

        this.permissionsGroup.get(key).setValue(permissions[key]);
      }
    }
    this.role_form.setValue(role);
  }

  permissionSelected(field, authorityJson) {
    this.permissions[field].setPermission(authorityJson.id);
    this.permissionsGroup.get(field).setValue(this.permissions[field]);
  }

  markAsTouched() {
    const userGroup = this.userGroup;
    for (let i = 0; i < this.formsArray.length; i++) {
      userGroup.get(this.formsArray[i]).markAsTouched();
    }
    for (let i = 0; i < PERMISSIONS_KEYS.length; i++) {
      userGroup.get("permissions").get(PERMISSIONS_KEYS[i]).markAsTouched();
    }
  }

  hasSetPermission(user) {
    return user ? user.id !== this.userService.getUser().id : true;
  }
}
