import {Deserializable} from './deserializable.model';
import {translateEnum} from '../../shared/enum/translateEnum';
import {OPERATOR_NAME} from '../../shared/enum/operator';

export class Operator implements Deserializable {

    id: number;
    operator_name: string;
    lang: string;
    filename: string;
    color: string;

    deserialize(input: { operator_name: string, lang: string }) {
        this.setOperator(input.operator_name, input.lang);
        return this;
    }

    setOperator(operator_name, lang) {
        this.operator_name = operator_name;
        this.lang = lang;

        switch (operator_name) {
            case translateEnum.OPERATOR_EGGED_TAAVURA:
                this.filename = 'operator_egged_taavura.png';
                this.color = '#1a6830';
                this.id = OPERATOR_NAME.OPERATOR_EGGED_TAAVURA;
                break;
            case translateEnum.OPERATOR_EGGED:
                this.filename = 'operator_egged.png';
                this.color = '#26975e';
                this.id = OPERATOR_NAME.OPERATOR_EGGED;
                break;
            case translateEnum.OPERATOR_ELECTRA_AFIKIM:
                this.filename = 'operator_electra_afikim.png';
                this.color = 'white';
                this.id = OPERATOR_NAME.OPERATOR_ELECTRA_AFIKIM;
                break;
            case translateEnum.OPERATOR_ELECTRA_AFIKIM_TAHBURA:
                this.filename = 'operator_electra_afikim.png';
                this.color = 'white';
                this.id = OPERATOR_NAME.OPERATOR_ELECTRA_AFIKIM_TAHBURA;
                break;
            case translateEnum.OPERATOR_AFIKIM:
                this.filename = 'operator_afikim.png';
                this.color = 'white';
                this.id = OPERATOR_NAME.OPERATOR_AFIKIM;
                break;
            case translateEnum.OPERATOR_GB_TOURS:
                this.filename = 'operator_gb_tours.png';
                this.color = 'white';
                this.id = OPERATOR_NAME.OPERATOR_GB_TOURS;
                break;
            case translateEnum.OPERATOR_GALEEM:
                this.filename = 'operator_galeem.png';
                this.color = 'white';
                this.id = OPERATOR_NAME.OPERATOR_GALEEM;
                break;
            case translateEnum.OPERATOR_DANBR7:
                this.filename = 'operator_danbr7.png';
                this.color = 'white';
                this.id = OPERATOR_NAME.OPERATOR_DANBR7;
                break;
            case translateEnum.OPERATOR_DANBADAROM:
                this.filename = 'operator_danbadarom.png';
                this.color = '#253865';
                this.id = OPERATOR_NAME.OPERATOR_DANBADAROM;
                break;
            case translateEnum.OPERATOR_DANNORTH:
                this.filename = 'operator_dannorth.png';
                this.color = 'white';
                this.id = OPERATOR_NAME.OPERATOR_DANNORTH;
                break;
            case translateEnum.OPERATOR_DAN:
                this.filename = 'operator_dan.png';
                this.color = '#004b9a';
                this.id = OPERATOR_NAME.OPERATOR_DAN;
                break;
            case translateEnum.OPERATOR_SHUFAT_BUS:
                this.filename = 'operator_shufat_bus.png';
                this.color = 'none';
                this.id = OPERATOR_NAME.OPERATOR_SHUFAT_BUS;
                break;
            case translateEnum.OPERATOR_ATA_BUSES:
                this.filename = 'operator_ata_buses.png';
                this.color = 'none';
                this.id = OPERATOR_NAME.OPERATOR_ATA_BUSES;
                break;
            case translateEnum.OPERATOR_CENTER_BUSES:
                this.filename = 'operator_center_buses.png';
                this.color = 'none';
                this.id = OPERATOR_NAME.OPERATOR_CENTER_BUSES;
                break;
            case translateEnum.OPERATOR_SOUTH_BUSES:
                this.filename = 'operator_south_buses.png';
                this.color = 'none';
                this.id = OPERATOR_NAME.OPERATOR_SOUTH_BUSES;
                break;
            case translateEnum.OPERATOR_MOUNTOFOLIVES_BUS:
                this.filename = 'operator_mountofolives_bus.png';
                this.color = 'none';
                this.id = OPERATOR_NAME.OPERATOR_MOUNTOFOLIVES_BUS;
                break;
            case translateEnum.OPERATOR_SURBAHER_BUS:
                this.filename = 'operator_surbaher_bus.png';
                this.color = 'none';
                this.id = OPERATOR_NAME.OPERATOR_SURBAHER_BUS;
                break;
            case translateEnum.OPERATOR_RAMALLAH_BUS:
                this.filename = 'operator_ramallah_bus.png';
                this.color = 'none';
                this.id = OPERATOR_NAME.OPERATOR_RAMALLAH_BUS;
                break;
            case translateEnum.OPERATOR_SHUFAT:
                this.filename = 'operator_shufat_bus.png';
                this.color = 'none';
                this.id = OPERATOR_NAME.OPERATOR_SHUFAT;
                break;
            case translateEnum.OPERATOR_CALLKAV:
                this.filename = 'operator_callkav.png';
                this.color = 'white';
                this.id = OPERATOR_NAME.OPERATOR_CALLKAV;
                break;
            case translateEnum.OPERATOR_GOLAN_BUS:
                this.filename = 'operator_golan_bus.png';
                this.color = 'white';
                this.id = OPERATOR_NAME.OPERATOR_GOLAN_BUS;
                break;
            case translateEnum.OPERATOR_METROPOLINE:
                this.filename = 'operator_metropoline.png';
                this.color = 'white';
                this.id = OPERATOR_NAME.OPERATOR_METROPOLINE;
                break;
            case translateEnum.OPERATOR_NTT_BUSES:
                this.filename = 'operator_ntt_buses.png';
                this.color = 'white';
                this.id = OPERATOR_NAME.OPERATOR_NTT_BUSES;
                break;
            case translateEnum.OPERATOR_NATEEV_EXPRESS:
                this.filename = 'operator_nateev_express.png';
                this.color = 'white';
                this.id = OPERATOR_NAME.OPERATOR_NATEEV_EXPRESS;
                break;
            case translateEnum.OPERATOR_SUPERBUS:
                this.filename = 'operator_superbus.png';
                this.color = 'white';
                this.id = OPERATOR_NAME.OPERATOR_SUPERBUS;
                break;
            case translateEnum.OPERATOR_KAVIM:
                this.filename = 'operator_kavim.png';
                this.color = 'white';
                this.id = OPERATOR_NAME.OPERATOR_KAVIM;
                break;
            case translateEnum.OPERATOR_NAZARETH_UNBS:
                this.filename = 'operator_nazareth_unbs.png';
                this.color = 'white';
                this.id = OPERATOR_NAME.OPERATOR_NAZARETH_UNBS;
                break;
            case translateEnum.OPERATOR_NIGHT:
                this.filename = 'operator_night.png';
                this.color = '#151616';
                this.id = OPERATOR_NAME.OPERATOR_NIGHT;
                break;
            case translateEnum.OPERATOR_BETSHEMESH:
                this.filename = `operator_betshemesh_${lang}.png`;
                this.color = 'white';
                this.id = OPERATOR_NAME.OPERATOR_BETSHEMESH;
                break;
            case translateEnum.OPERATOR_TNUFA:
                this.filename = 'operator_tnufa.png';
                this.color = 'white';
                this.id = OPERATOR_NAME.OPERATOR_TNUFA;
                break;
            case translateEnum.OPERATOR_EXTRA:
                this.filename = 'operator_extra.png';
                this.color = 'white';
                this.id = OPERATOR_NAME.OPERATOR_EXTRA;
                break;
            case translateEnum.OPERATOR_EXTRA_JERUSALEM:
                this.filename = 'operator_extra.png';
                this.color = 'white';
                this.id = OPERATOR_NAME.OPERATOR_EXTRA_JERUSALEM;
                break;
            case translateEnum.EGED_DERECH:
                this.filename = 'eged_derech.png';
                this.color = 'white';
                this.id = OPERATOR_NAME.EGED_DERECH;
                break;
            default:
                this.filename = null;
                this.color = null;
                this.id = null;
        }
    }
}
