import { Component, Input } from "@angular/core";
import { translateEnum } from "../../../../shared/enum/translateEnum";

@Component({
  selector: "search-status",
  templateUrl: "search-status.component.html",
  styleUrls: ["search-status.component.css"],
})
export class SearchStatusComponent {
  readonly translateEnum = translateEnum;
  @Input() public table: any[];
  @Input() public loading: boolean;

  isEmptyTable() {
    return !this.table || !this.table.length;
  }
}
