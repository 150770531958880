import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import 'jspdf-autotable';
import {translateEnum} from '../../../shared/enum/translateEnum';
import {alignElement, rollbackElement} from '../../../core/service/flag-pdf/helpers';
import {FlagAlignElement, FlagBrailSetting} from '../../../shared/interfaces/flag';

export class FlagBrailPdfService {

    translateEnum = translateEnum;
    onUpdateEvent: Function;
    settings: FlagBrailSetting;

    constructor(settings: FlagBrailSetting, onUpdateEvent: Function) {
        this.onUpdateEvent = onUpdateEvent;
        this.settings = settings;
    }

    async download() {
        let img, eli, canvas;
        const self = this;

        window.scrollTo(0, 0);

        this.onUpdateEvent(`0%`);

        const el = document.getElementById(this.settings.key);
        const prev_style: FlagAlignElement = alignElement(el);

        try {
            const pdf = new jsPDF(
                this.settings.style.orientation,
                'pt',
                [this.settings.style.page_width / 2, this.settings.style.page_height / 2]
            );

            const img_width = this.settings.style.cell_width / 2 + this.settings.style.cell_width_margin;
            const img_height = this.settings.style.cell_height / 2 + this.settings.style.cell_height_margin;

            let columnIndex = (
                this.settings.style.page_height -
                (this.settings.style.cell_height + this.settings.style.cell_height_margin * 2) * this.settings.style.column_count
            ) / 4;

            for (let n = 0; n < this.settings.style.column_count; n++) {

                let rowIndex = (
                    this.settings.style.page_width -
                    (this.settings.style.cell_width + this.settings.style.cell_width_margin * 2) * this.settings.style.row_count
                ) / 4;

                for (let k = 0; k < this.settings.style.row_count; k++) {

                    eli = document.getElementById(`${this.settings.key}-cell-${n}-${k}`);
                    if (eli) {

                        canvas = await html2canvas(eli);
                        img = canvas.toDataURL('image/jpeg');
                        pdf.addImage(
                            img,
                            'JPEG',
                            rowIndex,
                            columnIndex,
                            img_width,
                            img_height
                        );
                        this.onUpdateEvent(
                            `${Math.floor((((n) + (k) / (this.settings.style.row_count - 1)) / this.settings.style.column_count) * 100)}%`
                        );
                    }

                    rowIndex += img_width;
                }

                columnIndex += img_height;
            }

            const frame_dots = ['top_left', 'top_right', 'bottom_left', 'bottom_right'];
            for (let i = 0; i < frame_dots.length; i++) {
                eli = document.getElementById(`${this.settings.key}-${frame_dots[i]}`);
                if (eli) {
                    canvas = await html2canvas(eli);
                    img = canvas.toDataURL('image/jpeg');
                    pdf.addImage(
                        img,
                        'JPEG',
                        this.settings.style[`dot_frame_${frame_dots[i]}_width`] / 2,
                        this.settings.style[`dot_frame_${frame_dots[i]}_height`] / 2,
                        this.settings.style.dot_frame_size / 2,
                        this.settings.style.dot_frame_size / 2
                    );
                }
            }

            this.onUpdateEvent(translateEnum.CREATING_FILE);
            pdf.save(self.settings.file_name);
        } catch (error) {
            console.log(error);
        }
        rollbackElement(el, prev_style);
    }

}
