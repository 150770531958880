import {Deserializable} from './deserializable.model';
import {DOWNLOAD_TYPE} from '../../shared/enum/report';
import {EXPORT_TYPE} from '../../shared/enum/export';

export class Export implements Deserializable {

    id: string = null;
    name: string = '';
    sifrur: string = '';
    columns: string[] = [];
    user_id: string = null;
    loading: boolean = false;
    report_type: DOWNLOAD_TYPE = null;
    type: EXPORT_TYPE = EXPORT_TYPE.REPORTS;
    custom: boolean = true;

    deserialize(input: any) {
        Object.assign(this, input);
        if (input.user) {
            input.user_id = input.user;
        }

        if (!input.type) {
            this.type = EXPORT_TYPE.REPORTS;
        }

        if (this.custom) {
            if (this.type === EXPORT_TYPE.REPORTS) {
                this.report_type = DOWNLOAD_TYPE.REPORTS_MANUAL;
            } else if (input.type === EXPORT_TYPE.PROJECTS) {
                this.report_type = DOWNLOAD_TYPE.PROJECTS_MANUAL;
            } else if (input.type === EXPORT_TYPE.PROJECT) {
                this.report_type = DOWNLOAD_TYPE.PROJECT_MANUAL;
            }
        }

        return this;
    }

    setLoading(status: boolean) {
        this.loading = status;
    }

}
