import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {translateEnum} from '../../../../shared/enum/translateEnum';
import {UploadCsv} from '../../../../shared/interfaces/upload';
import {Observable} from 'rxjs';

@Component({
  selector: "upload-csv",
  templateUrl: "../upload.component.html",
  styleUrls: ["../upload.component.css"],
})
export class UploadCsvComponent implements OnInit {
  readonly translateEnum: typeof translateEnum = translateEnum;
  @Input() elementId: string;
  @Output() result = new EventEmitter<UploadCsv>();
  @Input() reset: Observable<any>;
  reset_subscription: any;

  @Input() fileName: string = translateEnum.CHOOSE_FILE;
  loading: boolean = false;
  accept: string = ".csv";

  ngOnInit() {
    const self = this;
    if (this.reset) {
      this.reset_subscription = this.reset.subscribe(
        () => (self.fileName = translateEnum.CHOOSE_FILE)
      );
    }
  }

  ngAfterViewInit() {
    const self = this;

    setTimeout(function () {
      document.getElementById(self.elementId).addEventListener(
        "change",
        function () {
          self.loading = true;
          const file = (<HTMLInputElement>this).files[0];
          const fileReader = new FileReader();
          if (file) {
            fileReader.readAsText(file);
            self.fileName = file.name;
            let max_headers = 0;

       /**    fileReader.onload = function (fileLoadedEvent) {
              try {
                const data = fileLoadedEvent.target.result;
                const workbook = XLSX.read(data, { type: "binary" });
                workbook.SheetNames.forEach(function (sheetName) {
                  const text = XLSX.utils.sheet_to_csv(
                    workbook.Sheets[sheetName]
                  );

                  if (text.length === 0) {
                    // empty file
                    self.fileName = translateEnum.EMPTY_FILE;
                    self.result.emit({
                      header: [],
                      table: [],
                      file_name: self.fileName,
                      file,
                    });
                    return;
                  }

                  let sep, columns, columns_length, tl;
                  const universalNewline = /\r\n|\r|\n/g;
                  const rows = text.split(universalNewline);
                  const table = [];
                  for (let i = 0; i < rows.length; i++) {
                    for (
                      columns = rows[i].split((sep = sep || ",")),
                        columns_length = columns.length - 1,
                        tl;
                      columns_length >= 0;
                      columns_length--
                    ) {
                      if (
                        columns[columns_length]
                          .replace(/"\s+$/, '"')
                          .charAt(columns[columns_length].length - 1) === '"'
                      ) {
                        if (
                          (tl = columns[columns_length].replace(/^\s+"/, '"'))
                            .length > 1 &&
                          tl.charAt(0) === '"'
                        ) {
                          columns[columns_length] = columns[columns_length]
                            .replace(/^\s*"|"\s*$/g, "")
                            .replace(/""/g, '"');
                        } else if (columns_length) {
                          columns.splice(
                            columns_length - 1,
                            2,
                            [
                              columns[columns_length - 1],
                              columns[columns_length],
                            ].join(sep)
                          );
                        } else {
                          columns = columns.shift().split(sep).concat(columns);
                        }
                      } else {
                        columns[columns_length].replace(/""/g, '"');
                      }
                    }
                    rows[i] = columns;
                    max_headers = Math.max(max_headers, columns.length);
                    const json = columns.reduce((acc, column, index) => {
                      acc[index.toString()] = column;
                      return acc;
                    }, {});
                    table.push(json);
                  }
                  const header = Array.from(Array(max_headers).keys()).reduce(
                    (acc, _, i) => {
                      acc.push({
                        key: i.toString(),
                        title: i.toString(),
                      });
                      return acc;
                    },
                    []
                  );

                  self.result.emit({
                    header,
                    table,
                    file_name: self.fileName,
                    file,
                  });
                });
              } catch (err) {
                console.log(err);
              }
              self.loading = false;
            };
 */ 
            fileReader.onerror = function (err) {
              console.log(err);
              self.loading = false;
            };
          } else {
            self.loading = false;
          }
        },
        false
      );
    }, 200);
  }

  ngOnDestroy() {
    if (this.reset && this.reset_subscription) {
      this.reset_subscription.unsubscribe();
    }
  }
}
