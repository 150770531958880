
import {tap} from 'rxjs/operators';
import { Router} from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler ,HttpErrorResponse, HttpEvent} from '@angular/common/http';
import { Observable } from 'rxjs'
import { ApiService } from '../core/service/api.service';



@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(public auth: ApiService, private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
            //if (event instanceof HttpResponse) {}
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    // redirect to the login route
                    this.router.navigateByUrl('login');
                }
            }
        }));
    }
}
