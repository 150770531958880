import { Component, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ApiService } from '../../core/service/api.service';
import { UserService } from '../../core/service/user.service';
import { translateEnum } from '../../shared/enum/translateEnum';
import { Permissions } from '../../shared/models/permissions.model';
import { ADMIN_ROLE } from '../../shared/models/role.model';
import { User } from '../../shared/models/user.model';
import { UserComponent } from './user.component';

@Component({
    selector: 'adduser',
    templateUrl: 'user.component.html',
    styleUrls: ['./users.component.css', '../../app.component.css'],
})
export class AddUserComponent extends UserComponent {

    translateEnum = translateEnum;
    @Input() public userModal: ModalDirective;

    constructor(private api: ApiService, public userService: UserService) {
        super(userService);
        this.init();
        this.header = this.translateEnum.ADD_USER;
        this.setPermission = this.hasSetPermission(null);
    }

    onSubmit(userGroup, modal) {

        if (!userGroup.valid) {
            this.markAsTouched();
            return;
        }

        if (this.disabledUser) {
            return;
        }

        this.disabledUser = true;
        this.errorUser = null;

        const data = userGroup.value;

        this.api.addUser(
            data.first_name.trim(),
            data.last_name.trim(),
            data.email.trim(),
            User.phoneWithSeparator(data.phone),
            data.role.id,
            this.permissions,
            data.password.trim()
        ).subscribe(
            r => {
                this.successAction(r);
                this.closeModal(modal);
                this.init();
            },
            r => {
                console.log(r);
                this.errorUser = this.translateEnum.ERROR_MESSAGE.replace('[message]', r.error.message);
                this.disabledUser = false;
            });
    }

    init() {
        this.password_form = new FormControl('', Validators.required);
        this.permissions = new Permissions().deserialize(Permissions.defaultPermissions(ADMIN_ROLE));
        this.resetForm();
    }

    ngOnInit() {

    }

}
