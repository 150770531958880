export enum DOWNLOAD_TYPE {
    REPORTS_GENERAL = 1,
    REPORTS_FLAG = 2,
    REPORTS_BRAIL = 3,
    REPORTS_STATIC = 4,
    REPORTS_HEAD = 5,
    REPORTS_SHEDS = 6,
    REPORTS_MANUAL = 7,
    PROJECTS_GENERAL = 8,
    PROJECTS_MANUAL = 9,
    PROJECT_GENERAL = 10,
    PROJECT_MANUAL = 11,
    REPORTS_COMMENT = 12,
    REPORTS_OPERATOR_IN_CHARGE = 13,
    DOWNLOAD_PROJECTS=14,
    TRAFFIC_CHANGES=15,
    TRAFFIC_TOTALS=16,
    SCHEDUALE=17,
}
