import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiService } from '../../core/service/api.service';
import { UserService } from '../../core/service/user.service';
import { UsersService } from '../../core/service/users.service';
import { translateEnum } from '../../shared/enum/translateEnum';
import { USERS_PERMISSIONS } from '../../shared/models/permissions.model';
import { User } from '../../shared/models/user.model';

@Component({
    templateUrl: 'users.component.html',
    styleUrls: ['./users.component.css', '../../app.component.css'],
})
export class UsersComponent implements OnInit {

    // main table params
    public changePage: Function;
    users: User[] ;
    usersTable: User[] ;
    disabled_search: boolean = false;
    page: number = 1;
    translateEnum = translateEnum;

    // params search fields
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    

    first_name_modal: string;
    last_name_modal: string;
    email_modal: string;
    phone_modal: string;
    role_modal: string;
    password_modal: string;
    user_id_modal:string;


    // delete user warning modal params
    contentWarning: string;
    removeUser: User;

    modelshow:boolean=false;
    header="";
    btnupdate:boolean=false;
    btnadd:boolean=false;

    // edit user modal params
    editUser: User;

    // add new user success observer

    mobileView: boolean = false;

    writePermission: boolean = true;

    constructor(private api: ApiService, private usersService: UsersService, public userService: UserService) {
       

    
    }

    ngOnInit(): void {
        debugger;
        if (window.innerWidth <= 550) {
            this.mobileView = true;
        }
        this.resetFields();
    }

    search() {
        if (this.disabled_search) {
            return;
        }
        this.getUsers();
    }

    updateTable(page) {

        this.page = page;

        const result = [];

        if (this.users) {

            const count = this.users.length;
            const start = (page - 1) * 10;
            const finish = page * 10;

            if (start <= count) {

                for (let j = start; j < finish; j++) {

                    if (count <= j) {
                        break;
                    }

                    this.users[j].index = j + 1;
                    result.push(this.users[j]);
                }
            }
        }

        this.usersTable = result;
    }

    getUsers(page = 1) {
        debugger;
        this.disabled_search = true;
        this.api.users(this.first_name, this.last_name, User.phoneWithSeparator(this.phone), this.email)
            .subscribe(
                async r => {
                    this.users = r;
                    this.users = this.users.filter(x=>x.deleted==false)

                   

                    this.disabled_search = false;
                });

    }

    deleteUserWarning( data) {
        debugger;
        this.removeUser = data;
        
        this.api.deleteUser(this.removeUser.id)
            .subscribe(
                async r => {
                    alert('משתמש נמחק');
                    this.getUsers();
                });

    }

    deleteUserValidate(value) {
        return value === this.removeUser.name ? null : this.translateEnum.DELETE_USER_INPUT_ERROR_WARNING;
    }

    showAddUser(modal) {
        this.btnupdate=false;
        this.btnadd=true;
        this.modelshow=true;
        this.first_name_modal="";
        this.last_name_modal="";
        this.password_modal="";
        this.email_modal="";
        this.phone_modal="";
        this.role_modal="";
        this.header="הוספת משתמש";
    }

    showEditUser(editUserModal,user)
    {
        this.btnupdate=true;
        this.btnadd=false;
        debugger;
        this.first_name_modal=user.first_name;
        this.last_name_modal=user.last_name;
        this.password_modal=user.password;
        this.email_modal=user.email;
        this.phone_modal=user.phone;
        this.role_modal=user.role;
        this.modelshow=true;
        this.user_id_modal=user.id;
        this.header="עריכ תמשתמש";
    }

    closeModal() {
        this.modelshow=false;
      }

    successAddUser(response) {
        this.usersService.loadUsers();
        this.users.push(new User().deserialize(response));
        this.changePage(this.page);
    }

    successEditUser(response, user) {
        this.usersService.loadUsers();
        this.users[user.index - 1] = new User().deserialize(response);
        this.changePage(this.page);
    }

    onSubmitAdd(){
        this.api.addUser(
            this.first_name_modal.trim(),
            this.last_name_modal.trim(),
            this.email_modal.trim(),
            User.phoneWithSeparator(this.phone_modal),
            this.role_modal,
            undefined,
            this.password_modal
        ).subscribe(
            r => {
                this.getUsers()
                this.closeModal();
            },
            r => {
                console.log(r);
               
            });
    }

    onSubmitUpdate(){
        debugger;
        this.api.editUser(
            this.user_id_modal,
            this.first_name_modal.trim(),
            this.last_name_modal.trim(),
            this.email_modal.trim(),
            User.phoneWithSeparator(this.phone_modal),
            this.role_modal,
            undefined,
            this.password_modal
        ).subscribe(
            r => {
                debugger
                this.getUsers()
                this.closeModal();
            });
    }

    resetFields() {

        this.first_name = '';
        this.last_name = '';
        this.email = '';
        this.phone = '';

        this.getUsers();

    }

}
