import {Report} from "../../../shared/models/report.model";
import {translateEnum} from "../../../shared/enum/translateEnum";
import {User} from "../../../shared/models/user.model";

export const download = (reports: Report[], users: User[]) => {
	const data = [];
	let max = 0;
	let row, wrongs;

	for (const report of reports) {
		wrongs = 0;
		row = {
			[translateEnum.STOP_CODE_STATION]: report.stop.stop_code,
			[translateEnum.STOP_TYPE]: report.stopType,
			[translateEnum.SCHEDULE_PERFORMER]: report.userName(users),
		};

		for (const brail of report.brail) {
			if (brail.is_wrong) {
				row[`${translateEnum.WRONG_BRAIL} ${++wrongs}`] = brail.route;
			}
		}

		max = Math.max(max, wrongs);
		row[translateEnum.WRONG_COUNT_BRAIL] = wrongs;
		data.push(row);
	}

	let header: string[] = [
		translateEnum.STOP_CODE_STATION,
		translateEnum.STOP_TYPE,
		translateEnum.SCHEDULE_PERFORMER,
		translateEnum.WRONG_COUNT_BRAIL
	];

	for (let i = 1; i <= max; i++) {
		header.push(`${translateEnum.WRONG_BRAIL} ${i}`);
	}

	return {header, data};
}
