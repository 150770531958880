import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { NativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { RouterModule } from "@angular/router";
import { AlertModule } from "ngx-bootstrap/alert";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { BaseModule } from "../base/base.module";
import { BaseComponent } from "./base.component";
import { UploadResultMessageComponent } from "./upload-result-message/upload-result-message.component";
import { UploadsRoutingModule } from "./uploads-routing.module";
import { UploadsComponent } from "./uploads.component";

@NgModule({
  imports: [
    FormsModule,
    UploadsRoutingModule,
    BsDropdownModule,
    BsDatepickerModule.forRoot(),
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    AlertModule.forRoot(),
    MatDatepickerModule,
    NativeDateModule,
    MatButtonModule,
    MatIconModule,
    ModalModule,
    RouterModule,
    BaseModule,
  ],
  declarations: [
    UploadsComponent,
    UploadResultMessageComponent,
    BaseComponent,
    UploadResultMessageComponent,
  ],
  bootstrap: [],
})
export class UploadsModule {}
