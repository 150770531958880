import {Component, Input} from '@angular/core';
import {FlagPageLine} from '../../../shared/models/flag-page-line.model';
import flagStyle from '../flag-style';

@Component({
    selector: 'flag-line',
    templateUrl: 'flag-line.component.html',
    styleUrls: ['flag-line.component.css']
})

export class FlagLineComponent {

    public flagStyle = flagStyle;

    @Input() public flagPageLine: FlagPageLine | null;
    @Input() public lang: string;
    @Input() public showLine: boolean;
    @Input() public isStrip: boolean = false;

    line_signs: string | null = null;

    fontSizeImage: number = flagStyle.flagLineRowImageTextFontSize;
    fontSizeHead: number = flagStyle.heFlagLineRowTextFullNameHeadFontSize;
    fontSizeSub: number = flagStyle.enFlagLineRowTextFullNameSubFontSize;
    fontSizeNumber: number = flagStyle.flagLineRowTextFullNumberOneRowFontSize;

    flagLineRowTextFull() {
        if (this.isStrip) {
            return flagStyle.flagStrip;
        }

        if (!this.flagPageLine.only_numbers) {
            return flagStyle.flagLineRowTextFull;
        }

        return {...{justifyContent: 'flex-end'}, ...flagStyle.flagLineRowTextFull};
    }

    flagLineRowImage() {
        const styleOperator = this.flagPageLine && this.flagPageLine.show_operator && this.flagPageLine.operator.color ?
            {'background-color': this.flagPageLine.operator.color} :
            {};
        return this.lang === 'he' ?
            {...styleOperator, ...flagStyle.heFlagLineRowImage} :
            {...styleOperator, ...flagStyle.enFlagLineRowImage};
    }

    scaleImage() {
        if (this.flagPageLine) {

            const parent = document.getElementById(`flag-line-row-image-parent-${this.lang}-${this.flagPageLine.id}`);
            const text = document.getElementById(`flag-line-row-image-${this.lang}-${this.flagPageLine.id}`);
            if (parent && text) {

                if ((text.scrollWidth > parent.scrollWidth || text.scrollHeight > parent.scrollHeight) &&
                    this.fontSizeImage > 16) {

                    this.fontSizeImage -= 5;
                    text.style.fontSize = this.fontSizeImage + 'pt';

                    const self = this;
                    setTimeout(function () {
                        self.scaleImage();
                    }, 500);

                }
            }
        }
    }

    scaleText() {
        if (this.flagPageLine && !this.flagPageLine.only_numbers) {

            const parent = document.getElementById(`flag-line-row-text-full-${this.lang}-${this.flagPageLine.id}`);

            if (parent) {
                const head = document.getElementById(`flag-line-row-text-full-head-${this.lang}-${this.flagPageLine.id}`);
                const sub = document.getElementById(`flag-line-row-text-full-sub-${this.lang}-${this.flagPageLine.id}`);
                const number = document.getElementById(`flag-line-row-text-full-number-${this.lang}-${this.flagPageLine.id}`);
                const parentHeight = parent.offsetHeight;
                const headSubHeight = head.offsetHeight + sub.offsetHeight;
                const parentWidth = parent.offsetWidth - 15;
                const numberTitleWidth = Math.max(head.offsetWidth, sub.offsetWidth) + number.offsetWidth;
                const self = this;

                if (parentHeight <= headSubHeight || parentWidth <= numberTitleWidth) {

                    if (this.flagPageLine.line_dest_sub &&
                        sub.offsetWidth > head.offsetWidth &&
                        this.fontSizeSub > 60) {

                        this.fontSizeSub -= 3;
                        sub.style.fontSize = this.fontSizeSub + 'pt';

                        setTimeout(function () {
                            self.scaleText();
                        }, 500);

                    } /*else {
                        if (this.fontSizeHead > 97) {
                            this.fontSizeHead -= 4;
                            head.style.fontSize = this.fontSizeHead + 'pt';

                            setTimeout(function () {
                                self.scaleText();
                            }, 500);
                        }
                    }*/
                }
            }
        }
    }

    ngOnChanges(changes): void {
        if (changes.flagPageLine && changes.flagPageLine.currentValue) {
            const flagPageLine = changes.flagPageLine.currentValue;
            this.line_signs = flagPageLine ? flagPageLine.line_signs.join(',') : null;
        }
        if (changes.lang && changes.lang.currentValue) {
            const lang = changes.lang.currentValue;
            if (lang) {
                this.fontSizeHead = flagStyle[lang + 'FlagLineRowTextFullNameHeadFontSize'];
                this.fontSizeSub = flagStyle[lang + 'FlagLineRowTextFullNameSubFontSize'];
            }
        }
        this.fontSizeNumber = flagStyle.flagLineRowTextFullNumberOneRowFontSize;
    }

    ngAfterViewInit(): void {
        const self = this;
        setTimeout(function () {
            self.scaleImage();
            self.scaleText();
        }, 500);
    }

}
