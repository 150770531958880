import { Component, OnInit, ViewChild } from '@angular/core';
import { HeaderKey } from '../../shared/interfaces/header';
import { AlertConfig } from 'ngx-bootstrap/alert';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { UploadsService } from '../../core/service/uploads.service';
import { UserService } from '../../core/service/user.service';
import { UsersService } from '../../core/service/users.service';
import { getISO8601FromDate, getISO8601ToDate, letterToNumber } from '../../global.component';
import { translateEnum } from '../../shared/enum/translateEnum';
import { Header } from '../../shared/interfaces/header';
import { Upload, UploadResultMessage, UploadXlsx } from '../../shared/interfaces/upload';
import { UPLOAD_REPORT_PERMISSIONS } from '../../shared/models/permissions.model';
import { Shift } from '../../shared/models/shift.model';
import { Tasks } from '../../shared/models/tasks.model';
import { User } from '../../shared/models/user.model';
import * as XLSX from 'xlsx';
import { UploadXlsxComponent } from '../base/upload/upload-xlsx/upload-xlsx.component';


export function getAlertConfig(): AlertConfig {
    return Object.assign(new AlertConfig(), {type: 'success'});
}

@Component({
    templateUrl: 'uploads.component.html',
    styleUrls: ['./uploads.component.css', '../../app.component.css'],
    providers: [{provide: AlertConfig, useFactory: getAlertConfig}]
})
export class UploadsComponent implements OnInit {

    public getISO8601FromDate = getISO8601FromDate;
    public getISO8601ToDate = getISO8601ToDate;

    readonly translateEnum = translateEnum;
    public changePage: Function;
    format: string = 'YYYY-MM-DD';
    show_format: boolean = false;
    users: User[];

    header: Header[] = [];
    //doc: Upload[] = [];
    docs: any[] = [];
    user: User;
    tasks: Tasks;
    jobTypes: {
        [key: string]: string;
    }[];
    error: string[] = [];
    succeed: string[] = [];
    validation: string = '';
    success: string = null;
    from_date: string;
    to_date: string;
    name: string[]=[];
    disabled: boolean = false;
    showsubmit: boolean[] = [];
    showvalidation: boolean = false;
    show:boolean[]=[];

    validation_messages: any;
    validation_errors: any;

    usersSubject: Subject<any> = new Subject<any>();
    @ViewChild('uploadResultMessageModal', {static: false}) modal: ModalDirective;
    result_message_subject$: Subject<UploadResultMessage> = new Subject<UploadResultMessage>();
    reset_user_subject: Subject<void> = new Subject<void>();
    reset_from_date_subject: Subject<void> = new Subject<void>();
    reset_to_date_subject: Subject<void> = new Subject<void>();
    reset_file_subject: Subject<void> = new Subject<void>();

    public modalRef: BsModalRef;

    constructor(private usersService: UsersService,
                public userService: UserService,
                private uploadsService: UploadsService) {

        this.onSuccess = this.onSuccess.bind(this);
        this.onError = this.onError.bind(this);
        this.onReset = this.onReset.bind(this);
        this.userService.pagePermission(UPLOAD_REPORT_PERMISSIONS);
        this.getUsers();
    }

    ngOnInit(): void {
        this.onReset();
    }

    onReset() {
        this.validation_messages = {
            user: {
                required: this.translateEnum.FIELD_REQUIRED
            },
            from_date: {
                required: this.translateEnum.FIELD_REQUIRED
            },
            to_date: {
                required: this.translateEnum.FIELD_REQUIRED
            }
        };

        this.validation_errors = {
            name: null,
            user: null,
            from_date: null,
            to_date: null,
        };

        this.header = [];
      //  this.doc = [];
        this.docs = [];
        this.error = [];
        this.succeed=[];
        this.success = null;
      //  this.name = '';
        this.disabled = false;

      //  this.user = null;
        this.reset_user_subject.next();

      //  this.from_date = '';
        this.reset_from_date_subject.next();

      //  this.to_date = '';
        this.reset_to_date_subject.next();

        this.reset_file_subject.next();
    }

    // get all users from server
    getUsers() {
        this.users = [];
        if (this.usersService) {
            this.usersService.getUsers(false).subscribe(users => {
                this.users = users;
                this.usersSubject.next(users);
            });
            this.usersService.refresh();
        }
    }

    // set selected user in drop down
    selectValue(user: any) {
        debugger;
        this.user = user;
    }

    // handle upload file data
    onUpload({header, doc, file_name}: UploadXlsx) {
        debugger;
        if (doc[0] && Number.isNaN(+doc[0].A)) {
            // first row is header
            const firstLineHeader = doc[0];
            this.header = header.map((key: HeaderKey) => {
                return {title: firstLineHeader[key] || '', key};
            });
            doc.shift();
        } else {
            // first row is not header - set default header
            this.header = header.map((key: HeaderKey) => {
                return {
                    title: key === 'A' ? translateEnum.STOP_CODE_STATION : key === 'B' ? translateEnum.JOB_TYPE : (`${translateEnum.PERFORM_ACTION} ${letterToNumber(key) - 1}`),
                    key
                };
            });
        }

        this.error = [];
        this.succeed=[];
        this.validation_errors.stop_codes = !doc.length && this.translateEnum.EMPTY_FILE;
        debugger;
        doc[0].file_name=file_name;
        debugger;
        this.name.push(file_name);
        this.docs.push(doc);
        this.show.push(true);
        this.showsubmit.push(false)
        this.error.push('');
        this.succeed.push('')
    }

    onValidationSubmit(doc1: Upload[],index:number)
    {
        this.disabled = false;
        debugger;
        this.jobTypes = doc1.map((upload: Upload) => {
            return {
              [upload.A]: upload.B,
            };
          });
  
          // key 'B' represents the job type of the given stop
          const doc = doc1.map((upload: Upload) => {
              const a = {...upload};
              delete a.B;
              return a;
          });
          this.tasks = new Tasks().deserialize(doc.reduce((acc: {}, upload: Upload): Upload => {
              return {
                  ...acc, ...{
                      [upload.A]: Object.values(upload).reduce((acc, to_do, index) =>
                          index === 0 ? acc : [...acc, {to_do, status: 0}], [])
                  }
              };
          }, {}));
  
          const stop_codes = this.tasks.getStopCodes();
  
          this.error[index] = null;
          this.validation_errors = this.uploadsService.validation(this.name[index], this.user, this.from_date, this.to_date, stop_codes, doc1);
  
          if (Object.keys(this.validation_errors).length) {
              return;
          }
  
          this.uploadsService.submitValidation({
              name: this.name[index],
              user_id: this.user.id,
              from_date: this.from_date,
              to_date: this.to_date,
              stop_codes,
              jobTypes: this.jobTypes,
              tasks: this.tasks,
              
          }).subscribe(
            r => {
                // finish upload
                if(r.res.length>0)
                this.error[index] = translateEnum.UPLOAC_CODES_EXISTING +'</br><table style="background-color: #f9bfbf;"><tr><td style="border:1px solid gray;">תאריך</td><td style=" border:1px solid gray;">מקט תחנה</td><td style=" border:1px solid gray;">שם סקר</td></tr>'+ r.res.toString()+'</table>';
                else
                this.succeed[index] = translateEnum.UPLOAC_CODES_EXISTING_NOT_EXIST;
                this.showsubmit[index]=true;
            },
            r => {
                // finished - show error message
                this.error[index] = r.error;
                this.showsubmit[index]=true;
            });
    }

 

    onErrorValidation(error) {
        // finished - show error message
        this.error = error.message;
        this.disabled = false;
    }

    remove(doc1: Upload[],index:number) {
        this.show[index]=false;
    }

    onSubmit(doc1: Upload[],index:number) {
        this.validation =="";
        debugger;
        if (this.disabled) {
            return;
        }
        this.jobTypes = doc1.map((upload: Upload) => {
          return {
            [upload.A]: upload.B,
          };
        });

        // key 'B' represents the job type of the given stop
        const doc = doc1.map((upload: Upload) => {
            const a = {...upload};
            delete a.B;
            return a;
        });
        this.tasks = new Tasks().deserialize(doc.reduce((acc: {}, upload: Upload): Upload => {
            return {
                ...acc, ...{
                    [upload.A]: Object.values(upload).reduce((acc, to_do, index) =>
                        index === 0 ? acc : [...acc, {to_do, status: 0}], [])
                }
            };
        }, {}));

        const stop_codes = this.tasks.getStopCodes();

        this.error[index] =null;
        this.validation_errors = this.uploadsService.validation(this.name[index], this.user, this.from_date, this.to_date, stop_codes, doc1);

        if (Object.keys(this.validation_errors).length) {
            return;
        }

        this.show[index]=false;
        this.disabled = true;
        this.uploadsService.submit({
            name: this.name[index],
            user_id: this.user.id,
            from_date: this.from_date,
            to_date: this.to_date,
            stop_codes,
            jobTypes: this.jobTypes,
            tasks: this.tasks,
            on_success: this.onSuccess,
            on_error: this.onError,
        });
    }

    onSuccess(shift: Shift) {
        // finish upload
        const self = this;
        self.success = self.translateEnum.UPLOAD_CODES_SUCCESS;
        this.result_message_subject$.next({
            stop_codes: this.tasks.getStopCodes(),
            shift,
        });
        this.modal.show();
        this.modal.onHidden.subscribe(result => {
         //   this.onReset();
        });
    }

    onError(error) {
        // finished - show error message
        //this.error[index] = error.message;
        this.disabled = false;
    }

    onFromDate(date,i) {
        this.from_date = this.getISO8601FromDate(date);
    }

    onToDate(date,i) {
        this.to_date = this.getISO8601ToDate(date);
    }


    onDragOver(event: DragEvent) {
        event.preventDefault();
      }
    
      handleUpload(files: FileList) {
        // Iterate through each dropped file
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const reader = new FileReader();
          reader.onload = (e: any) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const jsonSheet = XLSX.utils.sheet_to_json(worksheet, { header: "A" });
      debugger;
            let header: string[] = [];
            let doc: any[] = [];
      
            if (jsonSheet.length > 0) {
              // Use Object.values to extract values of properties in the first row and convert them into an array of strings
              //header = Object.values(jsonSheet[0]);
              header = Object.keys(jsonSheet[0]) as HeaderKey[];
              // Remove the header row from the document data
              doc = jsonSheet.slice(1);
            }
            // Process the document data and cast it to Upload[]
            const uploads: Upload[] = doc.map((row: any) => {
              const upload: Upload = {
                // Process each row and construct an Upload object
                // Here you may need to map each property from the row to the Upload interface properties
                // For example:
                // property1: row['property1'],
                // property2: row['property2'],
                // ...
              };
              return upload;
            });
      
            const uploadXlsx: UploadXlsx = {
              header: header,
              doc: doc,
              file_name: file.name
            };
            debugger;
            this.onUpload(uploadXlsx);
          };
          reader.readAsArrayBuffer(file);
        }
      }
      
      // Function to handle the drop event
      onDrop(event: DragEvent) {
        event.preventDefault();
        const files = event.dataTransfer?.files;
        if (files && files.length > 0) {
          this.handleUpload(files); // Pass the FileList object to handle multiple files
        }
      }
    
     
}
