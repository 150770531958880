import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Input } from '@angular/core';
import { Task } from '../../../../shared/models/task.model';
import { taskStatuses } from '../../../../shared/options/task';
import { Option } from '../../../../shared/interfaces/option';
import { translateEnum } from '../../../../shared/enum/translateEnum';

@Component({
    selector: 'dynamic-task',
    templateUrl: 'dynamic-task.component.html',
    styleUrls: ['dynamic-task.component.css', '../../../../app.component.css'],
})

export class DynamicTask {
    readonly translateEnum = translateEnum;
    readonly taskStatuses = taskStatuses;

    @Input() public tasks: Task[] = [];
    @Input() public error: boolean = false;

    option: Option = null;

    constructor() {
        this.statusSelected = this.statusSelected.bind(this);
        this.add = this.add.bind(this);
    }

    statusSelected(task: Task, option: Option) {
        task.setStatus(option.id);
    }

    add() {
        this.tasks.push(new Task());
    }

    delete(i) {
        return () => new Promise(() => {
            this.tasks.splice(i, 1);
        });
    }

    drop(event: CdkDragDrop<Task[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }
    }
}
