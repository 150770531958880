import {Deserializable} from './deserializable.model';
import {BaseComponent} from '../../../app/base.component';
import {Option} from '../../shared/interfaces/option';

export class Tool extends BaseComponent implements Deserializable {

    reason: string;
    status: number = undefined;
    statusName: string;
    cell: string;
    is_wrong: boolean;
    is_invalid: boolean;
    options: Option[];

    constructor() {
        super();
    }

    deserialize(input: { options: Option[], data: any }) {
        Object.assign(this, input.data);
        this.options = input.options;
        this.setStatus(input.data && input.data.status);
        return this;
    }

    getStatusName() {
        const poster = this.getStatusInterface();
        if (poster) {
            return poster.name;
        }
        return '';
    }

    getStatusInterface() {
        return this.options.find((status: Option) => status.id === this.status);
    }

    setStatus(status: number | undefined) {
        this.status = status;
        this.is_wrong = Number.isInteger(status) && status !== 1;
        this.is_invalid = status === -1;
        this.statusName = this.getStatusName();
    }

    hasTool() {
        return this.status !== undefined;
    }

    getTool() {
        if (!this.hasTool()) {
            return {};
        } else {
            return {status: this.status, reason: this.reason};
        }
    }

}
