import {Option} from '../../shared/interfaces/option';
import {translateEnum} from '../../shared/enum/translateEnum';

export const exportReports: Option[] = [
    {id: 1, name: translateEnum.STOP_CODE_STATION},
    {id: 2, name: translateEnum.STOP_NAME},
    {id: 3, name: translateEnum.STOP_TYPE},
    {id: 4, name: translateEnum.MULTIPLE_SHEDS_COUNT},
    {id: 5, name: translateEnum.SCHEDULE_PERFORMER},
    {id: 6, name: translateEnum.CITY},
    {id: 7, name: translateEnum.ACTION_DATE},
    {id: 8, name: translateEnum.STOP_ADDRESS},
    {id: 9, name: translateEnum.FLAG_REQUIRED},
    {id: 10, name: translateEnum.WORKER_COMMENTS},
    {id: 11, name: translateEnum.NEXT_BUS_STATUS},
    {id: 12, name: translateEnum.NEXT_BUS_REASON},
    {id: 13, name: translateEnum.STATIC_POSTER_STATUS},
    {id: 14, name: translateEnum.STATIC_POSTER_REASON},
    {id: 15, name: translateEnum.STOP_HEAD_FLAG_INVALID},
    {id: 16, name: translateEnum.STOP_HEAD},
    {id: 17, name: translateEnum.ROUTE_DESC},
    {id: 18, name: translateEnum.BRAIL_DESC},
    {id: 19, name: translateEnum.INVALID_COUNT_FLAG},
    {id: 20, name: translateEnum.WRONG_FLAG},
    {id: 21, name: translateEnum.WRONG_BRAIL},
    {id: 22, name: translateEnum.WRONG_COUNT_BRAIL},
    {id: 22, name: translateEnum.OPERATOR_IN_CHARGE},
    {id: 22, name: translateEnum.AREA},
];

export const exportProjects: Option[] = [
    {id: 1, name: translateEnum.PROJECT_NAME},
    {id: 2, name: translateEnum.ENTER_DATE},
    {id: 3, name: translateEnum.ACTION_DATE},
    {id: 4, name: translateEnum.TOTAL_STOPS_COUNT},
    {id: 5, name: translateEnum.COMPLETED_STOPS_COUNT},
];

export const exportProject: Option[] = [
    {id: 1, name: translateEnum.STOP_CODE_STATION},
    {id: 2, name: translateEnum.STOP_NAME},
    {id: 3, name: translateEnum.STOP_ADDRESS},
    {id: 4, name: translateEnum.REPORT_PERFORMER},
    {id: 5, name: translateEnum.ACTION_DATE},
    {id: 6, name: translateEnum.TASKS},
    {id: 7, name: translateEnum.CONTROL},
    {id: 8, name: translateEnum.INSPECTOR_NAME},
    {id: 9, name: translateEnum.CONTROL_DATE},
    {id: 10, name: translateEnum.CONTROL_COMMENTS}
];
