import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as cloneDeep from 'lodash/cloneDeep';
import { Observable } from 'rxjs';
import { ApiService } from '../../core/service/api.service';
import { UserService } from '../../core/service/user.service';
import { translateEnum } from '../../shared/enum/translateEnum';
import { User } from '../../shared/models/user.model';
import { UserComponent } from './user.component';

@Component({
    selector: 'edituser',
    templateUrl: 'user.component.html',
    styleUrls: ['./users.component.css', '../../app.component.css'],
})

export class EditUserComponent extends UserComponent {

    user: User;
    translateEnum = translateEnum;

    @Input()
    public userModal: any;

    @Input() userObservable: Observable<User>;
    userSubscription: any;

    constructor(private api: ApiService, public userService: UserService) {
        super(userService);
        this.password_form = new FormControl('');
        this.resetForm();
        this.header = this.translateEnum.EDIT_USER;
        this.setPermission = this.hasSetPermission(null);
    }

    updateForm(user) {
        this.user = user;
        this.setPermission = this.hasSetPermission(user);
        this.first_name_form.setValue(user.first_name);
        this.last_name_form.setValue(user.last_name);
        this.email_form.setValue(user.email);
        this.phone_form.setValue(user.phone);
        this.password_form.setValue('');

        this.role_form.setValue(user.role.toJson());
        const self = this;
        setTimeout(function () {
            self.roleSubject.next(user.role.toJson());
        }, 500);

        this.permissions = cloneDeep(user.permissions);
        this.setUserPermission();
    }

    setUserPermission() {
        const permissions = this.permissions;
        const subjects = this.permissionSubjects;
        const authorities = this.authorities;
        for (const key in permissions) {
            if (permissions.hasOwnProperty(key) && subjects[key]) {

                setTimeout(function () {
                    subjects[key].next(authorities.getAuthority(permissions[key].id).toJson());
                }, 500);

                this.permissionsGroup.get(key).setValue(permissions[key]);
            }
        }
    }

    onSubmit(userGroup, modal) {

        if (!userGroup.valid) {
            this.markAsTouched();
            return;
        }

        if (this.disabledUser) {
            return;
        }

        this.disabledUser = true;
        this.errorUser = null;

        const data = userGroup.value;

        this.api.editUser(
            this.user.id,
            data.first_name.trim(),
            data.last_name.trim(),
            data.email.trim(),
            User.phoneWithSeparator(data.phone),
            data.role.id,
            this.permissions,
            data.password.trim()
        ).subscribe(
            r => {
                this.successAction(r, this.user);
                this.closeModal(modal);
            },
            r => {
                console.log(r);
                this.errorUser = this.translateEnum.ERROR_MESSAGE.replace('[message]', r.error.message);
                this.disabledUser = false;
            });
    }

    ngOnInit() {
        if (this.userObservable) {
            this.userSubscription = this.userObservable.subscribe((user: User) => this.updateForm(user));
        }
    }

    ngOnDestroy() {
        if (this.userObservable && this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }

}
