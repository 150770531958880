import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import 'jspdf-autotable';
import {translateEnum} from '../../../shared/enum/translateEnum';

import * as JSZip from 'jszip';
import * as saveAs from 'file-saver';
import {FlagAlignElement, FlagGeneralSetting} from '../../../shared/interfaces/flag';
import {alignElement, rollbackElement} from '../../../core/service/flag-pdf/helpers';

import domtoimage from 'dom-to-image';

export class FlagGeneralPdfService {

    translateEnum = translateEnum;
    settings: FlagGeneralSetting;
    onUpdateEvent: Function;

    constructor(settings: FlagGeneralSetting, onUpdateEvent: Function) {
        this.settings = settings;
        this.onUpdateEvent = onUpdateEvent;
    }

    async download() {
        debugger;
        const zip = new JSZip();
        let img, doc, blob;
        const self = this;

        window.scrollTo(0, 0);

        this.onUpdateEvent(`0%`);

        

        const settings = this.settings;
        const fileNames = settings.file_names;
    
        for (let i = 0; i < fileNames.length; i++) {
          const elementId = `${settings.key}${i + 1}`;
          const el = document.getElementById(elementId);
          const prevStyle = alignElement(el); // Assuming this function is defined elsewhere
          if(el.textContent.includes('Limited frequency'))
            el.style.whiteSpace = 'nowrap';
          el.style.display = 'inline-block';

          for(let j=0;j<el.getElementsByClassName('flag-stop-row-text-header').length;j++)
          {
            (el.getElementsByClassName('flag-stop-row-text-header')[j] as HTMLDivElement).style.whiteSpace='normal';
            (el.getElementsByClassName('flag-stop-row-text-header')[j] as HTMLDivElement).style.display='block';
          }
          
          const canvas = await html2canvas(el, {
            scrollX: 0,
            scrollY: 0,
            scale: 1
        });

          img = canvas.toDataURL('image/jpeg');
          doc = new jsPDF(this.settings.style.orientation, 'pt', [this.settings.style.page_width, this.settings.style.page_height]);
          doc.addImage(img, 'JPEG', 0, 0, this.settings.style.page_width + 1, this.settings.style.page_height + 1);


         // const dataUrl = await domtoimage.toJpeg(el, { quality: 1 });
    
  //        const doc = new jsPDF(settings.style.orientation, 'pt', [settings.style.page_width, settings.style.page_height]);
  //        doc.addImage(dataUrl, 'JPEG', 0, 0, settings.style.page_width + 1, settings.style.page_height + 1);
    
          blob = doc.output('blob');
          zip.file(`${fileNames[i]}.pdf`, blob);
    
          this.onUpdateEvent(`${Math.floor(((i + 1) / fileNames.length) * 100)}%`);
    
          rollbackElement(el, prevStyle);

        }

        this.onUpdateEvent(translateEnum.CREATING_ZIP);

        await zip.generateAsync({type: 'blob'})
            .then(blob => {
                saveAs(blob, `${self.settings.zip_name}.zip`);
            }, function (err) {
                console.log(err);
            });
    }

}
