import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {StopsFileUploadComponent} from './stops-file-upload.component';
import {BaseComponent} from './base.component';

const routes: Routes = [
    {
        path: '',
        component: BaseComponent,
        children: [
            {
                path: '',
                component: StopsFileUploadComponent,
                data: {
                    title: ''
                }
            }
        ]
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StopsFileUploadRoutingModule { }
