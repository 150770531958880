import {Observable, BehaviorSubject, timer} from 'rxjs';
import { ApiService } from '../api.service';
import { Injectable } from '@angular/core';
import { BuildService } from './build.service';

@Injectable({
    providedIn: 'root'
})
export class BuildPullService {

    private buildIsUpToDateSubject = new BehaviorSubject<boolean>(true);
    private currentVersion: string;

    constructor(buildService: BuildService, private api: ApiService) {
        this.currentVersion = buildService.build.version;
        this.pullBuild();
    }

    public get buildIsUpToDate(): Observable<boolean> {
        return this.buildIsUpToDateSubject;
    }

    private pullBuild() {
        const pullInterval = 10000 * 6 * 60; // fetch build every hour

        timer(pullInterval, pullInterval).subscribe(() => {
            this.api.getBuild().subscribe(
                r => {
                    const newVersion = r.version;
                    if (this.currentVersion !== newVersion) {
                        this.buildIsUpToDateSubject.next(false);
                    }
                },
                r => {
                    console.log(r);
                });
        });
    }
}
