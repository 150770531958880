import {Component, Input} from '@angular/core';

@Component({
    selector: 'alert-tooltip',
    templateUrl: 'alert-tooltip.component.html',
})
export class AlertTooltipComponent {
    @Input() public title: string = null;
    @Input() public hover: string = null;
    @Input() public color: string = 'red';
}
