import {translateEnum} from './shared/enum/translateEnum';
import {flagStatuses, flagSets} from './shared/options/flag';
import {brailStatuses, brailSets} from './shared/options/brail';
import {staticPosterStatuses, staticPosterSets} from './shared/options/static_poster';
import {nextBusStatuses, nextBusSets} from './shared/options/next_bus';
import {getISO8601FromDate, getISO8601ToDate, top, uniqueID, getDateTime, getDate} from '../app/global.component';
import {DOWNLOAD_TYPE} from './shared/enum/report';
import {EXPORT_TYPE} from './shared/enum/export';
import {operatorNames} from './shared/options/operator';

export class BaseComponent {

    public readonly translateEnum = translateEnum;
    public readonly flagStatuses = flagStatuses;
    public readonly brailStatuses = brailStatuses;
    public readonly staticPosterStatuses = staticPosterStatuses;
    public readonly nextBusStatuses = nextBusStatuses;
    public readonly flagSets = flagSets;
    public readonly brailSets = brailSets;
    public readonly staticPosterSets = staticPosterSets;
    public readonly nextBusSets = nextBusSets;
    public readonly getISO8601FromDate = getISO8601FromDate;
    public readonly getISO8601ToDate = getISO8601ToDate;
    public readonly top = top;
    public readonly uniqueID = uniqueID;
    public readonly getDateTime = getDateTime;
    public readonly getDate = getDate;
    public readonly DOWNLOAD_TYPE = DOWNLOAD_TYPE;
    public readonly EXPORT_TYPE = EXPORT_TYPE;
    public readonly operatorNames = operatorNames;

    constructor() {
    }

}
