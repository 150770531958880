import {Report} from "../../../shared/models/report.model";
import {translateEnum} from "../../../shared/enum/translateEnum";
import {User} from "../../../shared/models/user.model";

export const download = (reports: Report[], users: User[]) => {
	const data = [];
	let max = 0;
	let row, wrongs, invalids;

	for (const report of reports) {
		invalids = 0;
		wrongs = 0;
		row = {
			[translateEnum.STOP_CODE_STATION]: report.stop.stop_code,
			[translateEnum.STOP_TYPE]: report.stopType,
			[translateEnum.SCHEDULE_PERFORMER]: report.userName(users),
		};

		for (const flag of report.flag) {
			if (!flag.is_head) {
				if (flag.is_invalid) {
					invalids++;
				}

				if (flag.is_wrong) {
					row[`${translateEnum.WRONG_FLAG} ${++wrongs}`] = flag.route;
				}
			}
		}

		max = Math.max(max, wrongs);
		row[translateEnum.INVALID_COUNT_FLAG] = invalids;
		data.push(row);
	}

	let header: string[] = [
		translateEnum.STOP_CODE_STATION,
		translateEnum.STOP_TYPE,
		translateEnum.SCHEDULE_PERFORMER,
		translateEnum.INVALID_COUNT_FLAG
	];

	for (let i = 1; i <= max; i++) {
		header.push(`${translateEnum.WRONG_FLAG} ${i}`);
	}

	return {header, data};
}
