import { Component, Input } from '@angular/core';
import { stopTypes } from '../../../../shared/options/stop';
import { Option } from '../../../../shared/interfaces/option';
import { BaseComponent } from '../../../../base.component';
import { translateEnum } from '../../../../shared/enum/translateEnum';

@Component({
    selector: 'stop-type-selection',
    templateUrl: 'stop-type-selection.component.html',
    styleUrls: ['../../../../app.component.css'],
})
export class StopTypeSelectionComponent extends BaseComponent {

    translateEnum = translateEnum;
    stopTypes = stopTypes;

    @Input() public value: Option = null;
    @Input() public onSelect: Function;
    @Input() public enableClear: boolean;
    @Input() public error: string = null;

}
