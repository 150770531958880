import {Deserializable} from './deserializable.model';
import {translateEnum} from '../../shared/enum/translateEnum';

export const ADMIN_ROLE = 'admin';
export const WORKER_ROLE = 'worker';
export const OPERATOR_ROLE = 'operator';

export class Role implements Deserializable {

    id: string;
    name: string;

    deserialize(input: any) {
        this.id = input.id;
        this.name = this.setName();
        return this;
    }

    setName() {
        switch (this.id) {
            case ADMIN_ROLE:
                return translateEnum.ADMIN;
            case WORKER_ROLE:
                return translateEnum.WORKER;
            case OPERATOR_ROLE:
                return translateEnum.OPERATOR;
        }
    }

    isAdmin() {
        return this.id === ADMIN_ROLE;
    }

    toJson() {
        return {
            id: this.id,
            name: this.name
        };
    }

}
