import {Shift} from '../../../shared/models/shift.model';
import {translateEnum} from '../../../shared/enum/translateEnum';

export const download = (projects: any[]) => {
    const data = [];
    debugger;
    for (const prj of projects) {
        const row = {
            [translateEnum.EXECUTER_NAME]: prj.name,
            [translateEnum.MAKAT]: prj.stop_code,
            [translateEnum.MAP_NAME]: prj.area,
            [translateEnum.ACTION_1]: prj.comments.split('<br>')[0]==undefined ?'': prj.comments.split('<br>')[0],
            [translateEnum.ACTION_2]: prj.comments.split('<br>')[1]==undefined ?'': prj.comments.split('<br>')[1],
            [translateEnum.ACTION_3]: prj.comments.split('<br>')[2]==undefined ?'': prj.comments.split('<br>')[2],
            [translateEnum.ACTION_4]: prj.comments.split('<br>')[3]==undefined ?'': prj.comments.split('<br>')[3],
            [translateEnum.ACTION_5]: prj.comments.split('<br>')[4]==undefined ?'': prj.comments.split('<br>')[4],
            [translateEnum.ACTION_6]: prj.comments.split('<br>')[5]==undefined ?'': prj.comments.split('<br>')[5],
            [translateEnum.ACTION_7]: prj.comments.split('<br>')[6]==undefined ?'': prj.comments.split('<br>')[6],
            [translateEnum.ACTION_8]: prj.comments.split('<br>')[7]==undefined ?'': prj.comments.split('<br>')[7],
            [translateEnum.AREA]: prj.area2
        };

        data.push(row);
    }

    const header: string[] = [
        translateEnum.EXECUTER_NAME,
        translateEnum.MAKAT,
        translateEnum.MAP_NAME,
        translateEnum.ACTION_1,
        translateEnum.ACTION_2,
        translateEnum.ACTION_3,
        translateEnum.ACTION_4,
        translateEnum.ACTION_5,
        translateEnum.ACTION_6,
        translateEnum.ACTION_7,
        translateEnum.ACTION_8,
        translateEnum.AREA,
    ];

    return {header, data};
};
