import {Component, Input} from '@angular/core';

@Component({
    selector: 'component-loading',
    templateUrl: 'component-loading.component.html',
    styleUrls: ['component-loading.component.css'],
})
export class ComponentLoadingComponent {
    @Input() public loading: boolean = false;
    @Input() public size: number = 60;
}
