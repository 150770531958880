import {Component, Input} from '@angular/core';

@Component({
    selector: 'paging',
    templateUrl: 'paging.component.html',
    styleUrls: ['paging.component.css'],
})
export class PagingComponent {

    @Input() itemsPerPage: number = 10;

    currentPage: number = 1;
    maxSize: number = 3;

    @Input() boundaryLinks: boolean = true;
    @Input() changePage: Function;
    @Input() totalItems: number = Number.MAX_VALUE;
    @Input() page: number = 1;

    ngOnChanges(changes): void {
        if (changes.page && changes.page.currentValue) {
            this.currentPage = changes.page.currentValue;
        }
    }

    changeCurrentPage(page) {
        this.changePage(page);
    }

    showPagination() {
        return this.totalItems && this.totalItems !== Number.MAX_VALUE && this.totalItems > 0 && this.totalItems > this.itemsPerPage;
    }
}
