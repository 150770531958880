import { Component, Input } from '@angular/core';
import { ApiService } from '../../../../core/service/api.service';
import { ExportsService } from '../../../../core/service/exports.service';
import { translateEnum } from '../../../../shared/enum/translateEnum';
import { Option } from '../../../../shared/interfaces/option';
import { Export } from '../../../../shared/models/export.model';

@Component({
    selector: 'export-create',
    templateUrl: 'export-create.component.html',
    styleUrls: ['export-create.component.css', '../export.component.css', '../../../../app.component.css'],
})

export class ExportCreateComponent {
    @Input() public back: Function;
    @Input() public export: Export;
    @Input() public options: Option[];

    translateEnum = translateEnum;
    loading: boolean = false;
    error: string = null;
    validation_errors: { [key: string]: boolean } = {
        name: false,
        columns: false,
    };

    constructor(private api: ApiService, private exportsService: ExportsService) {
        this.save = this.save.bind(this);
    }

    save() {
        this.validation_errors.name = !this.export.name.trim().length;
        this.validation_errors.columns = !this.export.columns.length;
        if (Object.values(this.validation_errors).find(validation_error => !!validation_error)) {
            return;
        }
        if (this.loading) {
            return;
        }
        this.loading = true;
        this.error = null;

        const self = this;
        if (this.export.id) {
            self.exportsService.setLoadingById(this.export.id, true);
            self.api.patchExport(this.export)
                .subscribe(
                    r => {
                        self.exportsService.getExports(true);
                        self.back();
                    },
                    r => {
                        console.log(r.error);
                        self.error = r.error.message;
                    },
                    () => {
                        self.loading = false;
                    });
        } else {
            self.api.postExport(this.export)
                .subscribe(
                    r => {
                        self.exportsService.getExports(true);
                        self.back();
                    },
                    r => {
                        console.log(r.error);
                        self.error = r.error.message;
                    },
                    () => {
                        self.loading = false;
                    });
        }
    }

}
