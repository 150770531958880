import { Deserializable } from './deserializable.model';
import { Report } from './report.model';
import { ApiService } from '../../core/service/api.service';
import { getOrientation } from 'get-orientation';
(window as any).getOrientation = getOrientation;

export class Photo implements Deserializable {

    path: string;
    stop_code: number;
    date: string;
    time: string;
    name: string;
    fullname: string;
    id: string;
    ext: string;

    orientation_source: number;
    orientation: number;

    report: Report;
    blob: Blob;

    fail: boolean = false;
    selected: boolean = false;

    constructor(private api: ApiService) {

    }

    deserialize(input: any) {
        this.path = input;
        this.id = 'id-' + Math.random().toString(36).substr(2, 16);
        this.report = null;
        this.orientation = 0;
        this.orientation_source = 0;
        this.blob = null;
        this.name = this.fullname = input.substring(input.lastIndexOf('/') + 1);

        const cutFrom = input.indexOf('/images/') + 8;
        const cutTo = input.lastIndexOf('.');
        const cutPath = input.substring(cutFrom, cutTo);
        const catSplit = cutPath.split('/');

        this.ext = input.substring(cutTo);

        if (catSplit.length > 1) {

            const desc = catSplit[1];
            const arr = desc.split('_');

            if (arr) {

                if (arr.length > 1) {
                    this.stop_code = +arr[1];
                }

                if (arr.length > 2) {
                    this.date = arr[2];
                }

                let time = '';
                if (arr.length > 3) {
                    const times = arr[3].split(':');
                    if (times.length > 1) {
                        this.time = times[0] + ':' + times[1];
                        time = ['[', times[0], '-', times[1], ']'].join('');
                    }
                }

                this.name = [this.stop_code, this.date, time].join('_');
                this.fullname = this.name + this.ext;
            }
        }

        return this;
    }

    async downloadImageData() {
        try {
            const blob = await (this.api.photo(this.path).toPromise());
            this.blob = blob;

            // @ts-ignore
            this.setOrientation(await getOrientation(blob));

        } catch (e) {
            console.log(e);
        }
    }

    setOrientation(orientation_source) {
        this.orientation_source = orientation_source;

        switch (orientation_source) {
            case 1: // TOP_LEFT - Horizontal (Default)
            case 2: // TOP_RIGHT - Mirror Horizontal
                this.orientation = 0;
                break;
            case 6: // RIGHT_TOP - Rotate 90 CW
            case 7: // RIGHT_BOTTOM - Mirror horizontal and rotate 90 CW
                this.orientation = 90;
                break;
            case 3: // BOTTOM_RIGHT - Rotate 180
            case 4: // BOTTOM_LEFT - Mirror vertical
                this.orientation = 180;
                break;
            case 5: // LEFT_TOP - Mirror horizontal and rotate 270 CW
            case 8: // LEFT_BOTTOM - Rotate 270 CW
                this.orientation = 270;
                break;
        }
    }

    async downloadPhoto() {
        if (!this.fail) {
            if (this.blob === null) {
                await this.downloadImageData();
            }
            this.finishDownloadPhoto();
        }
    }

    finishDownloadPhoto() {
        const link = document.createElement('a');
        document.body.appendChild(link); // Firefox requires the link to be in the body
        link.download = this.fullname;
        link.href = window.URL.createObjectURL(this.blob);
        link.click();
        document.body.removeChild(link); // remove the link when done
    }

    setReport(report: Report) {
        this.report = report;
    }

    setFail() {
        this.fail = true;
        this.path = 'assets/img/progress.jpeg';
    }

}


