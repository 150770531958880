import {Component, Input} from '@angular/core';
import flagStyle from '../flag-style';
import * as cloneDeep from 'lodash/cloneDeep';
import { translateEnum } from '../../../shared/enum/translateEnum';
import { FlagPage, FlagUnion } from '../../../shared/models/flag-page.model';
import { FlagGeneralSetting, FlagGeneralStyle } from '../../../shared/interfaces/flag';
import { ApiService } from '../../../core/service/api.service';
import { compareNumber, compareString } from '../../../global.component';
import { Line } from '../../../shared/models/line.model';

@Component({
    selector: 'flag-page',
    templateUrl: 'flag-page.component.html',
    styleUrls: ['flag-page.component.css', '../../../app.component.css']
})
export class FlagPageComponent {

    readonly translateEnum = translateEnum;
    public flagStyle = flagStyle;

    @Input() public zoom: number;
    code: number = null;
    loading: boolean = false;
    error: string | null = null;
    flags: FlagPage[][] = [];
    template_key: string = 'flag';
    file_names: string[] = [];
    style: FlagGeneralStyle = {
        page_width: flagStyle.width,
        page_height: (flagStyle.height * 2 + 1),
        orientation: 'p',
    };

    constructor(private api: ApiService) {
        debugger;
        this.getSetting = this.getSetting.bind(this);
    }

    doubleStyle() {
        return {
            ...flagStyle.doubleFlag,
            ...{zoom: this.zoom + '%'}
        };
    }

    onCode() {
        debugger;
        if (this.loading) {
            return;
        }

        const self = this;
        self.flags = [];
        self.error = null;
        self.loading = true;
        this.api.getLinesByStopCode(this.code).subscribe(
            async r => {
                if (r.length === 0) {
                    self.error = translateEnum.NO_FLAG;
                    self.loading = false;
                    return;
                }

                const general_data = [];
                const download_data = [];
                const night_data = [];

                for (const line of r) {
                    if (line.isDownload()) {
                        download_data.push(line);
                    } else if (line.isNight()) {
                        night_data.push(line);
                    } else {
                        general_data.push(line);
                    }
                }

                const general_union = self.union(['operator_name', 'line_dest', 'is_train'], general_data);
                const download_union = self.union(['operator_name', 'line_dest', 'is_train'], download_data);
                const night_union = self.union(['line_dest', 'is_train'], night_data);
                const all_lines = general_union.concat(download_union, night_union);

                await self.createPages(all_lines);

                setTimeout(function () {
                    self.loading = false;
                }, 4000);
            },
            r => {
                console.log({r});
                self.error = translateEnum.ERROR_MESSAGE.replace('[message]', r.error.message);
                self.loading = false;
            });
    }

    union(keys, lines) {

        // sort by min line_sign
        let result = [];
        for (const line of lines) {
            const unique = keys.reduce((acc, key) => {
                return acc + `${line[key]}-`;
            }, '');
            if (!result[unique]) {
                result[unique] = {
                    line,
                    union_lines: [],
                    operator_name: '',
                    min: Number.MAX_VALUE,
                    bulks: [],
                };
            }
            result[unique].operator_name = line.operator_name;
            result[unique].union_lines.push(line);
            result[unique].min = Math.min(result[unique].min, line.line_sign_only_number);
        }
        result = Object.values(result);
        result.sort((a, b) => compareNumber(a, b, 'min'));

        // sort in operator
        let operators = [];
        for (const union of result) {

            union.union_lines.sort((a, b) => {
                const compare = compareNumber(a, b, 'line_sign_only_number');
                return compare === 0 ? compareString(a, b, 'line_sign') : compare;
            });

            let max = union.line.line_dest_sub_he || union.line.line_dest_sub_en ? 26 : 35;
            if (union.line.is_train) {
                max -= 3;
            }

            if (!operators[union.operator_name]) {
                operators[union.operator_name] = {
                    unions: [],
                    min: Number.MAX_VALUE
                };
            }

            operators[union.operator_name].unions.push(union);
            operators[union.operator_name].min = Math.min(operators[union.operator_name].min, union.min);
        }
        operators = Object.values(operators);
        operators.sort((a, b) => compareNumber(a, b, 'min'));

        result = operators.reduce((p, n) => p.concat(n.unions), []);

        return result;
    }

    // step 1
    async createPages(all_lines) {
        const result = [];
        let page = 0;
        const file_names = [];

        let union;

        const createPage = (input_page, count) => {

            // remove last row if no lines exist
            if (input_page.length) {
                const last_row = input_page[input_page.length - 1];
                if (!last_row.union_lines.length) {
                    input_page.pop();
                }
            }

            result.push([
                new FlagPage().deserialize({union: input_page, page_number: ++page, lang: 'he', count: count}),
                new FlagPage().deserialize({union: input_page, page_number: ++page, lang: 'en', count: count})
            ]);

            const stop_code: Line = input_page[0].line.stop_code;
            file_names.push(stop_code + '-' + (Math.floor((page - 1) / 2) + 1));
        };

        let flag = [];
        let count = 0;
        let max_rows = 6;
        for (let i = 0; i < all_lines.length; i++) {

            // step 2
            await this.flagDest(all_lines[i]);
            union = all_lines[i];

            if (count + union.bulks.length > max_rows) {

                if (count < max_rows) {
                    // there is empty rows in the page which can be filled
                    const diff = max_rows - count;
                    const diff_bulk = union.bulks.slice(0, diff);
                    const new_bulk = union.bulks.slice(diff);
                    const count_diff_union_lines = diff_bulk.reduce((acc, bulk_item) => acc + bulk_item.length, 0);
                    const diff_union_lines = union.union_lines.slice(0, count_diff_union_lines);
                    const new_union_lines = union.union_lines.slice(count_diff_union_lines);
                    const diff_union: FlagUnion = {
                        line: cloneDeep(union.line),
                        union_lines: diff_union_lines,
                        operator_name: union.operator_name,
                        min: union.min,
                        bulks: diff_bulk,
                    };
                    diff_union.line.id = diff_union.line.id + ((+new Date).toString(36).slice(-5).toString());

                    union.bulks = new_bulk;
                    union.min = +new_bulk[0][0];
                    union.union_lines = new_union_lines;

                    flag.push(diff_union);
                    count += diff_bulk.length;
                    i--;
                } else {
                    // count == max_rows => no more space left on page
                    createPage(flag, max_rows);

                    flag = [union];
                    max_rows = 8;
                    count = union.bulks.length;
                }

            } else {
                flag.push(union);
                count += union.bulks.length;
            }
        }

        if (flag.length > 0) {
            createPage(flag, max_rows);
        }

        this.file_names = file_names;
        this.flags = result;
    }

    // step 2.1
    buildDivRow(is_train) {
        const flag_line_row_text_full = document.createElement('div');
        let width = 1183.25;
        if (is_train) {
            width -= 175;
        }
        const css = {
            fontFamily: 'Oxygen, serif',
            width: `${width}pt`,
            height: '199.75pt',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            marginRight: '15pt',
            alignSelf: 'flex-end',
        };
        for (const prop in css) {
            if (css.hasOwnProperty(prop)) {
                flag_line_row_text_full.style[prop] = css[prop];
            }
        }
        return flag_line_row_text_full;
    }

    buildDivDesc() {
        const flag_line_row_text_desc = document.createElement('div');
        const css = {
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
        };
        for (const prop in css) {
            if (css.hasOwnProperty(prop)) {
                flag_line_row_text_desc.style[prop] = css[prop];
            }
        }
        return flag_line_row_text_desc;
    }

    // step 2.3
    buildSpanHead(line: Line) {
        const flag_line_row_text_head = document.createElement('span');
        const css = {
            fontFamily: 'Oxygen, serif',
            fontSize: '127pt',
            letterSpacing: '-10pt',
            lineHeight: '0.8',
            'margin-left': '15pt',
        };
        for (const prop in css) {
            if (css.hasOwnProperty(prop)) {
                flag_line_row_text_head.style[prop] = css[prop];
            }
        }

        flag_line_row_text_head.innerHTML =
            line.line_dest_head_he.length >= line.line_dest_head_en.length ?
                line.line_dest_head_he :
                line.line_dest_head_en;

        return flag_line_row_text_head;
    }

    // step 2.4
    buildSpanSub(line: Line) {
        const flag_line_row_text_sub = document.createElement('span');
        const css = {
            fontFamily: 'Oxygen, serif',
            fontSize: '76pt',
            letterSpacing: '-5pt',
            lineHeight: '0.8',
            'margin-left': '15pt',
            'white-space': 'nowrap'
        };
        for (const prop in css) {
            if (css.hasOwnProperty(prop)) {
                flag_line_row_text_sub.style[prop] = css[prop];
            }
        }

        if (line.line_dest_sub_he == null && line.line_dest_sub_en == null) {
            return flag_line_row_text_sub;
        }

        if (line.line_dest_sub_he == null) {
            flag_line_row_text_sub.innerHTML = line.line_dest_sub_en;
            return flag_line_row_text_sub;
        }

        if (line.line_dest_sub_en == null) {
            flag_line_row_text_sub.innerHTML = line.line_dest_sub_he;
            return flag_line_row_text_sub;
        }

        flag_line_row_text_sub.innerHTML =
            line.line_dest_sub_he.length > line.line_dest_sub_en.length ?
                line.line_dest_sub_he :
                line.line_dest_sub_en;

        return flag_line_row_text_sub;
    }

    // step 2.5
    buildDivNumber() {
        const flag_line_row_number = document.createElement('div');
        const css = {
            fontSize: '190pt',
            display: 'flex',
            lineHeight: 1,
            flexDirection: 'column',
            letterSpacing: '-18pt',
        };
        for (const prop in css) {
            if (css.hasOwnProperty(prop)) {
                flag_line_row_number.style[prop] = css[prop];
            }
        }
        return flag_line_row_number;
    }

    // step 2.6
    buildSpanNumber(line_signs: string[]) {
        const flag_line_row_number_text = document.createElement('span');
        const css = {
            letterSpacing: '-14pt',
            unicodeBidi: 'bidi-override !important'
        };
        for (const prop in css) {
            if (css.hasOwnProperty(prop)) {
                flag_line_row_number_text.style[prop] = css[prop];
            }
        }

        flag_line_row_number_text.innerHTML = line_signs.join(',');

        return flag_line_row_number_text;
    }

    // step 2
    async flagDest(union: FlagUnion) {
        const line: Line = union.line;
        const line_signs: string[] = union.union_lines.map(l => l.line_sign);

        const flag_line_row_text_full_dest = this.buildDivRow(line.is_train); // step 2.1
        const flag_line_row_text_desc_dest = this.buildDivDesc(); // step 2.2
        const flag_line_row_text_head_dest = this.buildSpanHead(line); // step 2.3
        const flag_line_row_text_sub_dest = this.buildSpanSub(line); // step 2.4
        const flag_line_row_number_dest = this.buildDivNumber(); // step 2.5
        const flag_line_row_number_text_dest = this.buildSpanNumber(line_signs); // step 2.6

        flag_line_row_text_desc_dest.appendChild(flag_line_row_text_head_dest);
        flag_line_row_text_desc_dest.appendChild(flag_line_row_text_sub_dest);

        flag_line_row_number_dest.appendChild(flag_line_row_number_text_dest);

        flag_line_row_text_full_dest.appendChild(flag_line_row_text_desc_dest);
        flag_line_row_text_full_dest.appendChild(flag_line_row_number_dest);

        document.body.appendChild(flag_line_row_text_full_dest);
        
        if(line_signs.length==3)
            debugger;
        if ((flag_line_row_text_full_dest.clientWidth <=
                flag_line_row_text_desc_dest.clientWidth + flag_line_row_number_dest.clientWidth+50) ||
            (flag_line_row_text_full_dest.clientHeight <= flag_line_row_text_desc_dest.clientHeight)) {

            let i = 0;
            let tmp = line_signs.slice(0, i + 1);
            let prev = tmp;

            
            while (i < line_signs.length) {
                flag_line_row_number_text_dest.innerHTML = tmp.join(',');

                if ((flag_line_row_text_full_dest.clientWidth >=
                        flag_line_row_text_desc_dest.clientWidth + flag_line_row_number_dest.clientWidth+50) &&
                    (flag_line_row_text_full_dest.clientHeight >= flag_line_row_text_desc_dest.clientHeight)) {
                    i++;
                    prev = tmp;
                } else {
                    if (i === 0) {
                        union.bulks = [[]];
                    } else {
                        union.bulks = [prev];
                        prev = null;
                    }
                    break;
                }

                tmp = line_signs.slice(0, i + 1);
            }

            if (i === line_signs.length && prev) {
                union.bulks = [prev];
                document.body.removeChild(flag_line_row_text_full_dest);
                return;
            }

            let j;
            const flag_line_row_text_full_number = this.buildDivRow(false); // step 2.1
            const flag_line_row_text_desc_number = this.buildDivDesc(); // step 2.2
            const flag_line_row_text_head_number = this.buildSpanHead(line); // step 2.3
            const flag_line_row_text_sub_number = this.buildSpanSub(line); // step 2.4
            const flag_line_row_number_number = this.buildDivNumber(); // step 2.5
            const flag_line_row_number_text_number = this.buildSpanNumber([]); // step 2.6

            flag_line_row_text_desc_number.appendChild(flag_line_row_text_head_number);
            flag_line_row_text_desc_number.appendChild(flag_line_row_text_sub_number);
            flag_line_row_number_number.appendChild(flag_line_row_number_text_number);
            flag_line_row_text_full_number.appendChild(flag_line_row_text_desc_number);
            flag_line_row_text_full_number.appendChild(flag_line_row_number_number);

            document.body.appendChild(flag_line_row_text_full_number);

            while (i < line_signs.length) {
                j = i;
                tmp = line_signs.slice(j, i + 1);
                prev = tmp;

                while (i < line_signs.length) {
                    // find numbers
                    flag_line_row_number_text_number.innerHTML = tmp.join(',');

                    await new Promise(r => setTimeout(r, 100));

                    if (flag_line_row_text_full_number.clientWidth > flag_line_row_number_number.clientWidth) {
                        i++;
                        prev = tmp;
                        tmp = line_signs.slice(j, i + 1);
                    } else {
                        union.bulks.push(prev);
                        prev = null;
                        break;
                    }

                }
            }
            if (i === line_signs.length && prev) {
                union.bulks.push(prev);
            }

            document.body.removeChild(flag_line_row_text_full_number);

        } else {
            union.bulks = [line_signs];
        }

        document.body.removeChild(flag_line_row_text_full_dest);
    }

    getSetting(): FlagGeneralSetting {
        return {
            key: this.template_key,
            zip_name: this.code.toString(),
            file_names: this.file_names,
            style: this.style,
        };
    }

}
