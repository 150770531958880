import {Option} from '../../shared/interfaces/option';
import {translateEnum} from '../../shared/enum/translateEnum';
import {STOP_TYPE_CODES} from '../../shared/enum/stop';

export var stopTypes: Option[] = [
    {id: STOP_TYPE_CODES.STATION, name: translateEnum.STOP_TYPE_STATION},
    {id: STOP_TYPE_CODES.PILLAR, name: translateEnum.STOP_TYPE_PILLAR},
    {id: STOP_TYPE_CODES.NOT_EXIST, name: translateEnum.STOP_TYPE_NOT_EXIST},
    {id: STOP_TYPE_CODES.INVALID, name: translateEnum.STOP_TYPE_INVALID},
    {id: STOP_TYPE_CODES.SHEDS, name: translateEnum.STOP_TYPE_SHEDS},
];
