import {Component, Input} from '@angular/core';

@Component({
    selector: 'checkbox',
    templateUrl: 'checkbox.component.html',
    styleUrls: ['checkbox.component.css']
})

export class Checkbox {
    @Input() public label: string;
    @Input() public check: boolean;
    @Input() public disabled: boolean;
    @Input() public onChange: Function;
    @Input() public item: any;

    changeValue(event) {
        this.onChange(event.checked, this.item);
    }
}
