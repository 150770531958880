import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Input } from '@angular/core';
import { translateEnum } from '../../../shared/enum/translateEnum';
import { Option } from '../../../shared/interfaces/option';

@Component({
    selector: 'drag-drop',
    templateUrl: 'drag-drop.component.html',
    styleUrls: ['drag-drop.component.css', '../../../app.component.css'],
})
export class DragDropComponent {
    translateEnum = translateEnum;

    @Input() public columns: string[] = [];
    @Input() public options: Option[] = [];
    @Input() public error: boolean = false;

    option: Option;

    constructor() {
        this.optionSelected = this.optionSelected.bind(this);
        this.add = this.add.bind(this);
    }

    optionSelected(option) {
        this.option = option;
    }

    add() {
        if (this.option) {
            this.columns.push(this.option.name);
        }
    }

    delete(i) {
        return () => new Promise(() => {
            this.columns.splice(i, 1);
        });
    }

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }
    }
}
