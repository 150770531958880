import {Deserializable} from './deserializable.model';
import {Authority, READ_PERMISSION, READ_WRITE_PERMISSION, NO_PERMISSION} from './authority.model';

export class Authorities implements Deserializable {

    read_write_permission: Authority;
    read_permission: Authority;
    no_permission: Authority;

    deserialize(input: any) {
        this.read_write_permission = new Authority().deserialize({id: READ_WRITE_PERMISSION});
        this.read_permission = new Authority().deserialize({id: READ_PERMISSION});
        this.no_permission = new Authority().deserialize({id: NO_PERMISSION});
        return this;
    }

    toArray() {
        return [
            this.read_write_permission.toJson(),
            this.read_permission.toJson(),
            this.no_permission.toJson()
        ];
    }

    getAuthority(permission) {
        switch (permission) {
            case READ_WRITE_PERMISSION:
                return this.read_write_permission;
            case READ_PERMISSION:
                return this.read_permission;
            case NO_PERMISSION:
                return this.no_permission;
        }
    }

}
