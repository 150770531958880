import {Component, Input} from '@angular/core';
import {Header} from '../../../shared/interfaces/header';
import {top} from '../../../../app/global.component';
import {translateEnum} from '../../../shared/enum/translateEnum';

@Component({
    selector: 'custom-table',
    templateUrl: 'custom-table.component.html',
    styleUrls: ['custom-table.component.css', '../../../app.component.css'],
})
export class CustomTableComponent {

    readonly translateEnum = translateEnum;

    @Input() public data: any[] = [];
    @Input() public header: Header[] = [];
    @Input() public show: boolean = false;
    @Input() public onCheckbox: Function | null = null;
    @Input() public id: string = '';
    @Input() public hideHeader: boolean = false;
    @Input() public overflow: boolean = true;
    @Input() public fullWidth: boolean = false;
    @Input() public showNoResult: boolean = false;

    public table: any[] = [];
    public page: number = 1;

    constructor() {
        
    }

    ngOnInit(): void {
        this.changePage = this.changePage.bind(this);
    }

    ngOnChanges(changes): void {
        if (changes.data) {
            this.changePage(1);
        }
    }

    onClick(t)
    {
        debugger;
    }

    changePage(page) {
        debugger;
        this.page = page;

        var result = [];

        if (this.data && this.data.length > 0) {

            var start = (page - 1) * 10;
            var finish = page * 10;

            if (start <= this.data.length) {

                for (var j = start; j < finish; j++) {

                    if (this.data.length <= j) {
                        break;
                    }

                    result.push(this.data[j]);
                }
            }
        }

        this.table = result;

        if (page > 1) {
            top(this.id);
        }
    }

}
