import {Component, Input} from '@angular/core';
import { ApiService } from '../../../core/service/api.service';
import { translateEnum } from '../../../shared/enum/translateEnum';
import { FlagGeneralSetting, FlagGeneralStyle } from '../../../shared/interfaces/flag';
import { FlagPage } from '../../../shared/models/flag-page.model';
import flagStyle from '../flag-style';

@Component({
    selector: 'flag-head',
    templateUrl: 'flag-head.component.html',
    styleUrls: ['flag-head.component.css', '../../../app.component.css']
})
export class FlagHeadComponent {

    readonly translateEnum = translateEnum;
    public flagStyle = flagStyle;

    @Input() public zoom: number;
    code: number = null;
    loading: boolean = false;
    error: string | null = null;
    heads: FlagPage[] = [];
    template_key: string = 'flag-head';
    file_names: string[] = [];
    style: FlagGeneralStyle = {
        page_width: flagStyle.stopHeadWidth,
        page_height: flagStyle.stopHeadHeight,
        orientation: 'l',
    };

    constructor(private api: ApiService) {
        this.getSetting = this.getSetting.bind(this);
    }

    onCode() {
        if (this.loading) {
            return;
        }

        const self = this;
        self.heads = [];
        self.error = null;
        self.loading = true;
        this.api.getLinesByStopCode(this.code).subscribe(
            async r => {
                debugger;
                if (r.length === 0) {
                    self.error = translateEnum.NO_FLAG;
                    self.loading = false;
                    return;
                }

                const heads = [];
                const union = {
                    line: r[0],
                    union_lines: [],
                    operator_name: '',
                    min: Number.MAX_VALUE,
                    bulks: [],
                };

                heads.push(new FlagPage().deserialize({union: [union], page_number: 1, lang: 'he', count: 0}));
                heads.push(new FlagPage().deserialize({union: [union], page_number: 1, lang: 'en', count: 0}));

                self.file_names = [`${union.line.stop_code}ע`, `${union.line.stop_code}א`];
                self.heads = heads;

                setTimeout(function () {
                    self.loading = false;
                }, 4000);
            },
            r => {
                console.log({r});
                self.error = translateEnum.ERROR_MESSAGE.replace('[message]', r.error.message);
                self.loading = false;
            });
    }

    stopHead() {
        return {
            ...{
                '-webkit-text-size-adjust': 'auto',
                'text-size-adjust': 'auto',
                zoom: this.zoom + '%'
            },
            ...flagStyle.stopHead,
        };
    }

    getSetting(): FlagGeneralSetting {
        return {
            key: this.template_key,
            zip_name: this.code && this.code.toString(),
            file_names: this.file_names,
            style: this.style,
        };
    }

}
