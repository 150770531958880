import {Deserializable} from './deserializable.model';
import {getDateTime} from '../../../app/global.component';

export class Audit implements Deserializable {

    index: number;
    id: string;
    date: string;
    user: string;
    comment: string;
    report_id: string;
    created_at: string;

    constructor(index) {
        this.index = ++index;
    }

    deserialize(input: any) {
        Object.assign(this, input);
        this.report_id = input.collectionId;
        this.date = getDateTime(input.created_at);
        return this;
    }

}
