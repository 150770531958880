import {Deserializable} from './deserializable.model';
import {Role} from './role.model';
import {ADMIN_ROLE, WORKER_ROLE, OPERATOR_ROLE} from './role.model';

export class Roles implements Deserializable {

    admin: Role;
    worker: Role;
    operator: Role;

    deserialize(input: any) {
        this.admin = new Role().deserialize({id: ADMIN_ROLE});
        this.worker = new Role().deserialize({id: WORKER_ROLE});
        this.operator = new Role().deserialize({id: OPERATOR_ROLE});
        return this;
    }

    toArray() {
        return [
            this.admin.toJson(),
            this.worker.toJson(),
            this.operator.toJson()
        ];
    }
}
