import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormArray, FormGroup } from "@angular/forms";
import { translateEnum as translations } from "../../../shared/enum/translateEnum";
import { ApiService } from "../../../core/service/api.service";

@Component({
  selector: "edit-table",
  templateUrl: "./edit-table.component.html",
  styleUrls: ["./edit-table.component.scss", "../../../app.component.css"],
})
export class EditTableComponent implements OnInit {
  @Input() table: any[] = [];
  @Output() editRow = new EventEmitter<{
    [key: string]: string;
  }>();
  tableForm: FormArray;
  toDisplay: any[] = [];
  columns: string[];
  inEditMode: string[] = [];
  page: number = 1;
  loading: boolean = false;
  translateEnum;
  error: string | null = null;
  success: boolean | null = null;

  constructor(private fb: FormBuilder, private api: ApiService) {
    this.translateEnum = translations;
  }

  ngOnInit() {
    // Bind this because passed in aother component
    this.changePage = this.changePage.bind(this);
    // Init the display data
    this.changePage(1);

    // Get the columns
    this.columns = Object.keys(this.table[0]);
    // Build form array dynamically with the data in this.table
    this.tableForm = this.fb.array([]);
    this.table.forEach((row) => {
      // Group for each row with the key as the col name with the according value from the row
      const fGroup = {};
      for (let col of this.columns) {
        fGroup[col] = this.fb.control(row[col]);
      }
      this.tableForm.push(this.fb.group(fGroup));
    });
  }

  // Filter out by the given identifier value
  deleteRow(identifier: string): void {
    this.toDisplay = this.toDisplay.filter(
      (row) => row.identifier !== identifier
    );
  }

  // Toggle edit which allows to edit multiple rows simultaneously
  toggleEditRow(identifier: string): void {
    if (this.inEditMode.indexOf(identifier) !== -1)
      this.inEditMode.splice(this.inEditMode.indexOf(identifier), 1);
    else this.inEditMode.push(identifier);
  }

  // Handles save only changed cols in given row
  saveRow(rowIdx: number): boolean {
    // Get the related form array by the given index
    const fArr = <FormGroup>this.tableForm.at(rowIdx);
    const controls = fArr.controls;

    // Get only dirty controls
    let dirties: {
      [key: string]: string;
    } = {};
    Object.keys(controls).forEach((controlName) => {
      if (controls[controlName].dirty)
        dirties[controlName] = controls[controlName].value;
    });

    if (!Object.keys(dirties).length) return false;

    // Make sure to append the identifier row with the value
    dirties["identifier"] = controls.identifier.value;

    // Output the requested row to patch
    this.editRow.emit(dirties);
  }

  changePage(page: number): void {
    this.page = page;

    this.toDisplay = this.table.slice((page - 1) * 10, page * 10);
  }

  handlePutToFinal(): void {
    this.loading = true;
    this.api.putToFinal().subscribe({
      next: (res) => {
        this.success = true;
      },
      error: (err) => {
        this.error = this.translateEnum.ERROR_MESSAGE.replace(
          "[message]",
          "בעת דחיפת הטבלה"
        );
      },
      complete: () => {
        this.loading = false;
      },
    });
  }
}
