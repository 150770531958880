import {Deserializable} from './deserializable.model';
import {Stop} from './stop.model';
import {User} from './user.model';
import {translateEnum} from '../../shared/enum/translateEnum';
import {datesCompare, datesDifference, getDate, getDateTime} from '../../../app/global.component';
import {SCHEDULE_STATUSES} from '../../shared/enum/schedule';
import {Report} from '../../shared/models/report.model';
import {Audit} from '../../shared/models/audit.model';
import {Tasks} from '../../shared/models/tasks.model';
import {ShiftReplaceStopCode, ShiftSetStops} from '../../shared/interfaces/shift';

export class Shift implements Deserializable {
    public datesDifference = datesDifference;
    readonly translateEnum: typeof translateEnum = translateEnum;

    id: string;
    comments: any;
    from_date: string;
    to_date: string;
    name: string;
    reports: number[];
    reports_objects: Report[] = [];
    status: number;
    stops: Stop[];
    tasks: Tasks;
    jobTypes: {
        [key: string]: string;
    }[];
    user: User;
    created_at: string;

    enter_date: string;
    action_range: string;

    toDay: number;
    toMonth: number;
    toYear: number;
    toDateObject: Date;

    fromDay: number;
    fromMonth: number;
    fromYear: number;
    fromDateObject: Date;

    colspanWeek: number;

    statusStyle: string = null;
    statusText: string = null;

    difference: number = 1;

    monthWidth: number = 0;

    total_stops_count: number = 0;
    completed_stops_count: number = 0;

    last_audit: Audit = null;

    deserialize(input: any) {
        debugger;
        Object.assign(this, input);

        if (input.user) {
            this.user = new User().deserialize({user: input.user, token: null});
        }

        this.setStops({
            stops: input.stops,
            comments: input.comments,
            reports: input.reports,
            tasks: input.tasks,
        });

        if(input.from_date!=undefined && input.to_date!=undefined)
        this.setDates(input.from_date, input.to_date);
        this.setStatus(input.status);

        this.enter_date = getDateTime(input.created_at);
        this.completed_stops_count = this.reports.length;

        return this;
    }

    setColspanWeek(colspan) {
        this.colspanWeek = colspan;
    }

    public setMonthWidth(length, width) {
        this.monthWidth = length * width - 8;
    }

    public setStatusStyle(statusStyle) {

        if (this.statusStyle) {
            this.statusStyle += ' ' + statusStyle;
        } else {
            this.statusStyle = ' ' + statusStyle;
        }

    }

    public deleteStop(stop: Stop) {

        const stops = {};
        const comments = this.comments;
        let deleteIndex = -1;

        for (let i = 0; i < this.stops.length; i++) {

            const item = this.stops[i];

            if (item.id === stop.id) {
                deleteIndex = i;
                if (comments) {
                    delete this.comments[item.stop_code];
                }
            } else {
                stops[item.stop_code] = (comments && comments[item.stop_code] ? comments[item.stop_code] : '');
            }

        }

        if (deleteIndex > -1) {
            this.stops.splice(deleteIndex, 1);
        }

        return stops;

    }

    public setComments(comments: any) {
        this.comments = comments;
    }

    public setTasks(tasks) {
        this.tasks = new Tasks().deserialize(tasks);
    }

    public getStopTasks(stop_code) {
        return this.tasks.getStopTasks(stop_code);
    }

    public setStops(data: ShiftSetStops) {
        const stops = [];

        if (data.comments) {
            this.setComments(data.comments);
        }
        this.setTasks(data.tasks);

        if (data.stops) {
            for (let i = 0; i < data.stops.length; i++) {
                const stop: Stop = (data.stops[i] instanceof Stop) ? data.stops[i] : new Stop().deserialize(data.stops[i]);
                if (data.comments && data.comments[stop.stop_code]) {
                    stop.setComment(data.comments[stop.stop_code]);
                }
                if (this.tasks) {
                    stop.setTasks(this.tasks.getStopTasks(stop.stop_code));
                }
                if (data.reports && data.reports.length > 0) {
                    if (data.reports.indexOf(stop.stop_code) > -1) {
                        stop.setReported(true);
                    }
                }
                stops.push(stop);
            }
        }

        this.stops = stops;
        this.total_stops_count = this.stops.length;
    }

    public async replaceStopCode(data: ShiftReplaceStopCode) {
        const self = this;
        const stop_codes: Set<number> = self.stops.reduce((acc: Set<number>, stop: Stop) => {
            acc.add(stop.stop_code);
            return acc;
        }, new Set<number>([]));

        if (data.delete_stop_code) {
            stop_codes.delete(data.delete_stop_code);
        }
        if (data.new_stop_code) {
            stop_codes.add(data.new_stop_code);
        }

        const tasks: Tasks = self.tasks.copyTasks();
        if (data.delete_stop_code) {
            tasks.deleteStopTasks(data.delete_stop_code);
        }

        if (data.new_stop_code) {
            tasks.setStopTasks(data.new_stop_code, data.new_stop_tasks);
        }

        try {
            const result = await (data.api.updateSchedule(this.id, {
                stop_codes: Array.from(stop_codes),
                tasks,
            }).toPromise());
            self.setStops({
                stops: result.shift.stops,
                comments: result.shift.comments,
                reports: result.shift.reports,
                tasks: result.shift.tasks,
            });
            data.on_success();
        } catch (result) {
            data.on_error(result.status === 500 ? result.error.message : result.error);
        }
    }

    public setStatus(status: number) {
        this.status = status;

        switch (status) {
            case SCHEDULE_STATUSES.WAITING:
                this.statusStyle = this.translateEnum.STATUS_WAITING_STYLE;
                this.statusText = this.translateEnum.STATUS_WAITING;
                break;

            case SCHEDULE_STATUSES.PROGRESS:
                this.statusStyle = this.translateEnum.STATUS_PROGRESS_STYLE;
                this.statusText = this.translateEnum.STATUS_PROGRESS;
                break;

            case SCHEDULE_STATUSES.COMPLETE:
                this.statusStyle = this.translateEnum.STATUS_COMPLETE_STYLE;
                this.statusText = this.translateEnum.STATUS_COMPLETE;
                break;
        }
    }

    public setUser(new_user: User) {
        this.user = new_user;
    }

    public setName(name: string) {
        this.name = name;
    }

    public setDates(from_date: string, to_date: string) {

        this.from_date = from_date;
        this.to_date = to_date;

        if (from_date) {
            const split_date_time = from_date.split('T');
            if (split_date_time && split_date_time.length > 0) {
                const split_date = split_date_time[0].split('-');
                if (split_date && split_date.length > 2) {
                    this.fromYear = +split_date[0];
                    this.fromMonth = +split_date[1];
                    this.fromDay = +split_date[2];
                    this.fromDateObject = new Date(this.fromYear, this.fromMonth - 1, this.fromDay);
                }
            }
        }

        if (to_date) {
            const split_date_time = to_date.split('T');
            if (split_date_time && split_date_time.length > 0) {
                const split_date = split_date_time[0].split('-');
                if (split_date && split_date.length > 2) {
                    this.toYear = +split_date[0];
                    this.toMonth = +split_date[1];
                    this.toDay = +split_date[2];
                    this.toDateObject = new Date(this.toYear, this.toMonth - 1, this.toDay);
                }
            }
        }

        this.difference = this.datesDifference(this.fromDateObject, this.toDateObject);
        this.action_range = `${getDate(this.from_date)} - ${getDate(this.to_date)}`;
    }

    dateHeader() {

        const header = [];

        if (this.fromDay === this.toDay &&
            this.fromMonth === this.toMonth &&
            this.fromYear === this.toYear) {
            const from = (this.fromYear).toString().slice(-2);
            header.push(this.fromDay + '/' + this.fromMonth + '/' + from);
        } else {
            const from = (this.fromYear).toString().slice(-2);
            const to = (this.toYear).toString().slice(-2);
            header.push(this.toDay + '/' + this.toMonth + '/' + to);
            header.push(' - ');
            header.push(this.fromDay + '/' + this.fromMonth + '/' + from);
        }

        return header.join('');

    }

    setReports(reports_objects: Report[]) {
        this.reports_objects = reports_objects;
        const sorted_audits = (this.reports_objects.filter((report: Report) => !!report.last_audit)
            .sort((a: Report, b: Report) => {
                return datesCompare(a.last_audit.created_at, b.last_audit.created_at);
            }));
        if (sorted_audits.length) {
            this.last_audit = sorted_audits[sorted_audits.length - 1].last_audit;
        }
    }

}
