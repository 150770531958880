const width: number = 1423;
const height: number = 1708;
const outBorder: number = 20;
const inBorder: number = 10;
const flagRowWidth: number = width - 2 * outBorder;
const flagStopRowHeight: number = (height - 2 * outBorder + inBorder) / 4;
const flagLineRowHeight: number = (height - 2 * outBorder - 7 * inBorder) / 8;
const borderColor: string = '#1a1919';
const backgroundColor: string = '#f3b229';
const flagLineRowImageTextFontSize = 90;

const stopHeadWidth = flagRowWidth + 2 * outBorder;
const stopHeadHeight = flagStopRowHeight + 2 * outBorder;

const heFlagStopRowTextHeaderFontSize = 153;
// const heFlagStopRowTextHeaderFontSize = 150;
// const enFlagStopRowTextHeaderFontSize = 160;
const enFlagStopRowTextHeaderFontSize = 155;

// const heFlagLineRowTextFullNameHeadFontSize = 150;
const heFlagLineRowTextFullNameHeadFontSize = 127;
const enFlagLineRowTextFullNameHeadFontSize = 127;

const heFlagLineRowTextFullNameSubFontSize = 76;
const enFlagLineRowTextFullNameSubFontSize = 76;

const flagLineRowTextFullNumberOneRowFontSize = 190;
// const flagLineRowTextFullNumberOneRowFontSize = 204.5;

const flagStripWidth = flagRowWidth - flagLineRowHeight;
const flagStripHeight = flagLineRowHeight;

const stopHead: { width: string, height: string, border: string } = {
    width: `${stopHeadWidth}pt`,
    height: `${stopHeadHeight}pt`,
    border: `${outBorder}pt solid ${borderColor}`,
};

const doubleFlag: { width: string, height: string } = {
    width: `${width}pt`,
    height: `${height * 2 + 1}pt`,
};

const flagFrame: { width: string, height: string, 'border': string, 'background-color': string } = {
    width: `${width}pt`,
    height: `${height}pt`,
    border: `${outBorder}pt solid ${borderColor}`,
    'background-color': backgroundColor,
};

const borderBottom: { 'border-bottom': string } = {
    'border-bottom': `${inBorder}pt solid ${borderColor}`
};

const flagStopRow: { width: string, height: string } = {
    width: `${flagRowWidth + 1}pt`,
    height: `${flagStopRowHeight}pt`
};

const flagStopRowImage: { width: string, height: string } = {
    width: `${flagStopRowHeight}pt`,
    height: `${flagStopRowHeight}pt`
};
const heFlagStopRowImage: { width: string, height: string, 'border-right': string, 'border-left': string } = {
    ...flagStopRowImage,
    ...{
        'border-right': `${inBorder}pt solid ${borderColor}`,
        'border-left': 'none'
    }
};
const enFlagStopRowImage: { width: string, height: string, 'border-right': string, 'border-left': string } = {
    ...flagStopRowImage,
    ...{
        'border-right': 'none',
        'border-left': `${inBorder}pt solid ${borderColor}`
    }
};
const flagStopRowImageHeadOnly: { height: string } = {
    height: `${flagStopRowHeight + 1}pt`
};

const flagStopRowText: { width: string } = {
    width: `calc(100% - ${flagStopRowHeight}pt)`,
};

const flagStrip: { width: string, height: string, 'background-color': string } = {
    width: `${flagStripWidth}pt`,
    height: `${flagStripHeight}pt`,
    'background-color': backgroundColor,
};

const flagLineRow: { width: string, height: string, 'background-color': string } = {
    width: `${flagRowWidth}pt`,
    height: `${flagLineRowHeight}pt`,
    'background-color': backgroundColor,
};

const flagLineRowImageSize = flagLineRowHeight;

const flagLineRowImage: { width: string, height: string } = {
    width: `${flagLineRowImageSize + inBorder}pt`,
    height: `${flagLineRowImageSize + 2}pt`
};
const heFlagLineRowImage: { width: string, height: string, 'border-left': string, 'border-right': string } = {
    ...flagLineRowImage,
    ...{
        'border-left': `${inBorder}pt solid ${borderColor}`,
        'border-right': 'none',
    }
};
const enFlagLineRowImage: { width: string, height: string, 'border-left': string, 'border-right': string } = {
    ...flagLineRowImage,
    ...{
        'border-left': 'none',
        'border-right': `${inBorder}pt solid ${borderColor}`,
    }
};

const flagLineRowImageImg: { 'max-width': string, 'max-height': string } = {
    ...{
        'max-width': `${flagLineRowImageSize - outBorder}pt`,
        'max-height': `${flagLineRowImageSize - outBorder}pt`,
    }
};

const flagLineRowTextFull: { width: string, height: string } = {
    width: `calc(100% - ${flagLineRowHeight + 2 * inBorder}pt)`,
    height: `${flagLineRowImageSize}pt`,
};

const flagBorderStopCode: { 'margin-right': string } = {
    'margin-right': `${flagLineRowImageSize * 2}pt`,
};

const flagLine: { 'width': string, height: string, 'background-color': string } = {
    width: 'calc(100% + 2pt)',
    height: `${inBorder}pt`,
    'background-color': borderColor,
};

const flagLineOnlyNumbers: { 'width': string, height: string, 'background-color': string, marginLeft: string } = {
    ...flagLine,
    width: flagLineRowImage.width,
    marginLeft: '-1pt',
};

const enFlagLineOnlyNumbers: { justifyItems: string, display: string } = {
    justifyItems: 'end',
    display: 'grid',
};

export default {
    backgroundColor,
    width,
    height,
    borderColor,
    flagFrame,
    doubleFlag,
    flagStopRow,
    flagStopRowImage,
    heFlagStopRowImage,
    enFlagStopRowImage,
    flagStopRowText,
    heFlagStopRowTextHeaderFontSize,
    enFlagStopRowTextHeaderFontSize,
    flagLineRow,
    flagLineRowImage,
    flagLineRowImageSize,
    heFlagLineRowImage,
    enFlagLineRowImage,
    flagLineRowImageImg,
    flagLineRowImageTextFontSize,
    flagLineRowTextFull,
    flagBorderStopCode,
    heFlagLineRowTextFullNameHeadFontSize,
    enFlagLineRowTextFullNameHeadFontSize,
    heFlagLineRowTextFullNameSubFontSize,
    enFlagLineRowTextFullNameSubFontSize,
    flagLineRowTextFullNumberOneRowFontSize,
    flagStopRowImageHeadOnly,
    flagLine,
    flagLineOnlyNumbers,
    enFlagLineOnlyNumbers,
    flagRowWidth,
    flagStopRowHeight,
    stopHead,
    stopHeadWidth,
    stopHeadHeight,
    flagStrip,
    flagStripWidth,
    flagStripHeight,
    borderBottom,
};
