import {Option} from '../../shared/interfaces/option';
import {translateEnum} from '../../shared/enum/translateEnum';
import {SCHEDULE_STATUSES} from '../../shared/enum/schedule';

export const scheduleStatuses: Option[] = [
    {id: SCHEDULE_STATUSES.COMPLETE, name: translateEnum.STATUS_COMPLETE},
    {id: SCHEDULE_STATUSES.PROGRESS, name: translateEnum.STATUS_PROGRESS},
    {id: SCHEDULE_STATUSES.WAITING, name: translateEnum.STATUS_WAITING}
];

export const scheduleSets: Set<number>[] = [
    new Set<number>([SCHEDULE_STATUSES.COMPLETE, SCHEDULE_STATUSES.PROGRESS, SCHEDULE_STATUSES.WAITING])
];
