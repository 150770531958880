import {Component, OnInit,ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ApiService } from '../../core/service/api.service';
import { UserService } from '../../core/service/user.service';
import { translateEnum } from '../../shared/enum/translateEnum';
import { Header } from '../../shared/interfaces/header';
import { UploadCsv } from '../../shared/interfaces/upload';
import { STOPS_FILE_UPLOAD_PERMISSIONS } from '../../shared/models/permissions.model';

@Component({
  selector: 'app-stops-file-upload',
  templateUrl: './stops-file-upload.component.html',
  styleUrls: ['./stops-file-upload.component.css']
})
export class StopsFileUploadComponent implements OnInit {

  translateEnum = translateEnum;
    write_permission: boolean = false;
    form: FormGroup;
    table: { [key: number]: string }[] = [];
    header: Header[] = [];
    loading: boolean = false;
    error: string = null;
    success: boolean = null;
    lines_logs_subject: Subject<void> = new Subject<void>();
    reset_file_subject: Subject<void> = new Subject<void>();
    resSubject: Subject<void> = new Subject<void>();
    show_format: boolean = false;

    @ViewChild('reportModal', {static: false}) reportModal: ModalDirective;

    constructor(private api: ApiService, public userService: UserService, private formBuilder: FormBuilder) {
        this.userService.pagePermission(STOPS_FILE_UPLOAD_PERMISSIONS);
        const user = this.userService.getUser();
        this.write_permission = user.permissions[STOPS_FILE_UPLOAD_PERMISSIONS].write;
    }

    ngOnInit(): void {
        this.reset();
    }

    reset() {
        this.form = this.formBuilder.group({
            file: [''],
        });
        this.table = [];
        this.header = [];
        this.loading = false;
        this.error = null;
        this.success = null;
        this.reset_file_subject.next();
    }

    onUpload({ file_name, file}: UploadCsv) {
        this.form.get('file').setValue(file);
       
    }

    onSubmit() {
        const self = this;
        self.loading = true;
        self.error = null;
        self.success = null;
        self.loading = true;
        const formData = new FormData();
        formData.append('file', this.form.get('file').value);
        this.api.stopsFileUpload(formData).subscribe(
            (res) => {
               
                self.success = true;
                self.reset();
                this.reportModal.show();
                self.loading = false;
                this.resSubject.next(res);
            },
            (err) => {
                console.log({err});
                self.error = this.translateEnum.ERROR_MESSAGE.replace('[message]', err.error.message);
            })
            .add(() => {
                self.loading = false;
            });
    }

}
