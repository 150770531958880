import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Option } from '../../../../shared/interfaces/option';
import { BaseComponent } from '../../../reports/base.component';
import { translateEnum } from '../../../../shared/enum/translateEnum';

@Component({
    selector: 'dropdown-status',
    templateUrl: 'dropdown-status.component.html',
    styleUrls: ['dropdown-status.component.css']
})
export class DropdownStatus extends BaseComponent implements OnInit {

    @Input() public list: Option[] = [];
    @Input() public selectedItems: Option[] = [];
    @Input() public header: string;
    @Input() public sets: Set<number>[] = [];
    @Output() onSelectEvent = new EventEmitter<any>();

    dropdownSettings: object = {};
    allowedItems: Set<number> = new Set<number>();
    translateEnum = translateEnum;

    ngOnInit() {
        this.dropdownSettings = {
            singleSelection: false,
            text: this.header,
            enableCheckAll: this.sets.length > 0 && this.sets[0].size === this.list.length,
            selectAllText: this.translateEnum.SELECT_ALL,
            unSelectAllText: this.translateEnum.UNSELECT_ALL,
            searchPlaceholderText: this.translateEnum.SEARCH,
            enableSearchFilter: false,
            classes: 'dropdownSettings',
            labelKey: 'name',
        };
    }

    ngOnChanges(changes): void {
        if (changes.selectedItems && changes.selectedItems.currentValue) {
            this.resetItemsAllowed();
        }
    }

    resetItemsAllowed() {
        this.allowedItems = this.list.reduce((acc, item) => {
            acc.add(item.id);
            return acc;
        }, new Set<number>());
    }

    onItemSelect(item: any) {
        if (this.allowedItems.has(item.id)) {
            // item allowed
            if (this.allowedItems.size === this.list.length) {
                // find item allowed set
                const set = this.sets.find(group => group.has(item.id));
                if (set) {
                    this.allowedItems = set;
                }
            }
        } else {
            // item not allowed - remove from selected
            this.onItemRemove(item);
        }
        this.onSelectEvent.emit(this.selectedItems);
    }

    onItemRemove(item: Option) {
        const index = this.selectedItems.indexOf(item);
        if (index > -1) {
            this.selectedItems.splice(index, 1);
        }
    }

    onItemDeSelect() {
        if (this.selectedItems.length === 0) {
            this.resetItemsAllowed();
        }
        this.onSelectEvent.emit(this.selectedItems);
    }

    onSelectAll() {
        this.onSelectEvent.emit(this.selectedItems);
    }

    onDeSelectAll() {
        this.onSelectEvent.emit(this.selectedItems);
    }

}
