import { AgmCoreModule, GoogleMapsAPIWrapper } from "@agm/core";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { AlertModule } from "ngx-bootstrap/alert";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { PopoverModule } from "ngx-bootstrap/popover";
import { AlertTooltipComponent } from "./alert-tooltip/alert-tooltip.component";
import { BaseRoutingModule } from "./base-routing.module";
import { ApproveComponent } from "./chars/approve/approve.component";
import { OffComponent } from "./chars/off/off.component";
import { OkComponent } from "./chars/ok/ok.component";
import { PendingComponent } from "./chars/pending/pending.component";
import { Checkbox } from "./checkbox/checkbox.component";
import { CustomTableComponent } from "./custom-table/custom-table.component";
import { Datepicker } from "./datepicker/datepicker.component";
import { DateTimePicker } from "./datetimepicker/datetimepicker.component";
import { DownloadImagesComponent } from "./download-images/download-images.component";
import { DragDropComponent } from "./drag-drop/drag-drop.component";
import { DropdownStatus } from "./dropdowns/dropdown-status/dropdown-status.component";
import { ServerSideSearchComponent } from "./dropdowns/server-side-search/server-side-search.component";
import { SingleSelectionComponent } from "./dropdowns/single-selection/single-selection.component";
import { StopTypeSelectionComponent } from "./dropdowns/stop-type-selection/stop-type-selection.component";
import { DynamicTask } from "./dynamic-list/dynamic-list-task/dynamic-task.component";
import { ExportAllComponent } from "./export/export-all/export-all.component";
import { ExportCreateComponent } from "./export/export-create/export-create.component";
import { ExportComponent } from "./export/export.component";
import { IconButtonComponent } from "./icon-button/icon-button.component";
import { ImagesComponent } from "./images/images.component";
import { MiniImageComponent } from "./images/mini-image.component";
import { ComponentLoadingComponent } from "./loading/component-loading/component-loading.component";
import { PageLoadingComponent } from "./loading/page-loading/page-loading.component";
import { MapComponent } from "./map/map.component";
import { PagingComponent } from "./paging/paging.component";
import { PhotosCarouselComponent } from "./photos-carousel/photos-carousel.component";
import { AuditActionComponent } from "./report/audit-action/audit-action.component";
import { AuditButtonComponent } from "./report/audit-button/audit-button.component";
import { TaskButtonComponent } from "./report/task-button/task-button.component";
import { TaskButtonUpgradeComponent } from "./report/task-button-upgrade/task-button-upgrade.component";
import { EditFormComponent } from "./report/edit-form/edit-form.component";
import { InfoTableComponent } from "./report/info-table/info-table.component";
import { PdfButtonComponent } from "./report/pdf-button/pdf-button.component";
import { ReportComponent } from "./report/report.component";
import { StopTableComponent } from "./report/stop-table/stop-table.component";
import { ViewFormComponent } from "./report/view-form/view-form.component";
import { EditScheduleComponent } from "./schedule/edit-schedule/edit-schedule.component";
import { SearchButtonComponent } from "./search/button/search-button.component";
import { SearchHeaderComponent } from "./search/header/search-header.component";
import { SearchStatusComponent } from "./search/status/search-status.component";
import { TaskComponent } from "./tasks/task/task.component";
import { TasksComponent } from "./tasks/tasks.component";
import { TitleChipComponent } from "./title-chip/title-chip.component";
import { UploadCsvComponent } from "./upload/upload-csv/upload-csv.component";
import { UploadXlsxComponent } from "./upload/upload-xlsx/upload-xlsx.component";
import { UploadFileComponent } from "./upload/upload-file/upload-file.component";
import { WarningComponent } from "./warning/warning.component";

@NgModule({
  imports: [
    CommonModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCU4ZNR-DTBZ5K1Addy_8kC_wHpGw6-0B0",
    }),
    FormsModule,
    ReactiveFormsModule,
    BaseRoutingModule,
    BsDropdownModule.forRoot(),
    PaginationModule.forRoot(),
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    CarouselModule,
    AlertModule.forRoot(),
    HttpClientModule,
    ModalModule,
    MatIconModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    DragDropModule,
    MatGridListModule,
    MatChipsModule,
    PopoverModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  declarations: [
    Datepicker,
    DateTimePicker,
    UploadXlsxComponent,
    UploadFileComponent,
    UploadCsvComponent,
    DropdownStatus,
    PagingComponent,
    MapComponent,
    WarningComponent,
    ImagesComponent,
    DownloadImagesComponent,
    SingleSelectionComponent,
    StopTypeSelectionComponent,
    ServerSideSearchComponent,
    MiniImageComponent,
    Checkbox,
    AlertTooltipComponent,
    OkComponent,
    OffComponent,
    PendingComponent,
    ApproveComponent,
    TasksComponent,
    TaskComponent,
    PageLoadingComponent,
    SearchHeaderComponent,
    SearchButtonComponent,
    ComponentLoadingComponent,
    ExportComponent,
    ExportCreateComponent,
    ExportAllComponent,
    DragDropComponent,
    IconButtonComponent,
    SearchStatusComponent,
    CustomTableComponent,
    ReportComponent,
    PdfButtonComponent,
    PdfButtonComponent,
    AuditActionComponent,
    AuditButtonComponent,
    TaskButtonComponent,
    TaskButtonUpgradeComponent,
    EditFormComponent,
    ViewFormComponent,
    StopTableComponent,
    InfoTableComponent,
    PhotosCarouselComponent,
    EditScheduleComponent,
    TitleChipComponent,
    DynamicTask,
  ],
  exports: [
    Datepicker,
    DateTimePicker,
    UploadXlsxComponent,
    UploadCsvComponent,
    DropdownStatus,
    PagingComponent,
    MapComponent,
    WarningComponent,
    ImagesComponent,
    DownloadImagesComponent,
    SingleSelectionComponent,
    StopTypeSelectionComponent,
    ServerSideSearchComponent,
    MiniImageComponent,
    Checkbox,
    AlertTooltipComponent,
    OkComponent,
    OffComponent,
    PendingComponent,
    ApproveComponent,
    TasksComponent,
    TaskComponent,
    PageLoadingComponent,
    SearchHeaderComponent,
    SearchButtonComponent,
    ComponentLoadingComponent,
    ExportComponent,
    ExportCreateComponent,
    ExportAllComponent,
    DragDropComponent,
    IconButtonComponent,
    SearchStatusComponent,
    CustomTableComponent,
    ReportComponent,
    PdfButtonComponent,
    AuditActionComponent,
    AuditButtonComponent,
    EditFormComponent,
    ViewFormComponent,
    StopTableComponent,
    InfoTableComponent,
    PhotosCarouselComponent,
    EditScheduleComponent,
    TitleChipComponent,
    DynamicTask,
    UploadFileComponent
  ],
  bootstrap: [
    UploadXlsxComponent,
    UploadCsvComponent,
    DropdownStatus,
    PagingComponent,
    Datepicker,
    MapComponent,
    WarningComponent,
    ImagesComponent,
    DownloadImagesComponent,
    SingleSelectionComponent,
    ServerSideSearchComponent,
    MiniImageComponent,
    Checkbox,
  ],
  providers: [GoogleMapsAPIWrapper],
})
export class BaseModule {}
