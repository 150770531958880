import {Deserializable} from './deserializable.model';
import {translateEnum} from '../enum/translateEnum';

export const READ_WRITE_PERMISSION = 'rw';
export const READ_PERMISSION = 'r';
export const NO_PERMISSION = null;

export class Authority implements Deserializable {

    id: string;
    name: string;

    deserialize(input: any) {
        this.id = input.id;
        this.name = this.getName();
        return this;
    }

    getName() {
        switch (this.id) {
            case READ_WRITE_PERMISSION:
                return translateEnum.WRITE_PERMISSION;
            case READ_PERMISSION:
                return translateEnum.READ_PERMISSION;
        }
        return translateEnum.NO_PERMISSION;
    }

    toJson() {
        return {
            id: this.id,
            name: this.name
        };
    }

}
