import {Component, Input,Output,EventEmitter} from '@angular/core';
import {Shift} from '../../../shared/models/shift.model';
import {Task} from '../../../shared/models/task.model';
import {uniqueID} from '../../../../app/global.component';

@Component({
    selector: 'tasks',
    templateUrl: 'tasks.component.html',
    styleUrls: ['tasks.component.css'],
    host: {
        '(window:resize)': 'isOverflow()'
    }
})
export class TasksComponent {
    @Input() shift: Shift;
    @Input() stop_code: number;
    @Input() show_popover: boolean = true;
    @Input() public source: string = "";
    @Output() taskEdit = new EventEmitter<{ shift_id: string,stop_code: number,task: {},i:number }>();
    @Output() taskDelete = new EventEmitter<{ shift_id: string,stop_code: number,task: {},i:number }>();



    id: string;
    tasks: Task[] = [];
    overflow: boolean = false;

    ngOnChanges(changes): void {
        const self = this;
        if ((changes.shift && changes.shift.currentValue) && (changes.stop_code && changes.stop_code.currentValue)) {
            const shift: Shift = changes.shift.currentValue;
            self.tasks = shift.getStopTasks(changes.stop_code.currentValue);
            self.id = self.stop_code + '_' + uniqueID();

            setTimeout(function () {
                self.isOverflow();
            }, 100);
        }
    }

    onHover(event) {
        event.stopPropagation();
    }
    Edit(event,i) {
        
       debugger;
       this.taskEdit.emit({ shift_id: this.shift.id,stop_code:this.stop_code,task:event,i:i });
       event.stopPropagation();

    }

    onDelete(event,i) {
        
        debugger;
        this.taskDelete.emit({ shift_id: this.shift.id,stop_code:this.stop_code,task:event,i:i });
 
     }


    isOverflow() {
        const element = document.getElementById(this.id);
        const height = element.offsetHeight;
        this.overflow = this.show_popover && height > 54;
    }

}
