import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from '../../../../base.component';
import { top } from '../../../../global.component';
import { translateEnum } from '../../../../shared/enum/translateEnum';
import { Option } from '../../../../shared/interfaces/option';
import { ValidationErrors } from '../../../../shared/interfaces/validation-errors';
import { Operator } from '../../../../shared/models/operator.model';
import { Route } from '../../../../shared/models/route.model';
import { operatorNames } from '../../../../shared/options/operator';

@Component({
    selector: 'stop-table',
    templateUrl: 'stop-table.component.html',
    styleUrls: ['./stop-table.component.css', '../../../../app.component.css'],
    providers: [{provide: CarouselConfig}]
})
export class StopTableComponent extends BaseComponent implements OnInit, OnDestroy,OnChanges {

    readonly operatorNames = operatorNames;
    translateEnum = translateEnum;

    @Input() public data: Route[] = null;
    @Input() data$: Observable<Route[]>;
    subs$: Subscription = new Subscription();
    @Input() public checkboxText: string = '';
    @Input() public key: string = '';
    @Input() public edit: boolean = false;
    @Input() public routeHeader: string = '';
    @Input() public showOperator: boolean = false;
    @Input() public options: Option[] = [];
    @Input() public validation_errors: ValidationErrors = {};

    table: Route[] = null;
    checkbox: boolean = false;
    page: number = 1;
    operator: Operator = null;
    responsive: boolean = window.innerWidth < 800;

    constructor() {
        super();
        this.onChangeCheck = this.onChangeCheck.bind(this);
        this.updateTable = this.updateTable.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.onChangeOperator = this.onChangeOperator.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
        this.onOperatorPopover = this.onOperatorPopover.bind(this);
    }

    fieldsChange(values:any,item:any):void {
        debugger;
        (values.target as HTMLInputElement).checked
      }

    ngOnInit(): void {
        this.subs$.add(this.data$?.subscribe((data) => {
            this.data = data
            this.updateTable(1);
        }));
    }
    ngOnDestroy(): void {
        this.subs$.unsubscribe();
    }

    ngOnChanges(changes: any): void {
        if (changes.data && changes.data.currentValue) {
            this.data = changes.data.currentValue;
            this.updateTable(1);
        }
    }

    onOperatorPopover(operator: Operator) {
        if (operator) {
            this.operator = operator;
        }
    }

    showAll() {
        return this.data && (this.data.length > 0 || this.edit);
    }

    onChangeCheck(checked) {
        this.checkbox = checked;
    }

    updateTable(page) {
        this.page = page;

        const result = [];

        if (this.data) {

            const count = this.data.length;
            const start = (page - 1) * 10;
            const finish = page * 10;

            if (start <= count) {

                for (let j = start; j < finish; j++) {

                    if (count <= j) {
                        break;
                    }

                    result.push(this.data[j]);
                }
            }
        }

        if (page > 1) {
            top(this.key);
        }

        this.table = result;
    }

    onChangeStatus(route, event) {
        if (event) {
            route.setStatus(event.id);
        }
    }

    onChangeOperator(route, event) {
        route.operator.setOperator(event && event.name, route.operator.lang);
    }

    onClickDelete(index) {
        this.data[index].setDeleted(true);
    }

    onClickAdd() {
        this.data.forEach((item: Route) => {
            item.setIndex(Number(item.index) + 1);
        });
        this.data.unshift(new Route().deserialize({
            options: this.options,
            data: {route: '', status: 1},
            index: 0
        }));
        this.updateTable(1);
    }

}
