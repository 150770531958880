export enum OPERATOR_NAME {
  OPERATOR_EGGED_TAAVURA = 1,
  OPERATOR_EGGED = 2,
	OPERATOR_AFIKIM = 3,
	OPERATOR_GB_TOURS = 4,
	OPERATOR_GALEEM = 5,
	OPERATOR_DANBR7 = 6,
	OPERATOR_DANBADAROM = 7,
	OPERATOR_DANNORTH = 8,
	OPERATOR_DAN = 9,
	OPERATOR_SHUFAT_BUS = 10,
	OPERATOR_ATA_BUSES = 11,
	OPERATOR_CENTER_BUSES = 12,
	OPERATOR_SOUTH_BUSES = 13,
	OPERATOR_MOUNTOFOLIVES_BUS = 14,
	OPERATOR_SURBAHER_BUS = 15,
	OPERATOR_RAMALLAH_BUS = 16,
	OPERATOR_SHUFAT = 17,
	OPERATOR_CALLKAV = 18,
	OPERATOR_GOLAN_BUS = 19,
	OPERATOR_METROPOLINE = 20,
	OPERATOR_NTT_BUSES = 21,
	OPERATOR_NATEEV_EXPRESS = 22,
	OPERATOR_SUPERBUS = 23,
	OPERATOR_KAVIM = 24,
	OPERATOR_NAZARETH_UNBS = 25,
	OPERATOR_BETSHEMESH = 26,
	OPERATOR_NIGHT = 27,
	OPERATOR_TNUFA = 28,
	OPERATOR_ELECTRA_AFIKIM = 29,
	OPERATOR_EXTRA = 30,
  OPERATOR_EXTRA_JERUSALEM = 31,
  OPERATOR_ELECTRA_AFIKIM_TAHBURA=32,
  EGED_DERECH=33
}
