import {Component, Input} from '@angular/core';
import {FlagBrailStyle} from '../../../../shared/interfaces/flag';

@Component({
    selector: 'flag-brail-dot',
    templateUrl: 'flag-brail-dot.component.html',
    styleUrls: ['flag-brail-dot.component.css', '../../../../app.component.css']
})

export class FlagBrailDotComponent {
    @Input() show: boolean = false;
    @Input() style: FlagBrailStyle;
}
