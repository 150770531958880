import {Shift} from '../../../shared/models/shift.model';
import {translateEnum} from '../../../shared/enum/translateEnum';

export const download = (shifts: any[]) => {
    const data = [];
    debugger;
    for (const shift of shifts) {
        const row = {
            [translateEnum.SCHEDUAL_DETAILS]: shift.name,
            [translateEnum.SCHEDUALE_USER_NAME]: shift.user.first_name+ " "+shift.user.last_name,
            [translateEnum.SCHEDUALT_STATUS]: shift.statues==2?'הסתיים':'לא הסתיים',
            [translateEnum.SCHEDUALT_DATE]: shift.from_date.split('T')[0],
        };

        data.push(row);
    }

    const header: string[] = [
        translateEnum.SCHEDUAL_DETAILS,
        translateEnum.SCHEDUALE_USER_NAME,
        translateEnum.SCHEDUALT_STATUS,
        translateEnum.SCHEDUALT_DATE
    ];

    return {header, data};
};
