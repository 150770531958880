import {Report} from "../../../shared/models/report.model";
import {translateEnum} from "../../../shared/enum/translateEnum";
import {Route} from "../../../shared/models/route.model";
import {User} from "../../../shared/models/user.model";

export const download = (reports: Report[], users: User[]) => {
	const data = [];

	for (const report of reports) {
		const flag = report.flag.find((flag: Route) => flag.is_invalid_head);

		if (flag) {
			data.push({
				[translateEnum.STOP_CODE_STATION]: report.stop.stop_code,
				[translateEnum.STOP_TYPE]: report.stopType,
				[translateEnum.SCHEDULE_PERFORMER]: report.userName(users),
				[translateEnum.STOP_HEAD_FLAG_INVALID]: flag.route,
			});
		}
	}

	let header: string[] = [
		translateEnum.STOP_CODE_STATION,
		translateEnum.STOP_TYPE,
		translateEnum.SCHEDULE_PERFORMER,
		translateEnum.STOP_HEAD_FLAG_INVALID
	];

	return {header, data};
}
