import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router/src/router_state';
import { ApiService } from './core/service/api.service';
import { UserService } from './core/service/user.service';

@Injectable()
export class NeedAuthGuard implements CanActivate {

    constructor(private api: ApiService, private userService: UserService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.userService.isLogged()) {
            const user = this.userService.getUser();

            this.api.user(user.id, user.token).subscribe(
                r => {
                    this.userService.setUser(r);
                    if (user.email !== r.email ||
                        user.first_name !== r.first_name ||
                        user.last_name !== r.last_name ||
                        user.phone !== r.phone ||
                        user.role.id !== r.role.id ||
                        user.permissions.flag.id !== r.permissions.flag.id ||
                        user.permissions.images.id !== r.permissions.images.id ||
                        user.permissions.reports.id !== r.permissions.reports.id ||
                        user.permissions.lines_csv_upload.id !== r.permissions.lines_csv_upload.id ||
                        user.permissions.stops_file_upload.id !== r.permissions.stops_file_upload.id ||
                        user.permissions.projects.id !== r.permissions.projects.id ||
                        user.permissions.schedules.id !== r.permissions.schedules.id ||
                        user.permissions.upload_report.id !== r.permissions.upload_report.id ||
                        user.permissions.users.id !== r.permissions.users.id) {
                        window.location.reload();
                    }
                },
                r => {
                    console.log(r.error);
                    this.router.navigateByUrl('login');
                });

            return true;
        }

        this.router.navigateByUrl('login');

        return false;
    }
}
