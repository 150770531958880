import {Component, OnInit, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import {Observable} from 'rxjs';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import 'moment/locale/he';
import {DateTimeAdapter, OwlDateTimeIntl, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE} from 'ng-pick-datetime';
import {MomentDateTimeAdapter} from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class';

const MY_CUSTOM_FORMATS = {
    fullPickerInput: 'DD-MM-YYYY HH:mm:ss',
    parseInput: 'DD-MM-YYYY HH:mm:ss',
    datePickerInput: 'YYYY-MM-DD HH:mm:ss',
    timePickerInput: 'YYYY-MM-DD HH:mm:ss',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
};

export class DefaultIntl extends OwlDateTimeIntl {
    cancelBtnLabel = 'Cancel';
    setBtnLabel = 'OK';
}

@Component({
    selector: 'datetimepicker',
    templateUrl: 'datetimepicker.component.html',
    styleUrls: ['datetimepicker.component.css'],
    providers: [
        {provide: OwlDateTimeIntl, useClass: DefaultIntl},
        {provide: OWL_DATE_TIME_LOCALE, useValue: 'he'},
        {provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE]},
        {provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS}
    ],
})

export class DateTimePicker implements OnInit, OnDestroy {

    @Input() header: string;
    @Input() error: string;
    @Output() result = new EventEmitter<any>();

    @Input() reset: Observable<any>;
    resetSubscription: any;

    @Output() dateChange = new EventEmitter<MatDatepickerInputEvent<any>>();

    date: any = undefined;

    updateDate(event) {
        const result = event.format('YYYY-MM-DDTHH:mm:ssZ');
        this.result.emit(result);
        this.error = null;
    }

    ngOnInit() {
        const self = this;
        if (this.reset) {
            this.resetSubscription = this.reset.subscribe((date: any) => self.date = (date ? date : undefined));
        }
    }

    ngOnDestroy() {
        if (this.reset && this.resetSubscription) {
            this.resetSubscription.unsubscribe();
        }
    }

}
