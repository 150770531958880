import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { Subject } from 'rxjs';
import { ApiService } from '../../core/service/api.service';
import { UserService } from '../../core/service/user.service';
import { UsersService } from '../../core/service/users.service';
import { getISO8601FromDate, getISO8601ToDate, top } from '../../global.component';
import { translateEnum } from '../../shared/enum/translateEnum';
import { Option } from '../../shared/interfaces/option';
import { IMAGES_PERMISSIONS } from '../../shared/models/permissions.model';
import { Photo } from '../../shared/models/photo.model';
import { User } from '../../shared/models/user.model';

@Component({
    templateUrl: 'photos.component.html',
    styleUrls: ['./photos.component.css', '../../app.component.css'],
    providers: [
        {provide: CarouselConfig}
    ]
})
export class PhotosComponent implements OnInit {

    public getISO8601FromDate = getISO8601FromDate;
    public getISO8601ToDate = getISO8601ToDate;

    readonly translateEnum = translateEnum;
    from_date: string;
    to_date: string;
    users: User[];
    selected_users: User[];
    stop_code: number;
    stop_type: Option;
    pages: Photo[][] = [];
    photos: Photo[] = [];
    disabled_search: boolean = false;
    disable_download: boolean = false;
    selected: number = 0;
    is_downloading: boolean = false;

    reset_user_subject: Subject<void> = new Subject<void>();
    users_subject: Subject<any> = new Subject<any>();
    reset_from_date_subject: Subject<string> = new Subject<string>();
    reset_to_date_subject: Subject<string> = new Subject<string>();

    // pagination
    page: number = 1;
    total_rows: number = 0;

    search_fields: {
        from_date: string
        to_date: string,
        stop_code: number,
        user_ids: string[],
        stop_type: Option
    } = null;

    constructor(private api: ApiService, private usersService: UsersService, public userService: UserService) {
        this.userService.pagePermission(IMAGES_PERMISSIONS);
        this.getUsers();

        this.search = this.search.bind(this);
        this.getPhotos = this.getPhotos.bind(this);
        this.selectStopType = this.selectStopType.bind(this);
        this.getDownloadPhotos = this.getDownloadPhotos.bind(this);
        this.onSelected = this.onSelected.bind(this);
        this.resetSelected = this.resetSelected.bind(this);
        this.isDownloading = this.isDownloading.bind(this);
    }

    ngOnInit(): void {
        this.initFields();
    }

    ngAfterViewInit() {
        const today = moment().format('YYYY-MM-DD');
        this.reset_from_date_subject.next(today);
        this.reset_to_date_subject.next(today);
    }

    search() {
        if (this.disabled_search) {
            return;
        }
        this.getFirstPage();
    }

    getPhotos(page, go_to_top = true) {
        const self = this;
        self.disabled_search = true;

        if (go_to_top) {
            top('photos_header');
        }

        if (page <= self.pages.length) {
            self.page = page;
            self.photos = self.pages[page - 1];
            self.disabled_search = false;
            self.total_rows = self.photos.length + 1;
            return;
        }

        let user_ids = null;
        if (this.selected_users.length > 0) {
            user_ids = this.selected_users.map((user: User) => user.id).join(',');
        }

        this.search_fields = {
            from_date: this.from_date,
            to_date: this.to_date,
            stop_code: this.stop_code,
            user_ids,
            stop_type: this.stop_type
        };

        this.disableDownload();

        self.api.photos(page, this.from_date, this.to_date, this.stop_code, user_ids, this.stop_type)
            .subscribe(
                r => {

                    if (r.length === 0) {

                        if (page === 1) {
                            self.total_rows = 0;
                            self.photos = [];
                            self.pages.push([]);
                            self.page = page;
                        } else {
                            self.photos = [self.pages[page - 2][0]];
                            self.total_rows = 1;
                            self.page = page;
                        }

                    } else {
                        self.total_rows = r.length;
                        if (r.length > 10) {
                            r.pop();
                        }
                        self.page = page;
                        self.photos = r;
                        self.pages.push(r);
                    }

                },
                r => {
                    console.log(r);
                },
                () => {
                    self.disabled_search = false;
                });
    }

    // get all users from server
    getUsers() {
        this.users = [];
        if (this.usersService) {
            this.usersService.getUsers(false).subscribe(users => {
                this.users = users;
                this.users_subject.next(users);
            });
            this.usersService.refresh();
        }
    }

    // set selected user in drop down
    selectUsers(users: User[]) {
        this.selected_users = users;
    }

    onFromDate(date: string) {
        this.from_date = this.getISO8601FromDate(date);
        this.reset_from_date_subject.next(date);
    }

    onToDate(date) {
        this.to_date = this.getISO8601ToDate(date);
    }

    initFields() {
        const today: string = moment().format('YYYY-MM-DD');

        this.onFromDate(today);
        this.reset_from_date_subject.next(today);

        this.onToDate(today);
        this.reset_to_date_subject.next(today);

        this.selected_users = [];
        this.reset_user_subject.next();

        this.stop_code = null;
        this.stop_type = null;

        this.getFirstPage();
    }

    getFirstPage() {
        this.pages = [];
        this.page = 0;
        this.selected = 0;
        this.getPhotos(1, false);
    }

    selectStopType(event: Option) {
        this.stop_type = event;
    }

    async getDownloadPhotos() {
        if (this.selected) {
            return this.pages.reduce((acc: Photo[], page: Photo[]) => {
                return acc.concat(page.filter((photo: Photo) => photo.selected));
            }, []);
        } else {
            const self = this;
            let page = self.pages.length + 1;
            let has_more = true;

            do {
                const page_photos: Photo[] = await (self.api.photos(
                    page,
                    this.search_fields.from_date,
                    this.search_fields.to_date,
                    this.search_fields.stop_code,
                    this.search_fields.user_ids,
                    this.search_fields.stop_type
                ).toPromise());

                if (page_photos.length > 10) {
                    has_more = true;
                    page_photos.pop();
                    page++;
                } else {
                    has_more = false;
                }
                if (page_photos.length) {
                    self.pages.push(page_photos);
                }

            } while (has_more);

            return this.pages.reduce((acc: Photo[], page: Photo[]) => {
                return acc.concat(page);
            }, []);
        }
    }

    isDownloading(status: boolean) {
        this.is_downloading = status;
    }

    onSelected() {
        this.selected = this.pages.reduce((acc: number, page: Photo[]) => {
            return acc + page.filter((photo: Photo) => photo.selected).length;
        }, 0);
        this.disableDownload();
    }

    disableDownload() {
        this.disable_download = (!this.from_date || !this.to_date) && this.selected === 0;
    }

    resetSelected() {
        this.selected = 0;
        this.disableDownload();
        this.pages.forEach((page: Photo[]) => {
            page.forEach((photo: Photo) => {
                photo.selected = false;
            });
        });
    }
}
