import {Component, Input} from '@angular/core';

@Component({
    selector: 'page-loading',
    templateUrl: 'page-loading.component.html',
    styleUrls: ['page-loading.component.css', '../../../../app.component.css'],
})
export class PageLoadingComponent {
    @Input() public loading: boolean = false;
    @Input() public title: string = null;

    frameStyle() {
        let width = window.innerWidth;
        let left = -30;
        if (window.innerWidth > 990) {
            const container = document.getElementById(`container-fluid`);
            width = container.clientWidth;
            left = 30;
        }
        return {'width': `${width}px`, left: `${left}px`};
    }
}
