import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { BaseModule } from "../base/base.module";
import { BaseComponent } from "./base.component";
import { PhotosRoutingModule } from "./photos-routing.module";
import { PhotosComponent } from "./photos.component";

@NgModule({
  imports: [
    FormsModule,
    PhotosRoutingModule,
    BsDropdownModule,
    BsDatepickerModule.forRoot(),
    CommonModule,
    CarouselModule,
    MatNativeDateModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatButtonModule,
    BaseModule,
  ],
  declarations: [BaseComponent, PhotosComponent],
})
export class PhotosModule {}
