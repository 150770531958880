import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseComponent } from '../../../../base.component';
import { translateEnum } from '../../../../shared/enum/translateEnum';
import { Option } from '../../../../shared/interfaces/option';
import { ValidationErrors } from '../../../../shared/interfaces/validation-errors';
import { Report } from '../../../../shared/models/report.model';
import { Stop } from '../../../../shared/models/stop.model';
import { User } from '../../../../shared/models/user.model';

@Component({
    selector: 'edit-form',
    templateUrl: 'edit-form.component.html',
    styleUrls: ['../../../../app.component.css', './edit-form.component.css']
})

export class EditFormComponent extends BaseComponent {

    @Input() public report: Report;
    @Input() public users: User[] = [];
    @Input() public validation_errors: ValidationErrors = {};
    @Input() public new_report: boolean = false;
    @Input() public stop_codes_limit: number[] = [];

    translateEnum = translateEnum;

    /** events sent from OUTER -> into COMPONENT to update item **/
    stopSubject: Subject<any> = new Subject<any>();
    resetDateSubject: Subject<void> = new Subject<void>();

    constructor() {
        super();
        this.onChangeFlag = this.onChangeFlag.bind(this);
        this.selectStopType = this.selectStopType.bind(this);
    }

    ngOnChanges(changes): void {
        if (changes.report && changes.report.currentValue) {
            const self = this;
            const report = changes.report.currentValue;

            self.report = report;

            setTimeout(function () {
                self.stopSubject.next(report.stop.stop_code);
                self.resetDateSubject.next(report.date);
            }, 300);
        }
        if (changes.users && changes.users.currentValue) {
            this.users = changes.users.currentValue;
        }
        if (this.users.length > 0 && this.report) {
            const user = this.users.find((user: User) => user.id === this.report.user);
            this.report.setUser(user);
        }
    }

    showMap() {
        return this.report && this.report.stop && this.report.stop.stop_lat && this.report.stop.stop_lon;
    }

    /** set selected stop from server-side-search component event **/
    selectStopCode(event: Stop) {
        if (event) {
            this.report.stop = event;
            if (this.stop_codes_limit.indexOf(event.stop_code) > -1) {
                this.validation_errors['stop_code'] = translateEnum.STATION_CODE_EXIST;
            } else {
                this.validation_errors['stop_code'] = undefined;
            }
        }
    }

    selectStopType(event: Option) {
        this.report.setStopType(event);
        if (this.report.num_of_stations === undefined) {
            this.report.setNumOfStations(0);
        }
    }

    selectDate(date) {
        this.report.date = date;
    }

    selectUser(user) {
        this.report.setUser(user);
    }

    selectStaticPoster(event: Option) {
        this.report.static_poster.setStatus(event && event.id);
    }

    onChangeFlag(checked) {
        this.report.new_flag_required = checked;
    }

}
