import { Component, Input } from "@angular/core";
import { translateEnum } from "../../../shared/enum/translateEnum";

@Component({
  selector: "title-chip",
  templateUrl: "title-chip.component.html",
  styleUrls: ["title-chip.component.css"],
})
export class TitleChipComponent {
  readonly translateEnum = translateEnum;

  @Input() title: string;
  @Input() background_color: string;
  @Input() round: boolean;
  @Input() font_size: string = "smaller";
  @Input() disabled: boolean = true;
  @Input() text_decoration: string = null;
}
