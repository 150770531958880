import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { User } from '../../shared/models/user.model';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    public observe: Subject<User[]> = new Subject();
    public users: User[] = [];
    public load: boolean = false;

    constructor(private api: ApiService, public router: Router) {
    }

    loadUsers() {
        this.load = true;

        this.api.users()
            .subscribe(
                r => {
                    this.observe.next(r);
                    this.users = r;
                },
                r => {
                    console.log(r.error);
                });
    }

    getUsers(request) {
        if (!this.load || request) {
            this.loadUsers();
        }
        return this.observe.asObservable();
    }

    refresh() {
        this.observe.next(this.users);
    }

}
