import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { finalize, map, take } from 'rxjs/operators';
import { ApiService } from '../../core/service/api.service';
import { UserService } from '../../core/service/user.service';
import { UsersService } from '../../core/service/users.service';
import { getISO8601FromDate, getISO8601ToDate, top } from '../../global.component';
import { EXPORT_TYPE } from '../../shared/enum/export';
import { translateEnum } from '../../shared/enum/translateEnum';
import { Option } from '../../shared/interfaces/option';
import { ReportInput } from '../../shared/interfaces/report';
import { REPORTS_PERMISSIONS } from '../../shared/models/permissions.model';
import { Photo } from '../../shared/models/photo.model';
import { Report } from '../../shared/models/report.model';
import { Route } from '../../shared/models/route.model';
import { User } from '../../shared/models/user.model';
import { ReportFlag } from './reports';
import { ReportsService } from './reports.service';

@Component({
  templateUrl: "reports.component.html",
  styleUrls: ["./reports.component.css", "../../app.component.css"],
})
export class ReportsComponent implements OnInit {
  getISO8601FromDate = getISO8601FromDate;
  getISO8601ToDate = getISO8601ToDate;
  translateEnum = translateEnum;

  EXPORT_TYPE = EXPORT_TYPE;

  users: User[];
  reportsTable: Report[] = [];
  reportsTable$: Observable<Report[]>;

  // search fields
  selectedUsers: User[];
    selectedoperatorInCharge: any[];
    selectedAreas: any[];
  stopCode: number;
    operatorInCharge: any[];
    areas: any[];
  stopType: Option;
  fromDate: string;
  toDate: string;
  staticPosterSelectedItems: any = [];
  nextBusSelectedItems: any = [];
  barilSelectedItems: any = [];
  flagSelectedItems: any = [];
  full_list: boolean = false;
  new_flag_required: boolean = false;
    searchAction:boolean=false;

  // disable buttons
  disabled_search: boolean = false;

  // table view columns
  showStopAddress: boolean = false;
  showDate: boolean = false;
  showUserName: boolean = false;
  showStaticStatus: boolean = false;
  showStaticReason: boolean = false;

  // pagination
  page: number = 1;
  totalRows: number = 0;

  report$: Subject<ReportInput> = new Subject<ReportInput>();
  currentReport$: Subject<Route[]> = new Subject<Route[]>();
  resetUserSubject: Subject<void> = new Subject<void>();
  usersSubject: Subject<any> = new Subject<any>();
    resetOperatorInChargeSubject: Subject<void> = new Subject<void>();
    operatorInChargeSubject: Subject<any> = new Subject<any>();
    resetAreasSubject: Subject<void> = new Subject<void>();
    areasSubject: Subject<any> = new Subject<any>();
  resetFromDateSubject: Subject<void> = new Subject<void>();
  resetToDateSubject: Subject<void> = new Subject<void>();

  imageSubject: Subject<Photo> = new Subject<Photo>();

  @ViewChild("reportModal", { static: false }) reportModal: ModalDirective;

  // delete report warning params
  removeReport: Report;
  deleteReportObserve: Function;
  deleteSuccessSubject: Subject<void> = new Subject<void>();
  deleteErrorSubject: Subject<void> = new Subject<void>();
  contentWarning: string;

  write_permission: boolean = false;

  constructor(
    private api: ApiService,
    private usersService: UsersService,
    public userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private reportsService: ReportsService
  ) {
    debugger;
    this.userService.pagePermission(REPORTS_PERMISSIONS);
    const user = this.userService.getUser();
    this.write_permission = user.permissions[REPORTS_PERMISSIONS].write;

    this.search = this.search.bind(this);
    this.resetFields = this.resetFields.bind(this);
    this.getFields = this.getFields.bind(this);
    this.deleteReportObserve = this.deleteReport.bind(this);
    this.onChangeCheckFullList = this.onChangeCheckFullList.bind(this);
    this.onChangeCheckFlagRequired = this.onChangeCheckFlagRequired.bind(this);
    this.updateReports = this.updateReports.bind(this);
    this.getPage = this.getPage.bind(this);
    this.selectStopType = this.selectStopType.bind(this);
    this.getFields = this.getFields.bind(this);

        this.getOperatorsInCharge()
        this.getAreas();
    this.getUsers();
    this.tableView();
  }

  ngOnInit(): void {
    this.resetFields(false);
    const stop_code = this.route.snapshot.queryParams["stop_code"];
    if (stop_code) {
      this.stopCode = +stop_code;
      this.full_list = true;
      this.router
        .navigate(["."], { relativeTo: this.route, queryParams: {} })
        .then((r) => {});
    }
    this.search();
  }

  openExport(modal) {
    modal.show();
  }

  // set table view by window width
  tableView() {
    const width = window.innerWidth;

    if (width >= 350) {
      this.showDate = true;
    }
    if (width >= 550) {
      this.showUserName = true;
    }
    if (width >= 750) {
      this.showStopAddress = true;
    }
    if (width >= 900) {
      this.showStaticStatus = true;
    }
    if (width >= 1000) {
      this.showStaticReason = true;
    }
  }

  search() {
    if (this.disabled_search) {
      return;
    }
        
        this.page = 0;
        this.getPage(1);
    }

  getFields(): ReportFlag {
    let user_ids = null;
    if (this.selectedUsers.length > 0) {
      user_ids = this.selectedUsers.map((user: User) => user.id).join(",");
    }
        let operators = null;
        if (this.selectedoperatorInCharge.length > 0) {
            operators = this.selectedoperatorInCharge.map((stop: any) => stop._id).join(',');
        }
        let areas = null;
        if (this.selectedAreas.length > 0) {
            areas = this.selectedAreas.map((stop: any) => stop._id).join(',');
        }
    return {
      from_date: this.fromDate,
      to_date: this.toDate,
      stop_codes: this.stopCode ? [this.stopCode] : null,
      user_ids,
      full_list: this.full_list,
      new_flag_required: this.new_flag_required,
      flag: this.flagSelectedItems,
      brail: this.barilSelectedItems,
      static_poster: this.staticPosterSelectedItems,
      next_bus: this.nextBusSelectedItems,
      stop_type: this.stopType,
      operators:     operators,
      areas:      areas,
      search: this.searchAction
    };
  }

  getPage(page) {
    const self = this;
    const limit = 50;

    const data = self.getFields();

    data["page"] = page;
    data["limit"] = limit;

    self.disabled_search = true;
    top("search-top");

    self.reportsTable$ = this.api.getReports(data).pipe(
        map((rows) => {
            self.totalRows = rows.length;
            if(rows.length > limit)
                rows.pop();
            
            self.page = page;
            return rows;
        }),
        finalize(() => self.disabled_search = false)
    );
  }

  // get all users from users service
  getUsers() {
    this.users = [];
    debugger;
    if (this.usersService) {
      this.usersService.getUsers(false).subscribe((users) => {
        this.users = users;
        this.usersSubject.next(users);
      });
      this.usersService.refresh();
    }
  }

    getOperatorsInCharge() {
        this.operatorInCharge = [];
        this.api.getStopsOperatorsInCharge(null)
        .subscribe(data => {
            this.operatorInCharge = data;
            this.operatorInChargeSubject.next(this.operatorInCharge);
        });
    }
    getAreas() {
        this.areas = [];
        this.api.getAreas(null)
        .subscribe(data => {
            this.areas = data;
            this.areasSubject.next(this.areas);
        });
    }

  // set selected user in drop down
  selectUsers(users: User[]) {
    this.selectedUsers = users;
  }

    selectOperatorInCharge(operatorInCharge: any[]) {
        this.selectedoperatorInCharge = operatorInCharge;
    }
    selectAreas(areas: any[]) {
      
        this.selectedAreas = areas;
    }

  selectStopType(event: Option) {
    this.stopType = event;
  }
   
    

  onFromDate(date) {
    this.fromDate = this.getISO8601FromDate(date);
  }

  onToDate(date) {
    this.toDate = this.getISO8601ToDate(date);
  }

  viewReport(report: Report, modal) {
    const { stop_code } = report.stop;
    localStorage.stopcode = stop_code;
    this.reportsService
      .getCurrentReport(stop_code)
      .pipe(
        take(1)
      )
      .subscribe({
        next: (data: Route[]) => {
          // Append another row each represents the stop name as the first row of the array (which should evetually be displayed at the stop-table table comp).
          // The new row should be the first item in the array.
          if('stop_name' in data[0]) {
            const firstRow = JSON.parse(JSON.stringify(data[0])) as Route & Record<"stop_name", string>;
            const stop_name: string = firstRow.stop_name;
            firstRow.route = stop_name;
            firstRow.operator.operator_name = '';
            Object.freeze(firstRow);
            data.unshift(firstRow);
          }
          this.currentReport$.next(data);
        }
      });
    this.report$.next({
      report,
      shift: null,
    });
    modal.show();
  }

  onSelectEventFlag(data) {
    this.flagSelectedItems = data;
  }

  onSelectEventBaril(data) {
    this.barilSelectedItems = data;
  }

  onSelectEventNextBus(data) {
    this.nextBusSelectedItems = data;
  }

  onSelectEventStaticPoster(data) {
    this.staticPosterSelectedItems = data;
  }

  resetFields(do_search: boolean = true) {
    this.fromDate = null;
    this.resetFromDateSubject.next();

    this.toDate = null;
    this.resetToDateSubject.next();

    this.selectedUsers = [];
    this.resetUserSubject.next();

        this.selectedoperatorInCharge = [];
        this.resetOperatorInChargeSubject.next();

        this.selectedAreas = [];
        this.resetAreasSubject.next();

    this.stopCode = null;

    this.staticPosterSelectedItems = [];
    this.nextBusSelectedItems = [];
    this.barilSelectedItems = [];
    this.flagSelectedItems = [];

    this.full_list = false;
    this.new_flag_required = false;

    this.stopType = null;

    if (do_search) {
      this.search();
    }
  }

  deleteReportWarning(modal, report) {
    this.removeReport = report;
    this.contentWarning =
      this.translateEnum.DELETE_REPORT_CONTENT_WARNING.replace(
        "[stop_code]",
        report.stop.stop_code
      );
    modal.show();
  }

  deleteReport(report) {
    const self = this;

    this.api.deleteReport(report.id).subscribe(
      (r) => {
        self.deleteSuccessSubject.next();
        self.getPage(self.page);
      },
      (r) => {
        self.deleteErrorSubject.next(r.error);
      }
    );
  }

  onChangeCheckFullList(checked) {
    this.full_list = checked;
  }

  onChangeCheckFlagRequired(checked) {
    this.new_flag_required = checked;
  }

  /** update report object in reports table after editing **/
  updateReports(editReport: Report) {
    for (let i = 0; i < this.reportsTable.length; i++) {
      if (this.reportsTable[i].id === editReport.id) {
        this.reportsTable[i] = editReport;
        return;
      }
    }
  }

  
  
}
