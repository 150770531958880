import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatNativeDateModule, MatOptionModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { AlertModule } from "ngx-bootstrap/alert";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { ReportsComponent } from "../../../app/views/reports/reports.component";
import { ApiService } from "../../core/service/api.service";
import { UserService } from "../../core/service/user.service";
import { UsersService } from "../../core/service/users.service";
import { BaseModule } from "../base/base.module";
import { BaseComponent } from "./base.component";
import { ReportsRoutingModule } from "./reports-routing.module";
import { ReportsService } from "./reports.service";

@NgModule({
  imports: [
    FormsModule,
    ReportsRoutingModule,
    BsDropdownModule,
    BsDatepickerModule.forRoot(),
    CommonModule,
    MatNativeDateModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    CarouselModule,
    MatCheckboxModule,
    PaginationModule,
    ModalModule,
    MatIconModule,
    AlertModule,
    MatRadioModule,
    MatMenuModule,
    MatButtonModule,
    MatSelectModule,
    MatOptionModule,
    BaseModule,
  ],
  declarations: [BaseComponent, ReportsComponent],
  providers: [ApiService, UsersService, UserService, ReportsService],
  exports: [],
})
export class ReportsModule {}
