import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output ,ViewChild} from "@angular/core";
import * as cloneDeep from "lodash/cloneDeep";
import { CarouselConfig } from "ngx-bootstrap/carousel";
import { ModalDirective } from "ngx-bootstrap/modal";
import { Observable, Subject, Subscription } from "rxjs";
import { map, take } from "rxjs/operators";
import { ApiService } from "../../../core/service/api.service";
import { UserService } from "../../../core/service/user.service";
import { UsersService } from "../../../core/service/users.service";
import { top } from "../../../global.component";
import { translateEnum } from "../../../shared/enum/translateEnum";
import { Header } from "../../../shared/interfaces/header";
import { ReportInput } from "../../../shared/interfaces/report";
import { ValidationErrors } from "../../../shared/interfaces/validation-errors";
import { Photo } from "../../../shared/models/photo.model";
import { Report } from "../../../shared/models/report.model";
import { Route } from "../../../shared/models/route.model";
import { Shift } from "../../../shared/models/shift.model";
import { Task } from "../../../shared/models/task.model";
import { User } from "../../../shared/models/user.model";
import { brailStatuses } from "../../../shared/options/brail";
import { flagStatuses } from "../../../shared/options/flag";
import { TaskButtonUpgradeComponent } from "./task-button-upgrade/task-button-upgrade.component";
import { TasksComponent } from "../tasks/tasks.component";
import { Line } from "../../../../app/shared/models/line.model";
import { HttpClient } from "@angular/common/http";
import { ReportsService } from '../../reports/reports.service';

@Component({
  selector: "report",
  templateUrl: "report.component.html",
  styleUrls: ["./report.component.css", "../../../app.component.css"],
  providers: [{ provide: CarouselConfig }],
  
})
export class ReportComponent implements OnInit, OnDestroy {

    @Input() public reportModal: ModalDirective;
    @Input() public close: Function = null;
    @Input() public write_permission: boolean = false;
    @Input() public taskupgrade: boolean = false;
    @Input() public source: string = "";
    @Input() public traffic_id: string = "";
    @Input() public next: boolean = false;
    @Input() public prev: boolean = false;
    
    @ViewChild(TaskButtonUpgradeComponent) taskbuttonupgrade: TaskButtonUpgradeComponent;
    @ViewChild(TasksComponent) taskscomponent: TasksComponent;
    


  readonly top = top;
  readonly translateEnum = translateEnum;
  readonly brailStatuses = brailStatuses;
  readonly flagStatuses = flagStatuses;

  aiReport:any;
  report: Report;
  shift: Shift;
  users: User[] = [];
    jobType: string;
  stop_codes_limit: number[] = [];
  @Output() closethemodal = new EventEmitter;
  @Output() nextthemodal = new EventEmitter;
  @Output() nexterrorthemodal = new EventEmitter;
  @Output() prevthemodal = new EventEmitter;

  historyHeader: Header[] = [
    { key: "date", title: translateEnum.DATE },
    { key: "comment", title: translateEnum.CHANGE },
    { key: "changedBy", title: translateEnum.PERFORMER },
  ];

  commentsHeader: Header[] = [
    { key: "datetime", title: translateEnum.COMMENT_DATE },
    { key: "text", title: translateEnum.COMMENT },
  ];

  auditsHeader: Header[] = [
    { key: "date", title: translateEnum.DATE },
    { key: "user", title: translateEnum.AUDIT },
    { key: "comment", title: translateEnum.CONTROL_COMMENTS },
  ];

  @Input() report$: Observable<ReportInput>;
  @Input() currentReport$: Observable<Route[]>;
  currentReportData: any[] =[];
  reportSubscription: Subscription;
  stop_code: number;
  taskmargin:string = '0px';
  @Input() imageSubject: Subject<Photo>;

  editReport: Report = null;
  @Input() updateReports: Function;
  editLoading: boolean = false;
  error: string = null;

  new_report: boolean = false;
  show_ddl_traffic:boolean=false;
  task_upgrade_flag:boolean=false;
  trafficStatus:string='1';
  action:string='0';

  getcurrentReportData:boolean = true;

  validation_errors: ValidationErrors = {};
  flagDataLines: any;
  flagDataDesc: any;
  flagDataLinesDB: any;
  flagDataDescDB: any;
  combinedStatic:any;
  combinedBrail:any

  brailDataDB: any;
  brailData: any;

  staticDataDB: any;
  staticData: any;

  combinedDesc: any;
  combinedLiens: { db: string; ai: string; }[];

  brailDataLinesDB: any;
  brailDataDesc: any;

  staticDataLinesDB: any;
  staticDataDesc: any;

  showTableFlag:false;
  showTableBrail:false;
  showTableStatic:false;

  constructor(
    
    private api: ApiService,
    private usersService: UsersService,
    public userService: UserService, private changes: ChangeDetectorRef
    , private http: HttpClient,  private reportsService: ReportsService
  ) {
    
    this.getUsers();
  
  }

  ngOnInit(): void {
    debugger;
    this.getcurrentReportData=true;
    if (this.report$) {
      this.reportSubscription = this.report$.subscribe((data: ReportInput) =>
      {
        this.setReport(data)
        debugger;
      }
      );
    }
    if(this.taskupgrade==true)
    {
    this.task_upgrade_flag=true;
    this.taskmargin='0px'
    }
    if(this.source=="traffic")
    {
      
      this.show_ddl_traffic=true;
    }

  
    
  }

  currentReport(){
   
    if(this.stop_code != localStorage.stopcode)
    {
      this.stop_code = localStorage.stopcode;

      this.getcurrentReportData=false;
      this.reportsService
      .getCurrentReport(this.stop_code)
      .pipe(
        take(1)
      )
      .subscribe({
        next: (data: Route[]) => {
          // Append another row each represents the stop name as the first row of the array (which should evetually be displayed at the stop-table table comp).
          // The new row should be the first item in the array.
          if('stop_name' in data[0]) {
            const firstRow = JSON.parse(JSON.stringify(data[0])) as Route & Record<"stop_name", string>;
            const stop_name: string = firstRow.stop_name;
            firstRow.route = stop_name;
            firstRow.operator.operator_name = '';
            Object.freeze(firstRow);
            data.unshift(firstRow);
          }
          this.currentReportData = data;
          return this.currentReportData;
        }
      });
    
  //  return this.currentReport$;
   
  }
    else{

    }
  }

  ngOnDestroy() {
    if (this.report$ && this.reportSubscription) {
      this.reportSubscription.unsubscribe();
    }
  }

  setTasks(stop_code: number, shift: Shift) {
    this.stop_code = stop_code;
    this.shift = shift;
  }

  onTaskEdit(eventData: { shift_id: string,stop_code:number,task:{},i:number }) {
    debugger;
    document.getElementById('task-button').click();
    this.taskmargin='20%'
    localStorage.editMode=true;
    localStorage.eventData=JSON.stringify(eventData);

  };


  onDelete(eventData: { shift_id: string,stop_code:number,task:{},i:number }) {
    debugger;
    let confirmAction = confirm("האם למחוק פעולה לביצוע?");
    if (confirmAction) {
    this.api.deleteTask(eventData).subscribe(
      r => {
        debugger;
        this.onGetShifts(eventData);

      },
      r => {
      });
    }
    
    this.taskmargin='20%'


  };

  combineArrays(arr1: string, arr2: string): { db: string, ai: string }[] {
    const combined = [];
  
    arr1=arr1.replace('[','').replace(']','');
    arr2=arr2.replace('[','').replace(']','');
    // Loop through both arrays
      const wordsArr1 = arr1 ? arr1.split(',') : [];
      const wordsArr2 = arr2 ? arr2.split(',') : [];
  
      const maxLength = Math.max(wordsArr1.length, wordsArr2.length);
  
      // Loop through the words in the current strings and combine them
      for (let j = 0; j < maxLength; j++) {
        combined.push({
          db: wordsArr2[j] || '', // Use empty string if value is missing
          ai: wordsArr1[j] || ''
        });
      }
    
  
    return combined;
  }

  isInArray(value: string, array: string[]): boolean {
    return array.includes(value);
  }

  setReport(data: ReportInput) {
    const report = data.report;
    const shift = data.shift;

    const self = this;

    if(localStorage.aiReport!=undefined)
    {
      this.aiReport=JSON.parse(localStorage.aiReport);
      if((this.aiReport).flagData!=undefined)
      {
      //  if(this.aiReport.flagData.lines!=undefined)
      //    this.flagDataLines = (this.aiReport.flagData.lines);
      //  if(this.aiReport.flagData.linesDB!=undefined)
      //    this.flagDataLinesDB = (this.aiReport.flagData.linesDB);
      //  if(this.aiReport.flagData.desc!=undefined)
       //   this.flagDataDesc = (this.aiReport.flagData.desc);
      //  if(this.aiReport.flagData.descDB!=undefined)
      //    this.flagDataDescDB = (this.aiReport.flagData.descDB);
      debugger;
      if(this.aiReport.flagData.both!=undefined)
      {
        var jsonString = (this.aiReport.flagData.both);
        this.flagDataDesc = JSON.parse(jsonString);
      }
   
    
      }
      if((this.aiReport).brailData!=undefined)
      {
        if(this.aiReport.brailData.lines!=undefined)
          this.brailData = (this.aiReport.brailData.lines);
        if(this.aiReport.brailData.linesDB!=undefined)
          this.brailDataDB = (this.aiReport.brailData.linesDB);
      }
      if((this.aiReport).staticData!=undefined)
        {
          if(this.aiReport.staticData.lines!=undefined)
            this.staticData = (this.aiReport.staticData.lines);
          if(this.aiReport.staticData.linesDB!=undefined)
            this.staticDataDB = (this.aiReport.staticData.linesDB);
        }
    }
  
    debugger;
 

    if(this.staticData!=undefined && this.staticDataDB !=undefined)      
      this.combinedStatic = this.combineArrays(this.staticData, this.staticDataDB);

    if(this.brailData!=undefined && this.brailDataDB !=undefined)      
      this.combinedBrail = this.combineArrays(this.brailData, this.brailDataDB);

    self.report = report;
    self.shift = shift;

    if (!report.id.length) {
      self.new_report = true;
      self.editReport = cloneDeep(report);
      self.editReport.setUser(this.userService.getUser());
    } else {
      self.new_report = false;
      self.editReport = null;
    }

    this.action = self.report.trafficaction;
    this.trafficStatus = self.report.trafficstatus;
    /*if (shift) {
            self.stop_codes_limit = shift.stops.map(stop => stop.stop_code);
        } else {
            self.stop_codes_limit = [];
        }*/
    self.stop_codes_limit = [];

    self.validation_errors = {};
    
    self.editLoading = false;
    self.error = null;
        debugger;
    self.shift = shift;
    if (!shift && report.shift) {
      self.api.getShift(report.shift).subscribe(
        (r) => {
          debugger;
          self.shift = r;
          const {
            stop: { stop_code },
          }: { stop: { stop_code: number } } = report;
          self.setTasks(stop_code, r);
        },
        (r) => {

          
        }
      );
    }
    setTimeout(function () {
      self.report = report;
      if (!self.new_report) {
        self.report.getHistory();
        self.report.getAuditsByReport();
        self.report.getComments();
      }
            self.changes.detectChanges();
    }, 300);
  }

    // get all users from server
    getUsers() {
        this.users = [];
        if (this.usersService) {
            this.usersService.getUsers(false).subscribe(users => {
                this.users = users;
            });
            this.usersService.refresh();
        }
    }


    onGetShifts(eventData: {shift_id:string,stop_code:number}){
        this.api.getShift(eventData.shift_id).subscribe(
          (r) => {
            this.shift = r;
            this.setTasks(eventData.stop_code, r);
            this.taskscomponent.tasks=r.tasks[eventData.stop_code];
          },
          (r) => {}
        );
      
    }


    

    closeModal(modal: ModalDirective) {
      debugger;
      if(modal!=undefined)
      modal.hide();
      this.closethemodal.emit('a');
      
        const self = this;
        
        return () => new Promise(() => {
          if(modal!=undefined)
          modal.hide();
            if (self.close) {
                self.close();
            }
          
        });
    }


    nextErrorModal(modal: ModalDirective) {
      debugger;
      if(this.traffic_id!="")
      {
      var res = this.api.updateTrafficStatus(this.report.id,this.action ,this.trafficStatus,this.traffic_id,JSON.parse(localStorage.USER).first_name +' '+JSON.parse(localStorage.USER).last_name).subscribe(
        r => {
          localStorage.projectId=this.traffic_id;
          this.nexterrorthemodal.emit('a');
        });
      }
      else
        this.nexterrorthemodal.emit('a');
    }

    nextModal(modal: ModalDirective) {
      debugger;
      if(this.traffic_id!="")
      {
      var res = this.api.updateTrafficStatus(this.report.id,this.action ,this.trafficStatus,this.traffic_id,JSON.parse(localStorage.USER).first_name +' '+JSON.parse(localStorage.USER).last_name).subscribe(
        r => {
          localStorage.projectId=this.traffic_id;
          this.nextthemodal.emit('a');
        });
      }
      else
        this.nextthemodal.emit('a');
    }

    prevModal(modal: ModalDirective) {
      debugger;
      if(this.traffic_id!="")
      {
      var res = this.api.updateTrafficStatus(this.report.id,this.action ,this.trafficStatus,this.traffic_id,JSON.parse(localStorage.USER).first_name +' '+JSON.parse(localStorage.USER).last_name).subscribe(
        r => {
          localStorage.projectId=this.traffic_id;
          this.prevthemodal.emit('a');
        });
      }
      else
        this.prevthemodal.emit('a');
    }

    

  onClickEdit() {
    this.editReport = cloneDeep(this.report);
  }

  closeEditing() {
    this.editReport = null;
  }

  getFlagData(): Observable<Route[]> {
    return this.report$.pipe(
      map(({ report }: { report: Report }) => {
        const data: Report = !!this.editReport ? this.editReport : report;
        return data.flag;
      })
    );
  }

  getBrailData() {
    if (this.report) {
      const data = !!this.editReport ? this.editReport : this.report;
      return data.brail;
    }
    return null;
  }

  getCurrentBraille() {
   
    return this.currentReport$.pipe(
      map((routes: Route[]) => {
        const brailleRoutes: Route[] = [];
        routes.map((route: Route & { braille: string }) => {
          if ("braille" in route && route.braille) {
            route.route = route.braille;
            brailleRoutes.push(route);
          }
        });

        return brailleRoutes;
      })
    );
  }

  
 

  validation() {
    this.validation_errors = {};

    if (!this.editReport.stop.stop_code) {
      this.validation_errors.stop_code = translateEnum.FIELD_REQUIRED;
    } else if (
      this.stop_codes_limit.indexOf(this.editReport.stop.stop_code) > -1
    ) {
      this.validation_errors.stop_code = translateEnum.STATION_CODE_EXIST;
    }

    if (this.editReport.stop_type === undefined) {
      this.validation_errors.stop_type = translateEnum.FIELD_REQUIRED;
    }

    if (!this.editReport.date) {
      this.validation_errors.date = translateEnum.FIELD_REQUIRED;
    }

    if (!this.editReport.user) {
      this.validation_errors.user = translateEnum.FIELD_REQUIRED;
    }

    if (this.editReport.flag) {
      for (let i = 0; i < this.editReport.flag.length; i++) {
        const item: Route = this.editReport.flag[i];
        if (!item.route || !item.route.trim().length) {
          this.validation_errors[`flags-table-${i}`] =
            translateEnum.FIELD_REQUIRED;
        }
      }
    }

    if (this.editReport.brail) {
      for (let i = 0; i < this.editReport.brail.length; i++) {
        const item: Route = this.editReport.brail[i];
        if (!item.route || !item.route.trim().length) {
          this.validation_errors[`brail-table-${i}`] =
            translateEnum.FIELD_REQUIRED;
        }
      }
    }
  }

  getData() {
    const data = {};

    if (
      this.report.stop.stop_code !== this.editReport.stop.stop_code ||
      this.new_report
    ) {
      data["stop_code"] = this.editReport.stop.stop_code;
    }

    if (this.report.stop_type !== this.editReport.stop_type) {
      data["stop_type"] = this.editReport.stop_type;
    }

    if (
      this.editReport.isMultipleSheds() &&
      this.report.num_of_stations !== this.editReport.num_of_stations
    ) {
      data["num_of_stations"] = this.editReport.num_of_stations;
    }

    if (this.report.date !== this.editReport.date) {
      data["date"] = this.editReport.date;
    }

    if (this.report.user !== this.editReport.user || this.new_report) {
      data["user"] = this.editReport.user;
    }

    if (
      this.report.static_poster.status !==
        this.editReport.static_poster.status ||
      this.report.static_poster.reason !== this.editReport.static_poster.reason
    ) {
      data["static_poster"] = this.editReport.static_poster.getTool();
    }

    if (
      this.report.next_bus.status !== this.editReport.next_bus.status ||
      this.report.next_bus.reason !== this.editReport.next_bus.reason
    ) {
      data["next_bus"] = this.editReport.next_bus.getTool();
    }

    if (this.report.new_flag_required !== this.editReport.new_flag_required) {
      data["new_flag_required"] = this.editReport.new_flag_required;
    }

    if (this.editReport.flag) {
      data["flag"] = this.editReport.flag.reduce(function (result, item) {
        if (!item.deleted) {
          result.push({
            route: item.route,
            status: item.status,
            operator_name: item.operator.operator_name,
            added_by_user: item.added_by_user,
          });
        }
        return result;
      }, []);
    }

    if (this.editReport.brail) {
      data["brail"] = this.editReport.brail.reduce(function (result, item) {
        if (!item.deleted) {
          result.push({
            route: item.route,
            status: item.status,
            operator_name: item.operator.operator_name,
            added_by_user: item.added_by_user,
          });
        }
        return result;
      }, []);
    }

    if (this.shift) {
      data["shift"] = this.shift.id;
    }

    return data;
  }

  saveEditing() {
    if (!this.editReport || this.editLoading) {
      return;
    }

    this.error = null;
    this.validation();

    if (Object.keys(this.validation_errors).length) {
      return;
    }

    this.editLoading = true;

    const data = this.getData();

    let request;
    if (this.new_report) {
      request = this.api.postReport(data);
    } else {
      request = this.api.updateReport(this.editReport.id, data);
    }

    const self = this;
    request.subscribe(
      async (r) => {
        if (data["stop_code"]) {
          await self.updateShift(data["stop_code"], self.report.stop.stop_code);
        }

        self.report = null;

        setTimeout(function () {
          self.report = r;
          if (!self.new_report) {
            self.report.getHistory();
          }
          self.new_report = false;
          self.updateReports(r);
        }, 300);

        self.closeEditing();
      },
      (r) => {
        self.error = r.message;
      },
      () => {
        self.editLoading = false;
      }
    );
  }

  async updateShift(new_stop_code: number, delete_stop_code: number) {
    const self = this;
    if (self.shift) {
      const tasks: Task[] = delete_stop_code
        ? self.shift.getStopTasks(delete_stop_code)
        : [];
      await self.shift.replaceStopCode({
        api: self.api,
        new_stop_code: new_stop_code,
        new_stop_tasks: tasks,
        delete_stop_code: delete_stop_code ? delete_stop_code : undefined,
        on_success: () => {},
        on_error: (error) => {
          self.error = error;
        },
      });
    }
  }
}
