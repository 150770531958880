import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { navItems } from '../../_nav';
import { translateEnum } from '../../shared/enum/translateEnum';
import { LINES_CSV_UPLOAD_PERMISSIONS,STOPS_FILE_UPLOAD_PERMISSIONS, Permissions, PERMISSIONS_KEYS, UPLOAD_REPORT_PERMISSIONS, TRAFFIC_CHANGE_PERMISSIONS } from '../../shared/models/permissions.model';
import { User } from '../../shared/models/user.model';
import { BuildPullService } from '../../core/service/build/build-pull.service';
import { UserService } from '../../core/service/user.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './default-layout.component.html',
    styleUrls: ['./default-layout.component.css'],
})
export class DefaultLayoutComponent {
    readonly translateEnum: typeof translateEnum = translateEnum;
    public navItems = [];
    public sidebarMinimized = true;
    private changes: MutationObserver;
    public element: HTMLElement = document.body;
    public buildIsUpToDate = true;

    constructor(public router: Router, public userService: UserService, private snackBar: MatSnackBar, buildPullService: BuildPullService) {
        this.navItems = this.navItemsByPermissions(userService.getUser());

        this.changes = new MutationObserver((mutations) => {
            this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
        });

        this.changes.observe(<Element>this.element, {
            attributes: true
        });

        buildPullService.buildIsUpToDate.subscribe(buildIsUpToDate => {
            this.buildIsUpToDate = buildIsUpToDate;
            if (!buildIsUpToDate) {
                const snackBarRef = this.snackBar.open(translateEnum.NEW_UPDATES, translateEnum.REFRESH);
                snackBarRef.onAction().subscribe(() => location.reload());
            }
        });
    }

    hideSideBar() {
        document.body.classList.remove('sidebar-show');
    }

    navItemsByPermissions(user: User) {
        const nav = [];
        let permission;
        const permissions: Permissions = user.permissions;
        for (let i = 0; i < PERMISSIONS_KEYS.length; i++) {
            permission = PERMISSIONS_KEYS[i];
            
           // if ((permission === UPLOAD_REPORT_PERMISSIONS && permissions[permission].write) ||
           //     (permission === LINES_CSV_UPLOAD_PERMISSIONS && permissions[permission].write) ||
           //     (permission === STOPS_FILE_UPLOAD_PERMISSIONS && permissions[permission].write) ||
           //     (permission !== UPLOAD_REPORT_PERMISSIONS && permission !== LINES_CSV_UPLOAD_PERMISSIONS && permission !== STOPS_FILE_UPLOAD_PERMISSIONS && permissions[permission].read) && navItems[permission]) {
                nav.push(navItems[permission]);
            //}
        }

        return nav;
    }

    ngOnInit(): void {

    }

    logout() {
        this.userService.logout();
        this.hideSideBar();
    }
}
