export enum translateEnum {
    COMPANY_FULL_NAME = 'אן אם בי יזמות ופיתוח בע״מ',
    REPORT_IDENTIFIER = 'מזהה דו״ח',
    DATE = 'תאריך',
    EDIT = 'עריכה',
    REPORT_DETAIL = 'פירוט מק״ט',
    STOP_CODE = 'מק״ט',
    STOP_CODE_STATION = 'מק״ט תחנה',
    STOP_NAME = 'שם תחנה',
    STOP_NUMBER = 'מספר',
    STOP_ADDRESS = 'כתובת תחנה',
    STOP_TYPE = 'סוג תחנה',
    USER_NAME = 'שם משתמש',
    ACTIONS = 'פעולות',
    STATIC_POSTER_STATUS = 'מצב פוסטר סטטי',
    STATIC_POSTER_REASON = 'סיבה מתקן סטטי',
    NEXT_BUS_STATUS = 'מצב נקסט בס',
    NEXT_BUS_REASON = 'סיבה נקסט בס',
    NEXT = 'הבא',
    NEXT_ERROR = 'ממתין',
    BACK='הקודם',
    MONITORING='בקרה',
    CITY = 'עיר',
    DETAILS='פירוט',
    STOP_HEAD = 'ראש תחנה',
    ROUTE_DESC = 'קו + יעד',
    DETAILS_BRAILLE_ON_FIELD = 'פירוט ברייל מהשטח',
    DETAILS_BRAILLE_RECENT='פירוט ברייל אחרונים',
    DETAILS_505_RECENT='פירוט 505 עדכני',
    DETAILS_505_ON_FIELD='פירוט 505 מהשטח',
    BRAIL_DESC = 'נגישות ברייל',
    WORKER_COMMENTS = 'הערות איש שטח',
    COMMENTS = 'הערות',
    FLAG_REQUIRED = 'דרוש דגל',
    SELECT = 'בחר..',
    SELECT_STOP = 'בחר מק״ט',
    SELECT_LINE_SIGN = 'בחר קו',
    SELECT_STOP_TYPE = 'בחר סוג תחנה',
    SELECT_USER = 'בחר משתמש',
    SELECT_OPERATOR_IN_CHARGE = 'בחר מפעיל אחראי',
    OPERATOR_IN_CHARGE = 'מפעיל אחראי',
    SELECT_AREA = 'בחר  אזור',
    SELECT_TEAM = 'בחר צוות',
    SELECT_ACTION_DATE = 'בחר תאריך ביצוע',
    SELECT_FROM_DATE = 'מתאריך',
    SELECT_TO_DATE = 'עד תאריך',
    ENTER_DATE = 'תאריך הזנה',
    SELECT_FROM_ENTER_DATE = 'מתאריך הזנה',
    SELECT_TO_ENTER_DATE = 'עד תאריך הזנה',
    ACTION_DATE = 'תאריך ביצוע',
    SELECT_FROM_ACTION_DATE = 'מתאריך ביצוע',
    SELECT_TO_ACTION_DATE = 'עד תאריך ביצוע',
    CONTROL_DATE = 'תאריך בקרה',
    CONTROL = 'בקרה',
    CONTROL_COMMENTS = 'הערות בקרה',
    SELECT_FROM_CONTROL_DATE = 'מתאריך בקרה',
    SELECT_TO_CONTROL_DATE = 'עד תאריך בקרה',
    PROJECT_NAME = 'שם פרויקט',
    AREA = 'אזור',
    TOTAL_STOPS_COUNT = 'כמות תחנות לפרויקט',
    MIN_TOTAL_STOPS_COUNT = 'מינימום כמות תחנות לפרויקט',
    MAX_TOTAL_STOPS_COUNT = 'מקסימום כמות תחנות לפרויקט',
    COMPLETED_STOPS_COUNT = 'כמות תחנות שבוצעה',
    MIN_COMPLETED_STOPS_COUNT = 'מינימום כמות תחנות שבוצעה',
    MAX_COMPLETED_STOPS_COUNT = 'מקסימום כמות תחנות שבוצעה',
    MIN = 'מינימום',
    MAX = 'מקסימום',
    STATUS_INVALID = 'פגום',
    STATUS_NOT_EXIST = 'חסר/לא קיים',
    STATUS_VALID = 'תקין',
    STATUS_INVALID_HEBREW = 'עברית פגום',
    STATUS_INVALID_ENGLISH = 'אנגלית פגום',
    TYPE = 'סוג',
    IMAGES_FILE_NAME = 'תמונות.zip',
    DOWNLOAD_IMAGES = 'הורדת תמונות',
    FLAG_TEST = 'דגל טסט',
    BRAIL_TEST = 'ברייל טסט',
    STATIC_TEST = 'סטטי טסט',
    TEST='בדיקה',
    REMOVE='הסר',
    DOWNLOAD_PDF = 'הורדת PDF',
    DOWNLOADED = 'הורדת',
    LOGOUT = 'יציאה',
    HELLO = 'שלום',
    IMAGES = 'תמונות',
    SEARCH = 'חיפוש',
    WAIT = 'המתן..',
    REPORTS = 'דוחות',
    PROJECTS = 'בקרת פרויקטים',
    LINES_CSV_UPLOAD = 'עדכון נספח',
    FILE_UPLOAD = 'העלאת קובץ ',
    CREATE_FLAG = 'יצירת תמרורים',
    CREATE_EXPORT = 'יצירת דו״ח',
    EDIT_EXPORT = 'עריכת דו״ח',
    ADDRESS = 'כתובת',
    UPLOAD_REPORT = 'העלאת סקר',
    TRAFFIC_CHANGE = 'שינוי תנועה',
    IMAGE_ANALYZER = 'פיענוח תמונה',
    ADD = 'הוספה',
    MAIL = 'מייל',
    MOBILE = 'נייד',
    PASSWORD = 'סיסמה',
    FULL_NAME = 'שם מלא',
    UPLOAD_FILE = 'העלאת קובץ',
    INVITES = 'הזמנות',
    ACTIONS_MAP = 'מפה לביצוע',
    ACTIONS_QUANTITIES = 'כתב כמויות',
    SHOW_FORMAT = 'הצג פורמט',
    HIDE_FORMAT = 'הסתר פורמט',
    OK = 'אישור',
    PRODUCE = 'הורדה',
    PRODUCEMAP = 'הורדה מפה לביצוע',
    PRODUCETOTAL = 'הורדה כתב כמויות',
    PREVIEW = 'תצוגה מקדימה',
    EMPTY_FILE = 'הועלה קובץ ריק, נסה שנית..',
    MISSING_CODES = 'חסרים קודים',
    INVALID_DATES = 'טווח תאריכים לא תקין',
    UPLOAD_CODES_SUCCESS = 'כל המק״טים הועלו בהצלחה',
    UPLOAC_CODES_EXISTING= 'המקטים הקיימים הם: ',
    UPLOAC_CODES_EXISTING_NOT_EXIST= 'אין מקטים קיימים בסטטוס מחכה/בתהליך',
    UPLOAD_FILE_SUCCESS = 'הקובץ הועלה בהצלחה',
    CHOOSE_FILE = 'בחר קובץ...',
    USERS = 'משתמשים',
    FIRST_NAME = 'שם פרטי',
    LAST_NAME = 'שם משפחה',
    ADD_USER = 'הוספת משתמש',
    EDIT_USER = 'עריכת משתמש',
    CLOSE = 'חזור',
    SAVE = 'שמירה',
    CANCEL = 'ביטול',
    LOGIN = 'כניסה',
    ERROR_MESSAGE = 'שגיאה: [message]',
    FIELD_REQUIRED = 'שדה חובה',
    INVALID_EMAIL = 'מייל לא תקין',
    INVALID_PHONE = 'נייד לא תקין',
    DELETE_WARNING = 'התראת מחיקה',
    DELETE_USER_CONTENT_WARNING = 'לאישור מחיקת [name] אנא הקלד את שמו',
    DELETE_SCHEDULE_CONTENT_WARNING = 'האם אתה בטוח שברצונך למחוק את [name]?',
    DELETE_REPORT_CONTENT_WARNING = 'האם אתה בטוח שברצונך למחוק את מק״ט תחנה [stop_code]?',
    DELETE_USER_INPUT_ERROR_WARNING = 'אין התאמה',
    STOP_TYPE_STATION = 'סככה',
    STOP_TYPE_PILLAR = 'עמוד',
    STOP_TYPE_NOT_EXIST = 'לא קיימת בשטח',
    STOP_TYPE_INVALID = 'לא תקינה',
    STOP_TYPE_SHEDS = 'מרובת סככות',
    SHEDS_COUNT = 'כמות סככות',
    MULTIPLE_SHEDS_COUNT = 'כמות מרובי סככות',
    SHIFT_NAME = 'שם סקר',
    COMMENT = 'הערה',
    REPORTED = 'דווח',
    STATION_MAINTENANCE = 'תחזוקת תחנות',
    ADMIN = 'מנהל',
    WORKER = 'עובד',
    OPERATOR = 'מפעיל',
    SELECT_STATIC_POSTER_STATUS = 'בחר סטטוס פוסטר סטטי',
    SELECT_NEXT_BUS_STATUS = 'בחר סטטוס נקסט בס',
    SELECT_BARIL_STATUS = 'בחר סטטוס ברייל',
    SELECT_FLAG_STATUS = 'בחר סטטוס דגל 505',
    SELECT_STATUS = 'בחר סטטוס',
    SELECT_ALL = 'בחר הכל',
    UNSELECT_ALL = 'נקה הכל',
    SHOW_FULL_LIST = 'הצג את כל הדוחות',
    NO_RESULT = 'אין תוצאות',
    CLEAN = 'נקה חיפוש',
    CHOOSE = 'בחירה',
    JANUARY = 'ינואר',
    FEBRUARY = 'פברואר',
    MARCH = 'מרץ',
    APRIL = 'אפריל',
    MAY = 'מאי',
    JUNE = 'יוני',
    JULY = 'יולי',
    AUGUST = 'אוגוסט',
    SEPTEMBER = 'ספטמבר',
    OCTOBER = 'אוקטובר',
    NOVEMBER = 'נובמבר',
    DECEMBER = 'דצמבר',
    SUNDAY = 'א׳',
    MONDAY = 'ב׳',
    TUESDAY = 'ג׳',
    WEDNESDAY = 'ד׳',
    THURSDAY = 'ה׳',
    FRIDAY = 'ו׳',
    SATURDAY = 'ש׳',
    TODAY = 'היום',
    MONTH = 'חודש',
    WEEK = 'שבוע',
    DAY = 'יום',
    TODAY_DATE = 'day לmonth, year',
    MONTH_FIRST_DATE = ' לmonth',
    STATUS = 'סטטוס',
    STATUS_WAITING_STYLE = 'start',
    STATUS_PROGRESS_STYLE = 'partially',
    STATUS_COMPLETE_STYLE = 'finish',
    STATUS_WAITING = 'מחכה',
    STATUS_PROGRESS = 'בתהליך',
    STATUS_COMPLETE = 'הסתיים',
    SCHEDULE_DETAIL = 'עריכת יום עבודה [name] ל[team]',
    STOP_DETAIL = 'עריכת תחנה',
    ADD_STOP = 'הוספת תחנה',
    ADD_STATION = 'הוסף תחנה',
    DELETE_SHIFT = 'מחק סקר',
    DELETE_SHIFT_WARNING = 'אשר מחיקת סקר זה',
    EXPORT_SHIFT = 'ייצוא סקר',
    EXPORT_REPORTS = 'ייצוא דוחות',
    GOTO_DATE = 'עבור לתאריך..',
    FOUND_SHIFTS_HEADER = 'נמצאו [count] סקרים',
    NO_SHIFTS_HEADER = 'לא נמצאו סקרים',
    PERMISSION_FOR = 'הרשאת',
    READ_PERMISSION = 'צפייה בלבד',
    WRITE_PERMISSION = 'כל ההרשאות',
    NO_PERMISSION = 'ללא הרשאות',
    LIMITED_PERMISSION = 'אין הרשאה',
    ROLE = 'תפקיד',
    AUDIT = 'מבקר',
    PERFORMER = 'מבצע',
    SCHEDULE_PERFORMER = 'מבצע סקר',
    REPORT_PERFORMER = 'מבצע תחנה',
    CHANGE = 'שינוי',
    EDIT_SCHEDULE = 'עריכת סקר',
    SHOW_BRAILS = 'הצג פירוט נגישות ברייל',
    SHOW_FLAGS = 'הצג פירוט קו + יעד',
    SHOW_HISTORY = 'הצג היסטוריה',
    SHOW_AUDITS = 'הצג ביקורת',
    APPROVE_AUDIT = 'אשר דו״ח',
    DO_AUDIT = 'בצע בקרה',
    TITLE_AUDIT = 'בקרה אחרונה בוצעה ע״י [user] ב-[date]',
    PAGE_NUMBER = '-עמוד [page] מתוך [total]-',
    STATION_CONFLICT = 'קו הוסף ידנית ואינו נמצא בסקר',
    YES = 'כן',
    NO = 'לא',
    FAILURE_TYPE = 'אופי התקלה',
    DOWNLOAD_BUTTON_REPORTS_STATIC = 'דו״ח תקלות סטטי',
    DOWNLOAD_BUTTON_REPORTS_GENERAL = 'דו״ח כללי',
    DOWNLOAD_BUTTON_REPORTS_BRAIL = 'דו״ח תקלות ברייל',
    DOWNLOAD_BUTTON_REPORTS_COMMENT = 'דו״ח הערות איש שטח',
    DOWNLOAD_BUTTON_REPORTS_OPERATOR_IN_CHARGE = 'דו"ח מפעיל אחראי',
    DOWNLOAD_BUTTON_REPORTS_FLAG = 'דו״ח תקלות 505',
    DOWNLOAD_BUTTON_REPORTS_HEAD = 'דו״ח ראש תחנה',
    DOWNLOAD_BUTTON_REPORTS_SHEDS = 'דו״ח מרובי סככות',
    DOWNLOAD_BUTTON_PROJECTS_GENERAL = 'דו״ח כללי',
    DOWNLOAD_BUTTON_PROJECT_GENERAL = 'דו״ח כללי',
    DOWNLOAD_FILE_NAME_REPORTS_STATIC = 'דו״ח תקלות סטטי.xlsx',
    DOWNLOAD_FILE_NAME_REPORTS_GENERAL = 'דו״ח ממצאים סקר.xlsx',
    DOWNLOAD_FILE_NAME_REPORTS_BRAIL = 'דו״ח תקלות ברייל.xlsx',
    DOWNLOAD_FILE_NAME_REPORTS_COMMENT = 'דו״ח הערות איש שטח.xlsx',
    DOWNLOAD_FILE_NAME_REPORTS_OPERATOR_IN_CHARGE = 'דו"ח מפעיל אחראי.xlsx',
    DOWNLOAD_FILE_NAME_REPORTS_FLAG = 'דו״ח תקלות 505.xlsx',
    DOWNLOAD_FILE_NAME_REPORTS_HEAD = 'דו״ח ראש תחנה.xlsx',
    DOWNLOAD_FILE_NAME_REPORTS_SHEDS = 'דו״ח מרובי סככות.xlsx',
    DOWNLOAD_FILE_NAME_PROJECTS_GENERAL = 'דו״ח כללי.xlsx',
    DOWNLOAD_FILE_NAME_SCHEDUALE='דוח תחזוקת תחנות.xlsx',
    DOWNLOAD_FILE_NAME_PROJECT_GENERAL = 'דו״ח כללי.xlsx',
    STOP_HEAD_FLAG_INVALID = 'ראש תחנה פגום',
    INVALID_COUNT_FLAG = 'כמות קו יעד פגומים',
    WRONG_FLAG = 'קו יעד פגום/חסר',
    WRONG_COUNT_BRAIL = 'כמות בריילים תקולים או חסרים',
    WRONG_BRAIL = 'ברייל חסר/פגום',
    CREATE_FLAG_505 = 'תמרור 505',
    CREATE_FLAG_STRIP = 'תמרור סטריפ',
    CREATE_FLAG_HEAD = 'תמרור ראש תחנה',
    CREATE_FLAG_BRAIL = 'תמרור ברייל',
    NO_FLAG = 'מק״ט לא נמצא',
    NO_LINE = 'קו לא נמצא',
    OPERATOR_EGGED_TAAVURA = 'אגד תעבורה',
    OPERATOR_EGGED = 'אגד',
    OPERATOR_AFIKIM = 'אפיקים',
    OPERATOR_ELECTRA_AFIKIM = 'אלקטרה אפיקים',
    
    OPERATOR_ELECTRA_AFIKIM_TAHBURA='אלקטרה אפיקים תחבורה',
    OPERATOR_GB_TOURS = 'גי.בי.טורס',
    OPERATOR_GALEEM = 'גלים',
    OPERATOR_DANBR7 = 'דן באר שבע',
    OPERATOR_DANBADAROM = 'דן בדרום',
    OPERATOR_DANNORTH = 'דן צפון',
    OPERATOR_DAN = 'דן',
    OPERATOR_SHUFAT_BUS = 'ירושלים - עיסאוויה מחנה שעפאט',
    OPERATOR_ATA_BUSES = 'ירושלים-אבו-תור-ענאתא איחוד',
    OPERATOR_CENTER_BUSES = 'ירושלים-אלווסט איחוד',
    OPERATOR_SOUTH_BUSES = 'ירושלים-דרום איחוד',
    OPERATOR_MOUNTOFOLIVES_BUS = 'ירושלים-הר הזיתים',
    OPERATOR_SURBAHER_BUS = 'ירושלים-צור באהר איחוד',
    OPERATOR_RAMALLAH_BUS = 'ירושלים-רמאללה איחוד',
    OPERATOR_SHUFAT = 'ירושלים-שועפאט',
    OPERATOR_CALLKAV = 'מועצה Fית אילות',
    OPERATOR_GOLAN_BUS = 'מועצה אזורית גולן',
    OPERATOR_METROPOLINE = 'מטרופולין',
    OPERATOR_NTT_BUSES = 'נסיעות ותיירות',
    OPERATOR_NATEEV_EXPRESS = 'נתיב אקספרס',
    OPERATOR_SUPERBUS = 'סופרבוס',
    OPERATOR_KAVIM = 'קווים',
    OPERATOR_NAZARETH_UNBS = 'ש.א.מ',
    OPERATOR_NIGHT = 'לילה',
    OPERATOR_BETSHEMESH = 'בית שמש אקספרס',
    OPERATOR_TNUFA = 'תנופה',
    OPERATOR_EXTRA = 'אקסטרה',
    OPERATOR_EXTRA_JERUSALEM = 'אקסטרה ירושלים',
    EGED_DERECH='דרך אגד עוטף ירושלים',
    FILTER_LINE_DEST = 'סנן יעד',
    PERFORM_ACTION = 'פעולה לביצוע',
    NEW_UPDATES = 'עדכונים חדשים זמינים',
    REFRESH = 'רענן',
    CREATING_ZIP = 'יוצר קובץ זיפ..',
    CREATING_FILE = 'יוצר קובץ',
    REPORT_NAME = 'שם דו״ח',
    CHOOSE_COLUMNS = 'בחירת עמודות',
    ADD_COLUMN = 'הוספת עמודה',
    COLUMNS_REQUIRED = 'יש לבחור עמודות',
    AREA_SOUTH = 'דרום',
    AREA_CENTER = 'מרכז',
    AREA_NORTH = 'צפון',
    AREA_EAST = 'מזרח',
    AREA_WEST_NORTH = 'מערב צפון',
    AREA_EAST_NORTH = 'מזרח צפון',
    AREA_JUDEA_SAMARIA = 'יו״ש',
    AREA_JUDEA_SAMARIA_PROTECTED = 'יו״ש מוגן',
    SEARCH_FIELDS = 'שדות חיפוש',
    PROJECT_NUMBER = 'מספר פרויקט',
    PROJECT_SUBMISSION_DATE = 'תאריך קבלת הפרויקט',
    PROJECT_LAST_AUDIT_DATE = 'תאריך בקרה אחרון',
    LAST_DATE = 'תאריך אחרון לביצוע',
    EXECUTER = 'מפעיל מזמין',
    SIFRUR = 'ספרור',
    MAPNAME = 'שם המפה',
    DONE = 'בוצע',
    UNDONE = 'לא בוצע',
    CONTRACTOR='קבלן מבצע',
    INSPECTOR_NAME = 'שם מבקר',
    COMMENT_DATE = 'תאריך הערה',
    CREATE_REPORT = 'הוספת מק״ט',
    NEW_REPORT = 'מק״ט חדש',
    STATION_CODE_EXIST = 'מק״ט כבר קיים לפרויקט',
    NO_DATA = 'אין נתונים',
    CHOOSE_DATES_REQUIRED = 'חובה לסנן לפי טווח תאריכים בכדי להוריד תמונות',
    CLEAN_SELECTED = 'נקה בחירת תמונות',
    FILE_NAME = 'שם קובץ',
    LINES_LOGS = 'לוג עדכוני נספח',
    SHOW_LINES_LOGS = 'הצג לוג עדכוני נספח',
    INVALID_STOP_CODE = 'מק״ט תחנה "[stop_code]" לא תקין',
    UPLOAD_RESULT_MESSAGE = 'בסה״כ עלו [total] מק״טים, מתוכם [fail] נכשלו:',
    TASKS = 'פעולות לביצוע',
    TASKS_STATUSES = 'סטטוס פעולות לביצוע',
    TASK_STATUS_PENDING = 'ממתין לביצוע',
    TASK_STATUS_DONE = 'בוצע',
    TASK_STATUS_APPROVE = 'אישור ביצוע',
    ADD_TASKS = 'הוספת פעולות לביצוע',
    EDIT_TASKS = 'עריכת פעולות לביצוע',
    APPROVE_TASK='פעולה לביצוע התווספה',
    JOB_TYPE="תפקיד",

    EXECUTER_NAME = 'שם מבצע',
    MAKAT = 'מקט תחנה',
    MAP_NAME = 'שם המפה',
    ACTION = 'פעולות לביצוע',
    ACTION_0 = 'פעולה',
    ACTION_1 = 'פעולה 1',
    ACTION_2 = 'פעולה 2',
    ACTION_3 = 'פעולה 3',
    ACTION_4 = 'פעולה 4',
    ACTION_5 = 'פעולה 5',
    ACTION_6 = 'פעולה 6',
    ACTION_7 = 'פעולה 7',
    ACTION_8 = 'פעולה 8',
    TASKS_ADD_HEAD_STOP = 'הוספת ראש תחנה',
    TASKS_ADD_STRIP = 'הוספת סטריפ',
    TASKS_REMOVE_HEAD_STOP = 'הסרת ראש תחנה',
    TASKS_REMOVE_STRIP = 'הסרת סטריפ',
    TASKS_ADD_BRAIL = 'הוספת ברייל',
    TASKS_REMOVE_BRAIL = 'הסרת ברייל',
    TASKS_STATIC_POSTER = 'סטטי',
    TASKS_POLIKARBONAT = 'פולי',
    TASKS_STATIC_COMPLAT = 'מתקן סטטי קומפלט',
    TASKS_FLAG_505 = 'דגל 505 אוטומטי',
    TASKS_MANUAL_505 = 'דגל 505 ידני',
    TASKS_FLAG_505_8 = 'דגל 505/8 אוטומטי',
    TASKS_MANUAL_505_8 = 'דגל 505/8 ידני',
    TASKS_ADAPTERS = 'מתאמים',
    TASKS_BORDERS = 'מסגרות',
    LINE_NUMBER = 'מספר קו',
    DESTINATION = 'יעד',
    CHARGE = 'לחיוב',
    NOT_CHARGE='לא לחיוב',
    FREE_TEXT = 'טקסט חופשי',
    FLAGS_COUNT = ' כמות דגלים בתחנה',
    BORDER = 'מסגרת?',
    BRAIL_NUMBER = 'מספר ברייל',
    SCHEDUAL_DETAILS = 'פירוט',
    SCHEDUALE_USER_NAME = 'שם משתמש',
    SCHEDUALT_STATUS = 'סטטוס',
    SCHEDUALT_DATE = 'תאריך',
    TURN_ON_AI = 'הפעל AI',
    TURN_OFF_AI = 'כבה AI'
}

