import {Option} from '../../shared/interfaces/option';
import {translateEnum} from '../../shared/enum/translateEnum';
import {FLAG_STATUSES} from '../../shared/enum/flag';

export const flagStatuses: Option[] = [
    {id: FLAG_STATUSES.VALID, name: translateEnum.STATUS_VALID},
    {id: FLAG_STATUSES.NOT_EXIST, name: translateEnum.STATUS_NOT_EXIST},
    {id: FLAG_STATUSES.INVALID, name: translateEnum.STATUS_INVALID},
    {id: FLAG_STATUSES.INVALID_HEBREW, name: translateEnum.STATUS_INVALID_HEBREW},
    {id: FLAG_STATUSES.INVALID_ENGLISH, name: translateEnum.STATUS_INVALID_ENGLISH}
];

export const flagSets: Set<number>[] = [
    new Set<number>([FLAG_STATUSES.VALID]),
    new Set<number>([FLAG_STATUSES.NOT_EXIST, FLAG_STATUSES.INVALID, FLAG_STATUSES.INVALID_HEBREW, FLAG_STATUSES.INVALID_ENGLISH])
];
