import {Report} from '../../../shared/models/report.model';
import {translateEnum} from '../../../shared/enum/translateEnum';
import {Export} from '../../../shared/models/export.model';
import {User} from '../../../shared/models/user.model';

export const download = (reports: Report[], exp: Export, users: User[]) => {
    const data = [];
    let row;
    const header: string[] = [...exp.columns];

    for (const report of reports) {
        row = {};

        for (const column of header) {
            switch (column) {
                case translateEnum.STOP_CODE_STATION:
                    row[column] = report.stop.stop_code;
                    break;
                case translateEnum.STOP_NAME:
                    row[column] = report.stop.stop_name;
                    break;
                case translateEnum.STOP_ADDRESS:
                    row[column] = report.stop.stop_desc;
                    break;
                case translateEnum.REPORT_PERFORMER:
                    row[column] = report.userName(users);
                    break;
                case translateEnum.ACTION_DATE:
                    row[column] = report.userDate;
                    break;
                case translateEnum.CONTROL:
                    row[column] = report.last_audit ? translateEnum.YES : translateEnum.NO;
                    break;
                case translateEnum.INSPECTOR_NAME:
                    row[column] = report.last_audit ? report.last_audit.user : '';
                    break;
                case translateEnum.CONTROL_DATE:
                    row[column] = report.last_audit ? report.last_audit.date : '';
                    break;
                case translateEnum.CONTROL_COMMENTS:
                    row[column] = report.last_audit ? report.last_audit.comment : '';
                    break;
                case translateEnum.TASKS:
                    row[column] = report.getTasksString();
                    break;
            }
        }
        data.push(row);
    }

    return {header, data};
};
