export enum FLAG_STATUSES {
    VALID = 1,
    NOT_EXIST = 0,
    INVALID = -1,
    INVALID_HEBREW = 2,
    INVALID_ENGLISH = 3
}

export enum FLAG_PDF_TYPE {
    GENERAL = 0,
    BRAIL = 1
}
