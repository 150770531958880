import {Deserializable} from "./deserializable.model";

export class Line implements Deserializable {

	id: string;
	operator_name: string;
	line_sign: string;
	line_code: number;
	stop_code: number;
	stop_name: string;
	stop_name_en: string;
	stop_act: string;
	line_dest: string;
	line_uniq: string;
	line_access: string;
	braille: string;
	lat: number;
	lng: number;
	city_name: string;
	city_street: string;
	city_street_num: number;
	line_dest_en: string;
	line_sign_only_number: number;
	is_train: boolean;
	line_dest_head_he: string;
	line_dest_sub_he: string | null;
	line_dest_head_en: string;
	line_dest_sub_en: string | null;
	bulks: string[];

	deserialize(input: any) {
		Object.assign(this, input);

		if (input.line_sign) {
			this.line_sign_only_number = +(input.line_sign.replace(/\D+/g, ''));

			//move non numeric last char to start
			const last_char = this.line_sign.charAt(this.line_sign.length - 1);
			if (!(/^\d+$/.test(last_char))) {
				this.line_sign = last_char + this.line_sign.substr(0, this.line_sign.length - 1);
			}
		}

		if (this.stop_name) {
			this.stop_name = this.stop_name.replace(/\//g, '/ ');
		}

		if (this.stop_name_en) {
			this.stop_name_en = this.stop_name_en.replace(/\//g, '/ ');
		}

		if (!this.isDownload() && this.isNight()) {
			this.operator_name = this.line_uniq;
		}

		this.is_train = this.line_uniq === 'רכבת';

		this.splitLineDest('he');
		this.splitLineDest('en');

		return this;
	}

	splitLineDest(lang) {
		if(lang==undefined)
			lang='he';
		let line_dest_field = lang == 'he' ? 'line_dest' : 'line_dest_en';
		if(this[line_dest_field]!=undefined)
			{
		let index = this[line_dest_field].lastIndexOf(',');
		if (index > -1) {
			this[`line_dest_head_${lang}`] = this[line_dest_field].slice(0, index).trim();
			this[`line_dest_sub_${lang}`] = this[line_dest_field].slice(index + 1).trim();
		} else {
			this[`line_dest_head_${lang}`] = this[line_dest_field];
			this[`line_dest_sub_${lang}`] = null;
		}
	}
	}

	isNight() {
		return this.line_uniq === 'לילה';
	}

	isDownload() {
		return this.line_dest === 'הורדה בלבד';
	}

}
