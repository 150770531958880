import {Component, Input} from '@angular/core';
import {translateEnum} from '../../../../shared/enum/translateEnum';
import {Audit} from '../../../../shared/models/audit.model';

@Component({
    selector: 'audit-action',
    templateUrl: 'audit-action.component.html',
    styleUrls: ['audit-action.component.css']
})

export class AuditActionComponent {

    readonly translateEnum = translateEnum;

    @Input() public audit: Audit | null = null;
    @Input() public show: boolean = false;

    public title: string = null;

    ngOnChanges(changes): void {
        if (changes.audit && changes.audit.currentValue) {

            const audit = changes.audit.currentValue;
            if (audit) {
                const user = audit.user ? audit.user.split('(')[0] : '';
                const date = audit.date ? audit.date.split(' ')[0] : '';

                this.title = this.translateEnum.TITLE_AUDIT
                    .replace('[user]', user.trim())
                    .replace('[date]', date);

            } else {
                this.title = null;
            }
        }
    }

}
