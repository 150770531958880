import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {FlagComponent} from './flag.component';
import {BaseComponent} from './base.component';

const routes: Routes = [
    {
        path: '',
        component: BaseComponent,
        children: [
            {
                path: '',
                component: FlagComponent,
                data: {
                    title: ''
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class FlagRoutingModule {
}
