import {Report} from "../../../shared/models/report.model";
import {translateEnum} from "../../../shared/enum/translateEnum";

export const download = (reports: Report[]) => {
	const data = [];

	for (const report of reports) {
		data.push({
			[translateEnum.STOP_CODE_STATION]: report.stop.stop_code,
			[translateEnum.SHEDS_COUNT]: report.num_of_stations
		});
	}

	let header: string[] = [
		translateEnum.STOP_CODE_STATION,
		translateEnum.SHEDS_COUNT
	];

	return {header, data};
}
