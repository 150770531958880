import {Deserializable} from './deserializable.model';
import {BaseComponent} from '../../../app/base.component';
import {Option} from '../../shared/interfaces/option';
import {FLAG_STATUSES} from '../../shared/enum/flag';
import {Operator} from '../../shared/models/operator.model';

export class Route extends BaseComponent implements Deserializable {

    route: string;
    status: number;
    statusName: string;
    added_by_user: boolean;
    operator: Operator;
    cell: string;
    index: number;
    deleted: boolean = false;
    options: Option[] = [];
    option: Option;
    is_wrong: boolean;
    is_invalid: boolean;
    is_head: boolean;
    is_invalid_head: boolean;
    background_color: string;

    constructor() {
        super();
    }

    deserialize(input: { options: Option[], data: any, index: number }) {
        Object.assign(this, input.data);
        this.options = input.options;
        this.setIndex(input.index);
        this.is_head = this.route && !(this.route.match(/\|/g) || []).length;  // head station do not contain |XX| chars
        this.operator = new Operator().deserialize({operator_name: input.data.operator_name, lang: 'he'});
        this.setStatus(input.data.status);
        return this;
    }

    setIndex(index: number) {
        this.index = index;
    }

    setStatus(status: number) {
        this.status = status;
        this.is_wrong = status !== FLAG_STATUSES.VALID;
        this.is_invalid = [FLAG_STATUSES.INVALID, FLAG_STATUSES.INVALID_HEBREW, FLAG_STATUSES.INVALID_ENGLISH].indexOf(status) > -1;
        this.option = this.options.find((status: Option) => status.id === this.status);
        this.statusName = this.option ? this.option.name : '';
        this.cell = [this.statusName, this.route].join(' - ');
        this.is_invalid_head = !this.added_by_user && this.is_invalid && this.is_head;

        switch (status) {
            case FLAG_STATUSES.NOT_EXIST:
                this.background_color = 'gray';
                break;
            case FLAG_STATUSES.VALID:
                this.background_color = 'green';
                break;
            default:
                this.background_color = 'red';
        }
    }

    setDeleted(deleted: boolean) {
        this.deleted = deleted;
    }

}
