import {Option} from '../../shared/interfaces/option';
import {translateEnum} from '../../shared/enum/translateEnum';
import {STATIC_POSTER_STATUSES} from '../../shared/enum/static_poster';

export const staticPosterStatuses: Option[] = [
    {id: STATIC_POSTER_STATUSES.VALID, name: translateEnum.STATUS_VALID},
    {id: STATIC_POSTER_STATUSES.NOT_EXIST, name: translateEnum.STATUS_NOT_EXIST},
    {id: STATIC_POSTER_STATUSES.INVALID, name: translateEnum.STATUS_INVALID}
];

export const staticPosterSets: Set<number>[] = [
    new Set<number>([STATIC_POSTER_STATUSES.VALID]),
    new Set<number>([STATIC_POSTER_STATUSES.NOT_EXIST, STATIC_POSTER_STATUSES.INVALID])
];
