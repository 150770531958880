import { Component, ViewEncapsulation } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ApiService } from '../../core/service/api.service';
import { ExportsService } from '../../core/service/exports.service';
import { UserService } from '../../core/service/user.service';
import { UsersService } from '../../core/service/users.service';
import { translateEnum } from '../../shared/enum/translateEnum';
import { User } from '../../shared/models/user.model';

@Component({
    selector: 'app-dashboard',
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.css', '../../app.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class LoginComponent {

    user: User;
    error: string;
    hide: boolean;
    search: boolean;
    errorUsername: boolean;
    errorPassword: boolean;
    translateEnum = translateEnum;

    constructor(private api: ApiService, private userService: UserService, private router: Router,
                private usersService: UsersService, private exportsService: ExportsService,
                private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {

        this.matIconRegistry.addSvgIcon(
            `perm_identity`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/img/svg/account-outline.svg`)
        );
        this.matIconRegistry.addSvgIcon(
            `visibility`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/img/svg/visibility.svg`)
        );
        this.matIconRegistry.addSvgIcon(
            `visibility_off`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/img/svg/visibility-off.svg`)
        );

        this.user = new User();
        this.hide = true;
        this.error = null;
        this.search = false;
    }

    isBlank(str) {
        return (!str || /^\s*$/.test(str));
    }

    tryLogin() {
        this.error = null;
        this.errorUsername = false;
        this.errorPassword = false;

        if (this.search) {
            return;
        }

        if (this.isBlank(this.user.email)) {
            this.errorUsername = true;
            return;
        }

        if (this.isBlank(this.user.password)) {
            this.errorPassword = true;
            return;
        }

        this.search = true;

        this.api.login(
            this.user.email,
            this.user.password
        ).subscribe(
            r => {
                this.userService.setUser(r);

                const route = this.userService.getUser().permissions.firstRouteByPermissions();

                if (route) {
                    this.usersService.getUsers(true);
                    this.exportsService.getExports(true);
                    this.router.navigateByUrl(route);
                } else {
                    this.error = translateEnum.LIMITED_PERMISSION;
                }
                this.search = false;

            },
            r => {
                console.log(r.error);
                this.error = r.error.message;
                this.search = false;
            });
    }

}
