import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'upload-file',
    templateUrl: '../upload.component.html',
    styleUrls: ['../upload.component.css'],
})
export class UploadFileComponent implements OnInit {

    @Input() elementId: string;
    @Output() result = new EventEmitter<any>();
    @Input() reset: Observable<any>;
    reset_subscription: any;

    fileName: string = '';
    loading: boolean = false;

    ngOnInit() {
        const self = this;
        if (this.reset) {
            this.reset_subscription = this.reset.subscribe(() => self.fileName = '');
        }
    }

    ngAfterViewInit() {
        const self = this;
        setTimeout(function () {
        
            document.getElementById(self.elementId).addEventListener('change', function () {
                self.loading = true;
                const file = (<HTMLInputElement>this).files[0];
                const fileReader = new FileReader();

                if (file) {
                    self.fileName = file.name;
                    self.result.emit({ file_name: self.fileName,file:file});
                    self.loading = false;

                } else {
                    self.loading = false;
                }
            }, false);
        }, 200);
    }

    ngOnDestroy() {
        if (this.reset && this.reset_subscription) {
            this.reset_subscription.unsubscribe();
        }
    }
}
