import { Component, Input } from '@angular/core';
import { FlagBrailPdfService } from '../../../core/service/flag-pdf/flag-brail-pdf.service';
import { FlagGeneralPdfService } from '../../../core/service/flag-pdf/flag-general-pdf.service';
import { FLAG_PDF_TYPE } from '../../../shared/enum/flag';
import { translateEnum } from '../../../shared/enum/translateEnum';
import { FlagBrailSetting, FlagGeneralSetting } from '../../../shared/interfaces/flag';


@Component({
    selector: 'flag-pdf-button',
    templateUrl: 'flag-pdf-button.component.html',
    styleUrls: ['../../../app.component.css']
})

export class FlagPdfButtonComponent {
    readonly translateEnum: typeof translateEnum = translateEnum;

    @Input() getSetting: Function;
    @Input() disabled: boolean = false;
    @Input() type: FLAG_PDF_TYPE = FLAG_PDF_TYPE.GENERAL;

    loading: boolean = false;
    progress: string = null;
    error: string = null;

    constructor() {
        this.onClick = this.onClick.bind(this);
        this.onUpdateEvent = this.onUpdateEvent.bind(this);
    }

    async onClick() {
        debugger
        this.progress = null;
        this.loading = true;
        try {
            // disable scroll for pdf creation with canvas
            document.body.style.setProperty('overflow', 'hidden', 'important');
            document.body.style.setProperty('overflow-x', 'hidden', 'important');
            document.body.style.setProperty('overflow-y', 'hidden', 'important');
            window.scrollTo(0, 0);

          //  await new Promise(r => setTimeout(r, 2000));

            const setting: FlagGeneralSetting | FlagBrailSetting = this.getSetting();

            let flagPdfService = null;
            switch (this.type) {
                case FLAG_PDF_TYPE.GENERAL:
                    flagPdfService = new FlagGeneralPdfService(<FlagGeneralSetting>setting, this.onUpdateEvent);
                    break;
                case FLAG_PDF_TYPE.BRAIL:
                    flagPdfService = new FlagBrailPdfService(<FlagBrailSetting>setting, this.onUpdateEvent);
                    break;
            }

            if (flagPdfService) {
                await flagPdfService.download();
            }

        } catch (e) {
            console.log(e);
        }
        // enable scroll back
        document.body.style.overflow = '';
        document.body.style['overflow-x'] = '';
        document.body.style['overflow-y'] = '';
        this.loading = false;
    }

    onUpdateEvent(data) {
        this.progress = data;
    }
}
