import {Injectable} from '@angular/core';
import {User} from '../../shared/models/user.model';
import {Router} from '@angular/router';
import * as cloneDeep from 'lodash/cloneDeep';
import {UPLOAD_REPORT_PERMISSIONS, LINES_CSV_UPLOAD_PERMISSIONS,STOPS_FILE_UPLOAD_PERMISSIONS,TRAFFIC_CHANGE_PERMISSIONS} from '../../shared/models/permissions.model';

const TOKEN = 'TOKEN';
const USER = 'USER';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    public user: User;

    constructor(public router: Router) {

    }

    isLogged() {
        return localStorage.getItem(TOKEN) != null;
    }

    setUser(user) {
        localStorage.setItem(TOKEN, user.token);

        const copyUser = cloneDeep(user);
        copyUser.permissions = user.permissions.toJson();
        copyUser.role = user.role.id;

        localStorage.setItem(USER, JSON.stringify(copyUser));
        this.user = user;
    }

    getUser() {
        if (!this.user) {
            const userStorage = localStorage.getItem(USER);
            this.user = new User();

            if (userStorage) {
                const tmp = JSON.parse(userStorage);

                if (typeof tmp.role === 'object') {
                    tmp.role = tmp.role.id;
                }

                this.user.deserialize({user: tmp, token: localStorage.getItem(TOKEN)});
            }
        }
        return this.user;
    }

    logout() {
        localStorage.removeItem(TOKEN);
        localStorage.removeItem(USER);
        this.router.navigateByUrl('login');
    }

    pagePermission(permission: string) {
        const user = this.getUser();
        const permissions = user.permissions;
        const test = (UPLOAD_REPORT_PERMISSIONS === permission || LINES_CSV_UPLOAD_PERMISSIONS === permission || STOPS_FILE_UPLOAD_PERMISSIONS===permission 
            || TRAFFIC_CHANGE_PERMISSIONS===permission) ?
            permissions[permission].write :
            permissions[permission].read;

        if (!test) {
            const route = permissions.firstRouteByPermissions();
            if (route) {
                this.router.navigateByUrl(route);
            } else {
                this.logout();
            }
        }
    }

}
