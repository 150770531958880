import {Deserializable} from "./deserializable.model";
import {FlagPageLine} from "../../shared/models/flag-page-line.model";
import {Line} from "../../shared/models/line.model";

export interface FlagUnion {
	line: Line,
	union_lines: Line[],
	operator_name: string,
	min: number,
	bulks: string[][],
}

export class FlagPage implements Deserializable {

	page_number: number;
	lang: string;
	stop_name: string;
	stop_code: number;
	lines: (FlagPageLine | null)[];

	deserialize(input: { union: FlagUnion[], page_number: number, lang: string, count: number }) {

		let index_download_night: number = -1;
		const parent_line: Line = input.union[0].line;
		const lines: FlagPageLine[] = [];
		let prev_operator: string | null = null;
		let line: Line, bulks, show_operator;

		for (let i = 0; i < input.union.length; i++) {

			line = input.union[i].line;
			bulks = input.union[i].bulks;

			for (let j = 0 ; j < bulks.length; j++) {

				if (line.isNight()) {
					show_operator = true;
				} else {
					show_operator = prev_operator != line.operator_name && j == 0;
				}

				lines.push(new FlagPageLine().deserialize({
					only_numbers: j > 0,
					line_signs: bulks[j],
					line,
					lang: input.lang,
					show_operator: show_operator,
				}));

				if (index_download_night == -1 &&
					this.isDownloadOrNightLine(line)) {
					index_download_night = lines.length - 1;
				}
			}

			prev_operator = line.operator_name;
		}

		let fill = new Array(0).fill(null);
		if(lines.length<=input.count)
			 fill = new Array(input.count - lines.length).fill(null);
		
		if (index_download_night > -1) {
			if (fill.length > 0) {
				lines[index_download_night].show_operator = true;
			}
			lines.splice(index_download_night, 0, ...fill);
		} else if (lines.length < input.count) {
			lines.splice(lines.length, 0, ...fill);
		}

		Object.assign(this, {
			page_number: input.page_number,
			lang: input.lang,
			stop_name: parent_line[input.lang == 'he' ? 'stop_name' : 'stop_name_en'],
			stop_code: parent_line['stop_code'],
			lines,
		});

		return this;
	}

	isDownloadOrNightLine(line) {
		return line.isDownload() || line.isNight();
	}

}
