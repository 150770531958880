import {Report} from '../../../shared/models/report.model';
import {translateEnum} from '../../../shared/enum/translateEnum';
import {User} from '../../../shared/models/user.model';
import {Export} from '../../../shared/models/export.model';

export const download = (reports: Report[], users: User[], exp: Export) => {
    const data = [];
    let row, index, wrongs, invalids;
    let maxFlags = 0, maxBarils = 0, maxWrongsFlags = 0, maxWrongsBarils = 0;
    let header: string[] = [...exp.columns];

    for (const report of reports) {
        row = {};

        for (const column of header) {
            switch (column) {
                case translateEnum.STOP_CODE_STATION:
                    row[column] = report.stop.stop_code;
                    break;
                case translateEnum.STOP_NAME:
                    row[column] = report.stop.stop_name;
                    break;
                case translateEnum.OPERATOR_IN_CHARGE:
                        row[column] = report.stop.operatorInCharge;
                        break;
                case translateEnum.AREA:
                            row[column] = report.stop.areas;
                            break;
                case translateEnum.STOP_TYPE:
                    row[column] = report.stopType;
                    break;
                case translateEnum.MULTIPLE_SHEDS_COUNT:
                    row[column] = report.isMultipleSheds() ? report.num_of_stations : '';
                    break;
                case translateEnum.SCHEDULE_PERFORMER:
                    row[column] = report.userName(users);
                    break;
                case translateEnum.CITY:
                    row[column] = report.stop.city;
                    break;
                case translateEnum.ACTION_DATE:
                    row[column] = report.userDate;
                    break;
                case translateEnum.STOP_ADDRESS:
                    row[column] = report.stop.address;
                    break;
                case translateEnum.FLAG_REQUIRED:
                    row[column] = report.new_flag_required ? translateEnum.YES : translateEnum.NO;
                    break;
                case translateEnum.WORKER_COMMENTS:
                    row[column] = report.comments_string;
                    break;
                case translateEnum.NEXT_BUS_STATUS:
                    row[column] = report.showNextBusStatus() ? report.next_bus.statusName : '';
                    break;
                case translateEnum.NEXT_BUS_REASON:
                    row[column] = report.showNextBusReason() ? report.next_bus.reason : '';
                    break;
                case translateEnum.STATIC_POSTER_STATUS:
                    row[column] = report.showStaticPosterStatus() ? report.static_poster.statusName : '';
                    break;
                case translateEnum.STATIC_POSTER_REASON:
                    row[column] = report.showStaticPosterReason() ? report.static_poster.reason : '';
                    break;
                case translateEnum.STOP_HEAD:
                case translateEnum.ROUTE_DESC:
                case translateEnum.STOP_HEAD_FLAG_INVALID:
                case translateEnum.INVALID_COUNT_FLAG:
                case translateEnum.WRONG_FLAG:
                    wrongs = 0;
                    invalids = 0;
                    index = 0;
                    for (const flag of report.flag) {
                        if (column === translateEnum.STOP_HEAD && flag.is_head) {
                            row[translateEnum.STOP_HEAD] = flag.cell;
                            break;
                        }
                        if (column === translateEnum.STOP_HEAD_FLAG_INVALID && flag.is_invalid_head) {
                            row[translateEnum.STOP_HEAD_FLAG_INVALID] = flag.route;
                            break;
                        }
                        if (column === translateEnum.ROUTE_DESC) {
                            row[`${translateEnum.ROUTE_DESC} ${++index}`] = flag.cell;
                        }
                        if (column === translateEnum.WRONG_FLAG && !flag.is_head && flag.is_wrong) {
                            row[`${translateEnum.WRONG_FLAG} ${++wrongs}`] = flag.route;
                        }
                        if (column === translateEnum.INVALID_COUNT_FLAG && !flag.is_head && flag.is_invalid) {
                            invalids++;
                        }
                    }
                    if (column === translateEnum.ROUTE_DESC) {
                        maxFlags = Math.max(maxFlags, index);
                    }
                    if (column === translateEnum.WRONG_FLAG) {
                        maxWrongsFlags = Math.max(maxWrongsFlags, wrongs);
                    }
                    if (column === translateEnum.INVALID_COUNT_FLAG) {
                        row[translateEnum.INVALID_COUNT_FLAG] = invalids;
                    }
                    break;
                case translateEnum.BRAIL_DESC:
                case translateEnum.WRONG_BRAIL:
                case translateEnum.WRONG_COUNT_BRAIL:
                    wrongs = 0;
                    index = 0;
                    for (const brail of report.brail) {
                        if (column === translateEnum.BRAIL_DESC) {
                            row[`${translateEnum.BRAIL_DESC} ${++index}`] = brail.cell;
                        }
                        if (brail.is_wrong) {
                            wrongs++;
                            if (column === translateEnum.WRONG_BRAIL) {
                                row[`${translateEnum.WRONG_BRAIL} ${wrongs}`] = brail.route;
                            }
                        }
                    }
                    if (column === translateEnum.BRAIL_DESC) {
                        maxBarils = Math.max(maxBarils, index);
                    }
                    if (column === translateEnum.WRONG_BRAIL) {
                        maxWrongsBarils = Math.max(maxWrongsBarils, wrongs);
                    }
                    if (column === translateEnum.WRONG_COUNT_BRAIL) {
                        row[translateEnum.WRONG_COUNT_BRAIL] = wrongs;
                    }
                    break;
            }
        }
        data.push(row);
    }

    header = concatDynamicHeader(maxFlags, header, translateEnum.ROUTE_DESC);
    header = concatDynamicHeader(maxBarils, header, translateEnum.BRAIL_DESC);
    header = concatDynamicHeader(maxWrongsFlags, header, translateEnum.WRONG_FLAG);
    header = concatDynamicHeader(maxWrongsBarils, header, translateEnum.WRONG_BRAIL);

    return {header, data};
};

const concatDynamicHeader = (max: number, header: string[], title: string): string[] => {
    if (max) {
        const index = header.findIndex(column => column === title);
        const sub_header = [];
        for (let i = 1; i <= max; i++) {
            sub_header.push(`${title} ${i}`);
        }
        return [...header.slice(0, index), ...sub_header, ...header.slice(index + 1)];
    }
    return header;
};
