import {Deserializable} from './deserializable.model';
import {Permissions} from './permissions.model';
import {Role} from './role.model';

export class User implements Deserializable {

    token: string;
    email: string;
    password: string;
    first_name: string;
    last_name: string;
    id: string;
    merchant: string;
    phone: string;
    name: string; // value content for options
    index: number;
    role: Role;
    permissions: Permissions;
    isAdmin: boolean;
    deleted:boolean;

    static phoneWithSeparator(phone: string) {

        if (phone && phone.length > 0) {

            phone = phone.trim();

            const separator = '-';
            const mobilePrefix = '05';
            const phonePrefixes = ['02', '03', '04', '08', '09'];
            let prefix = phone.substr(0, 2);
            let full;
            let position = null;

            if (prefix === mobilePrefix) {
                // mobile
                position = 3;

            } else if (phonePrefixes.includes(prefix)) {
                // phone
                position = 2;

            }

            prefix = phone.substr(0, position);
            if (prefix && prefix.length === position) {

                full = phone.substr(position);

                if (full && full.length > 0 && full[0] !== separator) {
                    phone = prefix + separator + full;
                }
            }
        }

        return phone;
    }

    deserialize(input: any) {
        Object.assign(this, input.user);
        this.token = input.token;
        this.name = (this.first_name + ' ' + this.last_name).trim();
        this.permissions = new Permissions().deserialize(input.user.permissions);
        this.role = new Role().deserialize({id: input.user.role});
        this.isAdmin = this.role.isAdmin();
        return this;
    }

}
