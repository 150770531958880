import {Deserializable} from './deserializable.model';
import {READ_WRITE_PERMISSION, READ_PERMISSION, NO_PERMISSION} from './authority.model';

export class Permission implements Deserializable {

    id: string;
    title: string;
    read: boolean;
    write: boolean;

    deserialize(input: any) {
        this.title = input.title;
        this.setPermission(input.id);
        return this;
    }

    readPermission() {
        return this.id === READ_PERMISSION || this.id === READ_WRITE_PERMISSION;
    }

    writePermission() {
        return this.id === READ_WRITE_PERMISSION;
    }

    setPermission(permission) {
        switch (permission) {
            case READ_WRITE_PERMISSION:
                this.id = READ_WRITE_PERMISSION;
                break;
            case READ_PERMISSION:
                this.id = READ_PERMISSION;
                break;
            case NO_PERMISSION:
                this.id = NO_PERMISSION;
                break;

        }

        this.read = this.readPermission();
        this.write = this.writePermission();
    }

}
