import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { AlertModule } from "ngx-bootstrap/alert";
import { ModalModule } from "ngx-bootstrap/modal";
import { BaseModule } from "../base/base.module";
import { BaseComponent } from "./base.component";
import { StopsFileUploadRoutingModule } from './stops-file-upload-routing.module';
import { StopsFileUploadComponent } from "./stops-file-upload.component";
import { StopsDiffComponent } from "./stops-diff/stops-diff.component";

@NgModule({
  imports: [
    StopsFileUploadRoutingModule,
    ReactiveFormsModule,
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    AlertModule,
    ModalModule,
    BaseModule,
  ],
  declarations: [BaseComponent, StopsFileUploadComponent,StopsDiffComponent],
  exports: [],
})
export class StopsFileUploadModule {}
