import { Component } from '@angular/core';
import { ApiService } from '../../../core/service/api.service';
import { datesCompare } from '../../../global.component';
import { translateEnum } from '../../../shared/enum/translateEnum';
import { Header } from '../../../shared/interfaces/header';
import { LineLog } from '../../../shared/models/line-log.model';
import { BaseModalComponent } from '../../../base-modal.component';

@Component({
    selector: 'lines-logs',
    templateUrl: 'lines-logs.component.html',
    styleUrls: ['./lines-logs.component.css', '../../../app.component.css']
})
export class LinesLogsComponent {

    readonly translateEnum = translateEnum;
    child: any;
    table: LineLog[] = [];

    header: Header[] = [
        {key: 'date', title: translateEnum.DATE},
        {key: 'user', title: translateEnum.USER_NAME},
        {key: 'file', title: translateEnum.FILE_NAME}
    ];

    error: string = null;

    constructor(private api: ApiService) {
        this.child = this;
    }

    open() {
        this.error = null;
        this.api.linesLogs().subscribe(
            async (res: LineLog[]) => {
                this.table = res.sort((a: LineLog, b: LineLog) => datesCompare(b.created_at, a.created_at));
            },
            (err) => {
                console.log({err});
                this.table = [];
                this.error = translateEnum.ERROR_MESSAGE.replace('[message]', err.error.message);
            });
    }

}
