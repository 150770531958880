import {Component, Input} from '@angular/core';
import {Header} from '../../../../shared/interfaces/header';

@Component({
    selector: 'info-table',
    templateUrl: 'info-table.component.html',
    styleUrls: ['../../../../app.component.css']
})

export class InfoTableComponent {

    @Input() public data: any[] = [];
    @Input() public label: string = null;
    @Input() public header: Header[] = [];
    @Input() public key: string = null;
    @Input() public overflow: boolean = true;

    public checkbox: boolean = false;

    constructor() {
        this.onChange = this.onChange.bind(this);
    }

    onChange(check) {
        this.checkbox = check;
    }

}
