import {Report} from "../../../shared/models/report.model";
import {translateEnum} from "../../../shared/enum/translateEnum";
import {User} from "../../../shared/models/user.model";

export const download = (reports: Report[], users: User[]) => {
	const data = [];

	for (const report of reports) {
		data.push({
			[translateEnum.STOP_CODE_STATION]: report.stop.stop_code,
			[translateEnum.STOP_TYPE]: report.stopType,
			[translateEnum.SCHEDULE_PERFORMER]: report.userName(users),
			[translateEnum.FAILURE_TYPE]: report.static_poster.reason,
		});
	}

	let header: string[] = [
		translateEnum.STOP_CODE_STATION,
		translateEnum.STOP_TYPE,
		translateEnum.SCHEDULE_PERFORMER,
		translateEnum.FAILURE_TYPE
	];

	return {header, data};
}
