import {Component, Input} from '@angular/core';
import {FlagBrailStyle} from '../../../../shared/interfaces/flag';

@Component({
    selector: 'flag-brail-dots',
    templateUrl: 'flag-brail-dots.component.html',
    styleUrls: ['flag-brail-dots.component.css', '../../../../app.component.css']
})

export class FlagBrailDotsComponent {

    @Input() letter: string = null;
    @Input() style: FlagBrailStyle;
    display: boolean[][] = [[false, false], [false, false], [false, false]];

    ngOnChanges(changes): void {
        if (changes.letter && changes.letter.currentValue) {
            this.setDisplay(changes.letter.currentValue);
        }
    }

    setDisplay(letter) {
        switch (letter) {
            case '1':
                this.display = [
                    [false, true],
                    [false, false],
                    [false, false]
                ];
                break;
            case '2':
                this.display = [
                    [false, true],
                    [false, true],
                    [false, false]
                ];
                break;
            case '3':
                this.display = [
                    [true, true],
                    [false, false],
                    [false, false]
                ];
                break;
            case '4':
                this.display = [
                    [true, true],
                    [false, true],
                    [false, false]
                ];
                break;
            case '5':
                this.display = [
                    [true, false],
                    [false, true],
                    [false, false]
                ];
                break;
            case '6':
                this.display = [
                    [true, true],
                    [true, false],
                    [false, false]
                ];
                break;
            case '7':
                this.display = [
                    [true, true],
                    [true, true],
                    [false, false]
                ];
                break;
            case '8':
                this.display = [
                    [true, false],
                    [true, true],
                    [false, false]
                ];
                break;
            case '9':
                this.display = [
                    [false, true],
                    [true, false],
                    [false, false]
                ];
                break;
            case '0':
                this.display = [
                    [false, true],
                    [true, true],
                    [false, false]
                ];
                break;
            case 'א':
                this.display = [
                    [false, true],
                    [false, false],
                    [false, false]
                ];
                break;
            case '#':
                this.display = [
                    [false, true],
                    [false, true],
                    [true, true]
                ];
                break;
        }
    }
}
