import { Component, Input } from "@angular/core";
import {
  taskColorStatuses,
  taskStatuses,
} from "../../../../shared/options/task";
import { TASK_STATUSES } from "../../../../shared/enum/task";
import { TaskInput } from "../../../../shared/interfaces/task";

@Component({
  selector: "task",
  templateUrl: "task.component.html",
})
export class TaskComponent {
  @Input() public source: string = "";
  
  readonly TASK_STATUSES = TASK_STATUSES;
  readonly taskStatuses = taskStatuses;
  readonly taskColorStatuses = taskColorStatuses;

  @Input() public task: TaskInput;
  @Input() public show_icon: boolean = true;
}
