import { Component, Input, ViewChild } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";
import { EXPORT_TYPE } from "../../../../shared/enum/export";
import { translateEnum } from "../../../../shared/enum/translateEnum";

@Component({
  selector: "search-header",
  templateUrl: "search-header.component.html",
  styleUrls: ["search-header.component.css", "../../../../app.component.css"],
})
export class SearchHeaderComponent {
  readonly translateEnum = translateEnum;
  readonly EXPORT_TYPE = EXPORT_TYPE;

  @Input() public disabled: boolean = false;
  @Input() public search: Function;
  @Input() public reset: Function;
  @Input() public getFields: Function;
  @Input() public type: EXPORT_TYPE;
  @Input() public onExportOpen: Function = null;
  @Input() public onExportClose: Function = null;
  @Input() public source: string;
  @ViewChild('exportModal') modal: ModalDirective;

  openExport(modal) {
    if (this.onExportOpen) {
      this.onExportOpen();
    }
    modal.show();
  }
  
  closeExport() {
    if(this.onExportClose)
      this.onExportClose();

   this.modal.hide();
  }

  ngOnInit() {
    this.closeExport = this.closeExport.bind(this);
  }
}
