import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { AlertModule } from "ngx-bootstrap/alert";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { BaseModule } from "../base/base.module";
import { AddUserComponent } from "./adduser.component";
import { BaseComponent } from "./base.component";
import { EditUserComponent } from "./edituser.component";
import { UsersRoutingModule } from "./users-routing.module";
import { UsersComponent } from "./users.component";

@NgModule({
  imports: [
    UsersRoutingModule,
    BsDropdownModule,
    BsDatepickerModule.forRoot(),
    CommonModule,
    MatNativeDateModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatDatepickerModule,
    CarouselModule,
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    BaseModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  declarations: [
    BaseComponent,
    UsersComponent,
    AddUserComponent,
    EditUserComponent,
  ],
})
export class UsersModule {}
