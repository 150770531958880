import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, delay, filter, map, takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from '../../../../base.component';
import { ApiService } from '../../../../core/service/api.service';
import { translateEnum } from '../../../../shared/enum/translateEnum';

@Component({
    selector: 'server-side-search',
    templateUrl: './server-side-search.component.html',
    styleUrls: ['./server-side-search.component.css']
})
export class ServerSideSearchComponent extends BaseComponent implements OnInit, OnDestroy {

    @Input() public header: string = '';

    /** api function name to request list from server **/
    @Input() public apiName: string = null;

    /** events sent from OUTER -> into COMPONENT to update item **/
    @Input() updateItemAction: Observable<any>;
    updateItemSubscription: any;

    /** events send from COMPONENT -> to OUTER on selected value **/
    @Output() onSelectEvent = new EventEmitter<any>();

    /** key name string to compare between items in list **/
    @Input() public compareKey: string = null;

    @Input() public error: string = null;

    public filterText: string = '';

    /** control for the selected value for server side filtering */
    public selectedCtrl: FormControl = new FormControl();

    /** control for filter for server side. */
    public filterCtrl: FormControl = new FormControl();

    /** indicate search operation is in progress */
    public searching: boolean = false;

    /** list filtered after server side search */
    public filteredList: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    /** Subject that emits when the component has been destroyed. */
    protected _onDestroy = new Subject<void>();

    translateEnum = translateEnum;

    constructor(private api: ApiService) {
        super();
    }

    selectedValue(selected) {
        if (selected) {
            this.onSelectEvent.emit(selected.value);
        } else {
            this.selectedCtrl.setValue(null);
            this.onSelectEvent.emit(null);
        }
    }

    ngOnChanges(changes): void {
        if (changes.error && changes.error.currentValue) {
            this.selectedCtrl.setErrors({required: changes.error.currentValue});
            this.selectedCtrl.markAsTouched();
        }
    }

    ngOnInit() {
        const self = this;

        if (self.updateItemAction) {
            self.updateItemSubscription = self.updateItemAction.subscribe((item: any) => self.updateItem(item));
        }

        // listen for search field value changes
        self.filterCtrl.valueChanges
            .pipe(
                filter(search => !!search),
                tap(() => this.searching = true),
                takeUntil(this._onDestroy),
                debounceTime(250),
                map(search => {
                    return search;
                }),
                delay(100)
            )
            .subscribe(search => {

                    if (self.apiName) {

                        self.api[self.apiName](search)
                            .subscribe(
                                r => {
                                    self.searching = false;
                                    self.filteredList.next(r);

                                    const selected = self.selectedCtrl.value;
                                    if (selected) {
                                        // find if prev selected item exist in new list
                                        const item = r.find(item => item[this.compareKey] === selected[this.compareKey]);
                                        if (!item) {
                                            self.selectedValue(null);
                                        }
                                    }

                                },
                                r => {
                                    console.log(r);
                                    self.reset(self);
                                    self.selectedValue(null);
                                });

                    }

                },
                error => {
                    console.log(error);
                    self.reset(self);
                    self.selectedValue(null);
                });

    }

    reset(self) {
        self.searching = false;
        self.filteredList.next([]);
    }

    updateItem(newItem: any) {
        const self = this;

        self.selectedCtrl.reset();

        if (newItem) {
            self.api[self.apiName](newItem)
                .subscribe(
                    r => {
                        self.filteredList.next(r);

                        // set selected item from list
                        const item = r.find(item => item[this.compareKey] === newItem);
                        if (item) {
                            self.selectedCtrl.setValue(item);
                        }
                    },
                    r => {
                        console.log(r);
                        self.reset(self);
                    });
        }

    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();

        if (this.updateItemAction && this.updateItemSubscription) {
            this.updateItemSubscription.unsubscribe();
        }
    }

}
