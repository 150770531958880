import {Shift} from '../../../shared/models/shift.model';
import {translateEnum} from '../../../shared/enum/translateEnum';
import {Export} from '../../../shared/models/export.model';

export const download = (shifts: Shift[], exp: Export) => {
    const data = [];
    let row;
    const header: string[] = [...exp.columns];

    for (const shift of shifts) {
        row = {};

        for (const column of header) {
            switch (column) {
                case translateEnum.PROJECT_NAME:
                    row[column] = shift.name;
                    break;
                case translateEnum.ENTER_DATE:
                    row[column] = shift.enter_date;
                    break;
                case translateEnum.ACTION_DATE:
                    row[column] = shift.action_range;
                    break;
                case translateEnum.TOTAL_STOPS_COUNT:
                    row[column] = shift.total_stops_count;
                    break;
                case translateEnum.COMPLETED_STOPS_COUNT:
                    row[column] = shift.completed_stops_count;
                    break;
            }
        }
        data.push(row);
    }

    return {header, data};
};
